import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

// COMPONENTS
import Error from '../../components/Error/Error';

const NoMatch = () => {
  return <Error errorType="404" />
};

NoMatch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default withRouter(NoMatch);
