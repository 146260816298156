import React from 'react';
import Link from '../../components/LinkWithCountry/LinkWithCountry';

// COMPONENTS
import Button from '../../components/Button/Button';

// STYLES
import styles from './VeganGlutenFree.module.css';

// IMGS
import ordernowCookieBoxImg from './imgs/ordernow-cookie-box.png';
import shipCookieBoxImg from './imgs/ship-cookie-box.png';
import endpoints from '../../shared/constants/endpoints';

const VeganGlutenFree = () => {
  const renderBanner = () => {
    return (
      <div className={styles.bannerSection}>
        <div className={styles.container}>
          <h1>
            <span>VEGAN &amp; GLUTEN-FREE</span>
            <span>CHOCOLATE CHIP COOKIE</span>
          </h1>
        </div>
      </div>
    );
  };

  const renderMobileHeadline = () => {
    return (
      <div className={styles.mobileHeadlineSection}>
        <div className={styles.container}>
          <h1>
            <span>VEGAN &amp; GLUTEN-FREE</span>
            <span>CHOCOLATE CHIP COOKIE</span>
          </h1>
        </div>
      </div>
    );
  };

  const renderNowAvailableSection = () => {
    return (
      <div className={styles.nowAvailableSection}>
        <div className={styles.container}>
          <h3>NOW AVAILABLE AT ALL STORES &amp; SHIPPING NATIONWIDE</h3>
        </div>
      </div>
    );
  };

  const renderNutritionalSection = () => {
    return (
      <div className={styles.nutritionalSection}>
        <div className={styles.container}>
          <p>Our vegan, gluten-free cookie is made without animal products or by-products and without any ingredients that contain gluten.</p>
          <a target="_blank" rel="noopener noreferrer" href="https://api.insomniacookies.com/assets/store/v2/img/vegan/vgf-nutritional-facts.pdf">
            <u>Click here</u>
            &nbsp;
            to view Nutritional Information.
          </a>
        </div>
      </div>
    );
  };

  const renderCtaSection = () => {
    return (
      <div className={styles.ctaSection}>
        <div className={styles.container}>
          <div className={styles.containerContent}>
            <div>
              <Link to={endpoints.homePage}>
                <Button
                  customContainerStyles={styles.buttonContainer}
                  label="ORDER NOW"
                />
              </Link>
              <div className={styles.ctaFooter}>Local Delivery &amp; Pickup</div>
            </div>
            <div>
              <Link to={endpoints.shippingPage}>
                <Button
                  customContainerStyles={styles.buttonContainer}
                  label="SHIP COOKIES"
                />
              </Link>
              <div className={styles.ctaFooter}>Nationwide Shipping</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderFaqSection = () => {
    return (
      <div className={styles.faqSection}>
        <div className={styles.container}>
          <h4>FREQUENTLY ASKED QUESTIONS</h4>
          <div className={styles.faqs}>
            <div className={styles.faq}>
              <h5>Which locations have Vegan &amp; Gluten-Free available in-store and for delivery?</h5>
              <p>
                The Vegan &amp; Gluten-Free cookie is available at all our bakeries nationwide, while supplies last.
                &nbsp;
                <Link to={endpoints.homePage}>Order Now!</Link>
              </p>
              <div className={styles.faqOrderNow}>
                <img src={ordernowCookieBoxImg} alt="Order Now" />
                <Link to={endpoints.homePage}>
                  <Button
                    customContainerStyles={styles.buttonContainer}
                    label="ORDER NOW"
                  />
                </Link>
              </div>
            </div>
            <div className={styles.faq}>
              <h5>Do you ship nationwide?</h5>
              <p>
                We do! You can place on order online here. Individually wrapped and sealed for freshness, recipients can enjoy our cookies anywhere in the U.S. Orders will be packaged and picked up within 1-2 days via FedEx. Orders placed on weekends or holidays will be packaged and picked up by FedEx the next business day.
              </p>
              <div className={styles.faqOrderNow}>
                <img src={shipCookieBoxImg} alt="Ship Cookies" />
                <Link to={endpoints.shippingPage}>
                  <Button
                    customContainerStyles={styles.buttonContainer}
                    label="SHIP COOKIES"
                  />
                </Link>
              </div>
            </div>
            <div className={styles.faq}>
              <h5>Is Insomnia Cookies allergy friendly?</h5>
              <p>
                Insomnia Cookies is not an allergy-free bakery. We do bake with products that contain nuts and dairy products. We recommend customers with allergies not consume our products. Our gluten-free products are prepared in an environment where there is a risk of cross-contamination with gluten. Guests with Celiac or gluten sensitivities should know that we will do our best but cannot guarantee that your order will not touch gluten somewhere in the baking and service process.
              </p>
            </div>
            <div className={styles.faq}>
              <h5>Is your Vegan &amp; Gluten-Free Chocolate Chip cookie organic?</h5>
              <p>
                 No, our ingredients are not organic.
              </p>
            </div>
            <div className={styles.faq}>
              <h5>Is your Vegan &amp; Gluten-Free Chocolate Chip cookie non-GMO?</h5>
              <p>
                It is made with Soy and therefore may contain GMO ingredients.
              </p>
            </div>
            <div className={styles.faq}>
              <h5>Does your Vegan &amp; Gluten-Free Chocolate Chip cookie contain soy?</h5>
              <p>
                Yes, our Vegan &amp; Gluten-Free cookie does contain soy and should be avoided if you have a soy allergy or if you avoid soy as part of your diet.
              </p>
            </div>
            <div className={styles.faq}>
              <h5>Is your icing vegan?</h5>
              <p>
                Our icing is only made with plant-based ingredients.
              </p>
            </div>
            <div className={styles.faq}>
              <h5>Can I order a Vegan &amp; Gluten-Free Chocolate Chip Cookie Cake?</h5>
              <p>
                The Vegan Gluten-Free Chocolate Chip cookie is not currently available in a cookie cake.
              </p>
            </div>
            <div className={styles.faq}>
              <h5>Can I order a Big’wich or a Cookie’wich with the Vegan &amp; Gluten-Free Chocolate Chip cookie?</h5>
              <p>
                We can make a Big’wich or a Cookie’wich with the Vegan & Gluten-Free Chocolate Chip cookie. However, whether or not you consume ice cream is up to your individual preference and dietary restrictions. For information on our icing, please see above.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      { renderBanner() }
      { renderMobileHeadline() }
      { renderNowAvailableSection() }
      { renderNutritionalSection() }
      { renderCtaSection() }
      { renderFaqSection() }
    </div>
  );
};

export default VeganGlutenFree;
