import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./LoyaltyReward.module.css";
import { getTimeRemaining } from "../../shared/utilities/dateTime";

function ProductCard({
  id,
  image,
  title,
  description,
  expiredAt,
  link,
  openDeliveryWidget
}) {
  const [time, setTime] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      if (expiredAt) {
        const expiredTime = getTimeRemaining(expiredAt);
        setTime(expiredTime)
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      id={`product_${id}`}
      className={`w-[343px] lg:w-[276px] h-[192px] lg:h-[315px] rounded-[20px] border-4 
      ${
        link
          ? "border-[#FF70A8]"
          : "border-darkDry"
      } pt-6 px-[10px] pb-4 flex flex-col gap-1 items-center
        transition-width transition-slowest ease`}
    >
      {time && (
        <div
          className={`${styles.expiredTime} bg-border w-[200px] h-5 -mt-6 rounded-[4px] flex items-center justify-center
        py-[2px] px-2
        text-sm leading-4 text-white font-filsonProBold font-bold`}
        >
          Ends in 
          {' '}
          {time}
        </div>
      )}
      <div className="flex flex-row lg:flex-col gap-3 items-center">
        <img
          src={image}
          alt={title}
          className={`${link ? "" : styles.lockedProduct} w-36 h-36`}
        />
        <div className="flex flex-col lg:items-center lg:w-full w-[191px] gap-3">
          <div className="flex flex-col lg:items-center h-full lg:h-16 ">
            <p className={`${link ? 'text-white' : 'text-darkElevationPrimary'} 
              text-xl leading-6 tracking-[-0.3px] font-filsonProBold font-bold lg:text-center`}
            >
              {title}
            </p>
            <p className="text-sm leading-4 font-filsonProRegular text-[#B4A5BB]">
              {description}
            </p>
          </div>

          <div className="h-[52px] w-[95px] lg:hidden">
            {link ? (
              <button
                onClick={() => openDeliveryWidget(link, id)}
                type="button"
                className="
          bg-button text-lg leading-[22px] tracking-[-0.1px] 
          font-filsonProBold text-white h-[52px] w-[95px] rounded-[40px]"
              >
                Get It!
              </button>
            ) : (
              <button
                type="button"
                className="
          bg-darkElevationPrimary text-lg leading-[22px] tracking-[-0.1px] 
          font-filsonProBold text-white h-[52px] w-[95px] rounded-[40px]"
              >
                Locked
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="hidden lg:block h-[52px] w-[95px]">
        {link ? (
          <button
            onClick={() => openDeliveryWidget(link, id)}
            type="button"
            className="
    bg-button text-white text-lg leading-[22px] tracking-[-0.1px] 
    font-filsonProBold h-[52px] w-[95px] rounded-[40px]"
          >
            Get It!
          </button>
        ) : (
          <button
            type="button"
            className="
      bg-darkElevationPrimary text-lg leading-[22px] tracking-[-0.1px] 
      font-filsonProBold text-white h-[52px] w-[95px] rounded-[40px]"
          >
            Locked
          </button>
        )}
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  expiredAt: PropTypes.string,
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  openDeliveryWidget: PropTypes.func.isRequired
};

ProductCard.defaultProps = {
  expiredAt: null
};

export default ProductCard;
