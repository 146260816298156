const initialState = {
    isGiftcardDisabled: true
};

export default(state = initialState, { type, value }) => {
    switch (type) {
        case 'SET_IS_GIFTCARD_DISABLED':
            return {...state, isGiftcardDisabled: value};
        default:
            return state;
    }
}
