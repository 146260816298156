/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {Component} from 'react';
import PropTypes, {bool, string} from 'prop-types';

// REDUX
import { connect } from 'react-redux';
import * as elementsActions from '../../../../redux/actions/elements';

import styles from './SendPhone.module.css';
import PhoneInputWidget from '../../../../components/PhoneInputWidget/PhoneInputWidget';
import messages from '../../../../shared/constants/messages';

class SendPhone extends Component {
    constructor(props){
        super(props);

        this.state = {
            telephone: '',
            phoneTelephone: '',
            prefixTelephone: '',
            phoneErorMessage: null,
        }
    }

    checkError(phoneData) {
      if (phoneData.maskValidation || phoneData.phone === '') {
        this.setState({
          phoneErorMessage: null,
        });
      } else {
        this.setState({
          phoneErorMessage: messages.phoneInput.maskValidation.replace('%s', phoneData.mask),
        });
      }
    }

    render() {
        const { telephone, phoneTelephone, prefixTelephone, phoneErorMessage } = this.state;
        const {setModalObject, updateUserData, subscriptionId, enabled, errorMessage} = this.props;
        return (
          <div className={styles.defaultModal}>
            <h3>Send profile phone number</h3>
            <p className={styles.error}>{errorMessage}</p>
            <p className={styles.error}>{phoneErorMessage}</p>
            <PhoneInputWidget
              customInputStyles={styles.inputStyles}
              customSelectStyles={styles.selectStyles}
              onInput={e => this.setState({
                telephone: e.telephone,
                phoneTelephone: e.phone,
                prefixTelephone: e.code,
              })}
              handleOnBlur={e => this.checkError(e)}
              label='Mobile Number'
              placeholder=" "
              telephone={telephone}
              phone={phoneTelephone}
              code={prefixTelephone}
              isNumberFormat
            />
            <div>
              <button onClick={() => {setModalObject(null)}} type='button'>Cancel</button>
              <button disabled={phoneErorMessage} onClick={() => { updateUserData(telephone, phoneTelephone, prefixTelephone, subscriptionId, enabled)}} type='button'>Send</button>
            </div>
          </div>
        )
    };
}

SendPhone.propTypes = {
  setModalObject: PropTypes.func,
  updateUserData: PropTypes.func,
  subscriptionId: string,
  enabled: bool,
  errorMessage: string,
};

SendPhone.defaultProps = {
  setModalObject: null,
  errorMessage: null,
  updateUserData: () => {},
  subscriptionId: null,
  enabled: null,
};

export const mapDispatchToProps = dispatch => ({
  setModalObject: value => dispatch(elementsActions.setModalObject(value)),
});

export default connect(null, mapDispatchToProps)(SendPhone);
