import _ from 'underscore';
import {
  PaymentInputCreditCardLegacy,
  PaymentInputGiftCard,
  PaymentInputSchoolCash,
  PaymentInputCash,
  PaymentInputCreditCardSavedList
} from './PaymentInputs';

export const paymentMethodIds = { giftCard: 2 };

export const keyDownA11y = handler => {
  return event => {
    if (['keydown', 'keypress'].includes(event.type) && ['Enter', ' '].includes(event.key)) {
      handler(event);
    }
  };
};

export const uiComponentTypes = {
  detailView: 'DETAIL_VIEW',
  button: 'BUTTON'
};

const paymentMethodIdSavedPaymentMethod = -1;

export const paymentMethodComponentMaps = {
  1: {
    paymentMethodId: 1,
    componentPaymentInput: PaymentInputCreditCardLegacy
  },
  2: {
    paymentMethodId: 2,
    componentPaymentInput: PaymentInputGiftCard
  },
  3: {
    paymentMethodId: 3,
    componentPaymentInput: PaymentInputSchoolCash
  },
  4: {
    paymentMethodId: 4,
    componentPaymentInput: PaymentInputCash
  },
  [paymentMethodIdSavedPaymentMethod]: {
    paymentMethodId: paymentMethodIdSavedPaymentMethod,
    componentPaymentInput: PaymentInputCreditCardSavedList
  }
};

export const getPaymentMethodGroups = storePaymentMethods => {
  const spms = storePaymentMethods.filter(x => !x.disabled);
  const urlImgBase =
    window.environment?.REACT_APP_GRAPHQL_URI?.toLowerCase().replace('/graphql', '') || '';
  const newStorePaymentMethods = spms.map(spm => {
    const toReturn = {
      ...spm,
      componentMap: paymentMethodComponentMaps[spm.paymentMethodId]
    };
    toReturn.iconUrlPath = toReturn.iconUrlPath ? `${urlImgBase}/${toReturn.iconUrlPath}` : '';
    toReturn.buttonUrlPath = toReturn.buttonUrlPath ? `${urlImgBase}/${toReturn.buttonUrlPath}` : '';
    return toReturn;
  });

  const groupsByUiGroup = _.groupBy(newStorePaymentMethods, spm => spm.groupTitle);

  const paymentMethodGroups = Object.entries(groupsByUiGroup).map(([groupTitle, spms2]) => ({
    groupTitle,
    storePaymentMethods: _.sortBy(spms2, pm => pm.theOrder || Number.MAX_VALUE)
  }));
  return paymentMethodGroups;
};
