export const isMainPage = (location, currentCountryPathUrl) => {
    if (location === '/' || location === `/${currentCountryPathUrl}` || location === `/${currentCountryPathUrl}/`) {
        return true;
    }

    return false
}

export const isHasCountryPathInLocation = (location, currentCountryPathUrl) => {
    let regexp = new RegExp(`^[/]${currentCountryPathUrl}[/]`);
    if (isMainPage(location, currentCountryPathUrl)) {
        regexp = new RegExp(`^[/]${currentCountryPathUrl}[/]?$`);
    }

    return regexp.test(location);
}

export const removeCountryPathFromLocation = (location) => {
    if (/^[/][a-z]{2}([/]|$)/g.test(location)) {
        return location.replace(/^[/][a-z]{2}/g, '');
    }
    return location;
}

export const getLocationWithoutCountryPath = (location, currentCountryPathUrl) => {
    if (isMainPage(location, currentCountryPathUrl) && currentCountryPathUrl) {
        return '/';
    } if (!isMainPage(location, currentCountryPathUrl) && currentCountryPathUrl) {
        const regexp = new RegExp(`^[/]${  currentCountryPathUrl  }[/]`);
        return isHasCountryPathInLocation(location, currentCountryPathUrl) ? location.replace(regexp, '/') : location;
    }

    return location;
}

export const addCountryPathToLocation = (location, currentCountryPathUrl, isCountrySelectActive) => {
    if (currentCountryPathUrl && isCountrySelectActive) {
        return isMainPage(location, currentCountryPathUrl) ? `/${currentCountryPathUrl}` : `/${currentCountryPathUrl}${location}`;
    }
    return location;
}