/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./DropoffImageWidget.module.css";
import { deliveryStatuses, timeDefaults } from "../../shared/constants/order";
import moment from "moment";

class DropoffImageWidget extends Component {

  renderDropoffImage = (deliveryImageUrl) => {
    const { className } = this.props;
    return (
      <div className={[styles.dropoffImageContainer, className].join(' ')}>
        <div className={styles.dropoffImageTitle}>Dropoff Location</div>
        <div className={styles.dropoffImage}>
          <img src={deliveryImageUrl} alt="Dropoff location" />
        </div>
      </div>
    );
  }

  isDropoffImageShouldBeRendered = () => {
    const { order } = this.props;
    const orderTime = order.deliveryTime === timeDefaults.asap
      ? order.invoiceDate
      : order.deliveryTime;
    const isOrderExpired = moment(orderTime).add(48, 'hours').isBefore(moment());
    return order?.deliveryStatus?.deliveryImageUrl
      && order?.status?.status >= deliveryStatuses.DRIVER_RETURNED
      && !isOrderExpired;
  }

  render() {
    const { order } = this.props;
    return this.isDropoffImageShouldBeRendered()
      ? this.renderDropoffImage(order.deliveryStatus.deliveryImageUrl)
      : null;
  }

}

DropoffImageWidget.propTypes = {
  order: PropTypes.object.isRequired,
  className: PropTypes.string,
};

DropoffImageWidget.defaultProps = {
  className: '',
};
  
export default DropoffImageWidget;
