/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Loader from '../Loader/Loader';

// STYLES
import styles from './Button.module.css';

const Button = ({ handleSubmit, label, customContainerStyles, disabled, id, theme, isLoading, hidden }) => (
  <div
    id={id}
    onClick={disabled ? () => {} : handleSubmit}
    hidden={hidden}
    className={[
      "bg-dark dark:bg-button text-white px-4 flex items-center justify-center",
      styles.buttonContainer,
      theme === 'Dark' ? styles.darkTheme : styles.lightTheme,
      disabled && theme === 'Dark' ? styles.isDisabledDark : null,
      disabled && theme === 'Light' ? styles.isDisabledLight : null,
      customContainerStyles
    ].join(' ')}
  >
    {isLoading ? <Loader height={30} /> : <p>{label}</p>}
  </div>
)

Button.propTypes = {
  handleSubmit: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  customContainerStyles: PropTypes.any, //eslint-disable-line
  disabled: PropTypes.bool,
  id: PropTypes.string,
  theme: PropTypes.string,
  isLoading: PropTypes.bool,
  hidden: PropTypes.bool
}

Button.defaultProps = {
  disabled: false,
  id: null,
  theme: 'Dark',
  isLoading: false,
  handleSubmit: null,
  hidden: false
}

export default Button;
