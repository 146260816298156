/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'underscore';

// COMPONENTS
import WidgetItem from '../WidgetItem/WidgetItem';

import styles from './OrderHistoryWidget.module.css';
import {returnLineCost} from "../../pages/ReviewOrder/rendering";
import {getOrderTotals} from "../../shared/utilities/renderings";
import { defaultCurrency } from '../../shared/constants/currency';
import { routeCountryPath } from '../../shared/utilities/common';

const coinIcon = require('./imgs/coin-icon.png');

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexShown: 1 // index starts at 0
    };
  }

  returnOrderMethod = (order) => {
    if (order.isCancelled) {
      return 'Cancelled';
    }

    if(order.isRefunded) {
      return 'Refunded';
    }

    switch (true) {
      case order.orderTypeId === 2:
        return 'Delivered';
      case order.orderTypeId === 3:
        return 'Picked Up';
      case order.orderTypeId === 6:
        return 'Shipped';
      default:
        return '';
    }
  };

  itemCount = () => {
    const { order } = this.props;

    const labelByCount = {
         1: 'Item'
    };

    const orderQuantities = _.pluck(order.items, 'quantity');
    const itemCount = orderQuantities.length ? orderQuantities.reduce((a, b) => a + b) : 0;

    return `${itemCount} ${  labelByCount[itemCount] || 'Items'}`;
  }

  /**
   * Handles state logic to show all products
   * not initially rendered
   */
    showAllItems = () => {

    const { order } = this.props;
    const { indexShown } = this.state;
    const itemsLeftToRender = order.items.length - 1 - indexShown;

    const fullItemsIndex = itemsLeftToRender + indexShown;

    this.setState({ indexShown: fullItemsIndex});
  };


  render() {
    const { order, currency } = this.props;
    const { indexShown } = this.state;

    const itemsLeftToRender = order.items.length - 1 - indexShown;

    const total = order.totals.find(item => {
         return item.title.toLowerCase() === 'total';
    });

    const isToday = (someDate) => {
      const today = new Date();
      return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear();
    }

    let deliveryDate = order.deliveryTime === '0000-00-00 00:00:00' ? order.invoiceDate : order.deliveryTime;
    deliveryDate = isToday(new Date(deliveryDate)) ? 'Today' : moment(deliveryDate).format('MMM D');
    const itemCount = this.itemCount();

    return (
      <div
        className={`
        ${styles.orderHistoryWrapper}
        bg-white dark:bg-dark px-1 py-2 border-[1px] border-lightDry dark:border-darkBorder
        text-dark dark:text-white
        rounded-[20px]
        `}
        key={order.id}
      >
        <div className={styles.orderHistoryContainer}>
          {/* COMPONENT HEADER */}
          <div className={`${styles.orderHistoryHeader}`}>
            <div>
              <h3>{itemCount}</h3>
              <p>
                {`${this.returnOrderMethod(order)} ${deliveryDate}`}
              </p>
            </div>

            <div className="totalsContainer hidden" style={{minWidth:'150px'}}>
              {order.totals.filter(orderTotal => {
                const isTotal = orderTotal.title.toLowerCase() === 'total';
                const isTip = orderTotal.title.toLowerCase() === 'tip';
                const hasValue = orderTotal.value > 0;
                const isCoupons = orderTotal.title.toLowerCase() === 'coupons';

                return !isTotal && !isTip && !isCoupons && hasValue;
              }).map(subTotal => {
                return returnLineCost(subTotal.title, subTotal.value, false, 'store' in order ? order.store.currency : currency);
              })}
              <div id="total" className={styles.totalsSection}>
                {returnLineCost('Total', order.totals.find(item => item.title.toLowerCase() === 'total').value, false, 'store' in order ? order.store.currency : currency)}
              </div>
            </div>
          </div>

          {/* COMPONENT LIST ITEMS */}
          <div className={styles.orderHistoryItemsContainer}>
            {order.items.map((item, index) => {
              if (!item.product) {
                  return false;
              }
              if (index <= indexShown) {
                const price = parseFloat(getOrderTotals(item)).toFixed(2);
                return (
                  <WidgetItem
                    key={item.title + index}
                    amount={item.quantity}
                    title={item.product.title}
                    type={
                      item &&
                      item.product &&
                      Array.isArray(item.product.categories) && item.product.categories.length
                          ? item.product.categories[0].title
                        : ''
                    } // eslint-disable-line
                    price={price}
                    currency={'store' in order ? order.store.currency : currency}
                  />
                );
              }
              return null;
            })}
            {indexShown <= 2 && order.items.length > 2 ? (
              <p onClick={() => { this.showAllItems() }}>
                {`+ ${itemsLeftToRender} more items`}
              </p>
            ) : null}
          </div>

          {/* COMPONENT FOOTER */}
          <div className={styles.orderHistoryFooter}>
            <div>
              <h3>{`${'store' in order ? order.store.currency.symbol : currency.symbol}${total.value.toFixed(2)}`}</h3>
              {order.loyaltyPoints > 0 && (
              <p className='flex gap-1 items-center justify-center'>
                <span>
                  <img alt="coin-icon" src={coinIcon} />
                </span>
                { `Earned ${order.loyaltyPoints} points`}
              </p>
)}
            </div>
            <a href={routeCountryPath(`/user/order-details/${order.code}`)}>View order details</a>
          </div>
        </div>
      </div>
    );
  }
}

OrderHistory.propTypes = { order: PropTypes.object.isRequired, currency: PropTypes.objectOf(PropTypes.string) };
OrderHistory.defaultProps = { currency: defaultCurrency};

export default OrderHistory;
