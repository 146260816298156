import React from 'react';
import { propTypesComponentPaymentInput } from '../../shared.propTypes';

const PaymentInputCash = () => (
  <div>
    <p>
      This is a placeholder for cash payment method. On website, cash is a uiComponentType=BUTTON
      and isEmbeddedHtml=false. So PaymentInputs is responsible for rendering the button. If you see
      this message, most likely the database is configured to have uiComponentType=DETAIL_VIEW or
      something else and the website has not implemented this component yet.
    </p>
  </div>
);

PaymentInputCash.propTypes = propTypesComponentPaymentInput;

export default PaymentInputCash;
