import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as userActions from "../../../redux/actions/user";
import * as userRepo from "../../../shared/repos/graphql/user";
import * as tagmanagerEvents from "../../../shared/utilities/tagmanagerEvents";


// COMPONENTS
import Button from "../../../components/Button/Button";
import OtpInput from "./OtpInput";
import LoginWithPhone from "./LoginWithPhone";
import ResendOtp from "../../../components/ResendOtp/ResendOtp";
import styles from "./Login.module.css";
import { loginType } from "../../../shared/constants/login";


// ICONS
const backIcon = require("./imgs/back.svg");

class LoginAfterOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      continueWithOtp: false,
      phoneNumber: "",
      token: null,
      otp: "",
      user: null,
      password: null,
      phoneOTPErrMessage: null,
    };
  }

  handleLoginWithPhone = (token, user, phoneNumber) => {
    this.setState({
      continueWithOtp: true,
      phoneNumber,
      user,
    });
  };

  loginWithMobile = () => {
    const { order } = this.props;
    const headerText = "The phone number used for this order is associated with an existing account.";
    const subHeaderText = "To earn points for this order, simply verify your phone number by sending a code to the phone listed below.";
    return (
      <LoginWithPhone
        handleLogin={this.handleLoginWithPhone}
        orderCompleted
        telephone={order?.phone}
        headerText={headerText}
        subHeaderText={subHeaderText}
        disabled
      />
    );
  };

  onCompleteOtp = otp => {
    this.setState({ otp });
  };

  loginWithOtp = async () => {
    const { otp, phoneNumber, user, password } = this.state;
    const { setUserToken, order, redirect } = this.props;

    try {
      this.setState({ loading: true });

      const result = await userRepo.confirmCustomerTelephoneOtp(
        otp,
        phoneNumber?.code,
        phoneNumber?.phone,
        user?.email,
        password,
        order.code
      );

      const { data } = result;

      if (data?.confirmCustomerTelephoneOtp?.telephoneVerified) {
        const updatedUser = {
          ...user,
          telephoneVerified: data?.confirmCustomerTelephoneOtp?.telephoneVerified,
        };

        this.setState({
          token: data?.confirmCustomerTelephoneOtp?.token,
          user: updatedUser,
          password,
        });

        await setUserToken(data?.confirmCustomerTelephoneOtp?.token);
        tagmanagerEvents.signIn(loginType.phone, updatedUser);
        redirect();
      }

      this.setState({ loading: false, continueWithOtp: false });
    } catch (e) {
      this.setState({
        phoneOTPErrMessage: e?.message || "Something went wrong! Please try again.",
        loading: false,
      });
    }
  };

  handleResendOtp = async () => {
    const { phoneNumber } = this.state;
    await userRepo.resendCustomerVerificationTelephoneOtp(phoneNumber?.code, phoneNumber?.phone);
  };

  continueWithOtp = () => {
    const { phoneNumber, otp, loading, phoneOTPErrMessage } = this.state;
    const { redirect } = this.props;
    const isDisabled = otp.length < 4;

    return (
      <div className="flex gap-3 flex-col w-full">
        <OtpInput
          onComplete={this.onCompleteOtp}
          length={4}
          classInput="bg-[#2C0C37] border-2 border-darkDry text-center text-4xl"
          label={
            phoneNumber?.phone
              ? `Enter the 4-digit code sent to you at ${phoneNumber.code} ${phoneNumber.phoneFormat}`
              : null
          }
        />

        {phoneOTPErrMessage && <p className={styles.hasError}>{phoneOTPErrMessage}</p>}

        <ResendOtp handleClick={this.handleResendOtp} />

        <div className="w-full mt-4">
          <Button
            isLoading={loading}
            disabled={isDisabled}
            customContainerStyles={`${styles.buttonContainer} ${isDisabled ? "!bg-disabled" : ""} !block w-full bg-button text-white text-center py-2 h-11`}
            handleSubmit={!isDisabled ? this.loginWithOtp : null}
            label="Continue"
          />
          <Button
            customContainerStyles={`${styles.buttonContainer} !bg-disabled !block w-full bg-button text-white text-center py-2 h-11 mt-4`}
            handleSubmit={redirect}
            label="Skip to Order Confirmation"
          />
        </div>
      </div>
    );
  };

  loginAfterOrderContainer = () => {
    const { continueWithOtp, token } = this.state;
    return (
      <div className="w-full max-w-screen-desktop mx-auto">
        <div className="border-2 border-darkDry rounded-full w-11 h-11 hidden">
          <img src={backIcon} alt="back" />
        </div>
        <div className={`${styles.pageWrapper} flex justify-center items-center text-white w-full`}>
          {((token !== '' && token === null) || continueWithOtp) && (
            <div className={`${styles.pageContainer} w-[343px]`}>
              {!continueWithOtp ? this.loginWithMobile() : this.continueWithOtp()}
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return this.loginAfterOrderContainer();
  }
}

LoginAfterOrder.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  order: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  setUserToken: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  setUserToken: value => dispatch(userActions.setUserToken(value)),
});

export default connect(null, mapDispatchToProps)(LoginAfterOrder);
