/* eslint-disable react/jsx-no-target-blank */
import React, {Component} from 'react';
import styles from './Accessibility.module.css'
import {reInitAudioEye} from "../../shared/utilities/audioEye";

class Accessibility extends Component{
    
    componentDidMount(){
        reInitAudioEye()
    }

    render(){
        return (
          <div className={styles.pageWrapper}>
            <div className={`${styles.pageContainer} text-dark dark:text-white`}>
              <div className={styles.pageSection}>
                <h1>Accessibility Statement</h1>

                <p>
                            This Accessibility Statement applies to:
                  {' '}
                  <a href="https://insomniacookies.com">https://insomniacookies.com</a>
                </p>
              </div>

              <div className={styles.pageSection}>
                <h3>We Value Digital Inclusion</h3>

                <p>
In our effort to provide a fully accessible and optimized user experience for all site
                            visitors, has taken careful measure to ensure an excellent user experience, regardless of
                            the assistive technology being used to access this site or the specific abilities of those
                            individuals seeking access to this site.
                </p>
              </div>

              <div className={styles.pageSection}>
                <p>
The Insomnia Cookies, LLC website is monitored and tested regularly by internal resources and
                            by AudioEye, a third-party provider of Web Accessibility testing and monitoring. As issues
                            of accessibility are identified, results of automated and manual testing are managed through
                            the AudioEye® Digital Accessibility Platform. As new solutions are discovered to improve the
                            user experience, remediation is tracked through the AudioEye system and fixes are
                            implemented to improve the website user experience.
                </p>
              </div>

              <div className={styles.pageSection}>
                <h3>AudioEye Accessibility Certification</h3>

                <p>
The AudioEye Certification seal represents a commitment to accessibility and digital
                            inclusion. The AudioEye certification process involves automatic and manual testing with the
                            goal of conforming with Web Content Accessibility Guidelines (WCAG) 2.0 Level AA Success
                            Criteria.
                </p>
              </div>

              <div className={styles.pageSection}>
                <p>
AudioEye certifies that the http://cookies.test  website has been designed and optimized to
                            conform with WCAG 2.0 Level AA Success Criteria to the greatest extent possible.
                </p>
              </div>

              <div className={styles.pageSection}>
                <p>
AudioEye and Insomnia Cookies, LLC continue to collaborate in an ongoing effort to maintain
                            conformance and provide an accessible user experience for all users.
                </p>
              </div>

              <div className={styles.pageSection}>
                <p>Last updated June 25, 2018</p>
                <p>Notice something wrong? Please provide your feedback</p>
              </div>

              <div className={styles.pageSection}>
                <p>
This website is regularly tested using a variety of assistive technologies. We recommend
                            using the following web browser / screen reader combinations for an optimized
                            experience:
                </p>
                <br />
                <p>
                  <strong>For Windows users:</strong>
                  {' '}
                            JAWS and Internet Explorer or NVDA and Firefox
                </p>
                <p>
                  <strong>For Mac users:</strong>
                  {' '}
                            VoiceOver and Safari or VoiceOver and Chrome
                </p>
                <p>
                  <strong>For mobile users:</strong>
                  {' '}
                            VoiceOver for the iPhone and TalkBack for Android devices
                </p>
              </div>

              <div className={styles.pageSection}>
                <h3>Improving the User Experience for Users of Assistive Technologies</h3>
              </div>

              <div className={styles.pageSection}>
                <h3>Web Accessibility Guidelines</h3>

                <p>
Insomnia Cookies, LLC has leveraged the Web Content Accessibility Guidelines (WCAG) 2.0 as
                            reference to ensure the web content made available from this site is more accessible for
                            individuals with disabilities and user friendly for everyone.
                </p>
              </div>

              <div className={styles.pageSection}>
                <p>
These globally recognized best practices (as recommended by the World Wide Web Consortium)
                            consist of three levels of accessibility measurement (A, AA, and AAA). To the greatest
                            extent feasible, Insomnia Cookies, LLC has elected to conform to Level AA of these
                            guidelines.
                </p>
              </div>

              <div className={styles.pageSection}>
                <p><strong>Related Links</strong></p>
                <p><a href="https://www.w3.org/WAI" target="_blank">Web Accessibility Initiative (WAI)</a></p>
                <p>Design guidelines for electronic and information technology</p>
              </div>

              <div className={styles.pageSection}>
                <h3>Feedback</h3>

                <p>
Dedicated web accessibility experts are available to assist site visitors with any issues
                            accessing content and the various services and functions made available across the website.
                            Site visitors may choose to engage with the Help Desk to report and troubleshoot web
                            accessibility related issues.
                </p>
              </div>

              <div className={styles.pageSection}>
                <p>
                            If you encounter issues with any page on our site that presents a challenge for individuals
                            with disabilities, please submit your
                  {' '}
                  <a data-ae-client-feedback-link="true" className="ae-feedback links" href="accessibility"> feedback</a>
                            .
                </p>
              </div>

              <div className={styles.pageSection}>
                <h3>Third-Party Sites</h3>

                <p>
Throughout this website, we make use of different third-party websites such as Instagram,
                            YouTube, Twitter, and Facebook to spread news and information about Insomnia Cookies, LLC
                            products and services. These sites, which are not controlled by Insomnia Cookies, LLC, may
                            present challenges for individuals with disabilities that we are not able to control or
                            remedy.
                </p>
              </div>

              <div className={styles.pageSection}>
                <p>
As made publicly available, here are the Accessibility Policies provided from these
                            third-party sites:
                </p>
                <br />
                <p>
                  <a href="https://www.facebook.com/help/141636465971794" target="_blank">
Facebook
                            Accessibility Policy
                  </a>
                </p>
                <p>
                  <a href="https://support.google.com/youtube/answer/189278?hl=en" target="_blank">
YouTube
                            Accessibility Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        );
    };
}

export default Accessibility;
