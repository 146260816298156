import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as orderActions from "../../redux/actions/order";
import { routeCountryPath } from "../../shared/utilities/common";

import LoginAfterOrder from "../PasswordLessAuth/Login/LoginAfterOrder";
import CreateAccountAfterOrder from "../PasswordLessAuth/CreateAccount/CreateAccountAfterOrder";
import endpoints from "../../shared/constants/endpoints";
import * as orderRepo from '../../shared/repos/graphql/order';

class CheckOutLoyalty extends Component {
    constructor(props) {
        super(props);
        const { id } = props.match.params;
        this.state = {
            order: null,
            isSignIn: false,
            isSignUp: false,
        };
        this.getConfirmedOrder(id); // Fetch order based on orderCode
    }

    getConfirmedOrder = async (orderCode) => {
        try {
            const { orderUpdated } = this.props;
            const response = await orderRepo.getConfirmedOrder(orderCode);
            const { order } = response.data;

            if (order) {
                this.setState({
                    order,
                    isSignIn: order?.customerAccountStatus?.signIn,
                    isSignUp: order?.customerAccountStatus?.signUp,
                });
                orderUpdated(order);
                await this.loginGuard();
            }
        } catch (error) {
            console.error("Error fetching confirmed order:", error);
        }
    };

    loginGuard = () => {
        const { userToken } = this.props;
        const { isSignIn, isSignUp, order } = this.state;
        const shouldRedirect = userToken || order?.customer?.id || !(isSignIn || isSignUp);

        if (shouldRedirect) {
            this.redirectToConfirmation();
        }
    };

    redirectToConfirmation = () => {
        const { history } = this.props;
        const { order } = this.state;

        if (order?.code) {
            const historyUrl = endpoints.checkoutConfirmationPage.replace(":id", order.code);
            history.push(routeCountryPath(historyUrl));
        }
    };

    checkOutLoyaltyContainer = () => {
        const { isSignIn, isSignUp, order } = this.state;

        if (isSignIn) {
            return <LoginAfterOrder order={order} redirect={this.redirectToConfirmation} />;
        }

        if (isSignUp) {
            return <CreateAccountAfterOrder order={order} redirect={this.redirectToConfirmation} />;
        }

        return null;
    };

    render() {
        return this.checkOutLoyaltyContainer();
    }
}

CheckOutLoyalty.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    userToken: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    orderUpdated: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
    const { userToken } = state.user;
    return { userToken };
};

export const mapDispatchToProps = (dispatch) => ({
    orderUpdated: (order) => dispatch(orderActions.orderUpdated(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutLoyalty);
