import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input/Input';
import { handleFormInput } from '../../shared/utilities/validations';
import styles from '../../pages/ReviewOrder/ReviewOrder.module.css';

const SecondaryInput = props => {
    const { component, label, state, isEdit, customClass, inputMask, maskChar, isNumberFormat, isAllowed, handleOnBlur} = props;

    const value = component.state[state];
    const isRequired= state !== 'streetAddress2' && !isEdit;
    const customContainerStyles = [styles.secondaryInputWrapper, customClass].join( ' ');
    const errorMessage = component.state[`${state}ErrorMessage`];

    return (
      <Input
        handleInput={e => handleFormInput(e, component, state)}
        handleOnBlur={handleOnBlur}
        value={value}
        errorMessage={errorMessage}
        customContainerStyles={`${customContainerStyles}`}
        theme={!isEdit ? 'Dark' : 'Light'}
        label={label}
        isRequired={isRequired}
        placeholder=" "
        inputMask={inputMask}
        maskChar={maskChar}
        isNumberFormat={isNumberFormat}
        isAllowed={isAllowed}
      />
    );
};

SecondaryInput.propTypes = {
    component: PropTypes.shape({
        state: PropTypes.shape()
    }).isRequired,
    label: PropTypes.string.isRequired,
    handleOnBlur: PropTypes.func,
    state: PropTypes.string.isRequired,
    isEdit: PropTypes.bool.isRequired,
    customClass: PropTypes.string,
    inputMask: PropTypes.oneOfType(
        [
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.instanceOf(RegExp))
        ]
    ),
    maskChar: PropTypes.string,
    isNumberFormat: PropTypes.bool,
    isAllowed: PropTypes.func
}

SecondaryInput.defaultProps = {
    customClass: null,
    inputMask: null,
    maskChar: "",
    isNumberFormat: false,
    isAllowed: null,
    handleOnBlur: () => {}
}

export default SecondaryInput;
