import {TOGGLE_SUBSCRIPTION, CUSTOMER_SUBSCRIPTIONS, CUSTOMER_SUBSCRIPTIONS_BY_TYPE} from "../../../graphql/subscription";
import { client } from "../../utilities/config";

/**
 * Toggle Opt-In/Out to subscription groups.
 *
 * @param {string} subscriptionId
 * @param {Boolean} enabled
 */
export const toggleSubscription = async (subscriptionId, enabled) => {
    return client.mutate({
        mutation: TOGGLE_SUBSCRIPTION,
        variables: {
            subscriptionId,
            enabled
        }
    });
};

/**
 * Get customer subscriptions.
 */
export const getCustomerSubscriptions = async () => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: CUSTOMER_SUBSCRIPTIONS,
        variables: {}
    });
};

export const getCustomerSubscriptionsByType = async (subscriptionTypeIds) => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: CUSTOMER_SUBSCRIPTIONS_BY_TYPE,
        variables: {
            subscriptionTypeIds
          }
    });
};
