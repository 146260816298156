import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import styles from "./Instructions.module.css";
import {
  getContentsByKey,
  getContentValueByKey
} from "../../shared/utilities/common";

function Instructions({ contents }) {
  const heading = getContentValueByKey(contents, "heading");
  const description = getContentValueByKey(contents, "description");
  const steps = getContentsByKey(contents, "steps");
  return (
    <div className="w-full flex flex-col justify-center gap-8 px-4">
      <div className="flex flex-col md:items-center justify-center gap-3 h-[88px]">
        <h1 className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-dark">
          {heading}
        </h1>
        <p className="text-darkElevationPrimary">{description}</p>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center justify-between gap-4">
        {(steps && steps.length) > 0 &&
          steps.map((content, i) => {
            const stepHeading = getContentValueByKey(
              content?.children,
              "heading"
            );
            const stepDescription = getContentValueByKey(
              content?.children,
              "description"
            );
            return (
              <div className="flex md:items-center md:justify-center flex-row md:flex-col gap-4 max-w-[362px]">
                <p
                  className="h-[43px] w-[43px] bg-dark text-white rounded-full flex items-center justify-center
              text-xl font-congenialBlack
            "
                >
                  {i + 1}
                </p>
                <div className="md:w-full w-[80%] flex flex-col md:items-center md:justify-center gap-2">
                  <h1 className="font-filsonProBold text-2xl leading-6 tracking-[-0.3px] text-dark">
                    {stepHeading}
                  </h1>

                  <p
                    className={`${styles.instruction_desc} 
                    md:text-center text-lg font-filsonProRegular leading-[22px] tracking-[-0.1px] text-darkElevationPrimary`}
                  >
                    <ReactMarkdown>{stepDescription}</ReactMarkdown>
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

Instructions.propTypes = {
  contents: PropTypes.shape({}).isRequired
};

export default Instructions;
