import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ViewOrder from '../../pages/ViewOrder/ViewOrder';
import ReviewOrder from '../../pages/ReviewOrder/ReviewOrder';
import ConfirmOrder from '../../pages/ConfirmOrder/ConfirmOrder';
import Product from '../../pages/Product/Product';
import OrderTracker from '../../pages/OrderTracker/OrderTracker';
import OrderTrackStep from '../../pages/OrderTrackerStep/OrderTrackerStep';
import endpoints from "../../shared/constants/endpoints";
import DoughProGiftCardPurchase from "../../pages/DoughProGiftCardPurchase/DoughProGiftCardPurchase";
import CheckOutLoyalty from "../../pages/CheckOutLoyalty/CheckOutLoyalty";

const CheckoutRoutes = () => {
  return (
    <Switch>
      <Route path="/checkout/view-order/products/single/:id/:poid" component={Product} />
      <Route path="/checkout/view-order/products/customize/:id/:poid" component={Product} />
      <Route path="/checkout/view-order/products/box/:id/:poid" component={Product} />
      <Route path={endpoints.cookieMagicGiftCardProductUpdate} component={DoughProGiftCardPurchase} />
      <Route path="/checkout/review-order/:id" component={ReviewOrder} />
      <Route path="/checkout/gift/:id" component={ConfirmOrder} />
      <Route path="/checkout/confirmation/:id" component={ConfirmOrder} />

      <Route path="/checkout/order-tracker/:id" component={OrderTracker} />
      <Route path="/checkout/order-tracker-step/:id" component={OrderTrackStep} />

      <Route path="/checkout/view-order/:id" component={ViewOrder} />
      <Route path="/checkout/view-order" component={ViewOrder} />
      <Route path="/checkout/loyalty-rewards/:id" component={CheckOutLoyalty} />
    </Switch>
  );
};

export default CheckoutRoutes;
