import React, { useState } from "react";
import PropTypes from "prop-types";

const Tabs = ({ children, active }) => {
  const [activeTab, setActiveTab] = useState(active || children[0].props.label);

  const handleClick = (e, newActiveTab) => {
    e.preventDefault();
    setActiveTab(newActiveTab);
  };

  return (
    <div className="max-w-md mx-auto">
      <div className="flex border-b border-darkDry">
        {children.map((child, index) => (
          <div
            role="button"
            tabIndex={index}
            key={child.props.label}
            className={`${
              activeTab === child.props.label
                ? "border-b-2 border-white rounded-none"
                : ""
            } 
            ${child.props.className}
            whitespace-nowrap inline
            flex-1 font-medium py-2 bg-transparent text-[28px] tracking-[-0.2px] text-center font-congenialBlack`}
            onClick={e => {
              if (child.props.onClick) child.props.onClick();
              else handleClick(e, child.props.label);
            }}
            onKeyDown={e => {
              if (child.props.onClick) child.props.onClick();
              else handleClick(e, child.props.label);
            }}
          >
            {child.props.label}
          </div>
        ))}
      </div>
      <div className="py-4">
        {children.map(child => {
          if (child.props.label === activeTab) {
            return <div key={child.props.label}>{child.props.children}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
};

const Tab = ({ label, children }) => {
  return (
    <div label={label} className="hidden">
      {children}
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func
      })
    })
  ).isRequired,
  active: PropTypes.string
};

Tabs.defaultProps = {
  active: ''
};

Tab.propTypes = {
  children: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired
};

export { Tabs, Tab };
