export const getTimeRemaining = (endtime) => {
  const t = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));

  let dateTime = "";
  if (days) dateTime = `${dateTime}${days}d : `;
  if (hours) dateTime = `${dateTime}${hours}h : `;
  if (minutes) dateTime = `${dateTime}${minutes}m : `;
  if (seconds) dateTime = `${dateTime}${seconds}s`;

  return dateTime;
}
