import React from 'react';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import styles from './HeroPromoContentCardTemplate.module.css';
import {ContentCardBase} from "../ContentCardBase";
import * as brazeActions from '../../../../../redux/actions/braze';
import * as elementsActions from "../../../../../redux/actions/elements";
import MOBILE_SIZE from "../../../../../shared/constants/size";
import elementConstant from "../../../../../shared/constants/element";
import * as contentCardConstants
  from "../../../../../shared/constants/contentCards";

class HeroPromoContentCardTemplate extends ContentCardBase {

    constructor(props) {
        super(props);

        this.state = {
            animateClass: styles.animate
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                animateClass: styles.animateActive
            });
        }, 100)
    }

    redirectTo = (cardData) => {
      const { location, setHeroContentCardData, openDeliveryWidget } = this.props;

      if(location?.pathname !== '/' || cardData.url.startsWith(contentCardConstants.EXTERNAL_URL_PREFIX)) {
        this.handleClick(cardData)
      } else {
        setHeroContentCardData({contentCardHandleClick: this.handleClick, contentCardData: cardData});
        if(openDeliveryWidget) {
          openDeliveryWidget()
        }
      }
    }

    render() {
        const { cardData } = this.props;
        const { imageUrl, extras } = cardData;
        const { leftImage, rightImage, actionButtonText, actionButtonFill, actionButtonTextColor } = extras;
        const { animateClass } = this.state;

        const actionButton = actionButtonText !== undefined ? (
          <button
            type='button'
            onClick={() => this.redirectTo(cardData)}
            style={{backgroundColor: actionButtonFill, color: actionButtonTextColor}}
          >
            {actionButtonText}
          </button>
) : null;

        return (
          <div className={[styles.templateContainer, animateClass].join(' ')}>
            <div className={styles.row}>
              <img src={imageUrl} className={styles.mainImage} alt="" />
              <div className={styles.imgWrapper}>
                <div className={styles.inner}>
                  <div className={styles.leftChildContainer}>
                    <img src={leftImage} alt="" />
                    {actionButton}
                  </div>
                  <div className={styles.rightChildContainer}>
                    <img src={rightImage} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }

    /**
     * Handles deeplink click to set scroll on product category section
     * @param {String} anchor - selected product string
     */
    handleDeepLink = (anchor) => {
        const { setProductCategory } = this.props;
        setProductCategory(anchor);
        const element = document.getElementById(anchor);
        let scrollDelta = this.categoryElement.current ? this.categoryElement.current.getBoundingClientRect().height : elementConstant.scrollDeltaDefaultHeight;
        scrollDelta = window.innerWidth > MOBILE_SIZE ? scrollDelta : elementConstant.scrollDeltaDefault;
        const yPos = element ? (element.offsetTop - scrollDelta) : null;
        if (yPos) window.scrollTo({ top: yPos, left: 0, behavior: 'smooth'}); // eslint-disable-line
    }
}

HeroPromoContentCardTemplate.propTypes = {
    cardData: PropTypes.shape(
        {
            id: PropTypes.string,
            imageUrl: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
            url: PropTypes.string,
            linkText: PropTypes.string,
            extras: PropTypes.shape(
                {
                    template: PropTypes.string,
                    leftImage: PropTypes.string,
                    rightImage: PropTypes.string,
                    actionButtonText: PropTypes.string,
                    actionButtonFill: PropTypes.string,
                    actionButtonTextColor: PropTypes.string
                }
            )
        }
    ).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    setProductCategory: PropTypes.func.isRequired,
}
export const mapStateToProps = state => {
    const { productCategory, isStickyCategories, menuCategories } = state.elements;
    return { productCategory, isStickyCategories, menuCategories };
}

export const mapDispatchToProps = (dispatch) => ({
  contentCardSession: (value) => dispatch(brazeActions.contentCardSession(value)),
  setProductCategory: (value) => dispatch(elementsActions.setProductCategory(value)),
  setCategoriesPosition: (value) => dispatch(elementsActions.setCategoriesPosition(value)),
  setHeroContentCardData: (value) => dispatch(elementsActions.setHeroContentCardData(value))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeroPromoContentCardTemplate));
