import { useSelector } from "react-redux";
import React from 'react';
import * as elementsActions from "../../redux/actions/elements";
import { store } from '../../redux/store';
import * as orderExperienceRepo from '../../shared/repos/graphql/orderExperience';

const Comment = () => {
  const rating = useSelector((state) => state.elements.orderExperienceRating)
  const orderCode = useSelector((state) => state.order.code)
  const comment = useSelector((state) => state.elements.orderExperienceComment)


  const submitCallback = () => {
    orderExperienceRepo.submitOrderExperience(rating, comment, orderCode);
    store.dispatch(elementsActions.setModalObject(null))
  }

  return (
    <div className="comment-div">
      Please let us know what can be improved. We will use these responses to improve our web ordering experience!
      <textarea
        className="comment inputDarkModeOverride"
        onChange={(e) => store.dispatch(elementsActions.setOrderExperienceComment(e.target.value))}
        value={useSelector((state) => state.elements.orderExperienceComment)}
      />
      <button onClick={() => { submitCallback() }} className="comment-submit" type='button'>Submit</button>
    </div>
  )
}


export default Comment;
