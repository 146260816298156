/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

export const COUNTRIES_QUERY = gql`
    query COUNTRIES($countryId: ID) {
        countries(countryId: $countryId) {
            all {
                id
                name
                short_name
                zone_id
                url
                path_url
                api_url
                iso3166_code
                locale_id
                locale {
                    id
                    name
                    code
                }
                phone_prefix_id
                currency {
                    name
                    code
                    symbol
                }
            }
            selected {
                id
                name
                short_name
                zone_id
                url
                path_url
                api_url
                iso3166_code
                locale_id
                locale {
                    id
                    name
                    code
                }
                phone_prefix_id
                currency {
                    name
                    code
                    symbol
                }
            }
        }
    }
`;

export const PHONE_PREFIXES_QUERY = gql`
  query PHONE_PREFIXES_QUERY {
    phonePrefixes {
      id
      code
      mask
      validators {
        type
        rule
        error
      }
      country {
        id
        name
        flag
        short_name
        url
        is_active
      }
    }
  }
`;