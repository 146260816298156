/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unused-state */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// GRAPHQL
import * as contactRepo from "../../shared/repos/graphql/contact";

import contactTabs from '../../shared/constants/contactTabs';

// SUB UI
import { renderFeedbackSection, renderInquiriesSection, renderRealEstateSection, renderVendorSection, renderPressSection } from './renderings';
import * as frontApp from '../../shared/utilities/frontApp';

import styles from './Contact.module.css';
import messages from '../../shared/constants/messages';

// ASSETS
const arrow = require('./imgs/arrow.svg');

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      expandOptions: false,
      comment: '', // eslint-disable-line,
      errorMessage: null, 
      formType: 'Feedback',
      orderNumber: '',
      fullName: '',
      email: '',
      phone: '',
      phoneData: {},
    }
    this.activeIndexCheck = null;
  }

    componentDidMount() {
        frontApp.initChat();
    }

    handleSectionUpdate = (option, index) => {
        const { history } = this.props;

        if (option.action) {
            return option.action(history);
        } 

        return this.setState({ 
            formType: option.name,
            activeIndex: index, 
            errorMessage: null, 
            expandOptions: false 
        }); 
    }

  handleSubmit = () => {
    const {
      formType,
      orderNumber,
      fullName,
      email,
      phone,
      store,
      comment,
      organizationName,
      eventLocation,
      eventDate,
      eventType,
      proposedLocation,
      productName,
      productCost,
      phoneData,
    } = this.state;

    if (!email || !comment) {
      window.scrollTo(0,0); // eslint-disable-line
      return this.setState({ errorMessage: 'Please fill out all necessary fields.'}) // eslint-disable-line
    }

    if (phoneData.phone && phoneData.maskValidation === false) {
      window.scrollTo(0,0); // eslint-disable-line
      return this.setState({ errorMessage: messages.phoneInput.maskValidation.replace('%s', phoneData.mask)});
    }

    return contactRepo.submitContactForm(
      {
        formType, orderNumber, fullName, email, phone, store, comment, organizationName, eventLocation, eventDate, eventType,
        proposedLocation, productName, productCost
      }
    ).then( () => {
        window.scrollTo(0,0); // eslint-disable-line
        this.setState({ successMessage: 'Form successfully submitted!' }); // eslint-disable-line
        setTimeout(() => this.setState({ successMessage: false }), 2500) // eslint-disable-line
        this.resetFormFields();
      }
    ).catch((error)=>{
      const { message } = error.graphQLErrors[0];
      window.scrollTo(0,0); // eslint-disable-line
      this.setState({ errorMessage: message }); // eslint-disable-line
    });

  }

  resetFormFields = () => {
    this.setState(
        {
          orderNumber: '',
          fullName: '',
          email: '',
          phone: '',
          store: '',
          comment: '',
          organizationName: '',
          eventLocation: '',
          eventDate: '',
          eventType: '',
          proposedLocation: '',
          productName: '',
          productCost: '',
          phoneData: {},
          errorMessage: null,
        }
    );
  }

  
  renderContactPage = () => {
    const { activeIndex, expandOptions } = this.state;
    const { contactUsSettings } = this.props;
    return (
      <div className={styles.pageWrapper}>
        <div className={styles.pageContainer}>
          <div className={styles.sectionsContainer}>
            <h3>Contact us</h3>
            <div className={styles.desktopOptions}>
              {contactTabs.map((option, index) => {
                if (
                  (index === 0 && contactUsSettings.contact_us_feedback_enable === '1')
                  || (index === 1 && contactUsSettings.contact_us_general_enable === '1')
                  || (index === 2 && contactUsSettings.contact_us_events_enable === '1')
                  || (index === 3 && contactUsSettings.contact_us_real_estate_enable === '1')
                  || (index === 4 && contactUsSettings.contact_us_vendor_enable === '1')
                  || (index === 5 && contactUsSettings.contact_us_press_enable === '1')
                  || (index === 6 && contactUsSettings.contact_us_merch_enable === '1')
                ) {
                  if (this.activeIndexCheck === null) {
                    this.setState(prevState => {
                      return {
                        ...prevState,
                        activeIndex: index,
                      }
                    });
                    this.activeIndexCheck = index;
                  }
                  return (
                    <div
                      key={option.name}
                      className={[
                        styles.optionContainer,
                        activeIndex === index ? styles.chosenOption : null,
                        'select-page'
                      ].join(' ')}
                      onClick={() => this.handleSectionUpdate(option, index)}
                    >
                      <p>{option.name}</p>
                    </div>
                  );
                }
                return <></>;
              }
              )}
            </div>
            <div className={styles.mobileOptions}>
              <div
                onClick={() => this.setState({ expandOptions: !expandOptions })}
                className={styles.optionsContainer}
              >
                <p>{contactTabs[activeIndex].name}</p>
                <img
                  className={expandOptions ? styles.expanded : null}
                  alt="arrow"
                  src={arrow}
                />
              </div>
              <div className={styles.optionsList}>
                {expandOptions ? (
                  contactTabs.map((option, index) => {
                    if (index !== activeIndex) {
                      return (
                        <div
                          key={option.name}
                          onClick={() => this.handleSectionUpdate(option, index)}                          
                          className={styles.listOption}
                        >
                          <p>{option.name}</p>
                        </div>
                      )
                    }
                    return null;
                  })
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.contentContainer}>
            { (activeIndex === 0 && contactUsSettings.contact_us_feedback_enable === '1') ? renderFeedbackSection(this, () => this.handleSubmit()) : null }
            { activeIndex === 1 ? renderInquiriesSection(this, () => this.handleSubmit()) : null }
            { (activeIndex === 3 && contactUsSettings.contact_us_real_estate_enable === '1') ? renderRealEstateSection(this, () => this.handleSubmit()) : null }
            { (activeIndex === 4 && contactUsSettings.contact_us_vendor_enable === '1') ? renderVendorSection(this, () => this.handleSubmit()) : null }
            { (activeIndex === 5 && contactUsSettings.contact_us_press_enable === '1' ) ? renderPressSection(this) : null }
          </div>
        </div>
      </div>
    )
  }

  render() {
    return(
      <this.renderContactPage />
    )
  }
}

export const mapStateToProps = (state) => {
  const {
    contactUsSettings
  } = state.contactUsSetting;
  return {
    contactUsSettings
  };
};

export const mapDispatchToProps = () => ({
  
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));
