export default {
    id: null,
    name: null,
    path_url: null,
    api_url: null,
    short_name: null,
    url: null,
    zone_id: null,
    currency:  {
        code: "USD",
        name: "US Dollar",
        symbol: "$"
    }
}