/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
  GoogleMap,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  Polygon
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import cookieMarker from './images/cookieMarker.png';
import locationMarker from './images/locationMarker.png';
import deliveryMarker from './images/DeliveryTracker.png';

import styles from './MapComponent.module.css';
import MarkerComponent from './MarkerComponent';
import * as userActions from "../../redux/actions/user";
import {orderTypeIds} from "../../shared/constants/order";
import Loader from '../Loader/Loader';

const DarkThemeConstant = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];

class MapComponent extends Component {
  constructor() {
    super();
    this.state = {
      travelMode: 'DRIVING',
      myLocation: { lat: '', lng: '' },
      response: null
    };
  }

  componentWillReceiveProps(props) {
    const { myLocation } = props;
    this.setState({ myLocation });
  }

  getDefaultZoomFromMode = (mode) => {
    let zoom = 14;
    switch (mode) {
      case 'directions':
        zoom = 16;
        break;
      case 'locations':
        zoom = 14;
        break;
      case 'static':
        zoom = 18;
        break;
      default:
        zoom = 14;
        break;
    }
    return zoom;
  }

  handleStoreClick = (store) => {
    const { setSelectedMapStore } = this.props;

    setSelectedMapStore(store);
  }

  /**
   * Handles direction response
   * @param {object} response - response object
   */
  directionsCallback = response => {
    if (response !== null && response.status === 'OK') {
      this.setState({ response });
    }
  };

  locationsContainer = () => {
    const {stores, selectedStoreId} = this.props;

    /* Hard coded paths for polygon shape to render on the map */
    const pathsOne = [
      { lat: 40.72477201717212, lng: -73.99263416810369 },
      { lat: 40.7172261389474, lng: -73.99520908875799 },
      { lat: 40.7172261389474, lng: -73.97529636903143 },
      { lat: 40.72386142859243, lng: -73.97353645130801 },
      { lat: 40.72347128202988, lng: -73.9813898565416 }
    ];

    const centerOne = { lat: 40.72099507923205, lng: -73.984051099256 };

    const pathsTwo = [
      { lat: 40.728865745926, lng: -74.00859867616033 },
      { lat: 40.72522323902838, lng: -73.99520908875799 },
      { lat: 40.730817006895045, lng: -73.98851429505682 },
      { lat: 40.73810120895293, lng: -74.0082553534064 }
    ];

    const centerTwo = { lat: 40.73159749526159, lng: -74.0012172369513 };

    const options = {
      fillColor: '#5e2975',
      fillOpacity: 0.2,
      strokeColor: '#5e2975',
      strokeOpacity: 1,
      strokeWeight: 2,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1
    };

    if (stores?.length) {
      return (
        <div>
          {stores.map((store) => {
            let storePath = JSON.parse(store.boundary);
            if (!(storePath instanceof Array)) {
              storePath = Object.values(JSON.parse(store.boundary));
            }
            const {google} = window;

            return (
              <div key={store.lat.toString()}>
                <Marker
                  icon={{
                    url: cookieMarker,
                    scaledSize: selectedStoreId === store.id ? new google.maps.Size(40, 40) : null
                  }}
                  animation={selectedStoreId === store.id ? google.maps.Animation.BOUNCE : null}
                  position={{lat: store.lat, lng: store.lng}}
                  onClick={() => this.handleStoreClick(store)}
                />
                <Polygon paths={storePath} options={options} />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        <div>
          <Marker
            icon={cookieMarker}
            onLoad={this.cookieOnLoad}
            position={centerOne}
          />
          <Polygon paths={pathsOne} options={options} />
        </div>
        <div>
          <Marker
            icon={cookieMarker}
            onLoad={this.cookieOnLoad}
            position={centerTwo}
          />
          <Polygon paths={pathsTwo} options={options} />
        </div>
      </div>
    );
  };

  directionsContainer = () => {
    const { response, travelMode } = this.state;
    const { storeAddress, customerAddress, order } = this.props;
    const isDelivery = order?.orderTypeId === orderTypeIds.delivery
    const origin = {
      lat: parseFloat(storeAddress.lat),
      lng: parseFloat(storeAddress.lng)
    };

    const destination = {
      lat: parseFloat(customerAddress.lat),
      lng: parseFloat(customerAddress.lng)
    };

    return (
      <div>
        {' '}
        {!response ? (
          <DirectionsService
            options={{
            destination,
            origin,
            travelMode
          }}
            callback={this.directionsCallback}
          />
        ) : null}
        {response ? (
          <DirectionsRenderer
            options={{
              directions: response,
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: '#4B1850',
                strokeWeight: 0
              }
            }}
          />
        ) : null}
        <Marker
          icon={cookieMarker}
          position={origin}
        />
        <Marker
          icon={locationMarker}
          position={destination}
        />
        {
          isDelivery ? <MarkerComponent order={order} /> : null
        }
      </div>
    );
  };

  staticContainer = () => {
    const { storeAddress, customerAddress } = this.props;
    const origin = {
      lat: parseFloat(storeAddress.lat),
      lng: parseFloat(storeAddress.lng),
    };

    const destination = {
      lat: parseFloat(customerAddress.lat),
      lng: parseFloat(customerAddress.lng),
    };

    return (
      <div>
        {' '}
        <Marker
          icon={cookieMarker}
          position={origin}
        />
        <Marker
          icon={locationMarker}
          position={destination}
        />
      </div>
    );
  };

  render() {
    const { mode, center, onMapLoad, showDefaultMap, searchAddress, mapDefaultCoordinates, driverLocation, theme } = this.props;
    const { lat, lng } = mapDefaultCoordinates;
    const { myLocation } = this.state;

    let mapCenter;
    let initialLoc;
    let loading = false;

    if (showDefaultMap) {
      mapCenter = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      };
    } else {
      loading = true;
    }

    if (searchAddress?.lng && searchAddress?.lat) {
      initialLoc = {
        lat: searchAddress.lat,
        lng: searchAddress.lng
      }
    }

    // handle map centering for directions
    if (this.props.customerAddress && (mode === 'directions' || mode === 'static')) {
      mapCenter = {
        lat: parseFloat(this.props.customerAddress.lat),
        lng: parseFloat(this.props.customerAddress.lng)
      };
      loading = false;
    } else if (this.props.center && mode === 'locations') {
      mapCenter = center;
      loading = false;
    }

    const zoom = this.getDefaultZoomFromMode(mode);

    return !loading ? (
      <GoogleMap
        options={{
            fullscreenControl: false,
            mapTypeControl: false,
            scrollwheel: true,
            streetViewControl: false,
            zoomControl: false,
            styles: theme?.theme === 'dark' ? DarkThemeConstant : null
          }}
        mapContainerClassName={styles.mapContainer}
        zoom={zoom}
        center={mapCenter}
        onLoad={onMapLoad || null}
      >
        {myLocation.lat && myLocation.lng ? (
          <Marker
            icon={locationMarker}
            onLoad={this.cookieOnLoad}
            position={mapCenter}
          />
        ) : null}
        {
          initialLoc ? (
            <Marker
              icon={locationMarker}
              position={initialLoc}
            />
          ) : null
        }
        {driverLocation.lat && driverLocation.lng && (
          <Marker
            icon={deliveryMarker}
            position={{ lat: parseFloat(driverLocation.lat), lng: parseFloat(driverLocation.lng) }}
          />
        )}
        {mode === 'directions' ? <this.directionsContainer /> : null}
        {mode === 'locations' ? <this.locationsContainer /> : null}
        {mode === 'static' ? <this.staticContainer /> : null}
      </GoogleMap>
    ) : (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }
}

MapComponent.propTypes = {
  customerAddress: PropTypes.shape({
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
  }),
  storeAddress: PropTypes.shape({
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  searchAddress: PropTypes.shape({
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  center: PropTypes.shape({
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
  }).isRequired,
  myLocation: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  driverLocation: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  mode: PropTypes.string,
  stores: PropTypes.arrayOf(PropTypes.object),
  showDefaultMap: PropTypes.bool.isRequired,
  setSelectedMapStore: PropTypes.func.isRequired,
  onMapLoad: PropTypes.func,
};

MapComponent.defaultProps = {
  myLocation: { lat: '', lng: '' },
  driverLocation: { lat: '', lng: '' },
  storeAddress: { lat: '', lng: '' },
  searchAddress: { lat: '', lng: ''},
  customerAddress: null,
  stores: [],
  mode: 'directions',
  onMapLoad: null
};

export const mapDispatchToProps = (dispatch) => ({
  setSelectedMapStore: (value) => dispatch(userActions.setSelectedMapStore(value))
});

export const mapStateToProps = (state) => {
  const {
    mapDefaultCoordinates
  } = state.elements;

  return {
    mapDefaultCoordinates,
    theme: state.theme
  };
}

export default connect(() => mapStateToProps, mapDispatchToProps)(MapComponent);
