/* eslint-disable import/prefer-default-export */
import {
    DOUGH_PRO_MEMBER,
    CANCEL_SUBSCRIPTION_MUTATION,
    CHANGE_PAYMENT_METHOD_MUTATION,
    DOUGH_PRO_PAYMENTS,
    DOUGH_PRO_FREE_COOKIE_ACTIVATE_MUTATION,
    DOUGH_PRO_FREE_COOKIE,
    DOUGH_PRO_SPECIAL_CATEGORY_QUERY,
    DOUGH_PRO_PRODUCT_QUERY
} from "../../../graphql/doughPro";
import { client } from "../../utilities/config";


// QUERIES

/**
 * Cancel Dough Pro subscription.
 *
 * @author Aqib <aqib@somepage.com>
 */
export const doughProMember = () => {
    return client.query({
        fetchPolicy: 'network-only',
        query: DOUGH_PRO_MEMBER
    });
};

/**
 * Cancel Dough Pro subscription payments.
 *
 * @author Aqib <aqib@somepage.com>
 */
export const doughProPayments = () => {
    return client.query({
        fetchPolicy: 'network-only',
        query: DOUGH_PRO_PAYMENTS
    });
};
export const doughProCategory = () => {
    return client.query({
        fetchPolicy: 'network-only',
        variables: { language: 'english' },
        query: DOUGH_PRO_SPECIAL_CATEGORY_QUERY
    });
};

export const doughProProduct = () => {
    return client.query({
        fetchPolicy: 'network-only',
        query: DOUGH_PRO_PRODUCT_QUERY
    });
};
/**
 * Cancel Dough Pro get free cookie of the day.
 *
 * @author Aqib <aqib@somepage.com>
 */
export const doughProFreeCookie = () => {
    return client.query({
        fetchPolicy: 'network-only',
        query: DOUGH_PRO_FREE_COOKIE
    });
};

// MUTATIONS

/**
 * Cancel Dough Pro subscription.
 *
 * @author Aqib <aqib@somepage.com>
 */
export const cancelSubscription = () => {
    return client.mutate({
        mutation: CANCEL_SUBSCRIPTION_MUTATION
    });
};

/**
 * Change Dough Pro subscription payment method.
 *
 * @author Aqib <aqib@somepage.com>
 */
export const changePaymentMethod = (paymentCredential) => {
    const variables = {
        paymentCredential
    };

    return client.mutate({
        mutation: CHANGE_PAYMENT_METHOD_MUTATION,
        variables
    });
};

/**
 * Dough Pro Free Cookie Activate.
 *
 * @author Aqib <aqib@somepage.com>
 */
export const doughProFreeCookieActivate = () => {
    return client.mutate({
        mutation: DOUGH_PRO_FREE_COOKIE_ACTIVATE_MUTATION
    });
};
