import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TopHeader from "./TopHeader";
import Instructions from "./Instructions";
import TopBanner from "./TopBanner";
import DeliciousRewards from "./DeliciousRewards";
import Faqs from "./Faqs";

import * as cms from "../../shared/repos/cms/cms";
import cmsKeys from "../../shared/constants/cms";
import Loader from "../../components/Loader/Loader";
import { getContentsByKey } from "../../shared/utilities/common";

// eslint-disable-next-line
class InsomniaRewards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    cms.getContent([cmsKeys.rewardPage], this);
  };

  render() {
    const { content, loading } = this.state;

    if (!content.length && !loading) {
      return <Loader />;
    }
    const components = content[0]?.children;
    const topHeaderContent = getContentsByKey(components, "top_header");
    const stepToEarnContent = getContentsByKey(components, "step_to_earn");
    const bannerContent = getContentsByKey(components, "banner");
    const howToEarnContent = getContentsByKey(components, "how_to_redeem");
    const faqsContent = getContentsByKey(components, "faqs");

    return (
      <div className=" w-full flex flex-col items-center pb-36 mx-auto">
        <div className="w-full">
          <div className="lg:max-w-screen-desktop w-full  p-0 border-0 mx-auto">
            <div className="w-full mt-14 md:mt-9">
              <TopHeader contents={topHeaderContent} />
            </div>
            <div className="w-full mt-14">
              <Instructions contents={stepToEarnContent} />
            </div>
          </div>
          <div className="w-full mt-20">
            <TopBanner contents={bannerContent} />
          </div>
          <div className="lg:max-w-screen-desktop w-full p-0 border-0 mx-auto mt-10">
            <div className="w-full mt-14">
              <DeliciousRewards contents={howToEarnContent} />
            </div>

            <div className="w-full mt-14">
              <Faqs contents={faqsContent} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InsomniaRewards.propTypes = {
  selectedCountry: PropTypes.shape({})
};

InsomniaRewards.defaultProps = {
  selectedCountry: null
};

export const mapStateToProps = state => {
  const { selectedCountry } = state?.country;
  return {
    selectedCountry
  };
};

export default withRouter(connect(mapStateToProps)(InsomniaRewards));
