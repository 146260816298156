/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const SHIPPING_OPTIONS = gql`
    query SHIPPING_OPTIONS($zipcode: String!, $orderCode: String!) {
        shippingOptions(zipcode: $zipcode, orderCode: $orderCode) {
            shippingMethodId
            class
            deliveryDate
            cost
        }
    }
`;

export const SHIPPING_UNAVAILABLE_PRODUCTS = gql`
    query SHIPPING_UNAVAILABLE_PRODUCTS($zipcode: String!, $orderCode: String!) {
        shippingUnavailableProducts(zipcode: $zipcode, orderCode: $orderCode) {
            errorMessage
            unavailableProducts
            __typename
        }
    }
`;