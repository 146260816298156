/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "underscore";
import DeliveryPickUpWidget from "../DeliveryPickUpWidget/DeliveryPickUpWidget";

import { PRODUCT_MAX_QUANTITY_SELECTION } from "../../shared/constants/product";
import { defaultCurrency } from "../../shared/constants/currency";

// REDUX
import * as elementsActions from "../../redux/actions/elements";

import styles from "./CheckoutCartButton.module.css";
import { orderMethods } from "../../shared/constants/order";

const quantityArrow = require("./imgs/CaretDown.svg");

export class CheckoutCartButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showQuantitySelector: false,
      quantityValues: this.quantities(props.quantity)
    };
    this.quantitytRef = React.createRef(); // Create a ref object
  }

  componentDidMount() {
    this.initScroll();
  }

  componentDidUpdate() {
    this.initScroll();
  }

  setQuantityRef(value) {
    const { quantity } = this.props;
    if (quantity === 1) {
      return value === 7 ? this.quantitytRef : null;
    }
    if (
      (quantity < 6 && value === 6) ||
      (quantity >= 6 && quantity === value)
    ) {
      return this.quantitytRef;
    }
    return null;
  }

  initScroll = () => {
    if (this.quantitytRef.current) {
      this.quantitytRef.current.scrollIntoView();
    }
  };

  quantities = quantity => {
    const maxQuantity = PRODUCT_MAX_QUANTITY_SELECTION;
    if (quantity === 1) {
      return _.range(maxQuantity, 1);
    }
    return _.range(maxQuantity, 0);
  };

  /**
   * Toggles showQuantitySelector state value
   */
  toggleQuantitySelector = e => {
    const { isDisabled } = this.props;
    if (!isDisabled) {
      if (e) {
        e.stopPropagation();
      }
      const { showQuantitySelector } = this.state;
      this.setState({ showQuantitySelector: !showQuantitySelector });
    }
  };

  /**
   * Handles local quality change and pass it to parent component callback
   * @param {number} value - value of item selected
   */
  handleQuantityChange = value => {
    const { onQuantityChange } = this.props;
    // toggle quantity selector view
    // pass back argument value to parent for higher level handling
    onQuantityChange(value);
    this.setState({ quantityValues: this.quantities(value) });
  };

  render() {
    const {
      alertMessage,
      label,
      onClick,
      price,
      quantity,
      isDisabled,
      selectedStore,
      userOrderMethod,
      setModalObject,
      currency,
      loyalty
    } = this.props;
    const { showQuantitySelector, quantityValues } = this.state;
    const isDelivery = userOrderMethod === orderMethods.delivery;
    const isPickUp = userOrderMethod === orderMethods.pickup;
    const isEgiftCard = userOrderMethod === orderMethods.eGiftCard;
    const isCheckoutMethod = isDelivery || isPickUp || isEgiftCard;

    
    return (
      <div
        className={`${styles.checkoutCartWrapper} ${
          isDisabled ? "opacity-50" : ""
        }`}
        onClick={() => {
          if (isDisabled) {
            return null;
          }
          if (userOrderMethod === "SHIPPING") {
            return onClick();
          }
          if (isCheckoutMethod) {
            if (selectedStore) {
              return onClick();
            }
          }
          return setModalObject({
            children: (
              <div className={styles.widgetWrapper}>
                <DeliveryPickUpWidget isModal />
              </div>
            )
          });
        }}
      >
        {alertMessage ? (
          <div className={styles.alertContainer}>
            <p>{alertMessage}</p>
          </div>
        ) : null}
        <div
          className={`
            ${styles.checkoutCartButton} 
            ${
              isDisabled
                ? `${styles.isDisabled} cursor-default`
                : "cursor-pointer"
            }
            ${showQuantitySelector ? styles.cartButtonShowQuantity : false}
            bg-dark dark:bg-button text-white font-filsonProBold leading-[22px] tracking-[-0.1px] z-50
          `}
        >
          {showQuantitySelector ? (
            <div
              className={`${styles.quantitySelectorContainer} bg-dark text-white dark:bg-button`}
            >
              <Scrollbars className={styles.scrollbar} style={{ height: 250 }}>
                {quantityValues.map(value => (
                  <p
                    ref={this.setQuantityRef(value)}
                    key={value.toString()}
                    onClick={e => {
                      e.stopPropagation();
                      this.handleQuantityChange(value);
                      this.setState({ showQuantitySelector: false });
                    }}
                  >
                    {value}
                  </p>
                ))}
              </Scrollbars>
            </div>
          ) : null}
          {/* Customize/Boxes quantity element */}
          {quantity ? (
            <div
              onClick={e => {
                this.toggleQuantitySelector(e);
              }}
              className={styles.quantityContainer}
            >
              {quantity}
              <img alt="quantity-arrow" src={quantityArrow} />
            </div>
          ) : null}
          {/* Label element */}
          <div
            className={[
              styles.labelPrice,
              quantity > 0 ? styles.labelPriceQuantity : null
            ].join(" ")}
          >
            <p>{label}</p>
            {price ? <span>&nbsp;&#8226;&nbsp;</span> : null}
            {price ? (
              <p className={styles.mobilePrice}>
                {loyalty ? `${price} pts` : `${currency.symbol}${price}`}
              </p>
            ) : null}
          </div>
          {/* Price element */}
          {price > 0 ? (
            <div className={styles.priceContainer}>
              <p>{loyalty ? `${price} pts` : `${currency.symbol}${price}`}</p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

CheckoutCartButton.propTypes = {
  alertMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quantity: PropTypes.number,
  isDisabled: PropTypes.bool,
  onQuantityChange: PropTypes.func,
  setModalObject: PropTypes.func,
  userOrderMethod: PropTypes.string,
  selectedStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  currency: PropTypes.objectOf(PropTypes.string),
  loyalty: PropTypes.string,
};

CheckoutCartButton.defaultProps = {
  alertMessage: null,
  quantity: 0,
  isDisabled: false,
  price: null,
  onQuantityChange: null,
  selectedStore: { id: "" },
  userOrderMethod: "",
  setModalObject: () => null,
  currency: defaultCurrency,
  loyalty: null
};

export const mapStateToProps = state => {
  const { selectedStore, userAddress, userOrderMethod } = state.user;
  const { currency } = state.currency;
  return { selectedStore, userAddress, userOrderMethod, currency };
};

export const mapDispatchToProps = dispatch => ({
  setModalObject: value => dispatch(elementsActions.setModalObject(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCartButton);
