/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import PropTypes from 'prop-types';

import styles from './CheckBox.module.css';

const checkIcon = require('./imgs/check.svg');

const CheckBox = ({ label, isChecked, onClick }) => (
  <div
    onClick={onClick}
    className={`${styles.checkBoxWrapper} flex gap-3 items-center`}
  >
    <div className={` 
      ${isChecked ? styles.isChecked : null}
      border-[1px] border-border dark:border-button 
        ${isChecked ? ' bg-border dark:bg-button' : null}`}
    >
      <img alt="check" src={checkIcon} className={`${isChecked ? 'block': 'hidden'}`} />
    </div>
    {label ? <span className='text-dark dark:text-white'>{label}</span> : null}
  </div>
)

CheckBox.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

CheckBox.defaultProps ={
  isChecked: false,
  label: null
}
export default CheckBox;
