import siteSetting from '../../shared/constants/siteSetting';

const cleanState = {
    footerMenuItemsSettings: {
        [siteSetting.IS_FOOTER_MENU_NEWSROOM_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_FAQ_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_PRIVACY_POLICY_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_TERMS_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_EVENT_TERMS_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_ACCESSIBILITY_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_CONTACT_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_ABOUT_US_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_CAREERS_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_CORPORATE_SALES_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_DONATIONS_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_MERCH_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_ALLERGEN_ENABLED]: false,
        [siteSetting.IS_FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_ENABLED]: false,
        
    },
    footerMenuItemsLinksSettings: {
        [siteSetting.FOOTER_MENU_NEWSROOM_LINK]: '',
        [siteSetting.FOOTER_MENU_FAQ_LINK]: '',
        [siteSetting.FOOTER_MENU_PRIVACY_POLICY_LINK]: '',
        [siteSetting.FOOTER_MENU_TERMS_LINK]: '',
        [siteSetting.FOOTER_MENU_EVENT_TERMS_LINK]: '',
        [siteSetting.FOOTER_MENU_ACCESSIBILITY_LINK]: '',
        [siteSetting.FOOTER_MENU_CONTACT_LINK]: '',
        [siteSetting.FOOTER_MENU_ABOUT_US_LINK]: '',
        [siteSetting.FOOTER_MENU_CAREERS_LINK]: '',
        [siteSetting.FOOTER_MENU_CORPORATE_SALES_LINK]: '',
        [siteSetting.FOOTER_MENU_DONATIONS_LINK]: '',
        [siteSetting.FOOTER_MENU_MERCH_LINK]: '',
        [siteSetting.FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_LINK]: '',
        [siteSetting.FOOTER_MENU_ALLERGEN_LINK]: '',
    },
    tiktokLink: '',
    instagramLink: '',
    facebookLink: '',
    passwordlessEnabled: false,
};
const initialState = {
    ...cleanState
};

export default (state = initialState, { type, value }) => {
    switch (type) {
        case 'SET_FOOTER_MENU_ITEMS_SETTINGS':
            return {...state, footerMenuItemsSettings: value};
        case 'SET_FOOTER_MENU_ITEMS_LINKS_SETTINGS':
            return {...state, footerMenuItemsLinksSettings: value};
        case 'SET_TIKTOK_LINK':
            return {...state, tiktokLink: value};
        case 'SET_INSTAGRAM_LINK':
            return {...state, instagramLink: value};
        case 'SET_FACEBOOK_LINK':
            return {...state, facebookLink: value};
        case 'SET_PASSWORDLESS_ENABLE':
            return {...state, passwordlessEnabled: value};
        default:
            return state;
    }
}
