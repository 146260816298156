/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import CheckoutContainer from '../CheckOutContainer/CheckoutContainer';
import DropDown from '../Dropdown/Dropdown';
import Loader from '../Loader/Loader';

// GRAPHQL
import { getStoreHours } from '../../shared/repos/graphql/store';

// STYLES
import styles from './DeliveryTimeWidget.module.css';
import checkoutStyles from '../CheckOutContainer/CheckoutContainer.module.css';
import {dateTimeFormat, orderTypeIds} from "../../shared/constants/order";
import {clearCart} from "../../shared/utilities/orders";
import endpoints from "../../shared/constants/endpoints";
import { routeCountryPath } from '../../shared/utilities/common';
import * as checkout from '../../redux/actions/checkout';

const moment = extendMoment(Moment);
function getDifferenceDays(){
  const currentDate = new Date()
  const lastDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  let currentYear = currentDate.getFullYear();
  let differenceDay = (new Date(currentYear, currentMonth + 1, 0).getDate()) - lastDay;

  for(let i = 1; i <= 6; i += 1){
    let month = currentMonth + i;
    if(month > 11){
      month -= 12;
      currentYear += 1
    }
    let dayCount = new Date(currentYear, month + 1, 0).getDate();
    if(lastDay < dayCount && i === 6){
      dayCount = lastDay
    }

    differenceDay += dayCount
  }
  return differenceDay;
}
class DeliveryTimeWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSelectionDisabled: props.timeSelectionDisabled ?? false,
      deliveryTime: null,
      loading: true,
      availableStoreHours: [],
      selectLoader: false,
      selectedDeliveryDate: props.saveDeliveryDate ?? null,
      selectedDeliveryTime: props.saveDeliveryTime ?? null,
      errorMessage: ''
    }
  }

  componentDidMount() {
    const {saveDeliveryDate} = this.props;
    if (saveDeliveryDate) {
      this.updateStoreHours(saveDeliveryDate, true);
    }
  }

  setSelectedTimeslot(storeHours) {
    const { order, saveDeliveryTime, customOrder } = this.props;
    let storeHoursTotalItems = [];
    storeHours.forEach(hours => {
      storeHoursTotalItems = storeHoursTotalItems.concat(hours.items);
    });
    const selectedOrder = customOrder === null ? order : customOrder;
    const filterDeliveryTime = storeHoursTotalItems.filter((item) => {
      if (saveDeliveryTime && saveDeliveryTime === item.value) {
        return true;
      } if (item.value === selectedOrder.deliveryTime) {
        return true;
      }
        return false;

    });
    const selectedDeliveryTime = filterDeliveryTime.length > 0 ? filterDeliveryTime[0].value : null;

    this.setState(
        {
          selectedDeliveryTime
        }
    );

    this.setDeliveryTime(selectedDeliveryTime);

    return selectedDeliveryTime;
  }

  updateStoreHours = (date, selectAndSaveTime = false) => {
    const { userCartId, justReturnMode, orderCode } = this.props;

    this.setState({
      errorMessage: '',
      selectedDeliveryTime: null
    });

    const code = justReturnMode ? orderCode : userCartId;

    getStoreHours(code, date).then(response => {
      if (response.data.storeHours) {
        const storeHours = [];
        let timeSelectionDisabled = false;

        response.data.storeHours.forEach((hours, key) => {
          storeHours[key] = {
            name: moment(hours.date).format('dddd'),
            type: 'group',
            items: []
          };

          hours.hours.forEach(hour => {
            if (hour.includes("CLOSED")) {
              storeHours[key].items.push({
                value: hour,
                label: hour
              });

              this.setState({
                errorMessage: hours.message || 'Store is closed'
              });

              timeSelectionDisabled = true;

              return;
            }

            const dateTime = moment(`${hours.date  } ${  hour}`, dateTimeFormat.yearMonthDayHourMinuteAMPM).format(dateTimeFormat.yearMonthDayHourMinuteSecond);

            storeHours[key].items.push({
              value: dateTime,
              label: hour
            });
          });
        });

        const selectedDeliveryTime = this.setSelectedTimeslot(storeHours);

        const isSelectAndSaveFirstTimeSlot = !selectedDeliveryTime && selectAndSaveTime;
        if (isSelectAndSaveFirstTimeSlot) {
          this.selectAndSaveFirstTimeSlot(storeHours);
        }

        this.setState({
          availableStoreHours: storeHours,
          selectedDeliveryDate: date,
          timeSelectionDisabled
        });
        const {setSaveDeliveryDate} = this.props;
        if (!justReturnMode) {
          setSaveDeliveryDate(date);
        }
      }

      this.toggleGetStoreHoursLoading(false);
    });

    this.toggleGetStoreHoursLoading(true);
  }

  selectAndSaveFirstTimeSlot = (storeHours) => {
    if (storeHours && storeHours[0] && storeHours[0].items && storeHours[0].items[0]) {
      this.setDeliveryTime(storeHours[0].items[0].value);
      this.updateSelectedDeliveryTime(storeHours[0].items[0].value)
    }
  }

  updateSelectedDeliveryTime = (dateTime) => {
    this.setState({
      selectedDeliveryTime: dateTime
    });

    this.setDeliveryTime(dateTime);
    const {setSaveDeliveryTime, justReturnMode} = this.props;
    if (!justReturnMode) {
      setSaveDeliveryTime(dateTime);
    }
  };

  toggleLoadingSetDeliveryPickupTime = (toggleValue) => {
    this.setState(
        {
          loadingSetDeliveryPickupTime: toggleValue
        }
    );
  }

  toggleGetStoreHoursLoading = (toggleValue) => {
    this.setState(
        {
          loadingStoreHours: toggleValue
        }
    );
  }

  setDefaultDeliveryTime = (time) => {
    const {onDefaultDeliveryTime} = this.props;
    onDefaultDeliveryTime(time)
  }

  setUpdateStates = () => {
    const {component} = this.props;
    clearCart();
    component.props.history.push(routeCountryPath(endpoints.getMenuUrl(null)))
  }

  setDeliveryTime = (selectedDeliveryTime) => {
    const { onDeliveryTimeUpdated } = this.props;
    this.setState({
      deliveryTime: selectedDeliveryTime,
      loading: false
    });

    if (selectedDeliveryTime && selectedDeliveryTime.includes("CLOSED")) {
      this.setState({
        deliveryTime: selectedDeliveryTime,
        loading: false
      });
      return;
    }

    const today = moment().startOf('day');
    const asap = moment(selectedDeliveryTime).isSame(today);

    const deliveryTime = asap ? '0000-00-00 00:00:00' : selectedDeliveryTime;

    this.setState({
      deliveryTime
    });
    onDeliveryTimeUpdated(deliveryTime);
    this.setDefaultDeliveryTime(selectedDeliveryTime);
  };

  isDisabled = () => {
    const { loadingSetDeliveryPickupTime, loadingStoreHours, timeSelectionDisabled } = this.state;
    const apiResultsLoading = loadingSetDeliveryPickupTime || loadingStoreHours;
    return timeSelectionDisabled || apiResultsLoading;
  };

  deliveryTimeContainer = () => {
    const { expanded, order, customOrder } = this.props;
    const selectedOrder = customOrder === null ? order : customOrder;
    const { store } = selectedOrder;
    const { loading, deliveryTime, errorMessage } = this.state;
    let formattedDeliveryTime = '';

    if (deliveryTime && !deliveryTime.includes("CLOSED")) {
      const deliveryDate = moment(deliveryTime, 'YYYY-MM-DD HH:mm:SS').startOf('day');

      formattedDeliveryTime = moment(deliveryTime).format('MMMM Do [-] h:mm A');
      if (deliveryDate) {
        const today = moment().startOf('day');

        const asap = (moment(deliveryTime).isValid() && moment(deliveryTime).isSame(today)) || deliveryTime === '0000-00-00 00:00:00';

        if (asap) {
          const pickupDeliveryEstimate = selectedOrder.orderTypeId === orderTypeIds.delivery ?
            selectedOrder.orderEstimate?.estimate || selectedOrder.deliveryEstimate :
            store.pickupEstimate;
          formattedDeliveryTime = `Today - ASAP (${pickupDeliveryEstimate})`;
        }
      }
    }

    const {
      timeSelectionDisabled,
      availableStoreHours,
      selectedDeliveryDate,
      selectedDeliveryTime,
      loadingSetDeliveryPickupTime,
      loadingStoreHours,
      selectLoader
    } = this.state;

    const differenceDay= getDifferenceDays();
    const range = moment.rangeFromInterval('day', differenceDay, moment());
    const days = Array.from(range.by('days')).map(day => {
      return {
        label: day.format('dddd, MMM Do'),
        value: day.format('YYYY-MM-DD')
      };
    });

    days[0].label = days[0].label.replace(/\w{1,},/, 'Today,');
    days[1].label = days[1].label.replace(/\w{1,},/, 'Tomorrow,');
    const apiResultsLoading = loadingSetDeliveryPickupTime || loadingStoreHours;
    if (!selectedDeliveryDate && !apiResultsLoading) {
      const selectedDateString = selectedOrder .deliveryTime ? selectedOrder.deliveryTime.split(' ')[0] : "";
      const isValidDate = moment(selectedDateString).isValid();
      const date = isValidDate ? selectedDateString : days[0].value;

      this.updateStoreHours(date, true);
    }

    const {header, subHeader, sectionHeader, className, forceExpanded, forceDisableExpand} = this.props;
    let longText = '';
    let subHeaderText = subHeader;

    if (deliveryTime) {
      if (deliveryTime.includes("CLOSED")) {
          subHeaderText = deliveryTime;
          longText = styles.placeholderText11;
      } else {
        subHeaderText = formattedDeliveryTime;
      }
    }

    const disabled = this.isDisabled();
    const labelSelect = selectLoader?  <Loader height={24} /> : 'Select';
    const selectionDisabled = timeSelectionDisabled || disabled;
    return loading ? (
      <div className={checkoutStyles.checkoutContainerWrapper}>
        <div className={checkoutStyles.checkoutContainer}>
          <Loader />
        </div>
      </div>
      ) : (
        <CheckoutContainer
          className={className}
          header={header}
          isAlert={!deliveryTime}
          subHeader={subHeaderText}
          btnDisabled={selectionDisabled}
          btnStyles={styles.selectionButton}
          expanded={forceExpanded || expanded || errorMessage.length > 0}
          label={labelSelect}
          forceDisableExpand={forceDisableExpand}
        >
          <p className={`${styles.sectionHeader} font-filsonProRegular font-[425] text-dark dark:text-white`}>{sectionHeader}</p>
          <div className={styles.dropDownRow}>
            <DropDown
              isDate
              onChange={e => this.updateStoreHours(e.value, false)}
              options={days}
              value={selectedDeliveryDate || days[0]}
            />
            <DropDown
              isTime
              disabled={timeSelectionDisabled}
              onChange={e => this.updateSelectedDeliveryTime(e.value)}
              options={availableStoreHours}
              value={selectedDeliveryTime}
              className={longText}
            />
          </div>
          { errorMessage.length > 0 ? (<p className={styles.errorMessage}>{errorMessage}</p>) : null }
        </CheckoutContainer>
      );
    };

  render() {
    return <this.deliveryTimeContainer />;
  }
}

DeliveryTimeWidget.propTypes = {
  onDeliveryTimeUpdated: PropTypes.func,
  onDefaultDeliveryTime: PropTypes.func,
  forceExpanded: PropTypes.bool,
  forceDisableExpand: PropTypes.bool,
  justReturnMode: PropTypes.bool,
  customOrder: PropTypes.object || null,
};
  
DeliveryTimeWidget.defaultProps = {
  onDeliveryTimeUpdated: () => {},
  onDefaultDeliveryTime: () => {},
  forceExpanded: false,
  forceDisableExpand: false,
  justReturnMode: false,
  customOrder: null,
};

export const mapStateToProps = (state) => {
  const { endpoint, userCartId } = state.user;
  const { saveDeliveryTime, saveDeliveryDate } = state.checkout;
  const { order } = state;
  return { endpoint, order, userCartId, saveDeliveryTime, saveDeliveryDate};
};

export const mapDispatchToProps = (dispatch) => ({
   setSaveDeliveryTime: (value) => dispatch(checkout.setSaveDeliveryTime(value)),
   setSaveDeliveryDate: (value) => dispatch(checkout.setSaveDeliveryDate(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryTimeWidget);
