/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const CMS_API_QUERY = gql`
    query CMS_API_QUERY($keys: [String!], $localeId: Int!) {
        cmsData(where: { keys: $keys, localeId: $localeId }) {
            key
            value
            children {
                key
                value
                children {
                    key
                    value
                    children {
                        key
                        value
                        children {
                            key
                            value
                            children {
                                key
                                value
                                children{
                                    key
                                    value
                                    children{
                                        key
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
