import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../CheckBox/CheckBox';
import styles from '../../pages/ReviewOrder/ReviewOrder.module.css';

const SelectionItem = (props) => {
    const { main, asset, label, header, isSelected, onSelect } = props;
    const classNames = [ styles.selectionContainer, main ? styles.mainContainer : null ].join(' ');

    return (
      <div className={classNames}>
        <div className={styles.itemDetailsContainer}>
          <img alt="asset" src={asset} />
          <div>
            <p>{label}</p>
            <h4>{header}</h4>
          </div>
        </div>
        {!main ? <CheckBox onClick={onSelect} isChecked={isSelected} /> : null}
      </div>
    );
};

SelectionItem.propTypes = {
    main: PropTypes.bool,
    asset: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.shape().isRequired
    ]).isRequired,
    label: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func
}

SelectionItem.defaultProps = {
    isSelected: false,
    onSelect: null,
    main: false,
}

export default SelectionItem;
