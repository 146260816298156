import React, { Component } from 'react';
import {isMobile, isTablet} from "react-device-detect";
import * as cms from '../../shared/repos/cms/cms';
import cmsKeys from '../../shared/constants/cms';
import {getKeyValue} from '../../shared/utilities/cms';

import styles from './CustomCakeTopperInfo.module.css'

class CustomCakeTopperInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: []
    };
  }

  componentDidMount() {
    cms.getContent([cmsKeys.customCakeTopperPage.pageKey], this);
  }

  render() {
    const {content} = this.state;
    const contentHtml = ((content.length) > 0 && !isMobile && !isTablet) ? (
      <div className={styles.cakeTopperInfoText}>
        { content.map(customCakeTopperPage => {
            const infoText = getKeyValue(customCakeTopperPage.children, cmsKeys.customCakeTopperPage.body);
            return (
              <div>{infoText}</div>
            )
          })}
      </div>
    ) : null;

    return (
      <div className={styles.cakeTopperInfoWrapper}>
        { contentHtml }
      </div>
    );
  }
}

export default CustomCakeTopperInfo;
