/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import _ from 'underscore';
import DeliveryTimeWidget from '../../components/DeliveryTimeWidget/DeliveryTimeWidget';
import CheckoutContainer from '../../components/CheckOutContainer/CheckoutContainer';
import Input from '../../components/Input/Input';
import ContactDetailsWidget from '../../components/ContactDetailsWidget/ContactDetailsWidget';
import Instructions from './Instructions/Instructions';
import GiftRecipient from './GiftRecipient/GiftRecipient';
import * as reduxStore from "../../redux/store";
import * as cms from "../../shared/repos/cms/cms";
import cmsKeys from "../../shared/constants/cms";
import { handleFormInput } from '../../shared/utilities/validations';

import styles from './ReviewOrder.module.css';
import checkoutContainerStyles from "../../components/CheckOutContainer/CheckoutContainer.module.css";
import subscription from '../../shared/constants/subscription';

export const returnRadioOptions = (chosenIndex, options, onChosen) => {
  return (
    <div className={styles.optionsContainer}>
      {options.map((option, index) => {
        const isChosen = chosenIndex === index;
        return (
          <div
            key={option.id}
            onClick={() => onChosen(index)}
            className={`
              h-[60px] dark:text-white font-filsonProRegular
              ${
                isChosen ?
                `${styles.chosen} border-none bg-border dark:bg-button text-white` :
                'border-lightDry dark:border-darkDry border-[1px] bg-white dark:bg-dark text-dark'}`}
          >
            {`${option}`}
          </div>
        );
      })}
    </div>
  );
};

/**
 * Returns line cost element UI
 * @param {String} label
 * @param {Number} price
 * @param {Boolean} isDiscount
 * @param currency
 */
export const returnLineCost = (label, price, isDiscount, currency, lineCostType, isTotal = false, isLoyaltyPoints = false) => {
  const store = reduxStore.store.getState();

  let customLabel = label;
  let orderTotalsJson = [];
  if (store) {
    const { orderTotalsTitles } = store.orderTotalsTitles;
    orderTotalsJson = JSON.parse(orderTotalsTitles);
  }

  if (orderTotalsJson && orderTotalsJson[label] !== undefined) {
    customLabel = orderTotalsJson[label];
  }
  
  let value = Number.isNaN(parseFloat(price))
      ? price : `${isDiscount && price > 0? '-' : ''}${currency.symbol}${parseFloat(price).toFixed(2)}`;
  if(isLoyaltyPoints) {
    value = `${price} pts`;
  }

  return (
    <>
      {customLabel ? (
        <div
          className={`${styles.lineCost} text-dark dark:text-white `}
          data-total-label={customLabel}
          data-total-value={value}
          data-line-cost-type={lineCostType}
          key={label}
        >
          <p className={`${isTotal ? 'font-bold' : ''}`}>{customLabel}</p>
          <p>{value}</p>
        </div>
      )
        : <div />}
    </>
  );
};

const handleDeliveryAddressInput = (e, component, state) => {
  const {value} = e.target;
  const {setSaveCheckoutDetails} = component.props
  setSaveCheckoutDetails({'aptNo': value});
  handleFormInput(e, component, state);
}

/**
 * @param {React.Component} component
 */
const deliveryAddressAdditionalInfo = (component) => {
  const { userAddress } = component.props;
  const orderStatusSubscription = _.find(
    component.state.customerSubscriptions,
    { keyField: subscription.subscriptionKeyField.ORDER_NOTIFICATION }
  );
  const { saveCheckoutDetails } = component.props.checkout;
  const apartmentPlaceholder = (cms.getValueByKey(cmsKeys.placeholders.checkoutDeliveryAddressApartment, component) || 'Add apartment#/suite#/dorm#')
    .replace(/<\/?[^>]+(>|$)/g, "");

  return (
    <div className={styles.deliveryAddressAdditionalInfo}>
      <div className={checkoutContainerStyles.checkoutContainerLeft}>
        <p>Delivery Address</p>
        <span>
          {userAddress.address1}
        </span>
      </div>

      <Input
        inputId="addressApartment"
        handleInput={(e) => handleDeliveryAddressInput(e, component, 'address2')}
        value={saveCheckoutDetails && saveCheckoutDetails.aptNo ? saveCheckoutDetails.aptNo : component.state.address2}
        customContainerStyles={styles.inputContainer}
        placeholder=" "
        label={apartmentPlaceholder}
      />

      <GiftRecipient orderStatusSubscription={orderStatusSubscription} />

    </div>
            );
}

// eslint-disable-next-line no-unused-vars
const onExpand = (expand) => {
  const { checkout, checkoutUpdated } = this.props;
  this.setState({ openForm: expand, isGift: expand });

  checkout.isGift = expand;
  checkoutUpdated(checkout);
}

/**
 * Returns delivery details sub UI
 * @param {React.Component} component
 */
export const deliveryRendering = (component) => {
  const { userAddress } = component.props;
  const { order, deliveryAddressOpen } = component.state;

  return (
    <div>
      <DeliveryTimeWidget
        className='selectDeliveryTime border-b-2 border-lightDry dark:border-darkDry text-dark dark:text-white'
        orderCode={order.code}
        header="Delivery Time"
        subHeader="Need delivery time"
        sectionHeader="Select a delivery time"
        onDefaultDeliveryTime={component.setDefaultDeliveryTime}
        component={component}
      />
      <CheckoutContainer
        header="Delivery Address"
        className='border-b-2 border-lightDry  dark:border-darkDry text-dark dark:text-white'
        subHeader={userAddress.address1}
        onExpand={(val) => component.onExpandDeliveryAddress(val)}
        expanded={deliveryAddressOpen}
      >
        {deliveryAddressAdditionalInfo(component)}
      </CheckoutContainer>

      <Instructions component={component} />
    </div>
  );
};

/**
 * Returns pick up details sub UI
 * @param {React.Component} component
 */
export const pickUpRenderings = component => {
  const { order } = component.state;

  return (
    <div>
      <CheckoutContainer
        header="Store Location"
        subHeader={
          order.store && order.store.address ? order.store.address : ''
        }
      />
      <DeliveryTimeWidget
        className='selectPickupTime'
        orderCode={order.code}
        header="Pickup Time"
        subHeader="Need pickup time"
        sectionHeader="Select a pickup time"
        onDefaultDeliveryTime={component.setDefaultDeliveryTime}
        component={component}
      />

      <ContactDetailsWidget setContactFormError={component.setContactFormError} />

      <Instructions component={component} />
    </div>
  );
};
