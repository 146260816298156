export const messages = {
    shipping: {
        disclaimer: 'Baked fresh for you! Orders placed before 3pm will ship same day.  Please note, we do not ship over the weekend to ensure freshness.'
    },
    productHeader: {
        welcome: 'Welcome',
        to: 'to insomnia',
        description: 'Warm cookies delivered crazy late.'
    },
    viewOrder:{
        submitButtonLabel:'Go to Checkout',
        goToCartLabel: 'Edit Cart',
        reviewOrder: 'Review Order',
        reviewOrderSubtext1: 'Getting warmer... warmer...',
        reviewOrderSubtext2: 'Double-check the details. You are almost there!',
    },
    loggedInProduct: {
        header: 'Hey there,',
        description: 'More warm, delicious cookies are in your future.'
    },
    shippingMenu: {
        shippingBannerHeader: 'We make gifting easy',
        shippingBannerSubHeader: 'Ship multiple gift boxes in minutes.',
        shippingBannerLabel1: 'Learn more',
    },
    mainMenu: {
        shippingBannerHeader: 'We ship nationwide',
        shippingBannerSubHeader: 'We\'ll ship whenever you need them the most.',
        shippingBannerLabel1: 'Order now',
    },
    orderTracker: {
        driverAssignedLabel: 'The driver was assigned',
    },
    phoneInput: {
        maskValidation: `Invalid phone number. Please use format %s`,
    }
};

export default messages;
