/* eslint-disable react/no-danger */
import React, { useEffect, useRef,  useState} from 'react';
import { connect } from 'react-redux';
import * as ccDetailsActions from '../../../../redux/actions/ccDetails';
import { propTypesComponentPaymentInput } from '../../shared.propTypes';
import styles from '../PaymentInputCreditCardLegacy/PaymentInputCreditCardLegacy.module.css';
import stylesShared from '../../shared.module.css';
import logger from '../../../../shared/utilities/logger';
import ddLog from '../../../../shared/utilities/datadog/log';
import { keyDownA11y, uiComponentTypes } from '../../shared';
import PaymentInputCreditCardSavedList from '../PaymentInputCreditCardSavedList/PaymentInputCreditCardSavedList';
import * as siteSettingRepo from '../../../../shared/repos/graphql/siteSetting';
import siteSetting from '../../../../shared/constants/siteSetting';

const PaymentInputEmbeddedExternal = props => {
  const {
    storePaymentMethod,
    onCancel,
    onSubmitPaymentCredentials,
    hideCancel,
    paymentCredentialSubmitted,
    paymentMethodsCustomerSaved,
    isSavePaymentMethodAllowed,
    setSaveCredentials,
    displayError
  } = props;
  const { embeddedJs, embeddedHtml } = storePaymentMethod;
  const [disableSaveCard, setDisableSaveCard] = useState(false);

  const views = {
      newCard: 1,
      savedCards: 2
  };

  const hasCredentials = useRef(false);
  const hasSavedCards = paymentMethodsCustomerSaved && paymentMethodsCustomerSaved.length > 0;
  const [view, setView] = useState(hasSavedCards ? views.savedCards : views.newCard);
  const [isSavedPaymentMethod, setIsSavedPaymentMethod] = useState(true);

  useEffect(() => {
    const onError = event => {
      const { detail, data } = event;
      displayError(detail?.errors);
      logger.logError(detail || data);
      ddLog.logError(detail || data);
    };

    window.addEventListener('onPaymentError', onError);

    const onPaymentEvent = event => {
      const {
        detail: {
          summary,
          paymentMethodId,
          paymentProcessorId,
          credentials,
          saveCredentials = false,
          sessionKey,
          issuer,
          data:{
                cardHolderName,
                expirationDate,
                postcode,
            },
        }
      } = event;

      if (
        // eslint-disable-next-line eqeqeq
        storePaymentMethod.paymentMethodId != paymentMethodId ||
        hasCredentials.current
      ) {
        return;
      }
      onSubmitPaymentCredentials({
        summary,
        storePaymentMethod,
        paymentCredential: {
          paymentMethodId,
          paymentProcessorId,
          credentials,
          saveCredentials,
          sessionKey,
          issuer,
          summary,
          data: {
                cardHolderName,
                expirationDate,
                postcode
            },
        }
      });
    };

    window.addEventListener('onPaymentCredentialsReceived', onPaymentEvent);

    let script = null;
    if (embeddedJs) {
      script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.innerHTML = embeddedJs;
      document.head.appendChild(script);
    }

    return () => {
      window.removeEventListener('onPaymentCredentialsReceived', onPaymentEvent);
      window.removeEventListener('onPaymentError', onError);
      if (!script) return;
      document.head.removeChild(script);
    };
  }, [embeddedJs]);

  const handleChange = () => {
    setIsSavedPaymentMethod(current => !current);
  };

  const onCancelCc = () => {
    if (onCancel) {
      if (hasSavedCards) {
        setView(views.savedCards);
      }
      onCancel();
    }
  };

  useEffect(() => {
    (async() => {
      const response =  await siteSettingRepo.getSiteSettings([siteSetting.DISABLE_SAVE_CARD])
      const isDisabledSaveCard = !!((response?.data?.siteSettings.length > 0 && response?.data?.siteSettings[0].value === '1' ));

      setDisableSaveCard(isDisabledSaveCard);
      setIsSavedPaymentMethod(!isDisabledSaveCard)
    })();
  }, []);

  useEffect(() => {
    setSaveCredentials(isSavedPaymentMethod);
  }, [isSavedPaymentMethod]);

  return (
    <>
      {storePaymentMethod.uiComponentType === uiComponentTypes.detailView  ? (
        <PaymentInputCreditCardSavedList
          storePaymentMethod={storePaymentMethod}
          paymentCredentialSubmitted={paymentCredentialSubmitted}
          onCancel={onCancelCc}
          hideCancel={hideCancel}
          paymentMethodsCustomerSaved={paymentMethodsCustomerSaved}
          hidden={view !== views.savedCards}
          onSubmitPaymentCredentials={onSubmitPaymentCredentials}
          onAddNewCardClick={() => {
              setView(views.newCard);
            }}
        />
       ) : null}

      {storePaymentMethod.uiComponentType === uiComponentTypes.detailView && (isSavePaymentMethodAllowed)  ?  (
        <>
          <div hidden={view !== views.newCard}>
            {!disableSaveCard && (
            <label htmlFor="Save my credit card info">
              <input
                type="checkbox"
                defaultChecked
                value={isSavedPaymentMethod && !disableSaveCard}
                onChange={handleChange}
                name="inputSavePaymentMethod"
                style={{"width" : "8%"}}
              />
              Save my credit card info
            </label>
          )}
          </div>
          <div dangerouslySetInnerHTML={{ __html: embeddedHtml }} hidden={view !== views.newCard} className={styles.secondaryAddressContainer} />
        </>
) : <div style={{"width" : "100%"}} dangerouslySetInnerHTML={{ __html: embeddedHtml }} />}
      {storePaymentMethod.uiComponentType === uiComponentTypes.detailView && !hideCancel ? (
        <div
          className={stylesShared.footerActions}
          onClick={onCancelCc}
          onKeyDown={keyDownA11y(onCancelCc)}
          role="button"
          tabIndex={0}

          hidden={view !== views.newCard}
        >
          Change Payment Method
        </div>
      ) : null}
    </>
  );
};

PaymentInputEmbeddedExternal.propTypes = propTypesComponentPaymentInput;

export const mapDispatchToProps = (dispatch) => ({
  setSaveCredentials: (value) => dispatch(ccDetailsActions.setSaveCredentials(value))
});
export default connect(null, mapDispatchToProps)(PaymentInputEmbeddedExternal);
