/* eslint-disable import/prefer-default-export */
import { SITE_SETTING, SITE_SETTINGS, SAVE_DEVICE_ID } from '../../../graphql/siteSetting';

import { client } from "../../utilities/config";

export const getSiteSetting = name => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: SITE_SETTING,
        variables: {
            name
        }
    });
};
export const getSiteSettings = (names, returnAllCountries) => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: SITE_SETTINGS,
        variables: {
            names,
            returnAllCountries
        }
    });
};

export const saveDeviceId = (deviceID, appVersion) => {
    return client.mutate({
        mutation: SAVE_DEVICE_ID,
        variables: {deviceID, appVersion}
    })
};