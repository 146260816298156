export const ADD_TO_ORDER_TEXT = "Add to Order";
export const ADD_TO_ORDER_NO_ADDRESS_TEXT = "Check Local Availability";
export const UPDATE_PRODUCT_TEXT = "Update Order";
export const OUT_OF_STOCK_TEXT = "Out of Stock";
export const PRODUCT_MAX_QUANTITY_SELECTION = 20;
export const COOKIE_MAGIC_BRANDED = "CookieMagic";
export const COOKIE_DEALS = "deals";
export const PICKUP_UNAVAILABLE_PRODUCT_MESSAGE =
  "The product you selected is not available for pickup";
export const QUICK_CHECKOUT = "Quick Checkout";
export const FEATURED_PRODUCT_TYPE_CART = "cart";
export const FEATURED_PRODUCT_TYPE_DETAIL = "detail";
export const UNAVAILABLE = "unavailable";
export const OPTION_GROUP_TYPE = {
  SIZE_SELECT: "SIZE_SELECT",
  SELECT_LARGE: "SELECT_LARGE",
  PRIMARY: "PRIMARY",
  SELECT_SMALL: "SELECT_SMALL"
};
