import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckoutContainer from '../CheckOutContainer/CheckoutContainer';
import * as orderRepo from '../../shared/repos/graphql/order';
import * as orderActions from '../../redux/actions/order';
import { defaultCurrency } from "../../shared/constants/currency";
import { EZDINE } from '../../shared/constants/loyaltyType';

const coinIcon = require('./imgs/coin-icon.svg');

class CookieDoughWidget extends Component {
  constructor(props) {    
    super(props);    

    this.state = {
        loading: false
    }
  }

  handleUpdateCookieDough = (data) => {
    const { orderUpdated } = this.props;    

    orderUpdated(data);

    this.setState({ 
        loading: false 
    });
  }

  updateCookieDough = async e => {

      e.stopPropagation();

      const { order } = this.props;

      this.setState({
          loading: true
      });

      const response = await orderRepo.updateCookieDough(order);

      this.handleUpdateCookieDough(response.data.updateOrder);
  }

  render() {
      const { loading } = this.state;
      const { order, currency, activeLoyalty } = this.props;
      const { useCookieDough } = order;
      const cookieDoughBalance = order.customer ? order.customer.loyalty.rewards : 0;

      if ( activeLoyalty !== EZDINE) {
        return null;
      }

      return (
        <CheckoutContainer
          onChange={(isSelected, e) => this.updateCookieDough(e)}
          header="Use My Cookie Dough"
          subHeaderAsset={coinIcon}
          subHeader={`${currency.symbol}${  cookieDoughBalance}`}
          disabled={!parseInt(cookieDoughBalance, 10)}
          check
          cookieDough
          loading={loading}
          isSelected={useCookieDough}
        />
      );
  }
}

CookieDoughWidget.propTypes = {
     order: PropTypes.shape({
         useCookieDough: PropTypes.bool,
         id: PropTypes.number,
         customer: PropTypes.shape({
             loyalty: PropTypes.shape({
                 rewards: PropTypes.string.isRequired
             }).isRequired
         })
     }).isRequired,
    orderUpdated: PropTypes.func.isRequired,
    currency: PropTypes.objectOf(PropTypes.string),
    activeLoyalty: PropTypes.string,
};

CookieDoughWidget.defaultProps = {
    currency: defaultCurrency,
    activeLoyalty: null
};

export const mapStateToProps = state => {
    const { currency } = state.currency;
    return {
        order: state.order,
        currency,
        activeLoyalty: state.loyalty.activeLoyalty
    };
};

export const mapDispatchToProps = (dispatch) => ({
    orderUpdated: (value) => dispatch(orderActions.orderUpdated(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieDoughWidget);
