/* eslint-disable import/prefer-default-export */
import {
    COUNTRIES_QUERY,
    PHONE_PREFIXES_QUERY
} from "../../../graphql/country";
import { client } from "../../utilities/config";

/**
 * Get Countries Data
 */
export const getCountries = () => {
    return client.query({
        fetchPolicy: 'network-only',
        query: COUNTRIES_QUERY
    });
};

export const getPhonePrefixes = () => {
    return client.query({
        query: PHONE_PREFIXES_QUERY,
    });
};
