const initialState = {
    isCaloriesActive: false,
    upsellEdit: {openModal: false, product: null, item: null},
    isShowOosProducts: false,
  };
  
  export default (state = initialState, { type, value }) => {
    switch (type) {
      case "CALORIES_ACTIVE":
        return {
          ...state,
          isCaloriesActive: value
        };
      case "UPSELL_PRODUCT_MODAL_ACTIVE":
        return {
          ...state,
          upsellEdit: value
        };
      case "SHOW_OOS_PRODUCTS":
        return {
          ...state,
          isShowOosProducts: value
        };
      default:
        return state;
    }
  };
  