import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

/**
 * Errors we don't want to log
 */
const errorsToIgnore = Object.freeze([
    // please update logger unit test when modifying this list
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:0',
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:1',
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:2'
]);

/**
 * The origins that are allowed to log errors to Sentry. 
 * For example, if a script is loaded from "connect.facebook.net" (3rd party), but "connect.facebook.net" is not in this list, then errors from that script won't be logged.
 */
const urlsAllowedToLog = Object.freeze([
    /https?:\/\/(.*\.)?insomniacookies\.com/,
    /https?:\/\/localhost(:[0-9]+)/
]);

/**
 * 
 * @param {object} event - the Sentry logging event that will be sent to the Sentry servers 
 * @param {object} hint - holds the original exception so that additional data can be extracted or grouping is affected.
 * @returns the event to be sent to Sentry
 */
const beforeSendCallback = (event, hint) => { // eslint-disable-line no-unused-vars
    try {
        if (event && event.extra) {
            if (event.extra.statusCode || event.extra.networkErrorMessage) {
                // eslint-disable-next-line no-param-reassign
                event.fingerprint = [
                    event.extra.statusCode?.toString(),
                    event.extra.networkErrorMessage,
                    event.extra.graphQlOperationName,
                    '{{ default }}'
                ].filter(Boolean);
            } else if (event.extra.graphQlOperationName) {
                // eslint-disable-next-line no-param-reassign
                event.fingerprint = [
                    event.extra.graphQlOperationName,
                    '{{ default }}'
                ];
            }
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error in Logger -> "beforeSendCallback". Setting cusomt fingerprints did not work. See error', e)
    }
    return event;
}

/**
* Initialize the logger (Sentry). This logs unhandled execptions and network errors throughout the application.
* @param {object} environment - the object that stores client-facing environment variables, set in /app/public/index.html
* @param {object} [user] - the currently logged-in user. This is used to enhance metadata of the logs. 
* @param {object} [reduxState] - the current state of the application. 
 */
const initialize = (environment, user, reduxState) => {
    if (!environment?.REACT_APP_SENTRY_DSN) {
        // eslint-disable-next-line no-console
        console.log('REACT_APP_SENTRY_DSN was not set in the environment. Sentry logging will not occur.');
        return;
    }

    Sentry.init({
        dsn: environment.REACT_APP_SENTRY_DSN,
        environment: environment.REACT_APP_ENV || 'unknown',
        integrations: [new Integrations.BrowserTracing()],
        normalizeDepth: 0,
        tracesSampleRate: 0,
        release: environment.REACT_APP_BUILD_ID,
        ignoreErrors: errorsToIgnore,
        allowUrls: urlsAllowedToLog,
        beforeSend: beforeSendCallback
    });

    Sentry.setExtra('Redux State', reduxState);

    if (user?.userId) {
        Sentry.setUser({
            id: user.userId
        });
    }
}

/**
 * 
 * @param {object} error - the Apollo error from the API
 * @param {object} error.networkError - the network error from Apollo. Usually this has the details of a 4xx or 5xx error.
 * @param {object} error.operation - the attempted (but failed) GraphQL operation
 * @param {object} error.response - will have even more error detail like the errors from graphQL. 
 * @param {object} [reduxState] - state of the application used to enhance metadata
 */
const logApiError = ({ networkError, operation, response }, reduxState) => {

    /*
        Error cases:
        1. Network error - client cannot reach endpoint at all. The web facing servers (nginx) are down OR the client has spotty internet. Only networkError.message will most likely be set, no statusCode.
        2. Network error - client can reach endpoint, web facing servers (nginx) are fine, but nginx cannot reach the backing servers. networkError.statusCode will be available which is the status of nginx's attempt to communicate with backing servers
        3. Network error - backend code is throwing an error that is not a graphql error
        3. GraphQL error in an endpoint - code executing in the graphql endpoint threw an error. "errors" should be populated   
    */

    try {
        const { statusCode, bodyText, message: networkErrorMessage } = networkError || {};
        const { operationName: graphQlOperationName } = operation || {};
        const { data: responseData } = response || {};
        let { errors } = response || {};
        errors = errors || []; // not using default in above destructing in case 'errors' is null (defaults only apply when variable is undefined)
        const statusCodeString = statusCode ? `[Status Code ${statusCode}]` : '';
        let errorStrings = errors.map(e => `${e.message}${e.debugMessage ? (` -> ${  e.debugMessage}`) : ''}`);
        errorStrings = ['API Error', graphQlOperationName, statusCodeString, networkErrorMessage, ...errorStrings].filter(Boolean);
        const errorMessage = errorStrings.join(' - ');

        Sentry.withScope((scope) => {
            if (graphQlOperationName) {
                scope.setTag("graphql-operation", graphQlOperationName);
            }
            if (statusCode) {
                scope.setTag("status-code", statusCode.toString());
            }
            scope.setExtras({
                errorStrings,
                networkErrorMessage,
                statusCode,
                graphQlOperationName,
                bodyText,
                responseData,
                errors
            });

            if (reduxState) {
                scope.setExtra('Redux State', reduxState);
            }

            try {
                Sentry.captureException(new Error(errorMessage));
            } catch (e1) {
                // eslint-disable-next-line no-console
                console.error('logApiError -> "Sentry.captureException(new Error(errorMessage))" failed. See error: ', e1)
            }
        });
    } catch (e2) {
        // eslint-disable-next-line no-console
        console.error('logApiError -> Tried to log to Sentry, but failed. See error: ', e2)
    }

}

/**
 * Log an Error instance or a string
 * Note: if you use string, a new Error instance will be created for Sentry, and the stack trace will always point to this logger.logError method.
 * @param {(Error|string)} e - Instance of an Error or a string. 
 * @returns 
 */

const logError = (e) => {
    let errorToLog;
    const isError = e instanceof Error || (e && e.stack && e.message);
    const isString = typeof e === 'string';

    if (isString) {
        errorToLog = new Error(e);
    } else if (isError) {
        errorToLog = e;
    } else {
        errorToLog = JSON.stringify(e);
    }

    try {
        Sentry.captureException(errorToLog);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('logger.logException failed because Sentry.captureException threw an exception. See error for more detials', e)
    }
}

export default { initialize, beforeSendCallback, logApiError, errorsToIgnore, urlsAllowedToLog, logError };