import React, { Component } from 'react';
import Appboy from '@braze/web-sdk';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom'
import * as contentCardConstants from "../../../../shared/constants/contentCards";
import windowNames from '../../../../shared/constants/windowNames';
import { routeCountryPath } from '../../../../shared/utilities/common';

export class ContentCardBase extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };

    this.categoryElement = React.createRef();
  }

    handleClick = (cardData) => {
      const {
        history,
        contentCardSession,
      } = this.props;

      if (cardData) {
        const { url, extras } = cardData;
        const { logEventName } = extras;
        Appboy.logCardClick(cardData, true);
        const isExternal = cardData.url.startsWith(contentCardConstants.EXTERNAL_URL_PREFIX);
        
        contentCardSession(true);
        if (logEventName !== undefined) {
          Appboy.logCustomEvent(logEventName, {});
        }
        if (isExternal) {
          window.open(cardData.url, windowNames.CONTENT_CARD_SESSION);
        } else {
          const hasDeepLink = url.match('#');
          if (hasDeepLink !== null) {
            const redirectLink = url.replace('#', "");
            this.handleDeepLink(redirectLink);
          } else {
            history.push(routeCountryPath(cardData.url));
          }
        }
      }
    }

    render() {
      return null;
    }
}

ContentCardBase.propTypes = {
  cardData: PropTypes.shape(
    {
      id: PropTypes.string,
      imageUrl: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      extras: PropTypes.shape(
        {
          template: PropTypes.string,
          isLimited: PropTypes.string,
          isCookieLab: PropTypes.string,
          leftImage: PropTypes.string,
          rightImage: PropTypes.string,
          actionButtonColorText: PropTypes.string,
          actionButtonFill: PropTypes.string,
          actionButtonText: PropTypes.string,
          logEventName: PropTypes.string
        }
      )
    }
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  contentCardSession: PropTypes.func.isRequired
}

export default withRouter(ContentCardBase);