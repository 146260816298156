import React, { Component } from 'react';
import styles from './PrivacyPolicy.module.css'
import * as cms from '../../shared/repos/cms/cms';
import cmsKeys from '../../shared/constants/cms';
import Loader from '../../components/Loader/Loader';


class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
          loading: true,
          content: []
      };
  }

  componentDidMount() {
      cms.getContent([cmsKeys.privacyPolicy], this);
  }

  render() {
      const {content, loading} = this.state;

      if (loading) {
          return (
            <div className={styles.pageWrapper}>
              <div className={styles.pageContainer}>
                <Loader id="loader" />
              </div>
            </div>
          );
      }
    
      if (!content.length && !loading) {
        return (
          <div className={styles.pageWrapper}>
            <div className={styles.pageContainer}>
              <h1>Oops, No data found for this page</h1>
            </div>
          </div>
        );
      }

      return (
        <div className={styles.pageWrapper}>
          {content.map(privacyPolicy => {
              return (
                <div className={`${styles.pageContainer} text-dark dark:text-white`}>
                  <div className={styles.pageSection}>
                    {/* eslint-disable-next-line */}
                    <div dangerouslySetInnerHTML={{__html: privacyPolicy.value}} />
                  </div>
                </div>
              )
          })}
        </div>
      );
  }
}

export default PrivacyPolicy
