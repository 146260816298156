import React from "react";
import PropTypes from "prop-types";

import Link from "../../components/LinkWithCountry/LinkWithCountry";
import {
  getContentsByKey,
  getContentValueByKey
} from "../../shared/utilities/common";

function Faqs(props) {
  const { contents } = props;
  const heading = getContentValueByKey(contents, "heading");
  const description = getContentValueByKey(contents, "description");
  const links = getContentsByKey(contents, "links");

  return (
    <div className="w-full flex flex-col h-[158px] md:items-center justify-center gap-3 px-4">
      <h1 className="text-dark font-congenialBlack text-4xl leading-9 tracking-[-1px]">
        {heading}
      </h1>

      <p className="font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] text-darkElevationPrimary">
        {description}
      </p>

      <div className="flex flex-col md:flex-row gap-3">
        {links &&
          links.map(l => {
            const title = getContentValueByKey(l.children, "title");
            const url = getContentValueByKey(l.children, "url");
            return (
              <Link to={url}>
                <button
                  type="button"
                  className="
              bg-button text-lg leading-[22px] tracking-[-0.1px] 
              font-filsonProBold text-white h-[52px] rounded-[40px] px-5 py-[15px]"
                >
                  {title}
                </button>
              </Link>
            );
          })}
      </div>
    </div>
  );
}

Faqs.propTypes = {
  contents: PropTypes.shape({}).isRequired
};

export default Faqs;
