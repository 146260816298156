/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line jsx-a11y/interactive-supports-focus
import React from "react";

import PropTypes from "prop-types";

import styles from "./DeliveryLocationsListItem.module.css";

const DeliveryLocationsListItem = ({
    icon,
    address1,
    addressDetails,
    onClick,
    isSelected,
    customStyles,
    isFooter,
    onMouseDown
}) => {
    const street = (addressDetails?.address1 && !addressDetails?.dormId) ? addressDetails.address1 : address1.split(",")[0];
    const city = addressDetails && addressDetails.city? addressDetails.city : address1.split(",")[1];
    const state = addressDetails && addressDetails.state? addressDetails.state : address1.split(",")[2];

    return (
      <div
        onClick={() => onClick()}
        onKeyDown={() => onClick()}
        onMouseDown={e => onMouseDown(e)}
        className={[
            styles.deliveryLocationsContainer,
            isSelected ? styles.deliveryLocationsSelected : null,
            isFooter ? styles.footerDeliveryLocationsContainer : null,
            "bg-white text-dark",
            "hover:bg-light",
            "border-b-[1px] border-light",
            customStyles
        ].join(" ")}
        id={address1}
      >
        <div className="w-full">
          <p 
            data-ddog-id="delivery_location_listitem" 
            className="text-dark"
          >
            {street}
, 
            {' '}
            {city}
, 
            {' '}
            {state}
          </p>
        </div>
        <img alt="location-icon" src={icon} />
      </div>
    );
};

DeliveryLocationsListItem.propTypes = {
    icon: PropTypes.node.isRequired,
    address1: PropTypes.string.isRequired,
    addressDetails: PropTypes.shape({
        address1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string
    }),
    onClick: PropTypes.func.isRequired,
    customStyles: PropTypes.any, //eslint-disable-line
    isSelected: PropTypes.bool,
    isFooter: PropTypes.bool,
    onMouseDown: PropTypes.func
};

DeliveryLocationsListItem.defaultProps = {
    isSelected: false,
    isFooter: false,
    addressDetails:{
        address1:null,
        city:null,
        state:null,
    },
    onMouseDown: () => {}
};

export default DeliveryLocationsListItem;
