/* eslint-disable react/sort-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */

import React, { Component, useState } from "react";
import { connect } from "react-redux";
import _ from "underscore";
import { useMutation } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import {
  LOCATIONS_VIA_ADDRESS_QUERY,
  STORES_QUERY
} from "../../graphql/location";
import { UPDATE_ORDER_LOCATION } from "../../graphql/order";
import * as storeRepo from "../../shared/repos/graphql/store";
// REDUX
import * as addressActions from "../../redux/actions/address";
import * as elementsActions from "../../redux/actions/elements";
import * as userActions from "../../redux/actions/user";
import * as countryActions from "../../redux/actions/country";
import * as loyaltyAction from '../../redux/actions/loyalty';

// COMPONENTS
import MapComponent from "../../components/MapComponent/MapComponent";
import DeliveryLocationListItem from "../../components/DeliveryLocationsListItem/DeliveryLocationsListItem";
import Loader from "../../components/Loader/Loader";
// HELPERS
import { getCurrentPosition } from "../../shared/utilities/geolocation";
import { client, setSessionToken } from "../../shared/utilities/config";

// SUB UI RENDERINGS
import {
  handlePickupSubmit,
  renderChosenLocation,
  renderExitIcon,
  renderListItems
} from './renderings';
import
  DeliveryPickUpWidget
 from "../../components/DeliveryPickUpWidget/DeliveryPickUpWidget";
import styles from './Locations.module.css';
import {orderMethods, orderTypeIds} from "../../shared/constants/order";
import {shippingMethods} from "../../shared/constants/store";
import * as orderRepo from "../../shared/repos/graphql/order";
import * as reduxStore from "../../redux/store";
import { routeCountryPath } from "../../shared/utilities/common";
import { METADATA_INFO } from "../../shared/constants/common";
import endpoints from "../../shared/constants/endpoints";
import {setCaptchaToken} from "../../shared/utilities/captcha";
import captchaAction from "../../shared/constants/captchaAction";
import httpStatusConstant from "../../shared/constants/httpStatus";
import ReCAPTCHAForm from "../../components/ReCaptcha/ReCaptcha";
import elementConstants from "../../shared/constants/element";
import {getLocations} from "../../shared/repos/graphql/locations";

const searchIcon = require("./imgs/search-icon.svg");
const searchArrow = require("./imgs/current-location-icon.svg");
const locationIcon = require("./imgs/location-icon.svg");
const clearIcon = require("./imgs/close-icon.svg");
const lightExitIcon = require("./imgs/light-exit-icon.svg");
const mobileBackIcon = require("./imgs/mobile-back.svg");

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationsList: props.location?.state?.stores || [],
      showLocation: false,
      selectedAddress: "",
      mobileSearchHeight: "calc(40vh)",
      searchValue: props.widgetValue,
      searchLocation: { lat: null, lng: null, externalId: null },
      chosenLocationIndex: undefined,
      locationLoading: false,
      storesLoading: false,
      showDefaultMap: false,
      selectedStoreIdFromTheSearchList: null,
      showModal: false,
      addressType: "pickup",
      selectedStore: {},
      showLocationSearchResults: false,
      hasCaptchaError: false,
      showCaptchaChallenge: false,
      skipGenerateNewToken: false,
      locationSearchError: null,
    };
    this.searchInput = React.createRef();
    this.stores = React.createRef();
    this.recaptchaComponentRef = React.createRef();

    this.setSearchPosition = this.setSearchPosition.bind(this);
  }

  componentDidMount() {
    setSessionToken(uuidv4());
    const { match, useRecaptcha, setVerificationToken, setVerificationAction } = this.props;
    const storeId = match.params.store_id;

    if (storeId) {
      if (!Number.isNaN(storeId) && !Number.isNaN(parseFloat(storeId))) {
        this.getStore(storeId);
      } else {
        this.getStoreByLocationName(storeId);
      }
    } else {
      this.initState();
    }

    if (useRecaptcha === 1) {
      const { skipGenerateNewToken } = this.state;
      setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.addressSearching, skipGenerateNewToken);
    }
  }

  async componentDidUpdate(prevProps) {
    const { location, selectedMapStore } = this.props;
    if (location !== prevProps.location) {
      this.initState();
    }

    if (prevProps.selectedMapStore !== selectedMapStore) {
      await this.handleSelectedMapStore();
    }
  }

  componentWillUnmount() {
    const { setRedeemableItem } = this.props;
    setRedeemableItem({
      redeemableType: null,
      redeemableId: null
    })
  }

  async handleSelectedMapStore() {
    const { showLocation } = this.state;
    const { setSelectedMapStore, selectedMapStore } = this.props;

    if (showLocation) {
      await this.closeShownLocation();

      setTimeout(() => {
        setSelectedMapStore({ ...selectedMapStore });
      });
    }
  }

  async closeShownLocation() {
    await this.setState({ showLocation: false });
  }

  initState() {
    const { location } = this.props;
    const { showLocation } = this.state;

    if (location?.state?.showLocationSearchResults) {
      this.setState({
        showLocationSearchResults: location.state.showLocationSearchResults,
        searchValue: location.state.selectedAddress
      });
    }

    if (
      location.state &&
      location.state.myLocation &&
      location.state.searchLocation &&
      location.state.selectedAddress &&
      location.state.stores
    ) {
      const locationsList = location.state.stores ? location.state.stores : [];
      const isShowLocation = locationsList.length === 0 ? false : showLocation;

      this.setState({
        searchLocation: location.state.searchLocation,
        myLocation: { lat: "", lng: "" },
        locationsList,
        selectedAddress: location.state.selectedAddress,
        searchValue: location.state.selectedAddress,
        showLocation: isShowLocation
      });
    }

    this.getCurrentPosition();
  }

  getCurrentPosition() {
    const { location } = this.props;
    const locationDefined = !!(location.lat && location.lng);

    if (locationDefined) return;

    this.setState({ locationLoading: true });
    getCurrentPosition(this.setSearchPosition, this);
  }

  setSearchPosition(response) {
    const { searchValue, selectedAddress } = this.state;
    const { location } = this.props;
    const hasSelectedAddress = !!(
      location.state && location.state.selectedAddress
    );
    let address = "";

    if (
      (selectedAddress === "" || selectedAddress === undefined) &&
      response &&
      response.results &&
      response.results[0] &&
      response.results[0].place_id &&
      response.results[0].geometry &&
      response.results[0].geometry.location &&
      response.results[0].geometry.location.lat &&
      response.results[0].geometry.location.lng
    ) {
      this.setState({
        searchLocation: {
          externalId: response.results[0].place_id,
          lat: response.results[0].geometry.location.lat,
          lng: response.results[0].geometry.location.lng
        }
      });
    }

    if (searchValue !== "" && searchValue !== undefined) {
      this.setState({
        locationLoading: false
      });
      return null;
    }

    if (!response) {
      const data = {
        searchValue: address,
        locationLoading: false,
        selectedAddress: address
      };

      if (location.state) {
        address = location.state.selectedAddress;

        data.selectedAddress = address;
        data.searchValue = address;

        this.getStores(location.state.searchLocation);
      }

      this.setState({
        locationLoading: false,
        searchValue: address,
        selectedAddress: address
      });
      return null;
    }

    address = hasSelectedAddress
      ? location.state.selectedAddress
      : response.results[0].formatted_address;

    const myAddress = response.results[0].formatted_address;
    const externalId = response.results[0].place_id;

    const positionLocation = {
      externalId
    };

    const stateSearchLocation = hasSelectedAddress
      ? location.state.searchLocation
      : positionLocation;

    this.setState({
      locationLoading: false,
      searchValue: address,
      selectedAddress: address,
      searchLocation: stateSearchLocation,
      myLocation: positionLocation,
      myAddress
    });

    if (!location?.state?.stores?.length) {
      return this.getStores(stateSearchLocation);
    }
    return null;
  }

  async getStores(variables) {
    const { storesLoading } = this.state;

    if (storesLoading) {
      return;
    }

    this.setStoreLoading(true);

    try {
      const response = await client.query({
        query: STORES_QUERY,
        fetchPolicy: "network-only",
        variables
      });

      this.getStoresCallback(response.data.storeSearch);
    } catch (e) {
      this.setStoreLoading(false);
    }
  }

  getStore(id) {
    this.setStoreLoading(true);

    const { setAutocompleteSessionToken } = this.props;
    try {
      storeRepo.getStore(id).then(response => {
        if (response.data.store) {
          setAutocompleteSessionToken(null);
          const locationsList = response.data.store
            ? [response.data.store]
            : [];
          this.setState({
            showDefaultMap: false,
            selectedAddress: " ",
            locationsList,
            chosenLocationIndex: 0,
            showLocation: locationsList.length > 0
          });
        } else {
          this.setState({
            showLocation: false,
            searchValue: " ",
            selectedAddress: " ",
            locationsList: []
          });
        }
        this.setStoreLoading(false);
      });
    } catch (err) {
      this.setState({
        showLocation: false,
        searchValue: " ",
        selectedAddress: " ",
        locationsList: []
      });
      this.setStoreLoading(false);
    }
  }

  getStoreByLocationName(locationName) {
    this.setStoreLoading(true);
    const { setAutocompleteSessionToken } = this.props;
    try {
      storeRepo.getStoreByLocationName(locationName).then(response => {
        if (response.data.storesByLocation) {
          setAutocompleteSessionToken(null);
          const locationsList = response.data.storesByLocation
            ? [response.data.storesByLocation]
            : [];
          this.setState({
            showDefaultMap: false,
            selectedAddress: " ",
            locationsList,
            chosenLocationIndex: 0,
            showLocation: locationsList.length > 0
          });
        } else {
          this.setState({
            showLocation: false,
            searchValue: " ",
            selectedAddress: " ",
            locationsList: []
          });
        }
        this.setStoreLoading(false);
      });
    } catch (err) {
      this.setState({
        showLocation: false,
        searchValue: " ",
        selectedAddress: " ",
        locationsList: []
      });
      this.setStoreLoading(false);
    }
  }

  storeSelect = async (e, updateOrderLocation) => {
    const { userCart, userOrderMethod } = this.props;
    const {selectedStore} =  this.state;
    const currentOrderNotPickup = userOrderMethod ? userOrderMethod !== orderMethods.pickup : false;
    const isItemInCart = userCart?.length > 0 ;
    const changeStock = currentOrderNotPickup || isItemInCart;
    await handlePickupSubmit(e,changeStock,this,selectedStore,updateOrderLocation);
  };

  onCloseWidget = (setShowDeliveryWidget) => {
    this.setState({
      showModal: false,
    })
    setShowDeliveryWidget(false);
  };

  getStoresCallback(storeSearch) {
    const { addressUpdated, setAutocompleteSessionToken } = this.props;
    const { selectedAddress, searchLocation, showLocation } = this.state;

    addressUpdated(storeSearch.address);

    const storeSearchCoords = {
      lat: storeSearch.lat,
      lng: storeSearch.lng
    };
    const locationsList = storeSearch.stores ? storeSearch.stores : [];
    if (locationsList.length > 0) {
      setAutocompleteSessionToken(null);
      setSessionToken(uuidv4());
    }
    const isShowLocation = locationsList.length === 0 ? false : showLocation;
    this.setState({
      showDefaultMap: false,
      locationsList,
      searchLocation: selectedAddress
        ? {
            ...searchLocation,
            ...storeSearchCoords
          }
        : storeSearchCoords,
      showLocation: isShowLocation,
      showLocationSearchResults: locationsList.length > 0,
    });
    this.setStoreLoading(false);
  }

  myLocationSearch() {
    const { myAddress, myLocation } = this.state;

    this.setState({
      searchValue: myAddress
    });

    this.getStores(myLocation);
  }

  redirectToPdp = location => {
    const { redeemables, setRedeemableItem } = this.props;

    if (redeemables?.redeemableType) {
      setRedeemableItem({
        redeemableType: null,
        redeemableId: null
      })
      return routeCountryPath(endpoints.loyalty);
    }

    return location;
  };

  /**
   * Clears search value state
   */
  clearSearch = () => {
    const { setWidgetValue, setSelectedMapStore } = this.props;
    this.setState({
      searchValue: "",
      showLocationSearchResults: true,
    });
    setWidgetValue("");
    this.searchInput.current.focus();
    setSelectedMapStore(null);
  };

  /**
   * Handles location filtering based on input component value
   * @param value
   * @param variables
   */

  handleLocationFilter(value, variables) {
    const { setWidgetValue } = this.props;
    const { locationsList, selectedAddress } = this.state;
    const minLengthMet = value.length > 2;
    // reset redux value
    setWidgetValue("");
    // reset local state
    this.setState({
      searchValue: value,
      selectedAddress: "",
      showLocationSearchResults: !(locationsList.length > 0),
    });
    // if no input value, scroll element down
    if (!minLengthMet && locationsList.length === 0)
      return this.setState({ mobileSearchHeight: 90 });
    // otherwise increase search height to 50% of screen height
    this.setState({ mobileSearchHeight: "50%" });
    // get addresses
    if (variables && minLengthMet && !selectedAddress) {
      const query = variables;
      query.address = value;
      this.getAddressesDebounced(query);
    }
    return null;
  }

  getAddressesDebounced = _.debounce(async (variables) => {
    const { useRecaptcha,  setVerificationToken, setVerificationAction } = this.props;
    const { skipGenerateNewToken } = this.state;
    if (useRecaptcha === 1) {
      await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.addressSearching, skipGenerateNewToken);
    }
    this.getLocations(variables);
  }, 500);

  async getLocations(variables) {
    this.setState({ addressesLoading: true });
    const { setVerificationProvider } = this.props;

    try {
      const response = await client
        .query({
          query: LOCATIONS_VIA_ADDRESS_QUERY,
          fetchPolicy: "no-cache",
          variables
        });

        this.setState({
          showDefaultMap: false,
          addressesData: response.data,
          addressesLoading: false,
          locationLoading: false,
          showLocationSearchResults: false,
        });
        setVerificationProvider('');
    } catch (e) {
      this.locationErrorHandler(e.message)
    }
  }

  locationErrorHandler = (errorMsg) => {
    const hasUnauthorized = errorMsg.indexOf(httpStatusConstant.unauthorised) !== -1;
    if (hasUnauthorized) {
      this.setState({
        hasCaptchaError: hasUnauthorized,
        showCaptchaChallenge: hasUnauthorized,
      });
    } else {
      this.setState({
        showCaptchaChallenge: false,
        hasCaptchaError: false,
        skipGenerateNewToken: false,
        addressesData: {
          locationSearch: []
        },
      });
    }
    this.setState({ addressesLoading: false, locationSearchError: errorMsg });
  }

  setStoreLoading(isStoreLoading) {
    this.setState({
      storesLoading: isStoreLoading
    });
  }

  handleViewMenuClick = (store, e) => {
    this.setState({
      selectedStore: store,
      showModal: true,
    })
  };

  /**
   * Renders list search input UI
   */
  renderListSearch = () => {
    const {
      searchValue,
      selectedAddress,
      locationLoading,
      locationsList,
      myLocation,
      addressesData,
      addressesLoading,
      storesLoading,
      hasCaptchaError,
      locationSearchError
    } = this.state;
    const { lat, lng } = myLocation || { lat: "", lng: "" };
    const { widgetValue, userOrderMethod } = this.props;
    const showDorms = userOrderMethod !== orderMethods.shipping ?? false;

    const addressQueryVars = {
      address: searchValue,
      fullyQualified: false,
      showDorms
    };

    if (lat && lng) {
      addressQueryVars.lat = lat;
      addressQueryVars.lng = lng;
    }

    return (
      <div style={{ height: !locationsList.length ? "100%" : "" }}>
        <div className={`${styles.locationsInputWrapper}`}>
          <div
            className={`
            border-[1px] border-lightDry rounded-[48px] px-4
            ${styles.locationInputContainer}
            `}
          >
            <img alt="location-search" src={searchIcon} />
            <input
              type="text"
              id="search_location"
              ref={this.searchInput}
              value={widgetValue.length > 0 ? widgetValue : searchValue}
              className="text-dark dark:text-white bg-transparent inputDarkModeOverride"
              onChange={e =>
                this.handleLocationFilter(e.target.value, addressQueryVars)}
              placeholder="Search store, city, state, zip"
            />
            {(searchValue.length > 0 || widgetValue.length > 0) &&
            !addressesLoading ? (
              <>
                <img
                  onClick={this.clearSearch}
                  className={`${styles.clearIcon} block dark:hidden`}
                  alt="clear-icon"
                  src={clearIcon}
                />
                <img
                  onClick={this.clearSearch}
                  className={`${styles.clearIcon} hidden dark:block`}
                  alt="clear-icon"
                  src={lightExitIcon}
                />
              </>
            ) : null}
            {addressesLoading && searchValue.length > 0 ? (
              <span>
                <Loader height={25} />
              </span>
            ) : null}
          </div>
          {locationLoading ? (
            <span>
              <Loader height={25} />
            </span>
          ) : (
            myLocation && (
              <img
                alt="location-search"
                onClick={() => {
                  this.setState({
                    searchLocation: myLocation
                  });
                  this.myLocationSearch();
                }}
                src={searchArrow}
              />
            )
          )}
        </div>
        {hasCaptchaError ? this.renderCaptchaChallenge() : (locationSearchError) ? this.renderErrorUI() : null}
        {/* styling required here */}
        {addressesLoading ? null : searchValue.length > 0 &&
          !selectedAddress &&
          addressesData &&
          addressesData.locationSearch.length > 0 ? (
            <div className={styles.locationSearchAutoComplete}>
              {addressesData.locationSearch.map(location => (
                <DeliveryLocationListItem
                  customStyles={styles.customLocationItemStyles}
                  onClick={() => {
                  const searchLocation = {
                    externalId: location.externalId,
                    lat: location.lat,
                    lng: location.lng
                  };
                  this.setState({
                    searchLocation,
                    searchValue: location.address1,
                    selectedAddress: location.address1,
                    showLocationSearchResults: true,
                  });

                  this.getStores(searchLocation);
                }}
                  key={location.address1}
                  icon={locationIcon}
                  address1={location.address1}
                  addressDetails={location.addressDetails}
                />
            ))}
            </div>
        ) : null}
        {searchValue.length > 0 &&
        selectedAddress &&
        !storesLoading &&
        !locationsList.length ? (
          <div className={styles.locationSearchAutoComplete}>
            <div className={styles.noStoresFound}>
              <h3 className="text-dark dark:text-white">No stores found</h3>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  renderCaptchaChallenge = () => {
    const { showCaptchaChallenge } = this.state;
    const { useRecaptcha } = this.props;
    return (
      (showCaptchaChallenge && useRecaptcha) ? (
        <div className={styles.errorContainer}>
          <ReCAPTCHAForm
            ref={this.recaptchaComponentRef}
            setToken={this.onVerifyCaptcha}
          />
        </div>
      ) : null
    )
  };

  renderErrorUI = () => (
    <div className={styles.errorContainer}>
      <p>Oops, something went wrong</p>
      <p>There was a network error, try refreshing your browser.</p>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span onClick={() => window.location.reload()}>Reload Page</span>
    </div>
  );

  onVerifyCaptcha = (token) => {
    if (token) {
      this.setState({
        showCaptchaChallenge: false,
        skipGenerateNewToken: true
      });

      const { setVerificationToken, setVerificationProvider, userOrderMethod } = this.props;
      const { searchValue } = this.state;
      setVerificationToken(token);
      setVerificationProvider(elementConstants.challengeRecaptcha);

      const showDorms = userOrderMethod !== orderMethods.shipping ?? false;

      const addressQueryVars = {
        address: searchValue,
        fullyQualified: false,
        showDorms
      };
      this.handleLocationFilter(searchValue, addressQueryVars);
    }
  }

  locationsContainer = () => {
    const {
      showLocation,
      mobileSearchHeight,
      searchLocation,
      chosenLocationIndex,
      locationsList,
      showDefaultMap,
      selectedStoreIdFromTheSearchList,
      showModal,
      addressType,
      searchValue,
      selectedStore,
      showLocationSearchResults
    } = this.state;

    const {
      userOrderMethod,
      widgetOption,
      history,
      addressComponents,
      userId,
      setUserCartId,
      setSelectedStore
    } = this.props;

    const updateOrderLocations = useMutation(UPDATE_ORDER_LOCATION, {
      onCompleted: async res => {
        const { storeId } = res.updateOrder;
        const state = reduxStore.store.getState();
        const { userCartId } = state.user;

        if (!userCartId) {
          const address = addressComponents
            ? {
                address1: addressComponents.address1,
                city: addressComponents.city,
                lat: addressComponents.lat,
                lng: addressComponents.lng,
                postcode: addressComponents.postcode,
                state: addressComponents.state,
                countryId: addressComponents.countryId
              }
            : null;

          const data = {
            storeId,
            orderTypeId: orderTypeIds.pickup,
            shippingMethod: shippingMethods.pickup,
            address,
            customerId: userId || 0
          };

          const response = await orderRepo.createCart(data);
          const { createCart } = response.data;

          setUserCartId(createCart.code);
        }
        history.push(routeCountryPath(endpoints.getMenuUrl(null)));
      }
    });

    const updateOrderLocation =
      updateOrderLocations.length > 0 ? updateOrderLocations[0] : null;

    const [ setAddresType] = useState();
    const [showDeliveryWidget, setShowDeliveryWidget] = useState(false);

    return (
      <div
        className={`${styles.pageWrapper} max-w-screen-desktop mx-auto
          bg-white dark:bg-gradient-from-t dark:bg-gradient-to-b dark:from-darkBlack dark:to-dark`}
      >
        <div id="deliveryPickUpWidget">
          {showModal ? (
            <DeliveryPickUpWidget
              inputValue={searchValue}
              showPickUpButton
              clickPickUpButton={(e) => this.storeSelect(e, updateOrderLocation)}
              onClose={(e) => this.onCloseWidget(setShowDeliveryWidget)}
              onAddressType={setAddresType}
              addressType={addressType}
              selectedPopupStore={selectedStore}
            />
          ) : null}
        </div>
        <div className={`${styles.locationsContainer} md:flex flex-col gap-4`}>
          {showLocation ? renderExitIcon(this) : null}
          {/* MAIN HEADER */}
          {showLocation ? (
            <h1
              className="
               text-dark dark:text-white
              font-congenialBlack font-black text-4xl leading-9 tracking-tighter"
            >
              {locationsList[chosenLocationIndex].name}
            </h1>
          ) : (
            <h1
              className="text-dark dark:text-white
                font-congenialBlack font-black text-4xl leading-9 tracking-tighter"
            >
              {METADATA_INFO.HEADER_LINK.LOCATION}
            </h1>
          )}
          {/* SEARCH ELEMENT */}
          {!showLocation ? this.renderListSearch() : null}
          {/* LOCATION SEARCH LIST */}
          {showLocationSearchResults && !showLocation && locationsList
            ? renderListItems(this, locationsList, updateOrderLocation, this.handleViewMenuClick)
            : null}
          {/* DELIVERY PICKUP SEARCH LIST */}
          {!showLocation && widgetOption !== "delivery" && locationsList
            ? renderListItems(this, locationsList, updateOrderLocation, this.handleViewMenuClick)
            : null}
          {/* CHOSEN LOCATION */}
          {showLocation && chosenLocationIndex !== undefined
            ? renderChosenLocation(
                this,
                locationsList[chosenLocationIndex],
                updateOrderLocation,
                userOrderMethod,
                this.handleViewMenuClick
              )
            : null}
        </div>
        <div className={styles.mapContainer}>
          <img
            onClick={() => history.goBack()}
            alt="mobile-back"
            src={mobileBackIcon}
          />

          <MapComponent
            showDefaultMap={showDefaultMap}
            mode="locations"
            stores={locationsList}
            searchLocation={
              searchLocation && searchLocation.lat && searchLocation.lng
                ? searchLocation
                : undefined
            }
            searchAddress={addressComponents}
            center={
              searchLocation.lat && searchLocation.lng
                ? searchLocation
                : undefined
            }
            selectedStoreId={selectedStoreIdFromTheSearchList}
          />
          {/* MOBILE STYLES */}
          <div
            className={`${styles.locationsContainerMobile} bg-white dark:bg-dark`}
            style={{ height: showLocation ? "75%" : mobileSearchHeight }}
          >
            {showLocation ? renderExitIcon(this) : null}
            {/* CHOSEN LOCATION */}
            {showLocation && chosenLocationIndex !== undefined
              ? renderChosenLocation(
                  this,
                  locationsList[chosenLocationIndex],
                  updateOrderLocation,
                  userOrderMethod,
                  this.handleViewMenuClick
                )
              : null}
            {/* SEARCH ELEMENT */}
            {!showLocation ? this.renderListSearch() : null}
            {/* SEARCH LIST */}
            {!showLocation && locationsList
              ? renderListItems(this, locationsList, updateOrderLocation, this.handleViewMenuClick)
              : null}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <this.locationsContainer />;
  }
}

const mapStateToProps = state => {
  const { addressComponents } = state.address;
  const { widgetOption, widgetValue, useRecaptcha } = state.elements;
  const {
    userCartId,
    selectedMapStore,
    userOrderMethod,
    userCart,
    selectedStore,
    userId
  } = state.user;
  const { phonePrefixes } = state.country;
  const {
    selectedCountry,
    allCountries,
    isCountrySelectActive
  } = state.country;
  const { redeemables } = state.loyalty
  return {
    widgetOption,
    widgetValue,
    userCartId,
    addressComponents,
    userCart,
    selectedMapStore,
    userOrderMethod,
    phonePrefixes,
    selectedCountry,
    allCountries,
    isCountrySelectActive,
    selectedStore,
    redeemables,
    userId,
    useRecaptcha
  };
};

export const mapDispatchToProps = dispatch => ({
  setWidgetValue: value => dispatch(elementsActions.setWidgetValue(value)),
  setWidgetOption: value => dispatch(elementsActions.setWidgetOption(value)),
  setSelectedStore: value => dispatch(userActions.setSelectedStore(value)),
  setUserOrderMethod: value => dispatch(userActions.setUserOrderMethod(value)),
  setUserAddress: value => dispatch(userActions.setUserAddress(value)),
  setModalObject: value => dispatch(elementsActions.setModalObject(value)),
  addressUpdated: value => dispatch(addressActions.addressUpdated(value)),
  setUserCartId: value => dispatch(userActions.setUserCartId(value)),
  setSelectedMapStore: value =>
    dispatch(userActions.setSelectedMapStore(value)),
  setAutocompleteSessionToken: value =>
    dispatch(elementsActions.setAutocompleteSessionToken(value)),
  setSelectedCountry: value =>
    dispatch(countryActions.setSelectedCountry(value)),
  setRedeemableItem: value => dispatch(loyaltyAction.setRedeemableItem(value)),
  setVerificationToken: value =>
    dispatch(elementsActions.setVerificationToken(value)),
  setVerificationAction: value =>
    dispatch(elementsActions.setVerificationAction(value)),
  setVerificationProvider: value =>
    dispatch(elementsActions.setVerificationProvider(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
