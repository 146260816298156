const initialState = {
    isShippingActive: true,
};

export default(state = initialState, { type, value }) => {
    switch (type) {
        case 'SHIPPING_ACTIVE':
            return {...state, isShippingActive: value};
        default:
            return state;
    }
}
