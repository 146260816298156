export const METADATA_INFO  = {
    PAGE_TITLES: {
        LOCATION: 'Find a Bakery',
    },
    HEADER_LINK: {
        LOCATION: 'Find a Bakery'
    }
};

export const ERROR_CODES = {
    ORDER_ALREADY_COMPLETED: 'ORDER_ALREADY_COMPLETED',
    CAN_NOT_LOCATE_ORDER: 'CAN_NOT_LOCATE_ORDER',
}
