/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

// COMPONENTS

// HELPERS 

import styles from './Loyalty.module.css';

const loyaltyAsset = require('./imgs/loyalty-asset.svg');

/* eslint-disable import/prefer-default-export */
export const renderGiftHeroLeft = () => {
  return(
    <div className={styles.topLeft}>
      <h1>
        Get your favorite
        <br />
        cookies on us
      </h1>

      <p>
        Collect points with Cookie Dough to earn cookies.
      </p>
    </div>
  )
}

export const renderGiftHeroRight = () => (
  <div className={styles.topRight}>
    <img alt='confirmation' src={loyaltyAsset} />
  </div>
)
