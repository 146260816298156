import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import element from "../../shared/constants/element";

const ResendOtp = ({ handleClick }) => {
  const [seconds, setSeconds] = useState(element.resendOtpTimeout);
  const [time, setTime] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prevSeconds => {
          const minutes =
            `${Math.floor(prevSeconds / 60) 
            }:${ 
            prevSeconds % 60 ? prevSeconds % 60 : "00"}`;
          setTime(minutes);
          return prevSeconds - 1;
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleResendClick = () => {
    handleClick();
    setSeconds(element.resendOtpTimeout);
  };

  const showTimer = seconds > 0;

  return showTimer ? (
    <button
      disabled
      type="button"
      className="h-8 bg-disabled text-[#C1A4C9] text-sm font-filsonProRegular leading-4 p-0"
    >
      I didn’t receive a code (
      {time}
)
    </button>
  ) : (
    <button
      onClick={handleResendClick}
      type="button"
      className="h-8 bg-light text-[#1A0021] text-sm font-filsonProRegular leading-4 p-0"
    >
      I didn’t receive a code
    </button>
  );
};

ResendOtp.propTypes = {
  handleClick: PropTypes.func.isRequired, 
};

export default ResendOtp;
