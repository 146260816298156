import React from "react";
// eslint-disable-next-line import/no-cycle
import Footer from "./Footer";

const DarkAppleStoreIcon = require("./imgs/logo-apple.svg");
const DarkPlayStoreIcon = require("./imgs/logo-google-playstore.svg");
const MobileIcon = require("./imgs/mobile.png");

function FooterSection() {
  return (
    <div className="w-full flex flex-col items-center justify-center gap-0">
      <div className="w-full bg-mainLight lg:h-[370px]">
        <div className="max-w-screen-desktop mx-auto">
          <div className="relative">
            <div className="lg:grid grid-flow-col place-items-center lg:absolute top-[-30px] left-0">
              <div>
                <img src={MobileIcon} alt="mobile" />
              </div>
              <div className="py-[10%] lg:py-0 px-4">
                <h1 className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-white  pr-16">
                  Easy ordering, sweet rewards
                </h1>
                <p className="text-lg leading-[22px] tracking-[-0.1px] text-white font-filsonProRegular">
                  Save time when you order with our app. Join Insomnia Rewards and
                  earn free treats.
                </p>
                <div className="mt-4 flex gap-3">
                  <a
                    href="https://apps.apple.com/us/app/insomnia-cookies/id891379973"
                    className="h-[54px] w-[157px] 
                 rounded-[54px] py-3 bg-transparent border border-solid border-white
                  text-white font-filsonProBook"
                  >
                    <div className="flex gap-2 text-center items-center justify-center h-[22px] mt-1">
                      <img
                        className="w-5 h-5"
                        src={DarkAppleStoreIcon}
                        alt="logo-apple"
                      />
                      <p className="text-lg font-filsonProBold tracking-tighter">
                        App Store
                      </p>
                    </div>
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.insomniacookies.insomnia"
                    className="h-[54px] w-[175px] 
                  rounded-[54px] 
                  py-3 bg-transparent border border-solid border-white
                  text-white font-filsonProBook"
                  >
                    <div className="flex gap-2 text-center items-center justify-center h-[22px] mt-1">
                      <img
                        src={DarkPlayStoreIcon}
                        alt="logo-google-playstore"
                      />
                      <p className="text-lg font-filsonProBold tracking-tighter">
                        Google Play
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FooterSection;
