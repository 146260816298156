import {
  CHANGE_PASSWORD_MUTATION,
  SIGNUP_CUSTOMER_MUTATION,
  LOGIN_QUERY,
  ME_RECENT_ADDRESSES_QUERY,
  RESET_PASSWORD_MUTATION,
  ME_QUERY,
  UPDATE_USER_MUTATION,
  DEACTIVATE_USER_ACCOUNT,
  ME_HEADER_QUERY,
  ME_ORDERS_QUERY,
  CONFIRM_CUSTOMER_TELEPHONE_OTP_MUTATION,
  RESEND_CUSTOMER_TELEPHONE_OTP_MUTATION,
  REMOVE_CUSTOMER_CARD_MUTATION,
  CHANGE_CUSTOMER_PASSWORD_MUTATION,
  LOGOUT_CUSTOMER,
  LEAVE_CUSTOMER_LOYALTY_MUTATION,
  ME_VERIFY_PHONE_NUMBER,
  SIGNUP_CUSTOMER_MUTATION_V2,
  CONFIRM_CUSTOMER_SIGNUP,
  RESEND_CUSTOMER_VERIFICATION_TELEPHONE_OTP_MUTATION
} from '../../../graphql/user';

import { client } from "../../utilities/config";

/**
 * Reset Password.
 *
 * @param {String} key
 * @param {String} password
 *
 * @author Albert Rosa <arosa@insomniacookies.com>
 */
/* eslint-disable import/prefer-default-export */
export const login = (username, password, facebookId, gRepcaptchaResponse, code, phone) => {
  return client.query({
    fetchPolicy: 'no-cache',
    query: LOGIN_QUERY,
    variables: {
      username,
      password,
      facebookId,
      gRepcaptchaResponse,
      phoneNumber: {
        code,
        phone
      }
    }
  });
};


/**
 * Reset Password.
 *
 * @param {String} key
 * @param {String} password
 *
 * @author Albert Rosa <arosa@insomniacookies.com>
 */
/* eslint-disable import/prefer-default-export */
export const changePassword = (key, newPassword, confirmPassword) => {
  return client.mutate({
    mutation: CHANGE_PASSWORD_MUTATION,
    variables: {
      key,
      newPassword,
      confirmPassword
    }
  });
};

/**
 * Create Account and Login
 *
 * @param {String} email
 * @param {String} password
 * @param {String} gRepcaptchaResponse
 * @param {Boolean} newsletter
 * @author Albert Rosa <arosa@insomniacookies.com>
 */
export const createAccountAndLogin = (email, password, gRepcaptchaResponse, newsletter = false, subscriptionIds = []) => {
  return client.mutate({
    mutation: SIGNUP_CUSTOMER_MUTATION,
    variables: {
      email,
      password,
      gRepcaptchaResponse,
      newsletter,
      subscriptionIds
    }
  });
};

export const createAccountAndLoginV2 = (email, firstName, lastName, code, phone, gRepcaptchaResponse, newsletter= false, subscriptionIds = [], inviteCode = '') => {
  return client.mutate({
    mutation: SIGNUP_CUSTOMER_MUTATION_V2,
    variables: {
      email,
      firstName,
      lastName,
      code,
      phone,
      gRepcaptchaResponse,
      newsletter,
      subscriptionIds,
      inviteCode
    }
  });
};

export const resetPassword = email => {
  return client.mutate({
    mutation: RESET_PASSWORD_MUTATION,
    variables: {
      email
    }
  });
};

export const recentAddresses = () => {
  return client.query({
    query: ME_RECENT_ADDRESSES_QUERY
  });
};

export const getUser = () => {
  return client.query({
    fetchPolicy: 'no-cache',
    query: ME_QUERY
  });
};

export const verifyPhoneNumber = (email, password, code, phone) => {
  return client.query({
    fetchPolicy: 'no-cache',
    query: ME_VERIFY_PHONE_NUMBER,
    variables: {
      email,
      password,
      phoneNumber: {
        code,
        phone
      }
    }
  });
};

/**
 * @param {Object} input {
 *     firstName: String!,
 *     lastName: String!,
 *     email: String!,
 *     telephone: String!
 * }
 */
export const updateUser = (input) => {
  return client.mutate({
    mutation: UPDATE_USER_MUTATION,
    variables: {
      ...input
    }
  });
};

export const deactivateUser = () => {
  return client.mutate({
    mutation: DEACTIVATE_USER_ACCOUNT
  });
};

export const removeCard = (cardId) => {
  return client.mutate({
    mutation: REMOVE_CUSTOMER_CARD_MUTATION,
    variables: {
      cardId
    }
  });
};

export const changeCustomerPassword = (data) => {
  return client.mutate({
    mutation: CHANGE_CUSTOMER_PASSWORD_MUTATION,
    variables : {
      data
    }
  })
};

export const leaveCustomerLoyalty = () => {
  return client.mutate({
    mutation: LEAVE_CUSTOMER_LOYALTY_MUTATION
  })
};

export const getHeaderUser = () => {
  return client.query({
    fetchPolicy: 'no-cache',
    query: ME_HEADER_QUERY
  });
}

export const getOrderHistory = () => {
  return client.query({
    fetchPolicy: 'no-cache',
    query: ME_ORDERS_QUERY
  });
}

export const confirmCustomerTelephoneOtp = (telephoneOtp, code, phone, email, password, orderCode=null) => {
  return client.mutate({
    mutation: CONFIRM_CUSTOMER_TELEPHONE_OTP_MUTATION,
    variables: {
      telephoneOtp,
      code,
      phone,
      email,
      password,
      orderCode
    }
  });
};

export const confirmCustomerSignup = (telephoneOtp, id, orderCode=null) => {
  return client.mutate({
    mutation: CONFIRM_CUSTOMER_SIGNUP,
    variables: {
      telephoneOtp,
      id,
      orderCode
    }
  });
};

export const resendCustomerTelephoneOtp = () => {
  return client.mutate({
    mutation: RESEND_CUSTOMER_TELEPHONE_OTP_MUTATION
  });
};

export const resendCustomerVerificationTelephoneOtp = (code = null, phone = null, email = null, password = null, id = null) => {
  return client.mutate({
    mutation: RESEND_CUSTOMER_VERIFICATION_TELEPHONE_OTP_MUTATION,
    variables: {
      code,
      phone,
      email,
      password,
      id
    }
  });
};

export const logout = (rememberToken, orderCode) => {
  return client.mutate({
    mutation: LOGOUT_CUSTOMER,
    variables: {
      rememberToken,
      orderCode
    }
  });
};