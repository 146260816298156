import _ from 'underscore';
import * as subscriptionRepo from "../repos/graphql/subscription";

/**
 * Get customer subscriptions
 * @return {Promise<*>}
 */
export const getCustomerSubscriptions = async () => {
    const response = await subscriptionRepo.getCustomerSubscriptions();
    const {customerSubscriptions} = response.data;

    return customerSubscriptions;
}
export const getCustomerSubscriptionsByType = async (subscriptionTypeIds) => {
    const response = await subscriptionRepo.getCustomerSubscriptionsByType(subscriptionTypeIds);
    const {customerSubscriptions} = response.data;

    return customerSubscriptions;
}

/**
 * Toggle customer subscription
 * @param {Number}subscriptionId
 * @param {list}optInSelection
 * @param {Boolean}enabled
 * @return {Promise<*>}
 */
export const toggleCustomerSubscription = async (
    subscriptionId,
    optInSelection,
    enabled) => {
    await subscriptionRepo.toggleSubscription(
        parseInt(subscriptionId, 10),
        enabled
    );
    const arrayList = [...optInSelection];
    return optInSelection.includes(subscriptionId) ? _.without(arrayList, subscriptionId) : _.union(arrayList, [subscriptionId]);
};

/**
 * Get subscription by condition
 * @param {Object} condition
 * @returns {Promise<*>}
 */
export const getCustomerSubscriptionByCondition = async (condition) => {
    const response = await subscriptionRepo.getCustomerSubscriptions();
    if (response.data && response.data.customerSubscriptions && response.data.customerSubscriptions.length > 0) {
        const {customerSubscriptions} = response.data;
        return _.find(customerSubscriptions, condition);
    }
        return undefined;

}
