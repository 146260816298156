export const defaultCurrency = {
    symbol: "$",
    name: "US Dollar",
    code: "USD"
}

export const currencyNames = {
    "USD": "dollar",
    "CAD": "dollar",
    "GBP": "pound",
}