import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// DATA
import states from './states';

import styles from './SimpleDropdown.module.css';

const dropDownIcon = require('./imgs/dropdown-icon.png');

/**
 * Returns image element with dropdown icon asset
 */
const returnDropDownIcon = () => (
  <img className={styles.dropDownIcon} alt='dropdown-icon' src={dropDownIcon} />
)

const SimpleDropdown = ({ disabled, value, onChange, theme, options, className }) => (
  <Dropdown
    onChange={onChange}
    value={value}
    disabled={disabled}
    arrowClosed={returnDropDownIcon()}
    arrowOpen={returnDropDownIcon()}
    menuClassName=""
    controlClassName=""
    className={`${className} ${theme === 'Dark' ? styles.isDark : styles.isLight}`}
    options={options}
    placeholderClassName=""
  />
)

SimpleDropdown.propTypes = {
  value:  PropTypes.any, // eslint-disable-line
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  customDropDownStyles: PropTypes.string, // eslint-disable-line
  theme: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  })),
  className: PropTypes.string
};

SimpleDropdown.defaultProps = {
  disabled: false,
  theme: 'Dark',
  options: states,
  className: null,
}

export default SimpleDropdown;
