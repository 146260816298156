import {
  SUBMIT_ORDER_EXPERIENCE
} from '../../../graphql/orderExperience';

import { client } from "../../utilities/config";

export const submitOrderExperience = (rating, comment, orderCode) => {
return client.mutate(
    {
      mutation: SUBMIT_ORDER_EXPERIENCE,
      variables: {
        orderCode,
        rating,
        comment
      }
    }
  );
};
