import {
  LOYALTY_DEALS,
  LOYALTY_METRICS,
  LOYALTY_PROFILE,
  LOYALTY_REDEEMABLES
} from "../../../graphql/loyalty";
import { client } from '../../utilities/config';

export const getLoyaltyProfile = () => {
  return client.query({
    fetchPolicy: "no-cache",
    query: LOYALTY_PROFILE
  });
};

export const getLoyaltyDeals = () => {
  return client.query({
    fetchPolicy: "no-cache",
    query: LOYALTY_DEALS
  });
};

export const getLoyaltyMetrics = () => {
  return client.query({
    fetchPolicy: "no-cache",
    query: LOYALTY_METRICS
  });
};

export const getLoyaltyRedeemables = () => {
  return client.query({
    fetchPolicy: "no-cache",
    query: LOYALTY_REDEEMABLES
  });
};
