/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Link from '../../components/LinkWithCountry/LinkWithCountry';
import * as orderRepo from '../../shared/repos/graphql/order';
import * as orderActions from '../../redux/actions/order';
import Button from '../../components/Button/Button';

import {orderTypeIds, orderTrackerMessages} from "../../shared/constants/order";
import styles from './OrderTrackerStep.module.css';
import { routeCountryPath } from '../../shared/utilities/common';

const step1 = require('./imgs/steps/step-1.svg');
const step2 = require('./imgs/steps/step-2.svg');
const step3 = require('./imgs/steps/step-3.svg');
const step4 = require('./imgs/steps/step-4.svg');

const subHeaderAsset = require('./imgs/bell-icon.svg');

const orderActionTypes = {
  2: 'Delivering to',
  3: 'Picking up from',
  6: 'Shipping to',
  10: 'Shipping to'
};

export const orderStatuses = {
  2: {
    image: 1,
    headline: 'You <span>order</span> <br /> was sent',
    subhead: 'We\'re reviewing your order'
  },
  3: {
    image: 2,
    headline: 'We received <br /> your <span>order</span>',
    subhead: 'Hang tight'
  },
  4: {
    image: 3,
    headline: 'The driver has <br /> been assigned',
    subhead: 'Coming right up'
  },
  5: {
    image: 4,
    headline: 'Your <span>order</span> has <br /> been delivered',
    subhead: orderTrackerMessages.pickupOrderTrackSubhead
  },
  6: {
    image: 4,
    headline: 'Your <span>order</span> has <br /> been delivered',
    subhead: orderTrackerMessages.pickupOrderTrackSubhead
  },
  'pickup': {
    image: 1,
    headline: orderTrackerMessages.pickOrderTrack,
    subhead: orderTrackerMessages.pickupOrderTrackSubhead
  }
};

class OrderTrackerStep extends Component {
  constructor(props) {
    super(props);

    const trackingID = props.match.params.id;
    this.state = { trackingID };
  }

  async componentDidMount() {
    const { trackingID } = this.state;
    await this.getOrder(trackingID);
  }

  async getOrder(trackingID) {
    const { orderUpdated } = this.props;

    const response = await orderRepo.getTracker(trackingID);
    const { orderTracker } = response.data;

    orderUpdated(orderTracker);
  };

  renderOrderCopy = (headerHTML, subHeader) => {
    const { history, order } = this.props;

    return (
      <div className={styles.leftContainer}>
        <h1 dangerouslySetInnerHTML={{ __html: headerHTML }} />
        <p>
          <span>
            <img alt='alert' src={subHeaderAsset} />
          </span>

          {subHeader}
        </p>

        <Button
          handleSubmit={() => history.push(routeCountryPath(`/checkout/order-tracker/${order ? order.trackingID : null}`))}
          customContainerStyles={styles.buttonContainer}
          label='Track it'
          disabled={!order}
        />
      </div>
    )
  }

  renderOrderImage = step => {
    const imagesByStep = {
      1: step1,
      2: step2,
      3: step3,
      4: step4
    };

    const srcAsset = step ? imagesByStep[step] : step1;

    return (<img alt='step' src={srcAsset} />);
  }

  render() {
    const { order } = this.props;
    const isPickup = order && order.orderTypeId === orderTypeIds.pickup ;
    const descObj = isPickup ? orderStatuses.pickup : (order && order.status ? orderStatuses[order.status.status] : orderStatuses[2]);
    const storeAddress = order && order.store ? order.store.address : '';
    return (
      <div className={`${styles.pageWrapper} mb-16`}>
        <div className={styles.pageContainer}>
          <div className={styles.pageTop}>
            {this.renderOrderCopy(descObj.headline, descObj.subhead)}

            <div className={styles.rightContainer}>
              {this.renderOrderImage(descObj.image)}
            </div>
          </div>

          <div className={styles.pageBottom}>
            <div className={styles.bottomDirections}>
              <p>
                {order ? orderActionTypes[order.orderTypeId] : null}
                {' '}
                <span>
                  {
                    isPickup ? (
                      storeAddress
                      ) : (
                      order ? order.shippingAddress.address1 : null
                    )
                  }
                </span>
              </p>

              <div>
                <Link to='/contact'>Need help?</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrderTrackerStep.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  orderUpdated: PropTypes.func.isRequired,
  order: PropTypes.shape({
    trackingID: PropTypes.string.isRequired,
    orderTypeId: PropTypes.number.isRequired,
    store: PropTypes.shape().isRequired,
    shippingAddress: PropTypes.shape({
      address1: PropTypes.string,
    }).isRequired,
    status: PropTypes.shape({
      status: PropTypes.number.isRequired
    })
  })
}

OrderTrackerStep.defaultProps = {
  order: {}
};

export const mapStateToProps = state => {
  const { order } = state;

  return {
    order
  };
};

export const mapDispatchToProps = (dispatch) => ({
  orderUpdated: (value) => dispatch(orderActions.orderUpdated(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTrackerStep);
