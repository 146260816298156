import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import styles from '../ReviewOrder.module.css';
import CheckoutContainer from '../../../components/CheckOutContainer/CheckoutContainer';
import Loader from '../../../components/Loader/Loader';
import SelectionItem from '../../../components/SelectionItem/SelectionItem';
import Button from '../../../components/Button/Button';
import * as shippingRepo from '../../../shared/repos/graphql/shipping';
import * as orderRepo from '../../../shared/repos/graphql/order';
import {shippingMethodRequirements} from '../../../shared/constants/order';
import * as cms from "../../../shared/repos/cms/cms";
import cmsKeys from "../../../shared/constants/cms";
import * as elementsActions from "../../../redux/actions/elements";
import {openEmptyShippingOptionsModal} from "../../../shared/utilities/modals";
import endpoints from "../../../shared/constants/endpoints";

const truckIcon = require('../imgs/truck-icon.svg');

class ShippingMethods extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rates: [],
      getRatesLoading: false,
      selectedRateIndex: -1,
      content: []
    };
  }

  componentDidMount() {
    this.getRates();

    document.addEventListener('shippingAddressUpdated', () => {
      this.getRates();
    });
    cms.getContent([cmsKeys.checkoutPage.shippingNote], this);
  }

    getShippingNote= () => {
      return cms.getValueByKey(cmsKeys.checkoutPage.shippingNote, this);
    }

    setSelectedRate = () => {

      const { order } = this.props;
      const { rates } = this.state;

      const selectedRateIndex = order.shippingMethod ? rates.findIndex(rate => parseInt(rate.shippingMethodId, 10) === parseInt(order.shippingMethod.id, 10)) : -1;

      this.setState({
        selectedRateIndex
      });
    }

    getRates = async () => {
      const { order, setModalObject } = this.props;

      if (!order.shippingAddress.postcode) {
        return;
      }

      this.setState({
        getRatesLoading: true
      });

      try {
        const response = await shippingRepo.shippingOptions(order.shippingAddress.postcode, order.code);

        const { shippingOptions } = response.data;

        this.setState({
          getRatesLoading: false,
          rates: shippingOptions
        });

        this.setSelectedRate();
      } catch (exception) {
        const { message } = exception.graphQLErrors[0];

        this.setState({
          /* eslint-disable-next-line react/no-unused-state */
          errorMsg: message,
          getRatesLoading: false
        });

        setModalObject(openEmptyShippingOptionsModal(message, () => {
          this.backToViewCart();
        }));
      }
    }

    backToViewCart = () => {
      const { history, setModalObject } = this.props;
      history.push(endpoints.checkoutViewOrderPage);
      setModalObject(null);
    }

    selectRate = rateIndex => {
      this.setState({
        selectedRateIndex: rateIndex
      });
    }

    setRate = async () => {

      const { order } = this.props;
      const { rates, selectedRateIndex } = this.state;

      this.setState({
        setRateLoading: true
      });

      try {
        await orderRepo.setShippingMethod(order.code, rates[selectedRateIndex].shippingMethodId);

        this.setState({
          setRateLoading: false
        });
      } catch (exception) {
        const { message } = exception.graphQLErrors[0];

        this.setState({
          setRateLoading: false,
          /* eslint-disable-next-line react/no-unused-state */
          errorMsg: message
        });
      }
    }

    shippingOptions = () => {
      const { rates, setRateLoading, selectedRateIndex, content} = this.state;
      const getShippingNote = this.getShippingNote();
      return (
        <div>
          <p className={`${styles.sectionHeader} text-dark dark:text-white`}>Choose a shipping method</p>

          <span className={styles.selectionMinor}>
                    *Below are estimated method dates
          </span>

          {rates.map((rate, key) => {
            return <SelectionItem key={rate.cost.toFixed(2)} asset={truckIcon} label={rate.class} header={`${rate.deliveryDate}  ($${rate.cost.toFixed(2)})`} onSelect={() => this.selectRate(key)} isSelected={key === selectedRateIndex} />;
          })}

          {content.length && getShippingNote ? (
          // eslint-disable-next-line react/no-danger
            <p className='text-dark dark:text-white'><em dangerouslySetInnerHTML={{__html:getShippingNote}} /></p>
          ): null}


          <Button
            handleSubmit={() => this.setRate()}
            disabled={selectedRateIndex < 0}
            isLoading={setRateLoading}
            customContainerStyles={styles.selectionButton}
            label="Save Changes"
          />
        </div>
      );
    }

    render() {
      const { order } = this.props;
      const { getRatesLoading, rates, selectedRateIndex } = this.state;

      const shippingAddressMissing = !order.shippingAddress || !order.shippingAddress.address1 || !order.shippingAddress.city || !order.shippingAddress.state || !order.shippingAddress.postcode;
      const subHeaderAlert = shippingAddressMissing ? shippingMethodRequirements.shippingAddressMiss : shippingMethodRequirements.shippingMethodMiss;
      const deliverySubHeaderText= rates[selectedRateIndex] ?  `${rates[selectedRateIndex].class} - ${rates[selectedRateIndex].deliveryDate}` : null;

      const subHeader = order.shippingMethod && deliverySubHeaderText ? deliverySubHeaderText : subHeaderAlert;
      const noRates = getRatesLoading || !rates.length;

      return getRatesLoading ? <Loader /> : (
        <CheckoutContainer
          className="shippingMethodContainer"
          header="Shipping Method"
          subHeader={subHeader}
          isAlert={shippingAddressMissing || !order.shippingMethod || !deliverySubHeaderText}
          disabled={noRates}
        >
          {shippingAddressMissing ? null : this.shippingOptions()}
        </CheckoutContainer>
      );
    }
}

ShippingMethods.propTypes = {
  order: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  setModalObject: PropTypes.shape(PropTypes.func).isRequired,
};

export const mapStateToProps = state => {
  return {
    order: state.order
  };
};

export const mapDispatchToProps = dispatch => ({
  setModalObject: value => dispatch(elementsActions.setModalObject(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShippingMethods));

