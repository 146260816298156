import { PUNCHH_TYPE } from "../constants/loyaltyType";

const getRedeemablesPoints = (id, loyaltyProducts, loyalty) => {
  let points = 0;

  if (loyalty === PUNCHH_TYPE.REDEEMABLE) {
    loyaltyProducts.find(a => {
      return a.items.find(b => {
        if (b.product.id === id) {
          points = b.points;
          return true;
        }
        return false;
      });
    });
  } else {
    loyaltyProducts.find(a => {
      if (a.product.id === id) {
        points = a.points;
        return true;
      }
      return false;
    });
  }

  return points;
};

export const calculateLoyaltyPoints = (
  loyalty,
  id,
  poid,
  loyaltyPoints,
  loyaltyProducts,
  order
) => {
  let points = 0;

  const isUpdateOrder = order && order?.orderRedeemables?.length > 0 && poid;
  if (isUpdateOrder) {
    const orderRedeemable = order?.orderRedeemables.find(p => {
      return p.order_product_id === poid;
    });
    points = orderRedeemable ? orderRedeemable.points : 0;
  } else {
    if (!loyaltyProducts) {
      throw new Error("No redeemables found.");
    }
    points = getRedeemablesPoints(id, loyaltyProducts, loyalty);

    if (points > loyaltyPoints) {
      throw new Error("No enough points available.");
    }
  }
  return points;
};

export const newOfferAvailable = deals => {
  let newAvailablesDeals = localStorage.getItem("newAvailablesDeals");
  let isNewDealsAvaliable = false;
  if (deals && deals?.length > 0) {
    newAvailablesDeals = newAvailablesDeals || [];
    const newDealsId = deals.map(item => {
      const isNewOfferAvailable = newAvailablesDeals.indexOf(item.id) === -1;
      if (isNewOfferAvailable) {
        isNewDealsAvaliable = true;
      }
      return item.id;
    });
    localStorage.setItem("newAvailablesDeals", newDealsId);
  }
  return isNewDealsAvaliable;
};

export const newLevelUnlocked = (points, redeemables) => {
  let newRedeemableUnlocked = false;
  let updatedUnlockedLevel = null;
  let unlockedPoints = 0;
  if (redeemables && redeemables?.length > 0) {
    redeemables.forEach(item => {
      if (points >= item.points) {
        updatedUnlockedLevel = item.id;
        unlockedPoints = item.points;
      }
    });

    const lastUnlockedPoints = localStorage.getItem("unlockedPoints");
    const lastUnlockedLevel = localStorage.getItem("lastUnlockedLevel");

    const checkNewLevelUnlocked =
      updatedUnlockedLevel &&
      updatedUnlockedLevel !== lastUnlockedLevel &&
      unlockedPoints > +lastUnlockedPoints;

    if (checkNewLevelUnlocked) {
      newRedeemableUnlocked = true;
    }
  }

  localStorage.setItem("lastUnlockedLevel", updatedUnlockedLevel);
  localStorage.setItem("unlockedPoints", unlockedPoints);
  return newRedeemableUnlocked;
};
