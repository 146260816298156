import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './hooks/useCountdown';

const ShowCounter = (props) => {
    // eslint-disable-next-line react/prop-types
    const { days, hours, minutes, seconds, showSeconds } = props;
    const counterDays = days >= 1 ? (
      <DateTimeDisplay value={days} type="d" />
    ) : null;

    const counterSeconds = showSeconds ? (
      <DateTimeDisplay value={seconds} type='s' />
    ) : null;

    return (
      <span className="show-counter">
        { counterDays }
        <DateTimeDisplay value={hours} type="h" />
        <DateTimeDisplay value={minutes} type="m" />
        { counterSeconds }
      </span>
    );
};

const CountdownTimer = (props) => {
    const {targetDate, showSeconds} = props;
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return null;
    } 
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        showSeconds={showSeconds}
      />
    );
};

CountdownTimer.propTypes = {
    targetDate: PropTypes.string.isRequired,
    showSeconds: PropTypes.bool,
};

CountdownTimer.defaultProps = {
    showSeconds: false,
}
export default connect(null, null)(CountdownTimer);
