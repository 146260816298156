export const setFooterMenuItemsSettings = value => ({
    type: 'SET_FOOTER_MENU_ITEMS_SETTINGS',
    value
});

export const setFooterMenuItemsLinksSettings = value => ({
    type: 'SET_FOOTER_MENU_ITEMS_LINKS_SETTINGS',
    value
});

export const setTiktokLink = value => ({
    type: 'SET_TIKTOK_LINK',
    value
});

export const setInstagramLink = value => ({
    type: 'SET_INSTAGRAM_LINK',
    value
});

export const setFacebookLink = value => ({
    type: 'SET_FACEBOOK_LINK',
    value
});

export const setPasswordlessEnable = value => ({
  type: "SET_PASSWORDLESS_ENABLE",
  value
});