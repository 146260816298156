import siteSetting from "../../shared/constants/siteSetting";

const initialState = {
  theme: 'light',
  themeSettings: {
    [siteSetting.IS_THEME_SWITCH_ENABLED] : false
  }
};

export default(state = initialState, { type, value }) => {
  switch (type) {
    case 'SET_THEME':
      return {...state, theme: value};
    case 'SET_THEME_SETTINGS':
      return {...state, themeSettings: value};
    default:
      return state;
  }
}
