import { apiBase } from '../utilities/common';

export const endpoints = {
    shippingPage: '/shipping',
    contactPage: '/contact',
    homePage: '/',
    eventsPage: 'https://welcome.insomniacookies.com/sales/',
    menu: '/menu',
    checkoutConfirmationPage: '/checkout/confirmation/:id',
    checkoutOrderPage: '/checkout/review-order/:id',
    checkoutViewOrderPage: '/checkout/view-order',
    profilePage: '/user/profile',
    loginPage: '/login',
    veganGlutenFreePage: '/vegan-gluten-free-chocolate-chip-cookie',
    createAccount: '/create-account',

    careerSite: 'https://careers.insomniacookies.com',
    insomniaCookieMagic: 'https://insomniacookies.com/cookiemagic',
    giftCardOrderPage: `https://${apiBase()}/gift-cards/email-gift-card`,
    salesSite: 'https://welcome.insomniacookies.com/sales/',

    productSingle: '/products/single/:id',
    productCustomize: '/products/customize/:id',
    giftCard: '/gift-card',
    cookieMagicGiftCardPurchase: '/cookiemagic-gift-purchase',
    cookieMagicGiftCardProduct: '/cookiemagic-egift-product/:id',
    cookieMagicGiftCardProductUpdate: '/checkout/view-order/products/egift-card/:id/:poid',

    doughProPage: '/cookiemagic',
    doughProFreeCookiePage: '/cookiemagic-freecookie',
    doughProPaymentsPage: '/cookiemagic-payments',
    doughProTermsConditions: '/cookie-magic-terms-and-conditions',

    gearSite: 'https://theinsomniacshop.com/pages/contact',

    nutritionalInfo: 'https://insomniacookies.com/uploads/Insomnia%20Cookies%20Overall%20Nutritional%20Facts%20Guide.pdf',

    redirectToOnError: '/',

    cookielabTerms: '/cookielab-event-space-terms-and-conditions',
    donationPage: 'https://api.insomniacookies.com/donations',
    locations: '/findaninsomnia',
    orders: '/user/order-history',
    loyalty: '/user/loyalty-rewards',
    orderLoyalty: '/checkout/loyalty-rewards/:id',
    orderLoyaltyRegex: /checkout\/loyalty-rewards/g,
    rewardLandingPage: '/insomniarewards',
    rewardTermsPage: '/insomniarewards/terms',

    getMenuUrl: (store) => {
        return store && store?.slug ? `/menu/${store?.slug}`: '/menu';
    }
};

export default endpoints;
