/* eslint-disable react/prop-types */
import React, { Component } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import './datepicker.css';

import styles from './DatePickerComponent.module.css'

class DatePickerComponent extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            date: null,
            minDate: null,
        }
    }

    componentDidMount() {
        const date = new Date();
        this.setSelectedDate(date);

        this.setState({minDate:date})
    }

    setSelectedDate = (date) => {
        const {onDateChange} =this.props;
        this.setState({date});
        onDateChange(date);

    }

    render(){
        const { customContainerStyles, disable, selected} = this.props;
        const {date, minDate} = this.state;

        const selectedDate = selected ? new Date(selected) : date;

        return (
          <div className={styles.pickerWrapper}>
            <DatePicker
              className={[
                      styles.pickerContainer,
                      customContainerStyles,
              ].join(" ")}
              selected={selectedDate}
              onChange={(value) => this.setSelectedDate(value)}
              dateFormat='MM/dd/yyyy'
              disabled={disable}
              minDate={minDate|| null}
            />
          </div>
        )
    }
}

DatePickerComponent.propTypes = {
    onDateChange : PropTypes.func.isRequired,
};

export default DatePickerComponent;
