import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Link from '../../components/LinkWithCountry/LinkWithCountry';
import styles from './CreateAccount.module.css';
import * as userActions from '../../redux/actions/user';
import * as userRepo from '../../shared/repos/graphql/user';

// COMPONENT
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

// FORMS
import { handleFormInput, isValid } from '../../shared/utilities/validations';
import { isRecaptcha } from "../../shared/utilities/config";

// HELPERS
import * as tagmanagerEvents from '../../shared/utilities/tagmanagerEvents';
import CheckBox from "../../components/CheckBox/CheckBox";
import { routeCountryPath } from '../../shared/utilities/common';
import * as cms from '../../shared/repos/cms/cms';
import cmsKeys from '../../shared/constants/cms';
import {getKeyValue} from '../../shared/utilities/cms';
import subscription from '../../shared/constants/subscription';
import { getCustomerSubscriptionsByType } from '../../shared/utilities/subscription';
import * as elementsActions from "../../redux/actions/elements";
import {setCaptchaToken} from "../../shared/utilities/captcha";
import captchaAction from "../../shared/constants/captchaAction";

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      password: null,
      confirmPassword: null,
      emailErrorMessage: null,
      passwordErrorMessage: null,
      confirmPasswordErrorMessage: null,
      responseErrorMessage: null,
      content: [],
      subscriptionRequestCompleted: false,
      customerSubscriptions: [],
      customerSubscriptionsError: [],
    };

    this.loginGuard();
  }

  componentDidMount() {
    cms.getContent([cmsKeys.registrationPage], this);
    const {optIn, consent} = subscription.subscriptionType;
    const { EMAIL_OPT_IN} = subscription.subscriptionKeyField;
    getCustomerSubscriptionsByType([optIn,consent]).then(sub => {
      const filtereObj = (sub || []).filter(item => (item.subscriptionTypeId === optIn && item.keyField === EMAIL_OPT_IN) || item.subscriptionTypeId === consent);
      filtereObj.sort((a, b) => {
          // Sort ascending order (0 -> 1)
          return b.isRequired - a.isRequired;
      });
      this.setState({
          customerSubscriptions: filtereObj,
          subscriptionRequestCompleted: true,
      });
    });
  }

  loginGuard = () => {
    const { userToken, history } = this.props;

    if (userToken) {
      history.push(routeCountryPath('/user/profile'));
    }
  }

  handleRegister = async () => {
    if (isRecaptcha()) {
      const { setVerificationToken, setVerificationAction } = this.props;
      await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.signup);
    }
    this.handleSubmit();
  }

  /**
   * Handles form submit
   */
  handleSubmit = () => {

    const {
      email,
      password,
      confirmPassword,
      content,
      customerSubscriptions
    } = this.state;

    this.setState({
      responseErrorMessage: null,
      emailErrorMessage: null,
      passwordErrorMessage: null,
      confirmPasswordErrorMessage: null,
    });
    const subscriptions = customerSubscriptions.filter(item => item.defaultValue);
    const subscriptionIds = subscriptions.map((item)=> parseInt(item.subscriptionId, 10));

    // NULL VALUE LOGIC
    if ( !email || email.length === 0)
      return this.setState({ emailErrorMessage: 'Please enter an email address.' });
    if (!password)
      return this.setState({ passwordErrorMessage: 'Please enter a password' });
    // VALUE VALIDATION LOGIC
    if (email && isValid(email, 'email')) {
      if (password === confirmPassword && password && confirmPassword) {
        if (password.length < 8) {
          return this.setState({
            passwordErrorMessage: 'Password must be greater than 8 characters',
            confirmPasswordErrorMessage:
              'Password must be greater than 8 characters'
          });
        }
        if (!this.validateSubscriptions()) {
           return false;
        }
          this.setState({loading: true});

          userRepo.createAccountAndLogin(
              email,
              password,
              null,
              false,
              subscriptionIds
          )
              .then(response => {
                  const { signupCustomer } = response.data;
                  const { setUserToken, history } = this.props;
                  const { token } = signupCustomer;

                  tagmanagerEvents.signup('api');

                  tagmanagerEvents.joinLoyalty({email});

                  setUserToken(token);
                  history.push(routeCountryPath('/'));
              })
              .catch(error => {
                content.map(registrationPage => {
                  return this.setState({
                    responseErrorMessage: getKeyValue(registrationPage.children, error.message),
                    loading: false
                  });
                })
              });

          return true
      }

      return this.setState({
        confirmPasswordErrorMessage: "Passwords don't match"
      });
    }

    return this.setState({ emailErrorMessage: 'Invalid email address ' });
  }

  createAccountContainer = () => {
    const {
      emailErrorMessage,
      passwordErrorMessage,
      confirmPasswordErrorMessage,
      email,
      password,
      confirmPassword,
      loading,
      responseErrorMessage,
      subscriptionRequestCompleted,
      customerSubscriptions,
      customerSubscriptionsError
    } = this.state;
    const isDisabled = loading || subscriptionRequestCompleted === false;

    return (
      <div className={`${styles.pageWrapper} text-dark dark:text-white`}>
        <div className={styles.pageContainer}>
          <h1>
            Create your
            <br />
            account
          </h1>
          <Input
            handleInput={(e) => handleFormInput(e, this, 'email', 'email')}
            placeholder=" "
            value={email}
            label="Email address"
            errorMessage={emailErrorMessage}
          />
          <Input
            handleInput={(e) => handleFormInput(e, this, 'password')}
            placeholder=" "
            value={password}
            type="password"
            label="Password"
            errorMessage={passwordErrorMessage}
          />
          <Input
            handleInput={(e) => handleFormInput(e, this, 'confirmPassword')}
            placeholder=" "
            value={confirmPassword}
            type="password"
            label="Confirm Password"
            errorMessage={confirmPasswordErrorMessage}
          />
          {customerSubscriptions.map((item, index) => (
            <div key={item.id} className={styles.registerCheckbox}>
              <CheckBox
                onClick={() => this.toggleCheckbox(index)}
                isChecked={item.defaultValue}
                label={<div className='text-dark dark:text-white' dangerouslySetInnerHTML={{__html: item.description }} />}
                name={`subscription-${item.subscriptionId}`}
              />
              {customerSubscriptionsError[index] && (
              <p className={styles.hasError}>
You must agree for `
                {item.name}
`
              </p>
)}
            </div>
          ))}
          {responseErrorMessage && (
          <p className={styles.hasError}>
            {responseErrorMessage}
          </p>
) }
          <div className={styles.actionsContainer}>
            <p>
              Already have an account?
              <Link to="/login">Login</Link>
            </p>
            <Button
              isLoading={loading}
              disabled={isDisabled}
              handleSubmit={
                isDisabled
                    ? null
                    : () =>
                        this.handleRegister()
              }
              customContainerStyles={styles.buttonContainer}
              label="Create Account"
            />
          </div>
          {/* SSO CONTAINER */}
          <div className={styles.ssoContainer}>
            <p className={styles.privacyTermsCopy}>
              <Link to="/privacy-policy">Privacy Policy</Link>
              &nbsp; & &nbsp;
              <Link to="/terms">Terms and Conditions</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }

  toggleCheckbox = (index) => {
    const { customerSubscriptions} = this.state;
    customerSubscriptions[index].defaultValue = !customerSubscriptions[index].defaultValue;
    let newSubscriptionRequestCompleted;

    if(this.validateSubscriptions()){
      newSubscriptionRequestCompleted = true;
    } else {
      newSubscriptionRequestCompleted = false
    };
    this.setState({
        customerSubscriptions,
        subscriptionRequestCompleted: newSubscriptionRequestCompleted
    });
  }

  validateSubscriptions = () => {
    const { customerSubscriptions } = this.state;
    const customerSubscriptionsError = customerSubscriptions.map(item => !item.defaultValue && item.isRequired);

    this.setState({ customerSubscriptionsError });

    // Return true if no errors
    return customerSubscriptionsError.every(error => !error);
  };

  render() {
    return <this.createAccountContainer />;
  }
}

CreateAccount.propTypes = {
  setUserToken: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  userToken: PropTypes.string,
  setVerificationToken: PropTypes.func.isRequired,
  setVerificationAction: PropTypes.func.isRequired,
};

CreateAccount.defaultProps = {
  userToken: null
};

const mapStateToProps = state => {
    const { userToken } = state.user;

    return {
      userToken
    };
};

export const mapDispatchToProps = (dispatch) => ({
  setUserToken: (value) => dispatch(userActions.setUserToken(value)),
  setVerificationToken: value => dispatch(elementsActions.setVerificationToken(value)),
  setVerificationAction: value => dispatch(elementsActions.setVerificationAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
