const cleanState = {
    selectedCountry: {
        id: null,
        name: null,
        path_url: null,
        api_url: null,
        short_name: null,
        url: null,
        zone_id: null,
        locale_id: null,
        currency:  {
            code: "",
            name: "",
            symbol: ""
        }
    },
    allCountries: [],
    isCountrySelectActive: false,
    phonePrefixes: [],

};
const initialState = {
    ...cleanState
};

export default (state = initialState, { type, value }) => {
    switch (type) {
        case 'SET_COUNTRY_SELECTED':
            return {...state, selectedCountry: value};
        case 'SET_ALL_COUNTRIES':
            return {...state, allCountries: value};
        case 'COUNTRY_SELECT_ACTIVE':
            return {...state, isCountrySelectActive: value};
        case 'SET_PHONE_PREFIXES':
            return {
                ...state,
                phonePrefixes: value
            };
        default:
            return state;
    }
}