/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Link from '../LinkWithCountry/LinkWithCountry';
import styles from './SideCart.module.css';

const SideCart = ({ toggleSideCart }) => (
  <div className={styles.sideCartWrapper}>
    <div className={styles.sideCartContainer}>
      <div className={styles.sideCartHeader}>
        <h3>View Order</h3>
        <span onClick={toggleSideCart}>Close</span>
      </div>
      <div className={styles.sideCartDetailsContainer}>
        <h5>Your Delivery Order</h5>
        <p>Delivery - ASAP, Today</p>
      </div>
      <div className={styles.sideCartDetailsContainer}>
        <h5>Six Pack Cookies</h5>
        <p>4 Snicker Doodles, 2 Chocolate Chunk</p>
      </div>
      <div className={styles.sideCartDetailsContainer}>
        <h5>Suggested Items</h5>
        <div>
          <span>Item</span>
        </div>
      </div>
      <div className={styles.sideCartDetailsContainer}>
        <h5>Enter Promo Codes</h5>
        <div>
          <span>Enter Code</span>
        </div>
      </div>

      <div className={styles.sideCartDetailsContainer}>
        <p>Sub-total</p>
        <h5>Total</h5>
        <Link to="/order">
          <button type="button" onClick={toggleSideCart}>
            Review order
          </button>
        </Link>
      </div>
    </div>
  </div>
);

SideCart.propTypes = {
  toggleSideCart: PropTypes.func
};

SideCart.defaultProps = {
  toggleSideCart: null
};

export default SideCart;
