import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  propTypeStorePaymentMethod,
  propTypeCustomerSavedCards,
  propTypesCmsContent
} from '../shared.propTypes';
import Loader from '../../Loader/Loader';
import styles from '../shared.module.css';
import * as EmbeddedPaymentMethodGroup from './EmbeddedPaymentMethodGroup.module.css';
import { PaymentInputs } from '../PaymentInputs';
import { uiComponentTypes } from '../shared';
import log from '../../../shared/utilities/datadog/log';

const EmbeddedPaymentMethod = ({
  paymentMethodComponentGroup: group,
  isSavePaymentMethodCheckedAndDisabled,
  isSavePaymentMethodAllowed,
  paymentMethodsCustomerSaved,
  updatePaymentCredentials,
  isDoughProMember,
  isLoggedIn,
  cmsContent,
  embeddedPaymentMethod,
  onSuccessPayment,
  isFormCompleted,
}) => {
  const [storePaymentMethodSelected, setStorePaymentMethodSelected] = useState(
      embeddedPaymentMethod || null
    );
  const [isLoading, setIsLoading] = useState(false);
  const [showEmbeddedPaymentErrors, setShowEmbeddedPaymentErrors] = useState([]);

  const [paymentCredentialSubmitted, setPaymentCredentialSubmitted] = useState(null);

  const displayEmbeddedError = (errors) => {
    if (Array.isArray(errors)) {
      setShowEmbeddedPaymentErrors(errors);
      setTimeout(() => {
        setShowEmbeddedPaymentErrors([]);
      }, 10000)
    }
  }

  const onSubmitPaymentCredentials = pcSubmitted => {
    log.logInfo(
      "[EmbeddedPaymentMethod][onSubmitPaymentCredentials]",
      {
        "pcSubmitted": pcSubmitted,
      }
    );
    const later = delay => {
      return new Promise(resolve => {
        setTimeout(resolve, delay);
      });
    };

    let updateCredentialPromise = later(2000);
    if (updatePaymentCredentials) {
      updateCredentialPromise = updatePaymentCredentials(pcSubmitted.paymentCredential);
    }
    setStorePaymentMethodSelected(pcSubmitted.storePaymentMethod);
    updateCredentialPromise.then(() => {
      setPaymentCredentialSubmitted(pcSubmitted);
      setIsLoading(false);
      onSuccessPayment();
    });
    setIsLoading(true);
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.paymentsLoaderWrapper}>
          <Loader />
        </div>
        ) : null}
      <div>
        {showEmbeddedPaymentErrors.map((error) => (
          <p key={error} className={styles.errorMessage}>
            {' '}
            {error}
            {' '}
          </p>
          ))}
      </div>
      <div className={styles.optionsContainer2}>
        {group.storePaymentMethods.map(x => {
          const isMatchingEmbeddedButton = (x.uiComponentType === uiComponentTypes.button && x.isEmbeddedHtml && embeddedPaymentMethod?.storePaymentMethod?.paymentMethodId === x.paymentMethodId) ;
            if (isMatchingEmbeddedButton) {
              const urlImg = x?.buttonUrlPath;
              // Display payment input as a BUTTON with the embedded HTML as the button (for example injected ApplePay/GooglePay/External Hosted Credit Cards)
              return (
                isFormCompleted ? (
                  <PaymentInputs
                    storePaymentMethods={[x]}
                    storePaymentMethodSelected={storePaymentMethodSelected}
                    paymentCredentialSubmitted={paymentCredentialSubmitted}
                    onSubmitPaymentCredentials={onSubmitPaymentCredentials}
                    isSavePaymentMethodCheckedAndDisabled={isSavePaymentMethodCheckedAndDisabled}
                    isSavePaymentMethodAllowed={isSavePaymentMethodAllowed}
                    paymentMethodsCustomerSaved={paymentMethodsCustomerSaved}
                    isDoughProMember={isDoughProMember}
                    isLoggedIn={isLoggedIn}
                    cmsContent={cmsContent}
                    displayError={displayEmbeddedError}
                  />
                ) : (
                  <div
                    role="button"
                    tabIndex={0}
                    style={{ backgroundImage: `url(${urlImg})` }}
                    className={`${styles.embeddedpayment} ${EmbeddedPaymentMethodGroup.embeddedpayment}`}
                    onClick={() => onSuccessPayment()}
                    onKeyDown={(e) => {
                      const isEnterOrSpaceKey = (e.key === 'Enter' || e.key === 'Space');
                      if (isEnterOrSpaceKey) {
                        onSuccessPayment();
                      }
                    }}
                  >
                    {!urlImg ? x.paymentMethod.title : null }
                  </div>
                )
              );
            }
            return null;
          })}
      </div>
    </>
  );
};

EmbeddedPaymentMethod.propTypes = {
  paymentMethodComponentGroup: PropTypes.shape({
    groupTitle: PropTypes.string,
    storePaymentMethods: PropTypes.arrayOf(PropTypes.shape(propTypeStorePaymentMethod))
  }).isRequired,
  updatePaymentCredentials: PropTypes.func.isRequired,
  isSavePaymentMethodCheckedAndDisabled: PropTypes.bool,
  isSavePaymentMethodAllowed: PropTypes.bool,
  paymentMethodsCustomerSaved: propTypeCustomerSavedCards,
  isDoughProMember: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  cmsContent: propTypesCmsContent,
  embeddedPaymentMethod: PropTypes.shape(propTypeStorePaymentMethod).isRequired,
  onSuccessPayment: PropTypes.func.isRequired,
  isFormCompleted: PropTypes.bool.isRequired,
};

EmbeddedPaymentMethod.defaultProps = {
  isSavePaymentMethodCheckedAndDisabled: false,
  isSavePaymentMethodAllowed: false,
  paymentMethodsCustomerSaved: [],
  isDoughProMember: false,
  isLoggedIn: false,
  cmsContent: [],
};

export default EmbeddedPaymentMethod;
