export const contentCardsUpdated = value => ({
  type: 'CONTENT_CARDS_UPDATED',
  value
});

export const contentCardSession = value => ({
  type: 'CONTENT_CARD_SESSION',
  value
});

export const introBannerVisibilityUpdated = value => ({
  type: 'INTRO_BANNER_VISIBILITY_UPDATED',
  value
});

export const setDefaultHeroBanner = value => ({
  type: 'SET_DEFAULT_HERO_BANNER',
  value
});

export const setContentCardRefreshDelay = value => ({
  type: 'SET_CONTENT_CARD_REFRESH_DELAY',
  value
});

export const setIntroHeroBanner = value => ({
  type: 'SET_INTRO_HERO_BANNER',
  value
});