/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */

import React from 'react';

import Link from '../../components/LinkWithCountry/LinkWithCountry';

import Button from '../../components/Button/Button';
import LocationsListItem from '../../components/LocationsListItem/LocationsListItem';
import * as orderRepo from '../../shared/repos/graphql/order';

import { orderTypeIds } from '../../shared/constants/order';
import { shippingMethods, storeTypes } from '../../shared/constants/store';
import { guardOrderChange,guardOrderChangeToPickup, clearCart } from '../../shared/utilities/orders';
import { store as mainStore } from "../../redux/store";
import styles from './Locations.module.css';
import {getErrorMessages, getErrorReasons, routeCountryPath} from "../../shared/utilities/common";
import {openCartCreateFailModal, openOrderUpdateFailModal} from "../../shared/utilities/modals";
import * as phoneService from "../../shared/utilities/phone";

import errorCodes from "../../shared/constants/errorCodes";
import endpoints from '../../shared/constants/endpoints';

const exitIcon = require('./imgs/close-icon.svg');
const lightExitIcon = require('./imgs/light-exit-icon.svg');

export const createCart = async (component, store) => {
  const { addressComponents, setUserCartId, setUserAddress, setModalObject, setSelectedStore} = component.props;
  const {userCartId} = mainStore.getState().user;
  if (userCartId) {
    return;
  }

  const address = addressComponents ? {
    address1: addressComponents.address1,
    city: addressComponents.city,
    state: addressComponents.state,
    postcode: addressComponents.postcode,
    lat: addressComponents.lat,
    lng: addressComponents.lng,
    countryId: addressComponents.countryId,
  } : null;

  const data = {
    storeId: store.id,
    orderTypeId: orderTypeIds.pickup,
    shippingMethod: shippingMethods.pickup,
    address
  };

  try {
    const response = await orderRepo.createCart(data);
    const { createCart } = response.data;

    setUserCartId(createCart.code);
    setUserAddress(address);
    setSelectedStore(store);
  } catch (reason) {
    const message = getErrorMessages(reason, 0);
    const reasonType = getErrorReasons(reason, 0);
    const isCartCreateErrorReason = errorCodes.CART_CREATE_ERROR === reasonType;
    if (isCartCreateErrorReason) {
      setModalObject(openCartCreateFailModal(message, clearCart))
    }
  }
};

const selectStore = async (e, component, store, userCartId, updateOrderLocation, changeCountry) => {
  const {
    setModalObject,
    setUserAddress,
    setSelectedStore,
    setUserOrderMethod,
    addressComponents,
    setSelectedCountry,
    allCountries
  } = component.props;
  e.stopPropagation();

  const addressCountry = allCountries.find(country => +country.id === +store.countryId);

  const address = addressComponents ? {
    address1: addressComponents.address1,
    city: addressComponents.city,
    state: addressComponents.state,
    postcode: addressComponents.postcode
  } : null;

  if (userCartId) {
    try {
      await updateOrderLocation({
        variables: {
          orderCode: userCartId,
          data: {
            storeId: store.id,
            orderTypeId: orderTypeIds.pickup,
            shippingMethod: shippingMethods.pickup,
            address,
          }
        }
      });
      if (changeCountry) {
        setSelectedCountry(addressCountry);
      }
      setUserAddress(address)
      setSelectedStore(store);
      setUserOrderMethod('PICKUP');
    } catch (reason) {
      const message = getErrorMessages(reason, 0);
      const reasonType = getErrorReasons(reason, 0);
      const isCartCreateErrorReason = errorCodes.ORDER_UPDATE_ERROR === reasonType;
      if (isCartCreateErrorReason) {
        setModalObject(openOrderUpdateFailModal(message))
      }
    }

  } else {
    await createCart(component, store);
    setUserOrderMethod('PICKUP');
  }
}

const showCountryChange = (e, changeStock, component, store, userCartId, updateOrderLocation) => {
  const { setModalObject, isCountrySelectActive, history } = component.props;
  if (isCountrySelectActive) {

    setModalObject({
      title: 'You are searching for an address in another country, would you like to change your country selection?',
      description:
        'If you remain on the current country website, you may still proceed with ordering, however, all purchases will be completed in the local store currency, and your transaction and your visit to the website will be governed by the local laws.',
      action1: async () => {
        if (changeStock) {
          guardOrderChange(
            changeStock, selectStore, e, component, store, userCartId, updateOrderLocation, true
          );
          return;
        }

        selectStore(e, component, store, userCartId, updateOrderLocation, true);
        setModalObject(null);
        history.push(component.redirectToPdp(routeCountryPath(endpoints.getMenuUrl(store))));
        window.location.reload();
      },
      action2: async () => {
        if (changeStock) {
          guardOrderChange(
            changeStock, selectStore, e, component, store, userCartId, updateOrderLocation, false
          );
          return;
        }
        selectStore(e, component, store, userCartId, updateOrderLocation, false);
        setModalObject(null);
        history.push(component.redirectToPdp(routeCountryPath(endpoints.getMenuUrl(store))));
      },
      action1Label: 'Yes',
      action2Label: 'No',
    });
  }
  return true;
};

export const handlePickupSubmit = async (e, changeStock, component, store, updateOrderLocation) => {

  const {
    history,
    isCountrySelectActive,
    selectedCountry,
    userCart
  } = component.props;

  const {userCartId} = mainStore.getState().user;
  if (isCountrySelectActive === true && +store.countryId !== +selectedCountry.id) {
    showCountryChange(e, changeStock, component, store, userCartId, updateOrderLocation);
  } else {
    if (changeStock) {
      guardOrderChangeToPickup(
        (userCart.length > 0), selectStore, e, component, store, userCartId, updateOrderLocation, false
      );
      return;
    }
    await selectStore(e, component, store, userCartId, updateOrderLocation, false);
    history.push(component.redirectToPdp(routeCountryPath(endpoints.getMenuUrl(store))));
  }
};

/**
 * Renders chosen location UI
 * TODO: Use object argument to populate proper data
 */
export const renderChosenLocation = (component, store, updateOrderLocation, userOrderMethod, onViewMenuClick) => {
  const { phonePrefixes } = component.props;
  const promotionalText = store?.promotionalText ? store.promotionalText.split('&amp;').join('&') : ''
  return (
    <div className={`${styles.locationContainer} text-dark dark:text-white`}>
      <div className={styles.locationHero}>
        {store.isPickupOpen && store.storeType !== storeTypes.COMINGSOON? (
          <Button
            handleSubmit={e => onViewMenuClick(store, e)}
            customContainerStyles={`${styles.customButtonContainer} md:block hidden`}
            label="View Menu"
          />
        ) : null}
        <img alt="placeholder" src={store.storefrontImage} />
      </div>
      <div className={styles.locationDetailsContainer}>
        <h3 className='text-dark dark:text-white'>{store.name}</h3>
        <div className={styles.locationDetailsHeader}>
          <div>
            <p>{store.address}</p>
          </div>
          {store.storeType === storeTypes.COMINGSOON ? (
            <p className={styles.isClosed}>
              <span className={!store.isPickupOpen ? styles.isClosed : null}>
                {'Coming Soon '}
              </span>

            |
              {` ${store.distanceToStore}`}
            </p>
          )
            : (
              <p className={!store.isPickupOpen ? styles.isClosed : null}>
                <span className={!store.isPickupOpen ? styles.isClosed : null}>
                  {store.isPickupOpen ? 'Open' : 'Closed'}
                </span>
                {' '}
                |
                {` ${store.distanceToStore}`}
              </p>
            )}
        </div>

        <p>
          {phoneService.applyMask(store.phone, phonePrefixes)}
          {' '}
-
          {' '}
          <Link to='/contact' className={`${styles.needContactLink}`}>Need help?</Link>
        </p>

        {store.note ? (<p>{store.note}</p>) : null}

        <div className={styles.locationDetailsHours}>
          {store.hours ? store.hours.map(hour => {
            return (
              <div key={hour.type} className={`${styles.hoursColumn} text-dark dark:text-white`}>
                <h4 className='text-dark dark:text-white'>{hour.type === 'Retail Hours' ? 'Pickup Hours' : hour.type}</h4>
                {hour.days.map(day => {
                  return (
                    <div className={styles.hourColumnItem} key={`day_ ${day.day}`}>
                      <p>{day.day}</p>
                      <p>{day.hour}</p>
                    </div>
                  );
                })}
              </div>
            );
          }
          ) : null}
        </div>
        {store.blurb ? (<span className={`${styles.blurbText} text-dark dark:text-white`}>{store.blurb}</span>) : null}

        {store.promotionalText ? (<span className={styles.promotionalText}>{promotionalText}</span>) : null}

        {store.isPickupOpen && store.storeType !== storeTypes.COMINGSOON ? (
          <Button
            handleSubmit={e => onViewMenuClick(store, e)}
            customContainerStyles={`${styles.customButtonContainer} md:hidden block`}
            label="View Menu"
          />
        ) : null}
      </div>
    </div>
  );
};

/**
 * Render exit icon UI
 */
export const renderExitIcon = component => {
  const { setWidgetValue, setSelectedMapStore } = component.props;

  return (
    <div
      className='mt-8'
    >
      <img
        onClick={() => {
          setSelectedMapStore(null);
          setWidgetValue('');
          component.setState({
            showLocation: false,
            selectedStoreIdFromTheSearchList: null
          })
        }}
        className={`${styles.exitIcon} block dark:hidden`}
        alt="exit-light-icon"
        src={exitIcon}
      />
      <img
        onClick={() => {
          setSelectedMapStore(null);
          setWidgetValue('');
          component.setState({
            showLocation: false,
            selectedStoreIdFromTheSearchList: null
          })
        }}
        className={`${styles.exitIcon} hidden dark:block`}
        alt="exit-icon"
        src={lightExitIcon}
      />
    </div>
)};

/**
 * Renders list search items UI
 * TODO: Use object argument to populate proper data
 */
export const renderListItems = (component, stores, updateOrderLocation, onViewMenuClick) => {
  const { history } = component.props;
  if (stores.length) {
    return (
      <div className={`${styles.locationsList}`}>
        {stores.map((store, index) => {
          const { id, name, mailingAddress, city, state, zip, phone, distanceToStore, isPickupOpen, inDeliveryRange } = store;
          const { addressDetails } = component.state;
          return (
            <LocationsListItem
              key={id}
              to={endpoints.getMenuUrl(store)}
              id={id}
              name={name}
              isOpen={isPickupOpen}
              store={store}
              updateOrderLocation={updateOrderLocation}
              distanceFrom={distanceToStore}
              history={history}
              mailingAddress={mailingAddress}
              city={city}
              state={state}
              zip={zip}
              addressDetails={addressDetails}
              telephone={phone}
              isDeliveryInRange={inDeliveryRange}
              onViewMenuClick={onViewMenuClick}
              onClick={() => {
                component.setState({
                  showLocation: true,
                  chosenLocationIndex: index,
                  selectedStoreIdFromTheSearchList: id
                });
              }}
            />
          );
        })}
      </div>
    );
  }
  return <div />;
};
