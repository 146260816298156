import * as Sentry from "@sentry/react";

/**
 * @author Aghunik Babayan <aghunik@somepage.com>
 * @return {void}
 */
function loadWooBox(d, s, id) {
    const  fjs = d.getElementsByTagName(s)[0];

    if (d.getElementById(id)) return;

    const js = d.createElement(s); js.id = id;

    js.src = "https://woobox.com/js/plugins/woo.js";

    fjs.parentNode.insertBefore(js, fjs);
}
// eslint-disable-next-line import/prefer-default-export
export const initWooBox = () => {
    try{
        loadWooBox(document, 'script', 'woobox-sdk')
    }catch(err){
        Sentry.captureException(err);
    }
};

// eslint-disable-next-line import/prefer-default-export
export const reInitWooBox = () => {
    try{
        window.Woo.box()
    }catch(err){
        Sentry.captureException(err);
    }
};

