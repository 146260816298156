const initialState = null;

export default(state = initialState, { type, value }) => {
    switch (type) {
        case 'ORDER_UPDATED':
            return value || state;
        case 'VOID_ORDER':
            return null;
        default:
            return state;
    }
}
