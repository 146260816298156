import React, { Component } from 'react';

import * as contactRepo from '../../shared/repos/graphql/contact';

import {
    renderGiftHeroLeft,
    renderGiftHeroRight,
    renderForm
} from './renderings';

import styles from './Events.module.css';
import messages from '../../shared/constants/messages';

class Events extends Component {
    constructor(props){
        super(props);
        this.state = {
            formType: 'Events',
            comment: '', // eslint-disable-line
            fullName: '',
            email: '',
            organizationName: '',
            phone: '',
            eventLocation: '',
            eventDate: '',
            eventType: '',
            phoneData: {},
            errorMessage: null, // eslint-disable-line
        }
    }

    submitContactForm = async () => {
        const { formType, fullName, email, organizationName, phone, eventLocation, eventDate, eventType, comment, phoneData } = this.state;

        const fieldsNotFilled = !fullName || !email || !organizationName || !phone || !eventLocation || !eventDate || !eventType || !comment;

        if (fieldsNotFilled) {
            window.scrollTo(0,0); // eslint-disable-line
            return this.setState({ errorMessage: 'Please fill out all necessary fields.'}) // eslint-disable-line
        }

        if (phoneData.phone && phoneData.maskValidation === false) {
            window.scrollTo(0,0); // eslint-disable-line
            return this.setState({ errorMessage: messages.phoneInput.maskValidation.replace('%s', phoneData.mask)}); // eslint-disable-line
        }

        const data = {
            formType,
            fullName,
            email,
            phone,
            comment,
            organizationName,
            eventLocation,
            eventDate,
            eventType
        };

        try {
            await contactRepo.submitContactForm(data);

            window.scrollTo(0,0); // eslint-disable-line
            this.setState({ successMessage: 'Form successfully submitted!' }); // eslint-disable-line
            this.resetFormFields();
            return setTimeout(() => this.setState({ successMessage: false }), 2500) // eslint-disable-line
        } catch (exception) {
            window.scrollTo(0,0); // eslint-disable-line

            const { message } = exception.graphQLErrors[0];
            return this.setState({ errorMessage: message }); // eslint-disable-line
        }
    }

    resetFormFields = () => {
        this.setState(
            {
                fullName: '',
                email: '',
                phone: '',
                comment: '',
                organizationName: '',
                eventLocation: '',
                eventDate: '',
                eventType: '',
                phoneData: {},
                errorMessage: null, // eslint-disable-line
            }
        );
    }

    renderEventSection = () => {
        return (
          <div className={styles.pageWrapper}>
            <div className={styles.pageContainer}>

              <div className={styles.pageTop}>
                {renderGiftHeroLeft(this)}
                {renderGiftHeroRight()}
              </div>

              {renderForm(this, this.submitContactForm)}
            </div>
          </div>
        );
    }

    render() {
        const { location, userToken } = this.props; // eslint-disable-line

        return(<this.renderEventSection />);
    }
}

export default Events;
