import React, { Component } from 'react';


import PropTypes from 'prop-types';

// REDUX
import { connect } from 'react-redux';
import Link from '../../components/LinkWithCountry/LinkWithCountry';

// COMPONENTS
import Button from '../../components/Button/Button';
import { currencyNames } from '../../shared/constants/currency';

// SUB COMPONENT RENDERINGS
import {
  renderGiftHeroLeft,
  renderGiftHeroRight,
} from './renderings';

import styles from './Loyalty.module.css';
import { routeCountryPath } from '../../shared/utilities/common';

class Loyalty extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render() {
    const { selectedCountry, history } = this.props;

    return(
      <div className={styles.pageWrapper}>
        <div className={styles.pageContainer}>
          {/* TOP PAGE CONTAINER */}
          <div className={styles.pageTop}>
            {renderGiftHeroLeft(this)}
            {renderGiftHeroRight()}
          </div>
          {/* BOTTOM PAGE CONTAINER */}
          <div className={styles.pageBottom}>
            <div />
            <div className={styles.loyaltyContent}>
              <h3>
For every
                { currencyNames[selectedCountry.currency.code] ?? 'dollar' }
                {' '}
you spend you get 1 point
              </h3>
              <p>
For orders over
                { selectedCountry.currency.symbol }
20 online, you&apos;ll get 1.25 points per order.
              </p>

              <div>
                <p>
Get
                  { selectedCountry.currency.symbol }
10 for every 100 points
                </p>
                <p>
Spend your
                  { selectedCountry.currency.symbol }
10 reward on your favorite cookies in store or through the app.
                </p>
              </div>
              
              <div className={styles.actionsContainer}>
                <Button 
                  customContainerStyles={styles.customButtonContainer} 
                  handleSubmit={() => history.push(routeCountryPath('/create-account'))}
                  label="Join now" 
                />
                <Link to="/login">Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Loyalty.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  selectedCountry: PropTypes.shape({
    currency: PropTypes.shape({
      code: PropTypes.string.isRequired,
      symbol: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

const mapStateToProps = state => {
  const { selectedCountry } = state.country;
  return { selectedCountry };
}

export default connect(mapStateToProps)(Loyalty);
