/* eslint-disable import/prefer-default-export */

import React from 'react';

// COMPONENTS
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import StoreField from './StoreField/StoreField';

// HELPERS
import { handleFormInput } from '../../shared/utilities/validations';

// STYLES
import styles from './Contact.module.css';
import PhoneInputWidget from '../../components/PhoneInputWidget/PhoneInputWidget';

const renderInput = (component, label, state, isTextArea, validationType = 'string', inputMask = '') => {
  const text = component.state[state];

  return (
    <Input
      isTextArea={isTextArea}
      theme='dark'
      customContainerStyles={styles.inputWrapper}
      handleInput={(e) => handleFormInput(e, component, state, validationType)}
      placeholder=" "
      value={text}
      label={label}
      inputMask={inputMask}
    />
  )
}

const renderTextArea = (component) => {
  const { comment } = component.state;
  return (
    <div>
      <Input
        isTextArea
        theme='dark'
        customContainerStyles={styles.inputWrapper}
        handleInput={(e) => e.target.value.length <= 1000 ? handleFormInput(e, component, 'comment') : null}
        placeholder=" "
        value={comment}
        label="Leave a comment"
      />
      <span>{`${comment.length}/1000 Characters`}</span>
    </div>
  )
}

const renderFeedbackSection = (component, action) => {
  const { errorMessage, successMessage, phoneErrorMessage, phone } = component.state;
  return(
    <div className={styles.formContainer}>
      <p>Send some feedback</p>
      <span className={[
          errorMessage ? styles.error : null,
          successMessage ? styles.success : null
        ].join(' ')}
      >
        {errorMessage || successMessage}
      </span>
      {renderInput(component, 'Order number', 'orderNumber')}
      {renderInput(component, 'Full name', 'fullName')}
      {renderInput(component, 'Email address', 'email', false, 'email')}
      <PhoneInputWidget
        customSelectStyles={styles.phoneSelectStyle}
        onInput={(e) => {
          component.setState({
            phone: e.telephone,
            phoneData: e,
          })
        }}
        label='Mobile number'
        placeholder=" "
        errorMessage={phoneErrorMessage}
        telephone={phone}
        isNumberFormat
      />
      <StoreField component={component} />
      {renderTextArea(component)}
      <Button
        customContainerStyles={styles.submitButton}
        label="Submit"
        handleSubmit={action}
      />
    </div>
  )
}

const renderInquiriesSection = (component, action) => {
  const { errorMessage, successMessage, phoneErrorMessage, phone } = component.state;
  return(
    <div className={styles.formContainer}>
      <p>Questions?</p>
      <span className={[
          errorMessage ? styles.error : null,
          successMessage ? styles.success : null
        ].join(' ')}
      >
        {errorMessage || successMessage}
      </span>
      {renderInput(component, 'Full name', 'fullName')}
      <StoreField component={component} />
      {renderInput(component, 'Email address', 'email', false, 'email')}
      <PhoneInputWidget
        customSelectStyles={styles.phoneSelectStyle}
        onInput={(e) => {component.setState({
            phone: e.telephone,
            phoneData: e,
          })
        }}
        label='Mobile number'
        placeholder=" "
        errorMessage={phoneErrorMessage}
        telephone={phone}
        isNumberFormat
      />
      {/* TEXT AREA CUSTOM UI HANDLING */}
      {renderTextArea(component)}
      <Button
        customContainerStyles={styles.submitButton}
        label="Submit"
        handleSubmit={action}
      />
    </div>
  )
};

const renderRealEstateSection = (component, action) => {
  const { errorMessage, successMessage } = component.state;
  return(
    <div className={styles.formContainer}>
      <span className={[
          errorMessage ? styles.error : null,
          successMessage ? styles.success : null
        ].join(' ')}
      >
        {errorMessage || successMessage}
      </span>
      {renderInput(component, 'Full name', 'fullName')}
      {renderInput(component, 'Email address', 'email', false, 'email')}
      {renderInput(component, 'Proposed location', 'proposedLocation')}
      {/* TEXT AREA CUSTOM UI HANDLING */}
      {renderTextArea(component)}
      <Button
        customContainerStyles={styles.submitButton}
        label="Submit"
        handleSubmit={action}
      />
    </div>
  )
}

const renderVendorSection = (component, action) => {
  const { errorMessage, successMessage, phoneErrorMessage, phone } = component.state;
  return(
    <div className={styles.formContainer}>
      <span className={[
          errorMessage ? styles.error : null,
          successMessage ? styles.success : null
        ].join(' ')}
      >
        {errorMessage || successMessage}
      </span>
      {renderInput(component, 'Organization name', 'organizationName')}
      {renderInput(component, 'Email address', 'email', false, 'email')}
      <PhoneInputWidget
        customSelectStyles={styles.phoneSelectStyle}
        onInput={(e) => {component.setState({
            phone: e.telephone,
            phoneData: e,
          })
        }}
        label='Phone number'
        placeholder=" "
        errorMessage={phoneErrorMessage}
        telephone={phone}
        isNumberFormat
      />
      {renderInput(component, 'Product name', 'productName')}
      {renderInput(component, 'Product cost', 'productCost')}
      {/* TEXT AREA CUSTOM UI HANDLING */}
      {renderTextArea(component)}
      <Button
        customContainerStyles={styles.submitButton}
        label="Submit"
        handleSubmit={action}
      />
    </div>
  )
}

const renderPressSection = (component) => {
  const { contactUsSettings } = component.props;
  return (
    <div className={styles.formContainer}>
      <p>
        For all press enquiries, please contact
        <a href={contactUsSettings.contact_us_press_emails}>
          {contactUsSettings.contact_us_press_emails}
        </a>
      </p>
    </div>
  )
}

export {
  renderFeedbackSection,
  renderInquiriesSection,
  renderRealEstateSection,
  renderVendorSection,
  renderPressSection
}
