import React, {Component} from 'react';
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import PropTypes from "prop-types";

class ReCAPTCHAForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.recaptchaRef = React.createRef();
    }

    onChangeCaptcha = (value) => {
      const {setToken} = this.props;
      setToken(value)
    }

    render() {
        const { size } = this.props;
        return (
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size={size}
            sitekey={window.environment.REACT_APP_NOCAPTCHA_SITEKEY}
            onChange={this.onChangeCaptcha}
          />
        );
    }
}

ReCAPTCHAForm.propTypes = {
    setToken: PropTypes.func,
    size: PropTypes.string,
};

ReCAPTCHAForm.defaultProps = {
    setToken: null,
    size: 'normal'
};
export default ReCAPTCHAForm;
