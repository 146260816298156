import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Product from '../../pages/Product/Product';
import CustomCakeTopper from "../../pages/CustomCakeTopper/CustomCakeTopper";

const ProductRoutes = () => {
  return (
    <Switch>
      <Route path="/products/single/:id" component={Product} />
      <Route path="/products/customize/:id" component={Product} />
      <Route path="/products/box/:id" component={Product} />
      <Route path="/products/box/:id/assortment" component={Product} />
      <Route path="/products/custom-cake-topper/:id/" component={CustomCakeTopper} />
      <Redirect to="/menu" />
    </Switch>
  );
};

export default ProductRoutes;
