import React from 'react';

import styles from './RemoveProductModal.module.css';

const RemoveProductModal = (setModalObject, store, products, action) => {
    return setModalObject({
      children: (
        <div className={styles.orderRemovalWrapper}>
          <span>
Store Location:
            {store.address}
          </span>

          <h3>Are you sure you want change store location?</h3>
          <p>
            Some items in your cart are currently unavailable at this store
            location.
          </p>

          {products.map( product => (
            <div
              key={product.toString()} 
              className={styles.productContainer}
            >
              <p>{product.title}</p>
            </div>
          ))}

          <div className={styles.orderRemovalActions}>
            <button onClick={() => setModalObject(null)} type="button">
              Cancel
            </button>
            <button
              onClick={action} 
              type="button"
            >
              Remove items
            </button>
          </div>
        </div>
      )
    });
};

export default RemoveProductModal;
