export default {
    checkoutPage: {
        contactInfo: {
            alertMsg: {
                success: 'checkoutPage__contactInfo__alert--success',
                error: 'checkoutPage__contactInfo__alert--error'
            }
        }

    }
};
