/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React, { Component, createRef } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from "moment";
import Link from '../../components/LinkWithCountry/LinkWithCountry';


import * as modals from '../../shared/utilities/modals';
import endpoints from '../../shared/constants/endpoints';
// STORE
import {persistor} from '../../redux/store'

// REDUX
import * as elementsActions from '../../redux/actions/elements';
import * as userActions from '../../redux/actions/user';
import * as userRepo from '../../shared/repos/graphql/user';
import * as countryRepo from '../../shared/repos/graphql/country';

// FACEBOOK
import { facebookLogOut } from '../../shared/utilities/facebook';

// GRAPHQL
import { Query, useMutation } from '@apollo/client'; // eslint-disable-line
import * as doughProRepo from '../../shared/repos/graphql/doughPro';

// SECTIONS
import SendPhone from './Sections/SendPhone/SendPhone';

// COMPONENTS
import Hero from '../../components/Hero/Hero';
import Button from '../../components/Button/Button';
import Switch from '../../components/Switch/Switch';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';

// STYLES
import styles from './Profile.module.css';
import subscription from "../../shared/constants/subscription";
import actionsMode from "../../shared/constants/actionsMode";

// CMS content
import * as cms from "../../shared/repos/cms/cms";
import cmsKeys from "../../shared/constants/cms";

// UTILITY
import {getCustomerSubscriptions, toggleCustomerSubscription} from "../../shared/utilities/subscription";
import {getErrorMessages, routeCountryPath} from '../../shared/utilities/common';

import * as phoneService from '../../shared/utilities/phone';
import LoyaltyPointTracker from '../LoyaltyReward/LoyaltyPointTracker';
import { PUNCHH } from '../../shared/constants/loyaltyType';
import ReferralCode from '../../components/ReferralCode/ReferralCode';

const storeSvg = require(`./imgs/store.svg`)

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: true,
      error: false,
      me: null,
      optInSelection: [],
      customerSubscriptions: [],
      optInLoader: false,
    };

    this.smsDisclaimerRef = createRef();
    this.textDisclaimerRef = createRef();
  }

  componentDidMount(){
      const { setDoughProMember, history, userToken } = this.props;
      if (userToken === null) {
          history.push(routeCountryPath('/login'));
          return
      }
      if (userToken){
          doughProRepo.doughProMember().then((res) => {
              setDoughProMember(res.data.doughProMember);
          });
          this.getUser().then(() => {
            const {me} = this.state;
            if (me && me.telephone) {
              this.detectPhoneMusk().then(() => {});
            }
          });
      }
      this.loadCustomerSubscriptions();

      cms.getContent(
        [
          cmsKeys.profilePage.profileDisclaimerNote,
          cmsKeys.profilePage.profileDisclaimerSMSNote,
        ],
        this
      );
  }

  getDisclaimerNotes = () => {
    return cms.getValueByKey(cmsKeys.profilePage.profileDisclaimerNote, this);
  }

  getDisclaimerSMSNotes = () => {
    return cms.getValueByKey(cmsKeys.profilePage.profileDisclaimerSMSNote, this);
  }

  getUser = async () => {
     try {
       const { setUserInfo } = this.props;
         this.setState(
             { dataLoading: true }
         );
         const response = await userRepo.getUser();
         const { me } = response.data;
         setUserInfo({user: me});

         this.setState(
             {
                 me,
                 dataLoading: false,
             }
         )
     } catch(e) {
         this.setState(
             {
                    error: e.message,
                    dataLoading: false
             }
         )
     }
  }

  detectPhoneMusk = async () => {
    const phoneMask = await countryRepo.getPhonePrefixes().then((res) => {
      const {me} = this.state;
      const {phonePrefixes} = res.data;
      // const code = phoneService.detectCountryCode(me.telephone);
      const mask = phoneService.detectMask(me.prefixTelephone, phonePrefixes);
      const preMask = me.prefixTelephone ? me.prefixTelephone.replace(/\d/g, 9) : '';
      if (me.prefixTelephone && mask) {
        return `${preMask} ${mask}`.trim();
      }
      return `${preMask} (999) 999-9999`.trim();
    });
    return phoneMask;
  }

  /**
   * Load customer subscriptions
   * @return {Promise<void>}
   */
  loadCustomerSubscriptions = async () => {
      const {optIn} = subscription.subscriptionType;
      const customerSubscriptions = await getCustomerSubscriptions();
      const filterByOptInTypeObj = customerSubscriptions.filter(item => item.subscriptionTypeId === optIn);
      const filterObj = filterByOptInTypeObj.filter(item => item.customerIdentifier !== null);
      const optInSelection = _.pluck(filterObj, 'subscriptionId');
      this.setState({
          optInSelection,
          customerSubscriptions: filterByOptInTypeObj
      });
  }

    /**
   * Handles Hero component left child element rendering
   */
  returnHeroLeftChild = (points, pointsUntilNextReward, nextReward, selectedCountry) => (
    <div>
      <h1>{`You have ${points} pts`}</h1>
      <p>
        {pointsUntilNextReward}
        {' '}
      pts til your
        { ' ' }
        { selectedCountry.currency.symbol }
        {nextReward}
        {' '}
      reward.
      </p>
    </div>
  );

  /**
   * Returns proper point asset based on points argument
   * @param {Number} points - user points to be used on asset evaluation
   * @returns {React.Element} returns image element with proper source asset
   */
  returnPointAsset = (points) => {
    let asset = '0-points.png';
    if (points > 0 && points < 9) asset = '0-points.png';
    if (points > 10 && points < 19) asset = '10-points.png';
    if (points > 20 && points < 29) asset = '20-points.png';
    if (points > 30 && points < 39) asset = '30-points.png';
    if (points > 40 && points < 49) asset = '40-points.png';
    if (points > 50 && points < 59) asset = '50-points.png';
    if (points > 60 && points < 69) asset = '60-points.png';
    if (points > 70 && points < 79) asset = '70-points.png';
    if (points > 80 && points < 89) asset = '80-points.png';
    if (points > 90 && points < 99) asset = '90-points.png';
    return <img alt="points-asset" src={require(`./imgs/points/${asset}`)} />; // eslint-disable-line
  };

  /**
   * Handles Hero compononent right child rendering
   */
  returnHeroRightChild = (points, rewards, selectedCountry) => {
    return (
      <div>
        <div className={styles.pointsAssetWrapper}>
          {this.returnPointAsset(points)}
          <div>
            <h3>{points}</h3>
            <p className='text-xs'>points</p>
          </div>
          <div className={styles.rewardWrapper}>
            <h4>
              { selectedCountry.currency.symbol }
              {rewards}
            </h4>
            <p className='text-xs'>rewards</p>
          </div>
        </div>
      </div>
    );
  };

  /**
   * Handle profile section rendering
   */
  renderProfileSection = (me) => {
    const {
      firstname,
      lastname,
      prefixTelephone,
      phoneTelephone,
      email,
      birthday,
      hasPassword,
      isStudent,
      schoolName
    } = me;

    let studentStatus = 'NA';

    if (isStudent !== null) {
      studentStatus = isStudent ? 'Yes' : 'No'
    }

    const birthDayUi = birthday ? moment(birthday).format('MMM-DD-YYYY') : '';
    const fullPhone = `${prefixTelephone} ${phoneTelephone}`.trim();
    return (
      <div className="text-dark dark:text-white">
        <div className="flex justify-between w-full mb-4">
          <h4 className="font-bold">Profile</h4>
          <Link to="/user/update-account" className="text-border">
            Update Profile or Password
          </Link>
        </div>
        <div className="p-5 border-[1px] bg-white border-lightBorder rounded-xl w-full flex flex-col gap-3">
          <div className="flex flex-col md:flex-row gap-4 md:gap-0 justify-between w-full">
            <div className="w-full">
              <p className="text-xs leading-[14px] text-darkElevationPrimary">
                First Name
              </p>
              <p className="leading-5 inputLabel">{firstname}</p>
            </div>
            <div className="w-full font-filsonProRegular">
              <p className="text-xs leading-[14px] text-darkElevationPrimary">
                Last Name
              </p>
              <p className="leading-5 inputLabel">{lastname}</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-0 justify-between w-full">
            <div className="w-full">
              <p className="text-xs leading-[14px] text-darkElevationPrimary">
                Email
              </p>
              <p className="leading-5 inputLabel">{email}</p>
            </div>
            <div className="w-full">
              <p className="text-xs leading-[14px] text-darkElevationPrimary">
                Mobile Number
              </p>
              <p className="leading-5 inputLabel">{fullPhone}</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-0 justify-between w-full">
            <div className="w-full">
              <p className="text-xs leading-[14px] text-darkElevationPrimary">
                Birthday
              </p>
              <p className="leading-5 inputLabel">{birthDayUi}</p>
            </div>
            <div className="w-full">
              <p className="text-xs leading-[14px] text-darkElevationPrimary">
                Password
              </p>
              <p className="leading-5 inputLabel">
                {hasPassword ? "*******************" : ""}
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-0 justify-between w-full">
            <div className="w-full">
              <p className="text-xs leading-[14px] text-darkElevationPrimary">
                Are you currently a student?
              </p>
              <p className="leading-5 inputLabel">{studentStatus}</p>
            </div>
            {isStudent && (
            <div className="w-full">
              <p className="text-xs leading-[14px] text-darkElevationPrimary">
                My School
              </p>
              <p className="leading-5 inputLabel">{schoolName}</p>
            </div>
)}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Handle setting section rendering
   */
  renderSettingsSection = () => {
    return (
      <div className={`${styles.pageDetailsSection} text-dark dark:text-white`}>
        <div>
          <h4>Notification Settings</h4>
        </div>
      </div>
    );
  };

  handleScrollTo = (keyField) => {
    if(keyField === 'phone') {
      this.smsDisclaimerRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: "nearest" })
    } else {
      this.textDisclaimerRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: "nearest" })
    }
  }

    /**
     * Handle opt-in updates section rendering
     * @return {JSX.Element}
     */
    renderOptInSection = () => {
        const { optInSelection, customerSubscriptions, optInLoader } = this.state;
        const { isCountrySelectActive, selectedCountry } = this.props;
        const { optIn } = subscription.subscriptionType;
        const loader = optInLoader ? <Loader height={25} /> : null;
        const disclaimerNotes = this.getDisclaimerNotes() || '';
        const disclaimerSMSNotes = this.getDisclaimerSMSNotes() || '';
        return (
          <div className={`${styles.pageDetailsSection} p-5 border-[1px] bg-white border-lightBorder rounded-xl flex flex-col gap-4`}>
            <div className={styles.optInContainer}>
              {loader}
            </div>
            <div className={`${styles.optInOptions} !flex flex-col gap-4`}>
              {customerSubscriptions.map((item) => {
                    const enabled = optInSelection.includes(item.subscriptionId);
                    const displayOnlyOptIn = (optIn === item.subscriptionTypeId);
                      return (displayOnlyOptIn) ? (
                        <>
                          <div className={`${styles.settingsContainer} flex justify-between items-center`}>
                            <div className='text-dark dark:text-white w-full'>
                              <h3 onClick={() => this.handleScrollTo(item.keyField)} className='leading-[normal]'>
                                {item.name}
                                {isCountrySelectActive && selectedCountry.short_name !== 'null' ? ` (${selectedCountry.short_name})` : ''}
                              </h3>
                              <p className='leading-[normal]'>{item.altDescription}</p>
                            </div>
                            <div>
                              <Switch
                                on={enabled}
                                onClick={() => this.toggleOptInUpdate(item.subscriptionId, enabled)}
                              />
                            </div>
                          </div>
                        </>
                  ) : null;
                })}
            </div>
            <div className='flex flex-col'>
              <p ref={this.textDisclaimerRef} className={`${styles.disclaimerNotes} w-full text-darkElevationPrimary dark:text-darkElevationSecondary`} dangerouslySetInnerHTML={{__html:disclaimerNotes}} />
              <p ref={this.smsDisclaimerRef} className={`${styles.disclaimerSMSNotes} w-full text-darkElevationPrimary dark:text-darkElevationSecondary`} dangerouslySetInnerHTML={{__html:disclaimerSMSNotes}} />
            </div>
          </div>
        );
    };

    /**
     * Handle Favorite Bakeries section
     */
    renderFavoriteBakeriesSection = () => {
      const { me } = this.state;

      // Return null if there are no favorite stores
      if (!me?.favoriteStores?.length) {
        return null;
      }

      return (
        <>
          <div className={`${styles.pageDetailsSection} text-dark dark:text-white font-filsonProBold mb-10 .text-[18px]`}>
            <h4>Favorite Bakeries</h4>
          </div>

          {me.favoriteStores.map((store) => (
            <div
              key={store.id} // Ensure to provide a unique key, assuming store.id exists
              className={`${styles.pageDetailsSection} border-[1px] px-5 bg-white border-lightBorder rounded-xl flex flex-col`}
            >
              <div className="flex-col">
                <div className="w-full flex justify-between items-center">
                  <div className="p-2">
                    <img alt="store" src={storeSvg} />
                  </div>
                  <div className="text-dark dark:text-white w-full p-2">
                    <h3 className="leading-normal font-filsonProBold">
                      {store.name}
                    </h3>
                    <p className="leading-normal font-filsonProRegular text-[#745881]">
                      {store.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    };

    /**
     * Toggle customer subscription
     * @param {Number} subscriptionId
     * @param {Boolean} enabled
     * @return {Promise<void>}
     */
    toggleOptInUpdate = async (subscriptionId, enabled) => {
        const { optInSelection, me, customerSubscriptions } = this.state;
        const activeSub = _.find(customerSubscriptions, { subscriptionId });
        if (
            !enabled &&
            activeSub.keyField === subscription.subscriptionKeyField.SMS_OPT_IN &&
            !me.telephone
        ) {
            this.setPhone(subscriptionId, enabled);
            return;
        }

        try {
            this.setState({
                optInLoader: true
            });

            const result = await toggleCustomerSubscription(
                subscriptionId,
                optInSelection,
                !enabled
            );

            this.setState({
                optInLoader: false,
                optInSelection: result
            });
        } catch (e) {
            this.setState(
                {
                    error: e.message,
                    optInLoader: false
                }
            );
        }
    };

  /**
   * Manages the logout strategy
   */
  handleLogout = async () => {
      const { userToken, userCartId, setModalObject } = this.props;
      try{
          await userRepo.logout(userToken, userCartId)
          this.clearUserData()
      }catch(e){
          setModalObject({
              maxWidth: '450px',
              description: <p className={styles.error}>Sorry, but there was a problem with logging out </p>,
              action1Label: 'Cancel',
              action1: () => setModalObject(null),
          });
      }
  }

  logout = () => {
      const { setModalObject } = this.props;

     setModalObject({
          maxWidth: '450px',
          title: 'Are you sure you want to logout?',
          action1Label: 'Cancel',
          action2Label: 'Log Out',
          action1: () => setModalObject(null),
          action2:  () => this.handleLogout()
      });
  };

  deactivateUser = async () => {
      const { history, setModalObject } = this.props;
      try {
          await userRepo.deactivateUser();
          this.clearUserData()
      } catch (exception) {
          setModalObject(modals.userDeactivationErrorModal(history.push, routeCountryPath(endpoints.contactPage)));
      }
  };

  clearUserData = () => {
      const {  history, setModalObject, resetUserData} = this.props;
      facebookLogOut();
      persistor.purge().then(async () => {
          await resetUserData();
          history.push(routeCountryPath(endpoints.homePage));
          setModalObject(null)
      })
  };

  updateUserData = (telephone, phoneTelephone, prefixTelephone, subscriptionId, enabled) => {
      const { me } = this.state;
      const { setModalObject } = this.props;

      userRepo.updateUser({
          firstName: me.firstname,
          lastName: me.lastname,
          email: me.email,
          telephone,
          phoneTelephone,
          prefixTelephone,
          birthday: me.birthday ? me.birthday : '2020-01-01'
      }).then(() => {
          me.telephone = telephone;
          me.phoneTelephone = phoneTelephone;
          me.prefixTelephone = prefixTelephone;
          this.setState({me}, () => {
              this.toggleOptInUpdate(subscriptionId, enabled);
              setModalObject(null);
          });
      }).catch((errors) => {
          const validationMessages = errors.graphQLErrors[0]?.extensions?.validation;
          const message = Object.values(validationMessages)[0][0];
          this.setPhone(subscriptionId, enabled, message)
      });
  };

  setPhone = (subscriptionId, enabled, message) => {
      const { setModalObject } = this.props;
      setModalObject({
          maxWidth: '450px',
          children: <SendPhone
            setModalObject={setModalObject}
            updateUserData={this.updateUserData}
            subscriptionId={subscriptionId}
            enabled={enabled}
            errorMessage={message}
          />
      });
  };

  leaveLoyalty = async () => {
      const { history, setModalObject } = this.props;
      const { me } = this.state;
        try {
          await userRepo.leaveCustomerLoyalty();
            this.setState({
                me: {
                    ...me,
                    isLoyaltyMember: false
                }
            });
            setModalObject(null);
        } catch (err) {
            const message = getErrorMessages(err,0);
            setModalObject(modals.userAccountChangeErrorModal(message, history.push, routeCountryPath(endpoints.contactPage)))
        }
  }

  renderAccountTypeModal = () => {
      const { setModalObject } = this.props;
      const { me } = this.state;
      const { isLoyaltyMember } = me;

      if (isLoyaltyMember) {
          setModalObject(modals.userAccountStatusChangeModal(this.leaveLoyalty, actionsMode.lightMode, this.deactivateUser, actionsMode.lightMode))
      } else {
          setModalObject(modals.userDeactivationModal(actionsMode.lightMode, this.deactivateUser));
      }
  }

  profileSection = () => {
    const { selectedCountry, activeLoyalty, loyaltyPoints, referralEnabled, loyaltyRedeemables } = this.props;
    const { error, dataLoading, me } = this.state;
    if (error) {
      return <Error errorType="400" />;
    }

    if (dataLoading) {
      return (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      );
    }

    if (me && !dataLoading) {
      return (
        <div className={`${styles.pageWrapper} text-dark dark:text-white`}>
          {activeLoyalty === PUNCHH ? <LoyaltyPointTracker points={loyaltyPoints} redeemables={loyaltyRedeemables} /> : (
            <Hero
              leftChild={this.returnHeroLeftChild(me.loyalty.points, me.loyalty.pointsUntilNextReward, me.loyalty.nextReward, selectedCountry)}
              rightChild={this.returnHeroRightChild(
              me.loyalty.points,
              me.loyalty.rewards,
              selectedCountry
            )}
            />
)}
          <div className={styles.pageBarcodeContainer} />
          <div className={`${styles.pageDetailsContainer} max-w-[1000px] mx-auto `}>
            {/* REFERRAL CODE SECTION */}
            { referralEnabled && me && me.loyalty && me.loyalty.referralCode
              ? <ReferralCode referralCode={me.loyalty.referralCode} />
              : null }
            {/* PROFILE SECTION */}
            {this.renderProfileSection(me)}
            {/* SETTINGS SECTION */}
            <br />
            {this.renderSettingsSection()}
            {/* OPT-IN SECTION */}
            {this.renderOptInSection()}
            <br />
            {/* Favorite Bakery Section */}
            {this.renderFavoriteBakeriesSection()}
          </div>

          <div className={styles.pageActionsContainer}>
            <Button
              handleSubmit={() => this.logout()}
              customContainerStyles={`${styles.logoutButtonContainer}`}
              label="Logout"
            />
            <p
              onClick={this.renderAccountTypeModal}
              className='text-border dark:text-dryPink'
            >
              Delete / Deactivate Account
            </p>
          </div>
        </div>
      );
    }
  }

  render() {
    return <this.profileSection />
  }
}

Profile.propTypes = {
  setModalObject: PropTypes.func.isRequired,
  resetUserData: PropTypes.func.isRequired,
  setDoughProMember: PropTypes.func.isRequired,
  setUserInfo: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  userCartId: PropTypes.number.isRequired,
  userToken: PropTypes.string.isRequired,
  isCountrySelectActive: PropTypes.bool,
  selectedCountry: PropTypes.shape({
    short_name: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }) || null,
  activeLoyalty:PropTypes.bool,
  loyaltyPoints: PropTypes.number,
  referralEnabled: PropTypes.bool,
  loyaltyRedeemables: PropTypes.shape()
};

Profile.defaultProps = {
  isCountrySelectActive: false,
  selectedCountry: null,
  activeLoyalty: false,
  loyaltyPoints: null,
  referralEnabled: false,
  loyaltyRedeemables: null
};

export const mapStateToProps = (state) => {
    const {
        isBetaCustomer,
        userCartId,
        userToken
    } = state.user;
    const {
      isDoughProActive
    } = state.doughPro;
    const {
      selectedCountry,
      isCountrySelectActive
    } = state.country;

    const {activeLoyalty, loyaltyPoints, referralEnabled, loyaltyRedeemables} = state.loyalty;

    return {
        isBetaCustomer,
        userCartId,
        userToken,
        isDoughProActive,
        selectedCountry,
        isCountrySelectActive,
        activeLoyalty,
        loyaltyPoints,
        referralEnabled,
        loyaltyRedeemables
    };
};

export const mapDispatchToProps = (dispatch) => ({
  setModalObject: (value) => dispatch(elementsActions.setModalObject(value)),
  setDoughProMember: (value) => dispatch(userActions.setDoughProMember(value)),
  resetUserData: () => dispatch(userActions.resetUserData()),
  setUserInfo: (value) => dispatch(userActions.setUserInfo(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
