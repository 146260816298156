import React, {Component} from 'react';
import Loader from '../../components/Loader/Loader';
import * as cms from '../../shared/repos/cms/cms';
import cmsKeys from '../../shared/constants/cms';
import {getKeyValue} from '../../shared/utilities/cms';

import styles from './AboutUs.module.css'

class About extends Component {
    constructor(props) {
        super(props);

      this.state = {
            loading: true,
            content: []
        };
    }

    componentDidMount() {
        cms.getContent([cmsKeys.aboutUsPage], this);
    }

    render() {
        const {content, loading} = this.state;

        if (loading) {
            return (
              <div className={styles.pageWrapper}>
                <div className={styles.pageContainer}>
                  <Loader id="loader" />
                </div>
              </div>
            );
        }
      
        if (!content.length && !loading) {
          return (
            <div className={styles.pageWrapper}>
              <div className={`${styles.pageContainer} text-dark dark:text-white`}>
                <h1>Oops, No data found for this page</h1>
              </div>
            </div>
          );
        }

        return (
          <div className={styles.pageWrapper}>
            {content.map(aboutUsPage => {
                    const header = getKeyValue(aboutUsPage.children, 'header');
                    const subheader = getKeyValue(aboutUsPage.children, 'subheader');
                    const title = getKeyValue(aboutUsPage.children, 'title');
                    const body = getKeyValue(aboutUsPage.children, 'body');
                    const sections = getKeyValue(aboutUsPage.children, 'sections', true);
                    const history = getKeyValue(aboutUsPage.children, 'history', true);
                    const historyTitle = getKeyValue(history.children, 'title');
                    const historyInfo = getKeyValue(history.children, 'bodies', true);
                    return (
                      <div className={`${styles.pageContainer} text-dark dark:text-white`}>
                        <div className={styles.pageSection}>
                          <h1>{header}</h1>
                          <h4><strong>{subheader}</strong></h4>
                          <p>
                            <strong>{title}</strong>
                            {' '}
                            {body}
                          </p>
                        </div>

                        { (sections.children.length > 0) ? sections.children.map(section => {
                              const sectionBody = getKeyValue(section.children, 'body');
                              return (
                                <div>
                                  <div className={styles.pageSection}>
                                    <p>{sectionBody}</p>
                                  </div>
                                </div>
                              );
                          }) : null }

                        { history.children.length > 0 ? (
                          <div className={styles.pageSection}>
                            <h3>{historyTitle}</h3>
                            { historyInfo.children.length > 0 ?
                                    historyInfo.children.map(item => {
                                        const year = getKeyValue(item.children, 'year');
                                        const info = getKeyValue(item.children, 'info');
                                        return(
                                          <p>
                                            <strong>{year}</strong>
                                            {' '}
                                            {info}
                                          </p>
                                        )
                                    })
                                    : null }
                          </div>    
                        ) : null }
                      </div>
                    )
                })}
          </div>
        );
    }
}

export default About;
