export const setSelectedCountry = value => ({
    type: 'SET_COUNTRY_SELECTED',
    value
});

export const setAllCountries = value => ({
    type: 'SET_ALL_COUNTRIES',
    value
});

export const setIsCountrySelectActive = value => ({
    type: 'COUNTRY_SELECT_ACTIVE',
    value
});

export const setPhonePrefixes = value => ({
    type: 'SET_PHONE_PREFIXES',
    value
});
