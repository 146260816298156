/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import styles from './ReferralCode.module.css';
import {buttonContainer, lightTheme} from '../Button/Button.module.css';
// CMS content
import * as cms from "../../shared/repos/cms/cms";
import cmsKeys from "../../shared/constants/cms";

class ReferralCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: 'Copy Code',
    }
  }

  componentDidMount() {
    cms.getContent(
      [
        cmsKeys.profilePage.profileReferralCodeTitle,
        cmsKeys.profilePage.profileReferralCodeSubTitle,
      ],
      this
    );
  }

  getReferralCodeTitle() {
    return cms.getValueByKey(cmsKeys.profilePage.profileReferralCodeTitle, this);
  }

  getReferralCodeSubTitle() {
    return cms.getValueByKey(cmsKeys.profilePage.profileReferralCodeSubTitle, this);
  }

  copyToClipboard = () => {
    const { referralCode } = this.props;
    copy(referralCode, {
      format: 'text/plain',
      message: 'Copied to clipboard'
    });
    this.setState({ buttonText: 'Code Copied' });
  }

  render() {
    const { referralCode } = this.props;
    const { buttonText } = this.state;
    const title = this.getReferralCodeTitle() || '';
    const subTitle = this.getReferralCodeSubTitle() || '';
    return (
      <div className={`${styles.referralCodeContainer}`}>
        <div>
          <h4 dangerouslySetInnerHTML={{__html:title}} />
          <p dangerouslySetInnerHTML={{__html:subTitle}} />
        </div>
        <div className={`${styles.referralCodeBlock} 
          bg-white dark:bg-dark px-1 py-2 border-[1px] border-lightDry dark:border-darkBorder
          text-dark dark:text-white
          rounded-[20px]`}
        >
          <div className={`${styles.referralCode}`}>
            <div className={`${styles.referralCodeValue}`}>{ referralCode }</div>
            <div className={`${styles.referralCodeButton}`}>
              <div
                role="button"
                onClick={this.copyToClipboard}
                className={[
                  "bg-dark dark:bg-button px-4 flex items-center justify-center",
                  buttonContainer,
                  lightTheme,
                  "!bg-transparent border-[1px] border-dark dark:border-darkBorder text-dark dark:text-white"
                ].join(' ')}
              >
                <i className={`${styles.referralCodeButtonIcon} fa fa-clone`} aria-hidden="true" /> 
                {' '}
                {buttonText}
              </div>
            </div>
          </div>
        </div>
      </div>
      );
    }
}

ReferralCode.propTypes = {
  referralCode: PropTypes.string.isRequired,
};

export default ReferralCode;
