/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {addCountryPathToLocation} from '../../shared/utilities/pathes';

class LinkWithCountry extends Component {

    getToWithCountryPath = (to) => {
        if (typeof to === 'string') {
            return this.handleString(to);
        } if (typeof to === 'object') {
            return this.handleObject(to);
        }
        return to;
    }

    handleString = (to) => {
        const {selectedCountry, isCountrySelectActive} = this.props;
        return addCountryPathToLocation(to, selectedCountry.path_url, isCountrySelectActive);
    }

    handleObject = (to) => {
        const { selectedCountry, isCountrySelectActive} = this.props;
        return {
            ...to,
            pathname: addCountryPathToLocation(to.pathname, selectedCountry.path_url, isCountrySelectActive),
        }
    }

    render = () => {
        const { isCountrySelectActive, to } = this.props;
        if (isCountrySelectActive) {
            const toWithCountryPath = this.getToWithCountryPath(to);
            return (
              <Link
                {...this.props}
                to={toWithCountryPath}
              />
)
        } 
            return (
              <Link
                {...this.props}
              />
)
        
    }
}

LinkWithCountry.propTypes = {
    ...Link.propTypes,
}

LinkWithCountry.defaultProps = {
    ...Link.defaultProps,
}

export const mapStateToProps = state => {
    const { selectedCountry, isCountrySelectActive } = state.country;
    return { selectedCountry, isCountrySelectActive };
};

export default connect(mapStateToProps)(LinkWithCountry);
