const initialState = {
    designId: null
};
export default (state = initialState, { type, value }) => {
    switch (type) {
        case 'DESIGN_SUBMITTED':
            return {
                ...state,
                designId: value
            };
        case 'RESET':
            return initialState;
        default:
            return state;
    }
};
