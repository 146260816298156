/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'underscore';
import DeliveryPickUpWidget from '../../../components/DeliveryPickUpWidget/DeliveryPickUpWidget';
import Loader from '../../../components/Loader/Loader';
import * as elementActions from '../../../redux/actions/elements';
import * as userActions from '../../../redux/actions/user';

import { defaultCurrency } from '../../../shared/constants/currency';
import { orderMethods, orderTypeIds } from '../../../shared/constants/order';
import { shippingMethods, storeIds } from '../../../shared/constants/store';

import * as orderRepo from '../../../shared/repos/graphql/order';

import * as productRepo from '../../../shared/repos/graphql/product';
import * as storeRepo from '../../../shared/repos/graphql/store';

import CheckoutCartButton from '../../../components/CheckoutCartButton/CheckoutCartButton';
import ProductOptions from '../../../components/ProductOptions/ProductOptions';
import QuickCheckoutButton from "../../../components/QuickCheckoutButton/QuickCheckoutButton";

import * as tagmanagerEvents from '../../../shared/utilities/tagmanagerEvents';

import endpoints from "../../../shared/constants/endpoints";
import errorCodes from "../../../shared/constants/errorCodes";
import { historyAction } from "../../../shared/constants/history";
import * as productConstants from "../../../shared/constants/product";
import styles from './BoxProduct.module.css';

import { getErrorMessages, getErrorReasons, routeCountryPath } from "../../../shared/utilities/common";
import { openOrderUpdateFailModal } from "../../../shared/utilities/modals";
import { clearCart, isDeliveryOrPickup } from "../../../shared/utilities/orders";
import { handleProductWithDirectLink } from '../../../shared/utilities/product';
import { checkSelectedOptions, getBoxProducts, getSelectedOptions, renderLoyaltyPrice } from "../../../shared/utilities/renderings";
import { store } from '../../../redux/store';
import * as loyaltyAction from "../../../redux/actions/loyalty";
import { calculateLoyaltyPoints } from '../../../shared/utilities/loyalty';
import { PUNCHH_TYPE } from '../../../shared/constants/loyaltyType';
import PresetBoxes from '../PresetBoxes/PresetBoxes';

const backIcon = require('./imgs/back-icon.svg');
const lightBackIcon = require('./imgs/light-back-icon.svg');

class BoxProduct extends Component {

  constructor(props) {
    super(props);
    const { history } = this.props;
    this.state = {
      productOptionElement: {},
      pickAssortmentLoading: false,
      boxProducts: {},
      productPrice: 0.0,
      customizeProducts: null,
      quantity: null,
      updateCartLoading: false,
      updatePriceLoading: false,
      productAddError: '',
      locationKey: history.location.key,
      points: 0,
      presetSelectionId: 0
    };

    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  async componentDidMount() {
    await this.initializeBoxProducts();
    await this.setProductData();
    handleProductWithDirectLink(this);
  }

  async componentDidUpdate(prevProps) {
    const { selectedStore, history, location } = this.props;
    const { locationKey } = this.state;
    if (prevProps !== this.props) {
      await this.initializeBoxProducts();
    }

    if (selectedStore && selectedStore !== prevProps.selectedStore) {
      await this.setProductData();
    }
    if (history.action === historyAction.pop && location.key !== locationKey) {
      history.goBack()
    }
  }


  initializeBoxProducts = async () => {
    const { products, selectedStore } = this.props;

    const storeId = selectedStore ? selectedStore.id : storeIds.sampleMenu;

    if (!products) {
      await storeRepo.getStoreMenu(storeId).catch(() => { });
    }
  };

  setProductData = async () => {
    const { match, userCart, loyalty, redeemables, isShowOosProducts } = this.props;
    const { params } = match;
    const { id, poid } = params;
    const selectedItem = userCart.find(item => item.id === poid);
    const { customizeProducts, quantity } = this.state;
    let selectedOptions = customizeProducts;
    this.setState({
      updatePriceLoading: true,
      updateCartLoading: true,
      item: selectedItem,
    });
    const redeemableId = redeemables?.redeemableId ? redeemables?.redeemableId : null;
    const { data } = await productRepo.getProduct(id, redeemableId, isShowOosProducts);
    const { product } = data;
    this.setState({
      product,
      productPrice: product.price,
      updatePriceLoading: false,
      updateCartLoading: false
    })
    if (!selectedOptions) {
      selectedOptions = getSelectedOptions(product, selectedItem);
    }
    if (!quantity) {
      this.setState({
        quantity: selectedItem ? selectedItem.quantity : 1
      });
    }
    const options = checkSelectedOptions(product, selectedOptions);
    const boxProdObj = getBoxProducts(product, options);

    this.setState({
      customizeProducts: options,
      boxProducts: boxProdObj,
    });
    
    if (loyalty) {
      this.getProductLoyaltyPoints(loyalty, id, poid);
    }
  }

  getProductLoyaltyPoints = (loyalty, id, poid) => {
    const {order, loyaltyPoints, loyaltyDeals, loyaltyRedeemables, history} = this.props;
    const loyaltyProducts = loyalty === PUNCHH_TYPE.REDEEMABLE ? loyaltyRedeemables : loyaltyDeals
    try {
      const points = calculateLoyaltyPoints(
        loyalty,
        id,
        poid,
        loyaltyPoints,
        loyaltyProducts,
        order
      );
      this.setState({
        points
      })
    } catch (error) {
      history.push(endpoints.loyalty);
    }
  }

  setElement = element => {
    this.setState({
      productOptionElement: element
    });
  };

  /**
   * Renders header section UI
   */
  renderCustomizeHeader = (
    title,
    actionLabel,
    action,
    buttonLabel,
    buttonAction
  ) => {
    const { pickAssortmentLoading } = this.state;

    return (
      <div className={styles.boxSectionHeader}>
        <div>
          <p className="text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">{title}</p>
          {actionLabel ? <span onClick={action} className='text-[#983992] font-filsonProBold text-lg leading-[22px] tracking-[-0.1px]'>{actionLabel}</span> : null}
        </div>

        {buttonLabel ? (
          <button
            data-ddog-id="pick_for_me"
            onClick={buttonAction}
            type="button"
            className={`${styles.headerButton} bg-dark dark:bg-button text-white font-filsonProBold leading-[22px] tracking-[-0.1px] z-50`}
          >
            {pickAssortmentLoading ? <Loader height={25} /> : <span>Pick for me</span>}
          </button>
        ) : null}
      </div>
    );
  };

  pickAssortment = async () => {
    const { product, productOptionElement } = this.state;
    const { selectedStore } = this.props;

    const storeId = selectedStore ? selectedStore.id : storeIds.sampleMenu;
    const groupIds = _.pluck(product.options, 'groupId');

    this.setState({
      pickAssortmentLoading: true
    });

    const response = await productRepo.getProductAssortment(product.id, groupIds, storeId);
    const { productAssortment } = response.data;

    const boxProducts = {};
    const customizeProducts = {};

    productAssortment.forEach(optionGroup => {
      customizeProducts[optionGroup.id] = [];

      optionGroup.options.forEach(option => {
        boxProducts[option.productId] = [];

        for (let i = 1; i <= option.quantity; i++) {
          boxProducts[option.productId].push(option);
          customizeProducts[optionGroup.id].push(option.productId);
        }
      });
    });

    window.scrollTo({
      top: productOptionElement.current.offsetTop,
      left: 0
    })

    await this.setState({
      pickAssortmentLoading: false,
      boxProducts,
      customizeProducts
    });
  };

  onSelect = (preset) => {
    const { productOptionElement } = this.state;

    this.setState({
      pickAssortmentLoading: true
    });

    const boxProducts = {};
    const customizeProducts = {};

    preset.optionGroups.forEach(optionGroup => {
      customizeProducts[optionGroup.id] = [];

      optionGroup.options.forEach(option => {
        boxProducts[option.productId] = [];

        for (let i = 1; i <= option.quantity; i++) {
          const productOption = {
            id: option.productId.toString(),
            productId: option.productId.toString(),
            quantity: option.quantity
          };
          boxProducts[productOption.productId].push(productOption);
          customizeProducts[optionGroup.id].push(productOption.productId);
        }
      });
    });

    window.scrollTo({
      top: productOptionElement.current.offsetTop,
      left: 0
    })

    this.setState({
      pickAssortmentLoading: false,
      boxProducts,
      customizeProducts,
      presetSelectionId: +preset.id
    });
  };

  /**
   * Handles Option Modification
   * @param {array} options
   */
  handleOptionChange = options => {
    const { product } = this.state;
    const boxProducts = {};
    const customizeProducts = {};

    const opts = product.options.filter(option => {
      return ['PRIMARY', 'SELECT_LARGE', 'SELECT_SMALL'].includes(option.optionGroupType);
    }).map(item => item.options.map((e) => ({
      ...e,
      optional: item.minOptions < 1
    }))).reduce((a, b) => a.concat(b));

    Object.entries(options).forEach(([key, value]) => {
      value.forEach(optionId => {
        const selectedProduct = opts.find(item => item.id === optionId) || null;
        if (!selectedProduct) {
          return;
        }
        if (boxProducts[optionId]) {
          boxProducts[optionId].push(selectedProduct);
        } else {
          boxProducts[optionId] = [selectedProduct];
        }
        product.options.forEach(option => {
          if (option.id === key) {
            option.options.forEach(opt => {
              if (optionId === opt.id) {
                if (customizeProducts[option.id]) {
                  customizeProducts[option.id].push(optionId);
                } else {
                  customizeProducts[option.id] = [optionId];
                }
              }
            })
          }
        })
      });
    });
    this.setState({
      boxProducts,
      customizeProducts
    });
  }

  /**
   * Adds Product to Shipping Order
   */
  addForShipping = async () => {

    this.setState({
      updateCartLoading: true
    });

    const { userCartId, setUserCartId } = this.props;
    const { boxProducts } = this.state;
    const boxProductsArray = [];

    Object.entries(boxProducts).forEach((box) =>
      box[1].forEach(() => boxProductsArray.push(box[0]))
    );

    if (userCartId) {
      return this.handleAddProductToCart(userCartId);
    }

    const data = {
      orderTypeId: orderTypeIds.shipping,
      shippingMethod: shippingMethods.shipping,
      storeId: storeIds.shipping
    };

    try {
      const response = await orderRepo.createCart(data);

      const { createCart } = response.data;

      setUserCartId(createCart.code);

      return this.handleAddProductToCart(createCart.code);
    } catch (reason) {
      this.handleCartCreateError(reason)
    }

  };

  setCartStates = () => {
    const { history } = this.props;

    clearCart();
    history.push(routeCountryPath(endpoints.getMenuUrl(null)))
  }

  handleCartCreateError = (reason) => {
    const { setModalObject } = this.props;
    const message = getErrorMessages(reason, 0);
    const reasonType = getErrorReasons(reason, 0);
    const createReason = reasonType === errorCodes.CART_CREATE_ERROR;
    if (createReason) {
      setModalObject(openOrderUpdateFailModal(message, this.setCartStates));
    }
  }

  handleAddProductToCart = async (orderCode, quickCheckout = false) => {
    const { history, setUserCart, userOrderMethod, loyalty, loyaltyPoints, redeemables } = this.props;
    const { product, quantity, customizeProducts, points, presetSelectionId } = this.state;

    const boxProductsArray = [];
    Object.entries(customizeProducts).forEach(([key, value]) => {
      boxProductsArray.push({
        productIds: value,
        groupOptionId: key
      });
    });


    const data = {
      product: product.id,
      quantity,
      productOptions: boxProductsArray,
      redeemableId:
        loyalty === PUNCHH_TYPE.REDEEMABLE ? redeemables?.redeemableId : null,
      dealId: loyalty === PUNCHH_TYPE.DEALS ? redeemables?.redeemableId : null,
      presetSelectionId: presetSelectionId,
    };

    let result;

    if (quickCheckout) {
      tagmanagerEvents
        .clickQuickCheckout({ ...product, quantity, price: (product.price * quantity).toFixed(2) });
    } else {
      tagmanagerEvents
        .addToCart({ ...product, quantity, price: (product.price * quantity).toFixed(2) });
    }

    try {
      result = await orderRepo.addProductToOrder(orderCode, data);
      if ( loyalty ) {
        store.dispatch(loyaltyAction.loyaltyPoints(loyaltyPoints - points));
      }
    } catch (e) {
      this.setState({ updateCartLoading: false });

      if (!e.graphQLErrors) {
        this.setState({
          productAddError: 'Something went wrong! Please try again.',
        })

        return;
      }
      const reason = e.graphQLErrors ? getErrorReasons(e, 0) : '';
      const pickupUnavailable = reason === errorCodes.PICKUP_PRODUCT_UNAVAILABLE_REASON;
      const defaultErrorMessage = e.graphQLErrors ? e.graphQLErrors[0].message : e.message;
      const errorMessage = pickupUnavailable ? productConstants.PICKUP_UNAVAILABLE_PRODUCT_MESSAGE : defaultErrorMessage;
      this.setState({
        productAddError: errorMessage
      })

      return;
    }

    const localStorageCart = result.data.addProductToOrderV2.items.map((item) => item);
    setUserCart(localStorageCart);

    if (quickCheckout) {
      return result;
    }
    const addLink = userOrderMethod === orderMethods.shipping ? endpoints.shippingPage : endpoints.menu;
    history.push(routeCountryPath(addLink));

  }

  /**
   * Adds Product to Order For Pickup Or Delivery based on UserOrderMethod Passed In.
   * @param {String} userOrderMethod
   */
  addForPickupDelivery = (quickCheckout = false) => {
    this.setState({ updateCartLoading: true });
    const { userCartId, selectedStore, userOrderMethod, setUserCartId, userAddress } = this.props;
    const { boxProducts } = this.state;
    const boxProductsArray = [];
    Object.entries(boxProducts).forEach((box) =>
      box[1].forEach(() => boxProductsArray.push(box[0]))
    );

    if (selectedStore) {
      if (!userCartId) {
        let shippingMethod;
        let orderTypeId;

        if (userOrderMethod === orderMethods.delivery) {
          shippingMethod = 4;
          orderTypeId = 2;
        } else if (userOrderMethod === orderMethods.pickup) {
          shippingMethod = 3;
          orderTypeId = 3;
        }

        let storeId = null;
        if (selectedStore && selectedStore.id) {
          storeId = selectedStore.id;
        }

        const address = _.pick(userAddress, ['address1', 'city', 'lat', 'lng', 'postcode', 'state']);
        const data = { orderTypeId, shippingMethod, storeId, address }
        return orderRepo.createCart(data).then(
          (responseCreatCart) => {
            setUserCartId(responseCreatCart.data.createCart.code);
            return this.handleAddProductToCart(responseCreatCart.data.createCart.code, quickCheckout);
          }
        ).catch((reason) => {
          this.handleCartCreateError(reason)
        });
      }

      return this.handleAddProductToCart(userCartId, quickCheckout);
    }
    this.setState({ updateCartLoading: false });
    return true;
  };

  handleProductUpdate = async () => {

    const { userCartId, history, setUserCart, loyalty, redeemables } = this.props;
    const { item, product, quantity, customizeProducts, presetSelectionId } = this.state;

    const customizeProductsArray = [];
    Object.entries(customizeProducts).forEach(([key, value]) => {
      customizeProductsArray.push({
        productIds: value,
        groupOptionId: key
      });
    });

    tagmanagerEvents
      .addToCart({ ...product, quantity, price: (product.price * quantity).toFixed(2) });


    const updateRes = await orderRepo.addUpdateOrderProduct(userCartId, {
      orderProductId: parseInt(item.id, 10),
      product: product.id,
      price: product.price,
      quantity,
      productOptions: customizeProductsArray,
      redeemableId: loyalty === PUNCHH_TYPE.REDEEMABLE ? redeemables?.redeemableId: null,
      dealId: loyalty === PUNCHH_TYPE.DEALS ? redeemables?.redeemableId: null,
      presetSelectionId: presetSelectionId,
    });

    const order = updateRes.data.updateProductOrder;
    const localStorageCart = order.items.map(item1 => item1);
    setUserCart(localStorageCart);

    history.push(routeCountryPath(`/checkout/view-order/`));
  };

  /**
   * Handles the onClick event for Product
   */
  handleSubmit = async () => {
    const { userOrderMethod, setModalObject, updateProduct } = this.props;
    const { product } = this.state;
    if (product.oos === 1) {
      return;
    }
    if (updateProduct) {
      return this.handleProductUpdate();
    }

    if (userOrderMethod === orderMethods.shipping) {
      return this.addForShipping();
    }
    if (isDeliveryOrPickup(userOrderMethod)) {
      return this.addForPickupDelivery(false);
    }
    return setModalObject({
      children: (
        <div className={styles.widgetWrapper}>
          <DeliveryPickUpWidget isModal />
        </div>
      )
    });
  }

  handleQuickCheckout = async () => {
    const { userOrderMethod, userCart, userCartId } = this.props;
    const { product } = this.state;
    if (product.oos === 1) {
      return;
    }
    this.setCartLoading(true);

    if (isDeliveryOrPickup(userOrderMethod)) {
      const orderProductIds = _.pluck(userCart, 'id');
      if (orderProductIds.length) {
        await orderRepo.removeProducts(userCartId, orderProductIds);
      }

      const [res] = await Promise.all([
        this.addForPickupDelivery(true)
      ]);

      const { addProductToOrderV2 } = res.data;
      return { addProductToOrderV2 };
    }
  }

  setCartLoading = (value) => {
    this.setState({ updateCartLoading: value });
  }

  countOptionalCost = () => {
    const { boxProducts } = this.state;
    let optionalCost = 0;
    let isModifierHasExtraCost = false;
    const flattenedBox = Object.keys(boxProducts).reduce(
      (flattenedArray, prod) => {
        // check modifier has extra cost
        isModifierHasExtraCost = !boxProducts[prod][0].optional && boxProducts[prod][0].price > 0;
        if (isModifierHasExtraCost) {
          // Calculate extra cost
          optionalCost += boxProducts[prod][0].price * boxProducts[prod].length;
        } else if (boxProducts[prod][0].optional) {
          optionalCost += boxProducts[prod][0].price * boxProducts[prod].length;
          return flattenedArray;
        }
        return flattenedArray.concat(boxProducts[prod]);
      },
      []
    );

    return {
      optionalCost,
      flattenedBox
    }
  }

  clearPresets = () => {
    this.setState({ boxProducts: {}, customizeProducts: {}, presetSelectionId: 0 });
  }

  /**
   * Renders Component
   */
  boxProductContainer = () => {

    const {
      updateProduct,
      selectedStore,
      designId,
      cakeTopperProductId,
      cakeTopperUrl,
      history,
      isCaloriesActive,
      currency,
      loyalty
    } = this.props;

    const {
      pickAssortmentLoading,
      product,
      quantity,
      customizeProducts,
      updateCartLoading,
      productAddError,
      productPrice,
      updatePriceLoading,
      points
    } = this.state;

    if (!product) {
      return (
        <div className={styles.pageLoaderWrapper}>
          <Loader />
        </div>
      )
    }

    const storeId = selectedStore ? selectedStore.id : storeIds.sampleMenu;

    const productCount = product.options.reduce((total, option) => total + option.minOptions, 0);
    const addProductText = selectedStore ?
      productConstants.ADD_TO_ORDER_TEXT :
      productConstants.ADD_TO_ORDER_NO_ADDRESS_TEXT;

    const boxProductsCounts = this.countOptionalCost();
    const { flattenedBox, optionalCost } = boxProductsCounts;
    const totalCost = selectedStore && !updatePriceLoading ? parseFloat(productPrice + optionalCost).toFixed(2) : null;
    const header = product.title;
    const { description, options } = product;
    const btnDisabled = pickAssortmentLoading || (options.length && (productCount - flattenedBox.length > 0 || updateCartLoading));
    const cta = updateProduct ? productConstants.UPDATE_PRODUCT_TEXT : addProductText;
    const btnLabel = flattenedBox.length - productCount === 0 ? cta : `Pick ${productCount - flattenedBox.length} more`;
    const productPriceItem = selectedStore && !updatePriceLoading ? parseFloat(productPrice).toFixed(2) : null;
    const isShippingStore = selectedStore && selectedStore.id === storeIds.shipping;
    const calories = isCaloriesActive && product.calories !== null ? ` ${product.calories} kCal` : '';
    const outOfStockText = productConstants.OUT_OF_STOCK_TEXT;

    return (
      <div className={`${styles.boxPageContainer} flex justify-between gap-8 px-4 md:px-0`}>
        <div className={`lg:mt-20 ${styles.boxImageContainer}`}>
          {cakeTopperProductId === product.id && designId ? (
            <img alt="product" src={`${cakeTopperUrl}/${designId}`} />
          ) : (
            <img alt="product" src={product.productImage} />
          )}
        </div>
        <div className={`${styles.boxDetailsContainer} flex flex-col gap-4`}>
          <div
            className={`${styles.detailsHeader} flex flex-col gap-4 items-start `}
          >
            <div className={`${styles.backMenu}`}>
              <h3
                className="w-full flex justify-center text-dark dark:text-white items-center font-filsonProBold"
                onClick={() =>
                  history.push(
                    routeCountryPath(
                      isShippingStore
                        ? endpoints.shippingPage
                        : endpoints.getMenuUrl(selectedStore)
                    )
                  )}
              >
                <span>
                  <img
                    alt="back"
                    src={backIcon}
                    className="block dark:hidden h-6"
                  />
                  <img
                    alt="back"
                    src={lightBackIcon}
                    className="hidden dark:block h-6"
                  />
                </span>
                Back to Menu
              </h3>
            </div>
            {loyalty ? (
              <span className=" text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
                {points}
                {" pts"}
                {renderLoyaltyPrice(this)}
              </span>
            ) : productPriceItem ? (
              product.strike_price ? (
                <span className="text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
                  {currency.symbol}
                  <strike>{parseFloat(product.strike_price).toFixed(2)}</strike>
                  {currency.symbol}
                  {productPriceItem}
                </span>
              ) : (
                <span className="text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
                  {currency.symbol}
                  {productPriceItem}
                </span>
              )
            ) : null}
            <h2 className="font-congenialRegular font-bold text-[44px] leading-[46px] tracking-[-1px] text-dark dark:text-white">
              {header}
            </h2>
          </div>
          <p className="text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
            {description} 
            {' '}
            {calories}
          </p>
          {product.nutritionInfoLink ? (
            <a
              href={product.nutritionInfoLink}
              target="blank"
              className={`${styles.nutritionalInfo} text-[#983992] font-filsonProBold text-lg leading-[22px] tracking-[-0.1px]`}
            >
              Nutritional info
            </a>
          ) : null}

          <PresetBoxes
            productId={product.id}
            onSelect={this.onSelect}
            onClear={this.clearPresets}
            storeId={storeId}
          />

          <ProductOptions
            options={product.options}
            onOptionChange={this.handleOptionChange}
            customizeProducts={customizeProducts}
            setElement={this.setElement}
            isCaloriesActive={isCaloriesActive}
            currency={currency}
          />

          <div className={styles.cartWrapper}>
            {updateCartLoading ? (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            ) : null}
            <CheckoutCartButton
              onClick={() => {
                this.handleSubmit();
              }}
              isDisabled={btnDisabled}
              label={product.oos === 1 ? outOfStockText : btnLabel}
              price={loyalty ? (points) : totalCost}
              onQuantityChange={(value) => this.setState({ quantity: value })}
              quantity={loyalty ? null : quantity}
              loyalty={loyalty}
            />

            <QuickCheckoutButton
              addProductToCart={this.handleQuickCheckout}
              setCartLoading={this.setCartLoading}
              isDisabled={btnDisabled}
              product={product}
              history={history}
            />
            {productAddError.length ? (
              <p className={styles.errorMessage}>{productAddError}</p>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <this.boxProductContainer />;
  }
}

BoxProduct.propTypes = {
  cakeTopperProductId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  cakeTopperUrl: PropTypes.string,
  isCaloriesActive: PropTypes.bool,
  isShowOosProducts: PropTypes.bool,
  currency: PropTypes.objectOf(PropTypes.string)
};

BoxProduct.defaultProps = {
  cakeTopperProductId: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_PRODUCT_ID,
  cakeTopperUrl: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_URL,
  isCaloriesActive: false,
  isShowOosProducts: false,
  currency: defaultCurrency
};

export const mapDispatchToProps = (dispatch) => ({
  setUserCart: (value) => dispatch(userActions.setUserCart(value)),
  setUserCartId: (value) => dispatch(userActions.setUserCartId(value)),
  setModalObject: (value) => dispatch(elementActions.setModalObject(value)),
  setStoreId: (value) => dispatch(userActions.setSelectedStore(value)),
  setUserOrderMethodId: (value) => dispatch(userActions.setUserOrderMethod(value)),
  setUserAddress: (value) => dispatch(userActions.setUserAddress(value)),
});

export const mapStateToProps = (state) => {
  const {
    userCart,
    userOrderMethod,
    selectedStore,
    userCartId,
    userInfo,
    userToken,
    userAddress
  } = state.user;
  const { products } = state.elements;
  const { designId } = state.customCakeTopper;
  const { isCaloriesActive, isShowOosProducts } = state.product;
  const { currency } = state.currency;
  const { loyaltyRedeemables, loyaltyDeals, loyaltyPoints, redeemables } = state.loyalty;
  const { order } = state;
  return {
    userCart,
    userOrderMethod,
    selectedStore,
    userCartId,
    userInfo,
    products,
    designId,
    userToken,
    userAddress,
    isCaloriesActive,
    isShowOosProducts,
    currency,
    loyaltyRedeemables,
    loyaltyDeals,
    loyaltyPoints,
    order,
    redeemables
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoxProduct)
);
