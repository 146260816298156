/**
 * Set captcha token
 *
 * @param setVerificationToken
 * @param setVerificationAction
 * @param action
 * @param skip
 * @returns {Promise<void>}
 */
export const setCaptchaToken = async (setVerificationToken, setVerificationAction, action, skip = false) => {
  if (skip) {
    return;
  }
  // eslint-disable-next-line no-undef
  const token = await grecaptcha.enterprise.execute(window.environment.REACT_APP_ENTERPRISE_RECAPTCHA_SITE_KEY, {action});
  setVerificationToken(token);
  setVerificationAction(action);
}
