const initialState = {
    currency: {
        symbol: "$",
        name: "US Dollar",
        code: "USD"
    }
};

export default(state = initialState, { type, value }) => {
    switch (type) {
        case 'SET_CURRENCY':
            return {...state, currency: value};
        case 'RESET':
            return initialState;
        default:
            return state;
    }
}
