/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const SUBMIT_CONTACT_FORM_MUTATION = gql`
  mutation submitContactForm(
    $formType: String!,
    $orderNumber: String,
    $fullName: String,
    $email: String,
    $phone: String,
    $store: String,
    $comment: String,
    $organizationName: String,
    $eventLocation: String,
    $eventDate: String,
    $eventType: String,
    $proposedLocation: String,
    $productName: String,
    $productCost: String
  ) {
    submitContactForm(data: {
      formType: $formType,
      orderNumber: $orderNumber,
      fullName: $fullName,
      email: $email,
      phone: $phone,
      store: $store,
      comment: $comment,
      organizationName: $organizationName,
      eventLocation: $eventLocation,
      eventDate: $eventDate,
      eventType: $eventType,
      proposedLocation: $proposedLocation,
      productName: $productName,
      productCost: $productCost
    })
  }
`;

export const SUBMIT_NEWSLETTER_FORM_MUTATION = gql`
  mutation submitNewsletterForm(
    $email: String!
  ) {
    submitNewsletterForm(email: $email)
  }
`;
