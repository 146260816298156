/**
 * Find values by key from given array
 * @param node
 * @param keyName
 * @param isNotString
 * @return {null|*}
 */
export const getKeyValue = (node, keyName, isNotString = false) => {
   if (node.length === 0) {
     return null;
   }

   const value =  node.find((item) => {
     return (item.key === keyName);
   });

   const isNotUndefined = (value !== undefined);
   const objectCheck = (isNotString && isNotUndefined && value instanceof Object);
   if (objectCheck) {
     return value;
   }

   return (isNotUndefined) ? value.value : null;
}