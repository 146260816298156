import endpoints from './endpoints';

export default [
    {
        name: 'Feedback',
    },
    {
        name: 'General Inquiries',
    },
    {
        name: 'Events/Corporate Sales',
        action: () => {
            window.location.href = endpoints.eventsPage;
        }
    },
    {
        name: 'Real Estate',
    },
    {
        name: 'Vendor'
    },
    {
        name: 'Press'
    },
    {
        name: 'Donations',
        action: () => {
            window.location.href = endpoints.donationPage;
        }
    },
    {
        name: 'Merch',
        action: () => {
            window.location.href = endpoints.gearSite;
        }
    }
];
