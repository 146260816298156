import _ from "lodash";

/**
 * Detects country code from phone with format +x xxxxxxxxxx
 * @param {String} phone 
 * @returns String|null
 */
export const detectCountryCode = (phone) => {
    const arr = phone.split(' ');
    if (arr.length > 1 && /^\+\d{1,3}$/.test(arr[0])) {
        return arr[0]
    }
    return null;
}

/**
 * Get phone without country cude from phone with format +x xxxxxxxxx
 * @param {String} phone  
 * @returns String
 */
export const detectPhone = (phone) => {
    const rawPhone = phone.replace(/^\+\d{1,3}\s/, '');
    return rawPhone.replace(/\D/g, '');
}

/**
 * Fing phone must by country code
 * @param {String} phoneCode 
 * @returns String|null
 */
export const detectMask = (phoneCode, phoneCodes) => {
    const code = _.find(phoneCodes, {code: phoneCode});
    return code ? code.mask : null;
}

export const detectValidator = (phoneCode, phoneCodes) => {
    const code = _.find(phoneCodes, {code: phoneCode});
    return code ? code?.validators : null;
}

export const applyMask = (phone, phoneCodes) => {
    if (/^[+]\d+ \d+$/.test(phone)) {
        const phoneMask = detectMask(detectCountryCode(phone), phoneCodes);
        const phoneWithoutCode = detectPhone(phone);
        if (phoneMask && phoneWithoutCode) {
            const phoneCode = detectCountryCode(phone);
            let result = `${phoneCode  } `;
            let i = 0;
            _.forEach(phoneMask, (c) => {
                if ((c === '9' || c === 'x') && phoneWithoutCode[i]) {
                    result += phoneWithoutCode[i];
                    i+=1;
                } else {
                    result += c;
                }
            });
            return result;
        }
    }
    return phone;
}

export const castMaskFormat = (mask) => {  
  return mask ? mask.replace(/\d/g, "#") : "(###) ###-####";
};

export const formatPhoneNumber = phone => {
  return phone.replace(/\D/g, "");
};