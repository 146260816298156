import React, { Component } from "react";
import PropTypes from "prop-types";

import { store } from "../../redux/store";

import * as loyaltyAction from '../../redux/actions/loyalty';

import * as loyaltyRepo from "../../shared/repos/graphql/loyalty";

import ProductCard from "./ProductCard";
import { PUNCHH_TYPE } from "../../shared/constants/loyaltyType";

class LoyaltyRedeem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redeemables: []
    };
  }

  async componentDidMount() {
    await this.getLoyaltyRedeemables();
  }

  openDeliveryWidget = (link, id) => {
    const { openDeliveryWidget, setRedeemableItem } = this.props;
    setRedeemableItem({
      redeemableId: id,
      redeemableType: PUNCHH_TYPE.REDEEMABLE
    })

    openDeliveryWidget(link);
  }

  getLoyaltyRedeemables = async () => {
    const response = await loyaltyRepo.getLoyaltyRedeemables();
    store.dispatch(loyaltyAction.loyaltyRedeemables(response.data.loyaltyRedeemables))
    this.setState({
      redeemables: response.data.loyaltyRedeemables
    })
  };

  render() {
    const { points } = this.props;
    const { redeemables } = this.state;

    const notHaveLoyaltyRedeemables = !redeemables || redeemables.length <= 0;
    if (notHaveLoyaltyRedeemables) {
      return null;
    }

    return (
      <div className="mx-auto">
        {redeemables.map(redeemItem => {
          
          if ( redeemItem.items.length <= 0) return null

          return (
            <div className="pt-6">
              <div>
                <p className={`${points >= redeemItem.points ? 'text-white' : 'text-darkElevationPrimary'} 
                  text-2xl leading-6 tracking-[-0.3px] font-filsonProBold font-bold`}
                >
                  {redeemItem.title}
                </p>
                <p className="text-white text-sm leading-4 font-filsonProRegular">
                  {`${
                    points >= redeemItem.points
                      ? "Unlocked!"
                      : `${redeemItem.points - points} more points needed`
                  }`}
                </p>
              </div>
  
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 mt-6">
                {redeemItem.items.map(item => {
                  const isBox = item.product.hasAssortments;
                  const isCustom =
                    item.product.options && item.product.options.length > 0;
  
                  let link = null;
                  if (points >= redeemItem.points) {
                    link = `/products/single/${item.product.id}`;
  
                    if (isBox) {
                      link = `/products/box/${item.product.id}`;
                    } else if (isCustom) {
                      link = `/products/customize/${item.product.id}`;
                    }
                  }
                  return (
                    <ProductCard
                      image={item.product.productImageSmall}
                      title={item.product.title}
                      description={item.description}
                      id={item.id}
                      link={link}
                      openDeliveryWidget={this.openDeliveryWidget}
                    />
                  );
                })}
              </div>
            </div>
          )
        })}
      </div>
    );
  }
}

LoyaltyRedeem.propTypes = {
  points: PropTypes.string,
  openDeliveryWidget: PropTypes.func.isRequired,
  redeems: PropTypes.shape({
    length: PropTypes.func,
    map: PropTypes.func
  }),
  setRedeemableItem: PropTypes.func,
};

LoyaltyRedeem.defaultProps = {
  redeems: null,
  setRedeemableItem: () => {},
  points: 0
}

export default LoyaltyRedeem;
