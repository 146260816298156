export default {
    topHeaderOffsetDivHeightDefault: 99,
    topHeaderOffsetDivHeight: 137,
    introHeroBannerWidthThreshold: 1024,
    scrollDeltaDefaultHeight: 50,
    scrollDeltaDefault: 92,
    introBannerReduceTimeoutOffset: 500,
    manualDeltaScroll: 100,
    directLinkTimeout: 3000,
    resendOtpTimeout: 15,
    consumerPlatform: 'website',
    challengeRecaptcha: 'enterprise_v2'
};
