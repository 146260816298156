
export const setCurrency = value => (
    {
        type: 'SET_CURRENCY',
        value
    }
)

export const resetCurrencyData = () => ({
    type: 'RESET',
});
