import {client} from "../../utilities/config";
import {LOCATIONS_VIA_ADDRESS_QUERY, CURRENT_LOCATION_COORDINATES} from "../../../graphql/location";

/**
 * Gets a list of formatted locations from an input string and optional lat/lng
 *
 * @param {String} address
 * @param {Number} lat
 * @param {Number} lng
 * @param {boolean} fullyQualified
 * @param {boolean} showDorms
 * @returns {Promise<ApolloQueryResult<any>>}
 */
// eslint-disable-next-line import/prefer-default-export
export async function getLocations(address, lat, lng, fullyQualified = false, showDorms = true, filter = null) {

    return client.query(
        {
            query: LOCATIONS_VIA_ADDRESS_QUERY,
            fetchPolicy: 'no-cache',
            variables: {
                address,
                lat,
                lng,
                fullyQualified,
                showDorms,
                filter,
            }
        }
    );
}

/**
 * Gets a current location lat/lng
 *
 * @returns {Promise<ApolloQueryResult<any>>}
 */
// eslint-disable-next-line import/prefer-default-export
export async function getCurrentLocationCoordinates() {

  return client.query(
    {
      query: CURRENT_LOCATION_COORDINATES,
      fetchPolicy: 'no-cache',
    }
  );
}
