/* eslint-disable import/prefer-default-export */

import { capitalize } from './common';

const regexCollection = {
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
};

/**
 * Returns boolean
 * @param {String || Number} value 
 * @param {String} type 
 * @return {Boolean} - returns boolean based on value and type argument
 */
export const isValid = (value, type) => {
  let regex;
  switch(type) {
    case('email'):
      regex = regexCollection.email
      break;
    default:
      regex = /.*(?:)/;
      break;
  }
  return regex.test(value);
}

/**
 * Sets the error states based on component, state desired and reset flag
 * @param {React.Component} component - React component to manipulate
 * @param {React.State} state - React state to target
 * @param {boolean} reset - boolean flag to evaluate resetting state or updating
 * @param {String} message - optional message to set error state value to
 */
export const setErrorState = (
    component,
    state,
    reset = false,
    message = null
) => {
  const errorMessage = message || `${capitalize(state)} is not valid.`;

  component.setState({
    [`${state}ErrorMessage`]: reset ? null : errorMessage,
    errorMessage: reset ? null: `${capitalize(state)} is not valid.`,
    alertMessage: reset ? null : component.alertMessage
  })
}

const extractErrorsFromApiError = (error) => {
  const errors = [];

  let validationErrors;
  try {
    validationErrors = error.graphQLErrors
      .map(item => item.extensions.validation)
      .filter(item => item);
  } catch (e) {
    validationErrors = [];
  }

  if (validationErrors.length) {

    validationErrors.forEach(validation => {

      Object.keys(validation).forEach(key => {
        const keyParts = key.split('.');
        const component = keyParts[0];
        const field = keyParts[1] || keyParts[0];

        const message = validation[key].join("\n");
        errors.push({
          component,
          field,
          message
        });
      });
    });
  }

  return errors;
}

/**
 * Get errors from GraphQL Validation Error response
 * @param {Error} error - API Error
 * @return {array} Returns array with errors
 */
export const getValidationErrorMessagesFromApiError = (error) => {
  if (!error.graphQLErrors) {
    return [];
  }

  return extractErrorsFromApiError(error);
}

/**
 * Sets state error messages from GraphQL Validation Error response
 * @param {Error} error - API Error
 * @param {React.Component} component - React component to manipulate
 * @return {boolean} Returns true if validation errors are set, false otherwise
 */
export const setValidationErrorMessagesFromApiError = (error, component) => {
  let validationErrorsSet = false;

  if (!error.graphQLErrors) {
    return validationErrorsSet;
  }

  const errors = extractErrorsFromApiError(error);

  if (errors.length) {
    errors.forEach(err => {
      setErrorState(component, err.field, false, err.message);
      validationErrorsSet = true;
    });
  }

  return validationErrorsSet;
}

/**
 * 
 * @param {Event} e - event object to evaluate target value
 * @param {React.Component} component - React compononent to manipulate
 * @param {React.State} state - React state to target
 * @param {String} validationType
 */
export const handleFormInput = (e, component, state, validationType) => {
  let { value } = e.target;
  const { type } = e.nativeEvent;
  if(typeof value === 'string') {
    value = value.trimStart();
  }
  if (type === 'input') {
    setErrorState(component, state, true);
  }

  component.setState({
    [state]: value,
  });

  if(isValid(value, validationType)) {
    setErrorState(component, state, true)
  } else {
    setErrorState(component, state);
  }

  return null;
}