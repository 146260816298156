export default {
   // CHECKOUT ERRORS.
   COMPLETE_ORDER_OTP_REQUIRED: 'COMPLETE_ORDER_OTP_REQUIRED',
   COMPLETE_ORDER_OTP_INVALID: 'COMPLETE_ORDER_OTP_INVALID',
   COMPLETE_ORDER_OTP_EXPIRED: 'COMPLETE_ORDER_OTP_EXPIRED',
   COMPLETE_ORDER_TOO_MANY_OTP_ATTEMPTS: 'COMPLETE_ORDER_TOO_MANY_OTP_ATTEMPTS',
   COMPLETE_ORDER_UNAVAILABLE_PRODUCT: 'PRODUCT_UNAVAILABLE',
   CART_CREATE_ERROR: 'CART_CREATE_ERROR',
   ORDER_UPDATE_ERROR: 'ORDER_UPDATE_ERROR',
   PRODUCT_UPDATE_ERROR: 'PRODUCT_ADD_ERROR',

   COMPLETE_ORDER_ADDRESS_MISS: 'ADDRESS_MISS_ERROR',
   // PRODUCT ERRORS
   PICKUP_PRODUCT_UNAVAILABLE_REASON: 'PICKUP_PRODUCT_UNAVAILABLE',

   // DOUGH PRO ERRORS.
   DOUGH_PRO_SUBSCRIPTION_REQUIRED: 'DOUGH_PRO_SUBSCRIPTION_REQUIRED',
   DOUGH_PRO_CHANGE_PAYMENT_METHOD_ERROR: 'DOUGH_PRO_CHANGE_PAYMENT_METHOD_ERROR',
   DOUGH_PRO_CANCEL_SUBSCRIPTION_ERROR: 'DOUGH_PRO_CANCEL_SUBSCRIPTION_ERROR',
   DOUGH_PRO_CREATE_CUSTOMER_CONTACT_ON_ORDER_ERROR: 'DOUGH_PRO_CREATE_CUSTOMER_CONTACT_ON_ORDER_ERROR',
   DOUGH_PRO_ADD_PRODUCT_TO_ORDER_ERROR: 'DOUGH_PRO_ADD_PRODUCT_TO_ORDER_ERROR',

   // CUSTOMER
   CONFIRM_CUSTOMER_TELEPHONE_OTP_ERROR: 'CONFIRM_CUSTOMER_TELEPHONE_OTP_ERROR',
   CUSTOMER_TELEPHONE_OTP_TOO_MANY_OTP_ATTEMPTS: 'CUSTOMER_TELEPHONE_OTP_TOO_MANY_OTP_ATTEMPTS',
   CUSTOMER_TELEPHONE_OTP_INVALID: 'CUSTOMER_TELEPHONE_OTP_INVALID',
   CUSTOMER_TELEPHONE_OTP_EXPIRED: 'CUSTOMER_TELEPHONE_OTP_EXPIRED',

};
