import siteSetting from "../../shared/constants/siteSetting";
import tipsTypes from "../../shared/constants/tipsTypes";

const cleanState = {
    tipsSettings: {
        [siteSetting.TIPS_TYPE]: tipsTypes.TYPE_PERCENT,
        [siteSetting.TIPS_VALUES]: [18, 20, 25],
    },
};
const initialState = {
    ...cleanState
};

export default (state = initialState, { type, value }) => {
    switch (type) {
        case 'SET_TIPS_SETTINGS':
            return {...state, tipsSettings: value};
        default:
            return state;
    }
}