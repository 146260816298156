import { GIFT_CARD_BALANCE_QUERY } from "../../../graphql/giftcard";
import { client } from "../../utilities/config";

/**
 * Check Gift Card Balance.
 *
 * @param {Int|String}      cardNumber
 *
 * @author Albert Rosa <arosa@insomniacookies.com>
 */
/* eslint-disable import/prefer-default-export */
export const checkGiftCardBalance = (cardNumber, gRepcaptchaResponse) => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: GIFT_CARD_BALANCE_QUERY,
        variables: {
            cardNumber,
            gRepcaptchaResponse
        }
    });
};

