/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {Component} from 'react';
import { useQuery } from '@apollo/client';

// ROUTER
import {withRouter} from 'react-router-dom'

// QUERY
import {PRODUCT_QUERY} from "../../graphql/product";

// COMPONENTS
import CustomCakeTopperWidget from "../../components/CustomCakeTopperWidget/CustomCakeTopperWidget";
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';

import productStyles from "../Product/Product.module.css";
import CustomCakeTopperInfo from "../../components/CustomCakeTopperInfo/CustomCakeTopperInfo";

const productBg = require('../Product/imgs/product-bg.png');

class CustomCakeTopper extends Component{
    topperContainer = () => {
        const { match } = this.props;
        const { params } = match;
        const { id } = params;

        const { data, loading, error } = useQuery(PRODUCT_QUERY, {
            variables: { id },
            skip: !id,
        });

        // NETWORK ERROR UI
        if (error) {
            return(
              <Error errorType="400" />
            )
        }
        // LOADING UI
        if (loading || !data || !data.product ) {
            return (
              <div className={productStyles.loadingPageContainer}>
                <div className={productStyles.loaderWrapper}>
                  <Loader />
                </div>
              </div>
            );
        }
        
        const { product } = data;
        return (
          <div className={productStyles.productPageWrapper}>
            <img src={productBg} alt="product-bg" />
            <CustomCakeTopperInfo />
            <div className={productStyles.productPageContainer}>
              <CustomCakeTopperWidget productTitle={product.title} productId={product.id} />
            </div>
          </div>
        );
    };
    
    render() {
        return <this.topperContainer />
    }
}

export default withRouter((CustomCakeTopper));
