/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";

// COMPONENTS
import Loader from "../Loader/Loader";

import styles from "./SimpleInput.module.css";

const errorIcon = require("./imgs/error-icon.png");

const SimpleInput = ({
  inputId,
  placeholder,
  handleInput,
  handleOnBlur,
  errorMessage,
  alertMessage,
  buttonLabel,
  handleSubmit,
  customContainerStyles,
  disabled,
  label,
  type,
  theme,
  value,
  isLoading,
  isFooter,
  eventsConfig,
  inputMask,
  maskFunction,
  maskFormat,
  maskPermanents,
  maskChar,
  maxLength,
  isNumberFormat,
  isAllowed,
  name,
  inputIcon,fieldContainerClass, fieldClass
}) => {
  const eventsConfigCombined = {
    ...SimpleInput.defaultProps.eventsConfig,
    ...eventsConfig
  };
  const [password, showPassword] = useState("password");
  const [svgClass, setSvgClass] = useState("none");

  const InputType = isNumberFormat ? NumberFormat : InputMask;
  const MaskType = isNumberFormat ? { format: inputMask } : { mask: inputMask };

  if (isNumberFormat && isAllowed) {
    MaskType.isAllowed = values => isAllowed(values);
  }

  const handleShowPassword = () => {
    const fieldType = password === "password" ? "text" : "password";
    const svgColor = password === "password" ? "#983992" : "none";
    showPassword(fieldType);
    setSvgClass(svgColor);
  };

  const showLabel = () => {
    return label ? <></> : null;
  };

  const inputField = () => {
    if (type === "password") {
      return (
        <div
          className={`${styles.password} flex gap-2 items-center justify-center ${fieldContainerClass} px-2`}
        >
          {inputIcon ? (
            <img src={inputIcon} alt={placeholder} className="w-5 h-5 ml-4" />
          ) : (
            ""
          )}
          <InputType
            id={inputId || label || ""}
            name={name}
            className={`${fieldClass}`}
            value={value}
            type={password}
            placeholder={placeholder || ""}
            onBlur={
              eventsConfigCombined.onBlurEnabled
                ? handleOnBlur || handleInput
                : () => null
            }
            disabled={disabled}
            onChange={handleInput}
            beforeMaskedValueChange={maskFunction}
            {...MaskType}
            maskChar={maskChar}
            formatChars={maskFormat}
            permanents={maskPermanents}
            maxLength={maxLength}
          />
          {showLabel()}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={svgClass}
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            onClick={handleShowPassword}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </div>
      );
    }
    return (
      <div className={`${fieldContainerClass} flex gap-2 items-center justify-center px-2`}>
        {inputIcon ? (
          <img src={inputIcon} alt={placeholder} className="w-5 h-5 ml-4" />
        ) : (
          ""
        )}
        <InputType
          id={inputId || label || ""}
          name={name}
          className={`${fieldClass}`}
          value={value}
          type={type}
          placeholder={placeholder || ""}
          onBlur={
            eventsConfigCombined.onBlurEnabled
              ? handleOnBlur || handleInput
              : () => null
          }
          disabled={disabled}
          onChange={handleInput}
          beforeMaskedValueChange={maskFunction}
          {...MaskType}
          maskChar={maskChar}
          formatChars={maskFormat}
          permanents={maskPermanents}
          maxLength={maxLength}
        />
      </div>
    );
  };

  return (
    <div
      className={`
        ${styles.inputWrapper} 
        ${customContainerStyles} 
        ${
          theme === "Dark" ? styles.inputWrapperDark : styles.inputWrapperLight
        }`}
    >
      {/* ALERT MESSAGE CONTAINER */}
      {alertMessage ? (
        <div className={styles.alertContainer}>
          <p>{alertMessage}</p>
        </div>
      ) : null}

      <div>
        {/* INPUT CONTAINER */}

        {inputField()}

        {/* LABEL CONTAINER */}

        {type !== "password" ? showLabel() : null}

        {/* LOADER CONTAINER */}
        {isLoading ? (
          <span
            className={[
              styles.loaderWrapper,
              isFooter ? styles.footerLoaderWrapper : undefined
            ].join(" ")}
          >
            <Loader height={30} />
          </span>
        ) : null}

        {/* BUTTON CONTAINER */}
        {buttonLabel ? (
          <button
            disabled={disabled}
            className={[
              "bg-dark dark:bg-button text-white px-4 flex items-center justify-center placeholder-white",
              disabled ? styles.isDisabled : null,
              errorMessage || label ? styles.hide : null
            ].join(" ")}
            onClick={handleSubmit}
            type="button"
          >
            {buttonLabel}
          </button>
        ) : null}

        {/* ERROR ICON - TODO: GET IMAGE ASSETS */}
        <div
          className={[
            styles.errorIcon,
            !errorMessage ? styles.hide : null
          ].join(" ")}
        >
          <img src={errorIcon} alt="error-icon" />
        </div>
      </div>

      {/* ERROR CONTAINER */}
      <div className={styles.errorContainer}>
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};

SimpleInput.propTypes = {
  inputId: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  handleInput: PropTypes.func,
  handleOnBlur: PropTypes.func,
  errorMessage: PropTypes.string,
  alertMessage: PropTypes.string,
  buttonLabel: PropTypes.string,
  handleSubmit: PropTypes.func,
  customContainerStyles: PropTypes.any, //eslint-disable-line
  disabled: PropTypes.bool,
  type: PropTypes.string,
  theme: PropTypes.string,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  isFooter: PropTypes.bool,
  eventsConfig: PropTypes.objectOf(PropTypes.bool),
  inputMask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.instanceOf(RegExp))
  ]),
  maskFunction: PropTypes.func,
  maskChar: PropTypes.string,
  maxLength: PropTypes.number,
  maskFormat: PropTypes.objectOf(PropTypes.string),
  maskPermanents: PropTypes.arrayOf(PropTypes.number),
  isNumberFormat: PropTypes.bool,
  isAllowed: PropTypes.func,
  name: PropTypes.string,
  inputIcon: PropTypes.string,
  fieldContainerClass: PropTypes.string,
  fieldClass: PropTypes.string
};

SimpleInput.defaultProps = {
  inputId: "",
  label: "",
  errorMessage: null,
  alertMessage: null,
  buttonLabel: null,
  placeholder: "",
  handleInput: () => null,
  handleOnBlur: null,
  handleSubmit: null,
  customContainerStyles: null,
  disabled: false,
  type: "text",
  theme: "Dark",
  value: "",
  isLoading: false,
  isFooter: false,
  eventsConfig: {
    onBlurEnabled: true
  },
  inputMask: null,
  maskFunction: null,
  maskChar: null,
  maskFormat: null,
  maskPermanents: null,
  maxLength: null,
  isNumberFormat: false,
  isAllowed: null,
  name: "",
  inputIcon: '',
  fieldContainerClass: '',
  fieldClass: ''
};

export default SimpleInput;
