import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// ROUTER
import { withRouter } from 'react-router-dom';

// REDUX
import { connect } from 'react-redux';
import * as userActions from '../../redux/actions/user';

// GRAPHQL
import * as doughProRepo from '../../shared/repos/graphql/doughPro';

// COMPONENTS
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import DoughProFreeCookieTimer from './DoughProFreeCookieTimer';

// STYLES
import styles from './DoughProFreeCookie.module.css';

// UTILS
import { isMobileScreen, routeCountryPath } from '../../shared/utilities/common';
import endpoints from '../../shared/constants/endpoints';
import doughPro from '../../shared/constants/doughPro';
// IMGS
const logoImg = require('./imgs/logo.png');
const starIcon = require('./imgs/star.png');
const chocolateChunkImg = require('./imgs/chocolateChunk.png');
const cookieTimerHoles = require('./imgs/cookieTimerHoles.png');

const DoughProFreeCookie = (props) => {
  const [cookie, setCookie] = useState(null);
  const [fetchingCookie, setFetchingCookie] = useState(true);
  const [activatingCookie, setActivatingCookie] = useState(false);
  const [expirationInterval, setExpirationInterval] = useState(null);
  const [, setExpirationCountdown] = useState([0, 0]);
  const {userToken, doughProMember, setDoughProMember, history} = props;

  let freeCookieUsedAtUtc = null;
  let expirationDate = '';
  let expirationTime = '';

  const isAuthUser = userToken !== null;
  if(!isAuthUser){
    history.push(routeCountryPath(endpoints.loginPage))
  }

  if (doughProMember && doughProMember.freeCookieUsedAt) {
    freeCookieUsedAtUtc = moment.utc(doughProMember.freeCookieUsedAt)
      .add(doughProMember.freeCookieActiveDuration, 'minutes');
    expirationDate = freeCookieUsedAtUtc.local().format('M.D.Y');
    expirationTime = freeCookieUsedAtUtc.local().format('h:mm A');
  }

  const updateDoughProMember = () => {
    doughProRepo.doughProMember().then((response) => {
      setDoughProMember(response.data.doughProMember);
    });
  };

  const fetchCookie = () => {
    setFetchingCookie(true);

    doughProRepo.doughProFreeCookie().then((response) => {
      setFetchingCookie(false);
      setCookie(response.data.doughProFreeCookie);
    }, () => {
      setFetchingCookie(false);
    });
  };

  const activateFreeCookie = () => {
    setActivatingCookie(true);

    doughProRepo.doughProFreeCookieActivate().then((response) => {
      setCookie(response.data.doughProFreeCookieActivate);
      setActivatingCookie(false);
      fetchCookie();
      updateDoughProMember();
    }, () => {
      setActivatingCookie(false);
    });
  };

  const getCountDown = () => {
    if (!freeCookieUsedAtUtc) {
      return [0, 0]
    }
    const minutes = freeCookieUsedAtUtc.diff(moment.utc(), 'minutes');
    const seconds = freeCookieUsedAtUtc.diff(moment.utc(), 'seconds') % 60;
  
    return [minutes, seconds];
  }

  const cookieExpired = () => {
    const [minutes, seconds] = getCountDown();

    return minutes <= 0 && seconds <= 0;
  }

  useEffect(fetchCookie, []);
  useEffect(updateDoughProMember, []);

  const isActiveSubscriber = doughProMember !== null && doughProMember.active === true;
  if (!isActiveSubscriber){
    history.push(routeCountryPath(endpoints.profilePage))
  }

  const handleFreeCookie = () => {
    history.push(routeCountryPath(endpoints.productCustomize.replace(':id',doughPro.freeDoughProCookie)))
  }

  const desktopFreeCookie = () => {
    return (
      <div>
        <div className={styles.desktopLogo}>
          <img src={logoImg} alt="logo" />
        </div>
        <div className={styles.freeDoughDesktopBody}>
          <div className={styles.freeCookieDesktopBody}>
            <div className={styles.freeCookieDescription}>
              <h1>Looking for your free cookie?</h1>
              <div className={styles.freeCookieDescItem}>
                <div className={styles.starLeft}>
                  <img alt="liststar" src={starIcon} />
                </div>
                <p>Open this page on your mobile device to redeem your free in-store cookie for the day!</p>
              </div>
              <div className={styles.freeCookieDescItem}>
                <div className={styles.starLeft}>
                  <img alt="staricon" src={starIcon} />
                </div>
                <p>To add a free cookie to your delivery order, click the button below!</p>
              </div>

              <Button
                label="Free Cookie"
                customContainerStyles={styles.freeCookieDesktopButtonContainer}
                handleSubmit={handleFreeCookie}
              />
              <div className={styles.freeCookiePurchase}>
                <p>*purchase minimums apply</p>
              </div>

            </div>
            <div className={styles.freeCookieChocChunk}>
              <img src={chocolateChunkImg} alt="chochchunk" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!isMobileScreen()) {
    return desktopFreeCookie();
  }

  if (!doughProMember || fetchingCookie) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  const renderActivateScreen = () => {
    if (cookie) {
      return null;
    }

    let btnTxt = 'You Bet';

    if (activatingCookie) {
      btnTxt = 'Activating...'
    }

    return (
      <div className={styles.activationScreen}>
        <div className={styles.activationScreenContent}>
          <h3>
            Ready for your free cookie?
          </h3>
          <p>
            Once you start the timer, you have 5 minutes to redeem in-store for one free classic cookie.  Ready to start the countdown?
          </p>
          <div className={styles.activationScreenActions}>
            <Button
              id="activateFreeCookieBtn"
              customContainerStyles={styles.buttonContainer}
              label={btnTxt}
              disabled={cookie || activatingCookie}
              handleSubmit={() => activateFreeCookie()}
            />
            <Button
              id="redirectBtn"
              customContainerStyles={styles.buttonContainer}
              label="Not Yet"
              disabled={cookie || activatingCookie}
              handleSubmit={() => history.push(routeCountryPath(endpoints.homePage))}
            />
          </div>
        </div>
      </div>
    );
  };

  const zeroFill = (num) => {
    return num > 9 ? num : `0${num}`;
  }

  const renderCountdownScreen = () => {
    if (!doughProMember  || !cookie) {
      return null;
    }

    if (cookieExpired()) {
      return null;
    }

    
    if (!expirationInterval) {
      setExpirationInterval(setInterval(() => {
        const [minutes, seconds] = getCountDown();

        setExpirationCountdown([minutes, seconds]);
      }, 1000));
    }

    const [minutes, seconds] = getCountDown();
    const secondsLeft = minutes * 60 + seconds;
    const secondsTotal = doughProMember.freeCookieActiveDuration * 60;
    const progress = 100 - parseInt(((secondsLeft / secondsTotal) * 100).toFixed(2), 10)

    return (
      <div className={styles.countdownScreen}>
        <div className={styles.countdownScreenContent}>
          <p>Flash this screen in front of our baker’s eyes when you arrive to pick up your free cookie.</p>
          <div className={styles.countdownScreenActive}>
            <div className={styles.countdownScreenCookieTimer}>
              <div className={styles.countdownScreenCookieTimerClock}>
                <div>
                  <span>{minutes}</span>
                  <span>:</span>
                  <span>{zeroFill(seconds)}</span>
                </div>
                <div>Remaining</div>
              </div>
              <DoughProFreeCookieTimer progress={progress} />
              <div className={styles.countdownScreenCookieTimerOverlay} />
              <img src={cookieTimerHoles} alt='Cookie Holes' />
            </div>
            <h3>free cookie valid until:</h3>
            <div className={styles.countdownScreenDate}>
              <h3>{expirationDate}</h3>
            </div>
            <div className={styles.countdownScreenTime}>
              <h3>{expirationTime}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderExpirationScreen = () => {
    if (!cookieExpired() || !cookie) {
      return null;
    }

    if (expirationInterval) {
      clearInterval(expirationInterval);
      setExpirationInterval(null);
    }

    return (
      <div className={styles.expirationScreen}>
        <div className={styles.expirationScreenContent}>
          <p>Flash this screen in front of our baker’s eyes when you arrive to pick up your free cookie.</p>
          <h3>free cookie valid until:</h3>
          <div className={styles.expirationScreenExpired}>EXPIRED</div>
        </div>
      </div>
    );
  }

  return (
    <div className={[styles.doughProFreeCookie, cookie ? styles.doughProFreeCookieActivated : ''].join(' ')}>
      { !cookie && <div className={styles.chocolateChunkImg} style={{backgroundImage: `url(${chocolateChunkImg})`}} /> }
      <div className={styles.logo}>
        <img src={logoImg} alt="CookieMagic" />
      </div>
      {renderActivateScreen()}
      {renderCountdownScreen()}
      {renderExpirationScreen()}
    </div>
  );
};

DoughProFreeCookie.propTypes = {
  doughProMember: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    expirationDate: PropTypes.string,
    freeCookieCount: PropTypes.number,
    freeCookieUsedAt: PropTypes.string,
    freeCookieActiveDuration: PropTypes.number
  }),
  setDoughProMember: PropTypes.func.isRequired,
  userToken: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

DoughProFreeCookie.defaultProps = {
  doughProMember: null,
};

export const mapStateToProps = (state) => {
  const {
    doughProMember,
    userToken
  } = state.user;

  return {
    doughProMember,
    userToken
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setDoughProMember: (value) => dispatch(userActions.setDoughProMember(value)),
  setUserToken: (value) => dispatch(userActions.setUserToken(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DoughProFreeCookie));