import React, { Component } from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as locationsRepo from '../../../shared/repos/graphql/locations';
import * as orderRepo from '../../../shared/repos/graphql/order';
import styles from '../ReviewOrder.module.css';
import Loader from '../../../components/Loader/Loader';
import Input from '../../../components/Input/Input';
import { handleFormInput } from '../../../shared/utilities/validations';
import DeliveryLocationsListItem from '../../../components/DeliveryLocationsListItem/DeliveryLocationsListItem';
import {orderMethods} from "../../../shared/constants/order";

const locationIcon = require('../../../components/DeliveryPickUpWidget/icons/map-pin.svg');

class ShippingAddress extends Component {

    searchAddresses = _.debounce(async value => {
        const { userOrderMethod } = this.props;
        const showDorms = userOrderMethod !== orderMethods.shipping ?? false;
        const results = await locationsRepo.getLocations(value, null, null, true, showDorms);

        const { locationSearch } = results.data;

        this.setState({
            locationsLoading: false,
            setAddressLoading: false,
            locations: locationSearch,
            showLocations: true
        });
    }, 500);

    constructor(props) {    
        super(props);    

        this.state = {
            locations: [],
            locationsLoading: false,
            setAddressLoading: false,
            address: this.selectedAddress(),
            showLocations: false
        };
    }

    selectedAddress = () => {

        const { order } = this.props;
        let address = '';
        const hasAddress = order?.shippingAddress?.address1 && order?.shippingAddress?.city && order?.shippingAddress?.state && order?.shippingAddress?.postcode;

        if(hasAddress){
             address = [
                order.shippingAddress.address1,
                order.shippingAddress.city,
                order.shippingAddress.state,
                order.shippingAddress.postcode
            ].join(', ');
        }
        return address ;
    }

    getAddresses = e => {

        const { address } = this.state;

        handleFormInput(e, this, 'address');

        if (e.target.value === address) {
            return;
        }

        if (e.target.value.length < 3) {
            return;
        }

        this.setState({
            locationsLoading: true,
            showLocations: false
        });

        this.searchAddresses(e.target.value);
    }

    setAddress = async loc => {

        const { order } = this.props;

        this.setState({
            setAddressLoading: true,
            address: loc.address1,
            locations: []
        });

        await orderRepo.setShippingAddress(order.code, loc.externalId);
        this.setState({
            setAddressLoading: false,
        });
        document.dispatchEvent(new CustomEvent('shippingAddressUpdated'));
    }

    clearAddress = () => {

        const { locations } = this.state;

        if (!locations.length) {
            return;
        }

        this.setState({
            address: this.selectedAddress(),
            showLocations: false
        });
    }

    render() {
        const { showLocations, address, locations, locationsLoading, setAddressLoading } = this.state;
        return (
          <div>
            <div className={styles.shippingAddressContent}>
              <Input
                handleInput={e => this.getAddresses(e)}
                handleOnBlur={() => this.clearAddress()}
                value={address}
                isRequired
                customContainerStyles={styles.inputContainer}
                placeholder=" "
                label="Add shipping address"
              />

              {!locationsLoading && setAddressLoading && (
              <div className={styles.shippingAddressLoader}>
                <Loader height={24} />
              </div>
                )}
            </div>
            { locationsLoading ? <Loader /> : null}


            {showLocations ? locations.map(loc => (
              <DeliveryLocationsListItem
                key={loc.externalId}
                onMouseDown={() => this.setAddress(loc)}
                coords={{ lat: loc.lat, lng: loc.lng }}
                icon={locationIcon}
                address1={loc.address1}
                addressDetails={loc.addressDetails}
              />
                )): null}
          </div>
        );
    }
}

ShippingAddress.propTypes = {
    order: PropTypes.shape().isRequired,
    userOrderMethod: PropTypes.string
};
ShippingAddress.defaultProps = {
    userOrderMethod: null,
};
export const mapStateToProps = state => {
    const { order } = state;
    const { userOrderMethod } = state.user;
    return {order, userOrderMethod};
};

export default connect(mapStateToProps)(ShippingAddress);

