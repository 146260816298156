/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

import styles from './SmallProduct.module.css';
import { defaultCurrency } from '../../../shared/constants/currency';

const SmallProduct = ({
  title,
  image,
  price,
  strikePrice,
  onAdd,
  icon,
  className,
  doughProOverlay,
    currency
}) => {
  return (
    <div className={`${styles.smallContainer} ${className}`} onClick={onAdd}>
      <div
        onClick={onAdd}
        className={`
        dark:bg-button bg-dark rounded-full flex items-center justify-center
        text-dark dark:text-white
        ${styles.cartButton}`}
      >
        <img alt="cart-button" src={icon} />
      </div>
      <div onClick={onAdd} className={styles.imageContainer}>
        <img alt={title} src={image} />
      </div>
      <div
        onClick={onAdd}
        className={styles.smallDetails}
      >
        {price ? (
           strikePrice ? (
             <span>
               <strike>{`${currency.symbol}${strikePrice}  `}</strike>
               &nbsp;
               {`${currency.symbol}${price}`}
             </span>
           ): <span>{`${currency.symbol}${price}`}</span>
        ) : null}
        <h3>{title}</h3>
        {doughProOverlay ? (<h5>CookieMagic Member Exclusive</h5>) : null}
      </div>
    </div>
  );
};

SmallProduct.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  price: PropTypes.number,
  strikePrice: PropTypes.number,
  onAdd: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  className: PropTypes.string.isRequired,
  doughProOverlay: PropTypes.bool,
  currency: PropTypes.objectOf(PropTypes.string)
};

SmallProduct.defaultProps = {
  price: null,
  doughProOverlay: false,
  strikePrice: null,
  currency: defaultCurrency
}

export default withRouter(SmallProduct);
