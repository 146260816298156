import React from 'react';
import PropTypes from 'prop-types';

import styles from './DoughProFreeCookieTimer.module.css';

const DoughProFreeCookieTimer = (props) => {
    const { progress } = props;

    const getOffsetColor = (offset) => {
      if (progress >= offset * 100) {
        return '#23baed';
      }

      return '#ffffff';
    };

    const renderStops = () => {
      const elements = [];
      
      for (let i = 1; i <= 100; i += 1) {
        const offset = (i / 100).toFixed(2);

        elements.push(
        (<stop offset={offset} stopColor={getOffsetColor(offset)} stopOpacity="1" key={i} />)
        );
      }
      
      return elements;
    }

    return (
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 860 840">
        <defs>
          <linearGradient id="linear-gradient" x1="433.27" y1="820.05" x2="426.91" y2="13.59" gradientUnits="userSpaceOnUse">
            {renderStops()}
          </linearGradient>
        </defs>
        <path className={styles.cls} d="M823.16,578.41a423.7,423.7,0,0,1-38.56,62.42q-7.77,10.36-16.17,20.26c-.4.49-.87.95-1.18,1.41C690.13,752.73,575.56,810,447.49,810,215.43,810,27.26,621.83,27.26,389.79c0-146.47,74.93-275.5,188.56-350.66,10.75-7.13,21.91-13.8,33.36-19.94,1.12-.6,2.21-1.19,3.32-1.77.65-.33,1.29-.69,2-1a6.15,6.15,0,0,1,5.3,0,4.43,4.43,0,0,1,2.35,3.77,7.64,7.64,0,0,1-.79,3.54c-1.38,3.13-3.36,6-5,9-1,1.8-1.9,3.62-2.83,5.45-1.76,3.52-3.41,7.08-5,10.67-1.62,3.74-3.15,7.52-4.61,11.3-.29.7-.54,1.39-.81,2.07-1.31,3.46-2.55,6.9-3.74,10.38s-2.25,7-3.23,10.5-1.86,7.06-2.61,10.64-1.37,7.18-1.83,10.78-.79,7.27-.92,10.94c0,.64,0,1.28,0,1.92-.32,15.16,3.37,31,13.2,42.48,3.44,4.09,7.61,7.55,11.07,11.54a31.55,31.55,0,0,1,2.19,2.68c5.19,7.06,5,15.15,3.06,23.23-1.56,6.84-4.31,13.75-6,20-7.94,29.3,7.37,74.85,21.83,92.05,10.37,12.33,29.28,13.67,40.2,27.18,4.08,5.09,7.08,12,7.94,22q.58,7.67,1.18,13.9c2.2,28,4,36.83,27.4,53.08,13.82,9.66,34.48,11.79,58.73,27.41.72.48,1.42.94,2.06,1.43,9.26,7.13,6,14.83,8.78,27.46a57.77,57.77,0,0,0,8.18,18.94c3.14,4.94,10,7.93,18.46,10.14,15.46,4,36,4.85,47.73,8.86a75.4,75.4,0,0,1,24.43,12.57c1.25.94,2.43,2,3.62,3.13,7,6.6,8,12.41,14.21,19.79,10.12,12,35.49,12.5,35.49,12.5,8.4,1.41,31.65-1.73,43.59-9.67,6.92-4.63,15.63-4.94,23.55-1.72,9.34,3.84,17.83,11.94,26.95,16.56,29,14.92,51,18.85,87.31,9.44,29.22-7.55,46.57-22.16,70.92-39.28a9.93,9.93,0,0,1,2.61-1.34,3.24,3.24,0,0,1,3.6,1.12,5.38,5.38,0,0,1,.73,3.49,45.71,45.71,0,0,1-2.62,12,139.8,139.8,0,0,1-7.94,20" />
      </svg>
    )
};

DoughProFreeCookieTimer.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default DoughProFreeCookieTimer;