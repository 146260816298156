import { setModalObject } from '../../redux/actions/elements';
import { store } from '../../redux/store';
import { defaultCurrency } from '../constants/currency';

const modalSettings = {
  orderTypeChange: {
    title: 'Confirm Changing Store',
    description: 'You are selecting a new store with items in your cart. Clicking okay will clear your cart and select this new store.'
  }
}

export const orderTypeChangeModal = (target, action, ...args) => {
  return {
    title: 'Continue to Shipping?',
    description: `Switching to the shipping store requires the main store cart to be cleared.`,
    action1: () => store.dispatch(setModalObject(null)),
    action1Label: 'Cancel',
    action2: () => target(action, ...args),
    action2Label: 'Continue'
  };
};

export const orderLocationChangeModal = (target, action, ...args) => {
  return {
    title: 'Continue to new address?',
    description: `Switching to this address will change the nearest store and require the cart to be cleared.`,
    action1: () => store.dispatch(setModalObject(null)),
    action1Label: 'Cancel',
    action2: () => target(action, ...args),
    action2Label: 'Continue'
  };
};

export const orderPickTypeChangeModal = (target, action, ...args) => {
  return {
    title: 'Continue to Pickup?',
    description: `Switching to the pickup store requires the main store cart to be cleared.`,
    action1: () => store.dispatch(setModalObject(null)),
    action1Label: 'Cancel',
    action2: () => target(action, ...args),
    action2Label: 'Continue'
  };
};

export const giftCardOrderTypeModal = (target, action, ...args) => {
  return {
    ...modalSettings.orderTypeChange,
    action2: () => target(action, ...args),
    action2Label: 'Okay'
  };
};

export const doughProOrderTypeChangeModal = (action1Callback = null, action2Callback = null) => {
  return {
    ...modalSettings.orderTypeChange,
    action1: () => {
      if (action1Callback) {
        action1Callback()
      }
    },
    action1Label: 'Cancel',
    action2: () => {
      if (action2Callback) {
        action2Callback()
      }
    },
    action2Label: 'Okay'
  };
}

export const backToHomeModal = (action, ...args) => {
  return {
    title: 'Go to the Home Page?',
    description: 'Would you like to go to the home page?',
    action1: () => store.dispatch(setModalObject(null)),
    action1Label: 'Cancel',
    action2: () => {
      action(...args);
      store.dispatch(setModalObject(null))
    },
    action2Label: 'Okay'
  }
}

export const confirmToMenuModal = (action, action2, address, ...args) => {
  return {
    title: 'Confirm address?',
    description: `${address}`,
    action1: () => {
      action2(true);
      store.dispatch(setModalObject(null));
    },
    action1Label: 'Change',
    action2: () => {
      action(...args);
      store.dispatch(setModalObject(null))
    },
    action2Label: 'Confirm'
  }
}

export const backToMainModal = (target, action, ...args) => {
  return {
    title: 'Continue to Main Store?',
    description: 'Returning to the Main Store requires all shipping cart items and session data to be cleared.',
    action1: () => store.dispatch(setModalObject(null)),
    action1Label: 'Cancel',
    action2: () => target(action, ...args),
    action2Label: 'Okay'
  }
}

export const userDeactivationModal = (action2Mode, action, ...args) => {
  return {
    title: 'Are you sure you want to close your account?',
    description: 'Deactivating your account will result in all order history, loyalty points and balance being deleted.',
    action1Label: 'Cancel',
    action2Label: 'Deactivate account',
    action2Mode,
    action1: () => store.dispatch(setModalObject(null)),
    action2: () => action(...args)
  };
};


export const userAccountStatusChangeModal = (action1, action1Mode, action2, action2Mode) => {
  return {
    title: 'Please select from the following options',
    description: 'Leaving loyalty will result in your loyalty points and balance being deleted. <br><br>Deactivating your account will result in all ' +
    'order history, loyalty points and balance being deleted.',
    action1Mode,
    action1Label: 'Leave Loyalty',
    action2Mode,
    action2Label: 'Deactivate Account',
    action3Label: 'Nevermind',
    action1: () => action1(),
    action2: () => action2(),
    action3: () => store.dispatch(setModalObject(null))
  }
};

export const userAccountChangeErrorModal = (message, action, ...args) => {
  return {
    title: 'Oops! Something went wrong.',
    description: message,
    action1Label: 'Cancel',
    action2Label: 'Contact support',
    action1: () => store.dispatch(setModalObject(null)),
    action2: () => {
      action(...args);
      store.dispatch(setModalObject(null));
    }
  }
};

export const userDeactivationErrorModal = (action, ...args) => {
  return {
    title: 'Oops! Something went wrong.',
    description: 'Your account could not be deactivated. Please contact support for assistance.',
    action1Label: 'Cancel',
    action2Label: 'Contact support',
    action1: () => store.dispatch(setModalObject(null)),
    action2: () => action(...args),
  };
};

export const openFreeCookiePDPModal = (action1, action2) => {
  return {
    title: '1 FREE Classic Cookie',
    description: 'Hey CookieMagic member, don’t forget your FREE cookie with every order! Would you like to add one now?',
    action1Label: 'Nope',
    action2Label: 'Yes',
    action1: () => action1(),
    action2: () => action2()
  };
};

export const openUnavailableOrderProductModal = (action2, message) => {
  return {
    title: 'Product Unavailable',
    description: `${message} is not currently available`,
    action2Label: 'Go To Menu',
    action2: () => action2()
  }
};

export const openAddressMissingOrderModal = (action2, message) => {
  return {
    title: 'Order complete fail',
    description: message,
    action2Label: 'Go To Menu',
    action2: () => action2()
  }
}

export const openCartCreateFailModal = (message, action2) => {
  return {
    title: 'Unable create cart',
    description: message,
    action2Label: 'Okay',
    action2: () => action2()
  }
};

export const openOrderUpdateFailModal = (message, action2) => {
  return {
    title: 'Unable update order',
    description: message,
    action2Label: 'Okay',
    action2: () => action2 ? action2() : store.dispatch(setModalObject(null)),
  }
};

export const openEmptyShippingOptionsModal = ( message, action2) => {
  return {
    title: 'Warning',
    description: message,
    action2Label: 'View Cart',
    action2: () => action2 ? action2() : store.dispatch(setModalObject(null)),
  }
};

export const orderExperienceFeedbackModalPositiveReivew = () => {
  return {
    title: 'Success!',
    description: 'Thank you for submitting your feedback!',
  }
}

export const orderBelowMinimumDeliveryAmount = (defaultMinOrderAmount, currency = defaultCurrency.symbol) => {
  return {
    title: 'Delivery Minimum Not Reached',
    description: `The minimum subtotal for delivery orders is ${currency}${defaultMinOrderAmount}. Please add some more deliciousness to your cart to proceed to checkout.`,
    action1Label: 'Close',
    action1: () => store.dispatch(setModalObject(null)),
  }
}

export const noDeliveryAddressSelected = (action) => {
  return {
    title: 'Address Required to Add Items to Cart',
    description: `Please add your address to see available items from your nearest store. Once you add some deliciousness to your cart, you can proceed to the checkout page.`,
    action1Label: 'Close',
    action1: () => {
      store.dispatch(setModalObject(null))
      action()
    },
  }
}

export const notItemInTheCart = () => {
  return {
    title: 'Add Items to Cart',
    description: `You do not have any items in your cart. Please add an item to proceed to checkout.`,
    action1Label: 'Close',
    action1: () => {
      store.dispatch(setModalObject(null))
    },
  }
}

export const openUnavailableProductModal = (action2, message) => {
    return {
        title: 'Product Unavailable',
        description: `${message}`,
        action2Label: 'Go To Menu',
        action2: () => action2()
    }
};

export const shippingNotAllowToLoyaltyProduct = (action, action2, ...args) => {
  return {
    description: `Products can only be redeemed for Delivery or Pickup. Please change the address and use Delivery or Pickup.`,
    action1: () => {
      action2(true);
      store.dispatch(setModalObject(null));
    },
    action1Label: 'Close',
    action2: () => {
      action(...args);
      store.dispatch(setModalObject(null))
    },
    action2Label: 'Continue'
  }
}
