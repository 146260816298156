import React from 'react';

const NoMatch = () => {
  return (
    <>
      <h1>Sorry we could not find that page</h1>
    </>
  );
};

export default NoMatch;
