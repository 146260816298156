/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import { defaultCurrency } from '../../../shared/constants/currency';
import { getBackgroundImage } from '../../../shared/utilities/common';
import { getIconId } from "../../../shared/utilities/product";

import styles from './MediumProduct.module.css';

const MediumProduct = ({
    primaryImage,
    secondaryImage,
    price,
    strikePrice,
    title,
    onAdd,
    isLimited,
    description,
    alternate,
    icon,
    onClick,
    className,
    doughProOverlay,
    calories,
    currency,
    isSingle,
    oos,
}) => {
    return (
      <div
        data-ddog-id="medium_product"
        className={`
        ${styles.mediumWrapper}
        ${className}
        `}
        onClick={onClick}
      >

        {isLimited ? (
          <div className={`
            bg-[#983992]
            absolute right-4 top-4 w-[52px] h-[52px] px-1 py-3 text-center
            rounded-full text-white text-[10px] tracking-[-0.2px] leading-[10px]
            ${styles.bannerContainer}
            ${!alternate ? styles.alternateBannerContainer : null}`}
          >
            <p className=' capitalize'>LIMITED TIME ONLY</p>
          </div>
        ) : null}
        { oos === 1 && isSingle ? null : (
          <div
            data-ddog-id="medium_product_add_one"
            onClick={isSingle ? onAdd : onClick}
            className={[
                 styles.cartButton,
                 styles.alternateCartButton,
                 'dark:bg-button bg-dark rounded-full flex items-center justify-center'
             ].join(' ')}
          >
            <img alt="add-one" src={icon} id={getIconId(icon)} />
          </div>
        ) }
        <div className={`${styles.mediumContainer} relative`}>
          <div className={[
                   styles.mediumDetailsContainer,
                   styles.alternateContainer,
                   isLimited && alternate ? styles.isLimitedMediumDetailsContainer : null
               ].join(' ')}
          >
            <div
              onClick={onClick}
              className={`
             font-filsonProBold font-bold
              ${styles.mediumDetails}
            `}
            >
              <h3 className="text-[18px]">
                {title}
                {' '}
                {' '}
                {' '}
                {calories}
              </h3>
              {doughProOverlay ? (<h5>CookieMagic Member Exclusive</h5>) : null}
              <p className='hidden'>
                {description}
                {' '}

              </p>
              {price ?  (
                  strikePrice ? (
                    <span>
                      <strike>{`${currency.symbol}${strikePrice}  `}</strike>
                        &nbsp;
                      {`${currency.symbol}${price}`}
                    </span>
                  ): <span>{`${currency.symbol}${price}`}</span>
               ) : null}

            </div>
            <div
              onClick={onClick}
              className={`${styles.primaryImageContainer} absolute -top-10 -left-6 w-[174px] h-[174px]`}
            >
              <img alt="primary" src={primaryImage} id={getIconId(primaryImage)} />
            </div>
          </div>
        </div>
        {secondaryImage ? (
          <div
            onClick={onClick}
            className={[
                styles.secondaryImageContainer,
                !alternate ? styles.alternateSecondary : null
            ].join(' ')}
            style={{backgroundImage:`url(${getBackgroundImage(secondaryImage)})`}}
          />
        ) : null}
      </div>
    );
};

MediumProduct.propTypes = {
    primaryImage: PropTypes.node.isRequired,
    secondaryImage: PropTypes.node,
    price: PropTypes.number,
    strikePrice: PropTypes.number,
    title: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    isLimited: PropTypes.bool,
    description: PropTypes.string.isRequired,
    alternate: PropTypes.bool,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    className: PropTypes.string.isRequired,
    doughProOverlay: PropTypes.bool,
    calories: PropTypes.string,
    currency: defaultCurrency,
    isSingle: PropTypes.bool.isRequired,
    oos: PropTypes.oneOfType([
      PropTypes.number,
      null,
    ]),
};

MediumProduct.defaultProps = {
    secondaryImage: null,
    isLimited: false,
    alternate: false,
    price: null,
    strikePrice: null,
    doughProOverlay: false,
    calories: null,
    currency: defaultCurrency,
    oos: null,
};

export default withRouter(MediumProduct);
