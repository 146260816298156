import PropTypes from 'prop-types';

export const propTypeOrder = PropTypes.shape({
  useCookieDough: PropTypes.bool,
  id: PropTypes.number,
  customer: PropTypes.shape({
    loyalty: PropTypes.shape({
      rewards: PropTypes.string.isRequired
    }).isRequired
  })
});

export const propTypeStorePaymentMethod = PropTypes.shape({
  id: PropTypes.string.isRequired,
  paymentMethodId: PropTypes.number.isRequired,
  storeId: PropTypes.number.isRequired,
  active: PropTypes.bool,
  paymentMethod: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    variables: PropTypes.string
  }),
  iconUrlPath: PropTypes.string,
  isEmbeddedHtml: PropTypes.bool,
  embeddedJs: PropTypes.string,
  embeddedHtml: PropTypes.string,
  theOrder: PropTypes.number,
  variables: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }))
});

export const propTypePaymentCredentialData = {
  cardHolderName: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired
};

export const propTypePaymentCredential = {
  paymentMethodId: PropTypes.number,
  credential: PropTypes.string,
  securityCode: PropTypes.string,
  saveCredentials: PropTypes.bool,
  data: propTypePaymentCredentialData,
  paymentProcessorId: PropTypes.number,
  credentials: PropTypes.arrayOf(PropTypes.string),
  sessionKey: PropTypes.string,
  issuer: PropTypes.string,
  summary: PropTypes.string,
  defaultPaymentMethod: PropTypes.bool
};

export const propTypePaymentCredentialSubmitted = PropTypes.shape({
  storePaymentMethod: propTypeStorePaymentMethod,
  summary: PropTypes.string,
  paymentCredential: propTypePaymentCredential
});

export const propTypeCustomerSavedCards = PropTypes.arrayOf(
  PropTypes.shape({
    transactionId: PropTypes.string,
    last4: PropTypes.string,
    exp: PropTypes.string,
    cardType: PropTypes.string,
    favorite: PropTypes.bool
  })
);
export const propTypesCmsContent = PropTypes.arrayOf(
  PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
);

export const propTypesComponentPaymentInputBase = {
  isSavePaymentMethodAllowed: PropTypes.bool,
  isSavePaymentMethodCheckedAndDisabled: PropTypes.bool,
  onSubmitPaymentCredentials: PropTypes.func,
  onCancel: PropTypes.func,
  paymentMethodsCustomerSaved: propTypeCustomerSavedCards,
  hideCancel: PropTypes.bool,
  isDoughProMember: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  cmsContent: propTypesCmsContent,
};

export const propTypesComponentPaymentInputs = {
  ...propTypesComponentPaymentInputBase,
  hidden: PropTypes.bool,
  storePaymentMethods: PropTypes.arrayOf(propTypeStorePaymentMethod),
  storePaymentMethodSelected: propTypeStorePaymentMethod
};

export const propTypesComponentPaymentInput = {
  ...propTypesComponentPaymentInputBase,
  storePaymentMethod: propTypeStorePaymentMethod,
  isHidden: PropTypes.bool,
  setSaveCredentials: PropTypes.func,
};
