/* eslint-disable import/prefer-default-export */
import { CMS_API_QUERY } from '../../../graphql/cms';
import { client } from '../../utilities/config';

export const getContent = (keys, localeId) => {
    return client.query({
        query: CMS_API_QUERY,
        variables: { 
            keys,
            localeId,
        }
    });
};
