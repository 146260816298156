/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable-next-line react/prop-types */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';

// REDUX
import * as cakeTopperActions from '../../redux/actions/customCakeTopper';

// COMPONENT
import Loader from "../Loader/Loader";

// STYLE
import productStyles from "../../pages/Product/Product.module.css";
import singleProductStyle from '../../pages/Product/SingleProduct/SingleProduct.module.css';
import './topper.widget.css';
import { routeCountryPath } from '../../shared/utilities/common';

class CustomCakeTopperWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true
    };
  }

  // load cake topper widget
  componentDidMount() {
    this.loadWidget();
  }

  // load widget html
  widgetContainer = () => {
    const {productTitle, topperCss} = this.props;
    const {showLoader} = this.state;
    const widgetHtml = (
      <div className={singleProductStyle.productDetailsContainer}>
        <div className={singleProductStyle.productHeader}>
          <h2>{productTitle}</h2>
        </div>
        <div id="topperoo" />
        <link href={topperCss} rel='stylesheet' type='text/css' />
      </div>
    );

    if (showLoader) {
      return (
        <div className={productStyles.loadingPageContainer}>
          <div className={productStyles.loaderWrapper}>
            <Loader />
          </div>
        </div>
      );
    }

    return (
      <div className={[singleProductStyle.productPageContainer, "tab-center"].join(" ")}>
        { widgetHtml }
      </div>
    );
  };

  loadWidget() {
    // eslint-disable-next-line react/prop-types
    const {instanceID, productId, updateDesignId, history, location, topperJs} = this.props;
    // eslint-disable-next-line react/prop-types
    const { redirectProduct } = location.state || {redirectProduct: 'customize'};
    updateDesignId(null);
    // eslint-disable-next-line no-underscore-dangle
    window._tprcfg = {
      options: {
        tabsDisabled: [1, 3],
        defaultTab: 2,
        forceOpenTabOnInit: true
      }
    };
    ((t, o, pp, e, r, oo) => {
      // eslint-disable-next-line no-param-reassign
      t.tprSettings = {
        tpriid: instanceID
      };
      // eslint-disable-next-line no-param-reassign
      t.tprCallbacks = {
        'topperoo.order.submit': (data) => {
          if (typeof data.designId !== 'undefined') {
            updateDesignId(data.designId);
            history.push(routeCountryPath(`/products/${redirectProduct}/${productId}`), {displayProduct: true});
          }
        },
      };
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      r = o.getElementsByTagName('head')[0];
      // eslint-disable-next-line no-param-reassign
      oo = o.createElement('script');
      // eslint-disable-next-line no-param-reassign
      oo.async = 1;
      // eslint-disable-next-line no-param-reassign
      oo.src = e;

      // eslint-disable-next-line no-param-reassign
      oo.onload = () => {
        this.setState({
          showLoader: false
        });
      };

      r.appendChild(oo);

    })(window, document, 'script', topperJs);
  }

  render() {
    return <this.widgetContainer />;
  }
}

export const mapStateToProps = (state) => {
  const { designId } = state.customCakeTopper;
  return { designId };
};

export const mapDispatchToProps = dispatch => ({
  updateDesignId: (value) => dispatch(cakeTopperActions.updateDesignId(value))
});

CustomCakeTopperWidget.propTypes = {
  instanceID: PropTypes.string,
  productTitle: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  updateDesignId: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  topperCss: PropTypes.string,
  topperJs: PropTypes.string,
};

CustomCakeTopperWidget.defaultProps = {
  instanceID: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_ID,
  topperCss: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_CSS,
  topperJs: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_JS,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomCakeTopperWidget));