import { createStore } from 'redux';

// REDUX PERSIST PACKAGES
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// ROOT REDUCER
import rootReducer from './reducers/index';
import { expireStateTransform } from '../shared/utilities/redux/transformers';

const migrations = {
  0: (state) => {
    // migration clear out user state
    return state;
  },
  1: (state) => {
    // eslint-disable-next-line no-use-before-define
    const initialState = getStore().getState();
    const { user } = initialState;
    return {
      ...state,
      user: {
        ...user,
        ...state.user
      }
    }
  }
}

// REDUX PERSIST CONFIGURATIONS
const persistConfig = {
  key: 'persistedStorage',
  version: 1,
  storage,
  whitelist: [
    'user',
    'customCakeTopper',
    'currency',
    'country',
    'braze',
    'ccDetails',
  ],
  transforms: [
    expireStateTransform
  ],
  migrate: createMigrate(migrations, { debug: false }),
}

const modifiedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(modifiedReducer);
const persistor = persistStore(store);

export { store, persistor, migrations }

function getStore() {
  return store;
}

export const needReload = () => {
  const userData = store.getState().user;
  if (
    userData.selectedStore!== '' ||
      userData.userAddress !== ''  ||
      userData.userCart.length !== 0 ||
      userData.userCartId !== null ||
      userData.userOrderMethod !== '' ||
      userData.userToken !== null
  ) {
    return true
  }
  // dispatched
  return false;
};
