/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint no-param-reassign:  [2, { "props": false }] */

import React from 'react';

import PropTypes from 'prop-types';

import styles from './CustomProductOption.module.css';
import CustomQuantityOption from './CustomQuantityOption';
import CustomSingleOption from './CustomSingleOption';
import CustomSelectorOption from './CustomSelectorOption';

const renderPrice = (price, currency) => {
  return (price || price > 0)  ? (
    <span className={`${styles.price} text-dark dark:text-white`}>
(+
      {currency.symbol}
      {price.toFixed(2)}
)
    </span>
): null;
}

const returnQuantityOption = (
  image,
  name,
  quantity,
  onChangeAdd,
  onChangeRemove,
  calories,
  price = null,
  currency = null,
  description=null,
  oos = null,
) => {

  return (
    <CustomQuantityOption
      image={image}
      name={name}
      quantity={quantity}
      onChangeAdd={onChangeAdd}
      onChangeRemove={onChangeRemove}
      calories={calories}
      renderPrice={renderPrice}
      price={price}
      currency={currency}
      description={description}
      oos={oos}
    />
  )
};

const returnSingleOption = (image, name, onChange, isSelected, price = null, currency = null, description = null,  oos = null) => {
  return (
    <CustomSingleOption
      image={image}
      name={name}
      onChange={onChange}
      isSelected={isSelected}
      renderPrice={renderPrice}
      price={price}
      currency={currency}
      description={description}
      oos={oos}
    />
  );
};

const returnSelectorOption = (name, isSelected, onChange, price, calories, currency, oos = null) => {
  return (
    <CustomSelectorOption 
      name={name}
      isSelected={isSelected}
      onChange={onChange}
      price={price}
      calories={calories}
      currency={currency}
      renderPrice={renderPrice}
      oos={oos}
    />
  )
};

const CustomProductOption = ({
  type,
  image,
  name,
  quantity,
  onChange,
  onChangeAdd,
  onChangeRemove,
  isSelected,
  price,
  optionsCount,
  calories,
  currency,
  description,
  oos,
}) => {
  return (
    <div className={[
             styles.customOptionWrapper,
             type === 'selector' ? styles.selectorContainer : null,
             type === 'selector' && optionsCount > 1  ? styles.w_50 : null,
         ].join(' ')}
    >
      {type === 'quantity'
        ? returnQuantityOption(
            image,
            name,
            quantity,
            onChangeAdd,
            onChangeRemove,
            calories,
            price,
            currency,
            description,
            oos,
          )
        : null}
      {type === 'single'
        ? returnSingleOption(image, name, onChange, isSelected, price, currency, description, oos)
        : null}
      {type === 'selector'
        ? returnSelectorOption(name, isSelected, onChange, price, calories, currency, oos)
        : null}
    </div>
  );
};

CustomProductOption.propTypes = {
  type: PropTypes.string.isRequired,
  image: PropTypes.node,
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
  onChangeAdd: PropTypes.func,
  onChangeRemove: PropTypes.func,    
  price: PropTypes.number,
  optionsCount: PropTypes.number,
 calories: PropTypes.string
};

CustomProductOption.defaultProps = {
  quantity: 0,
  isSelected: false,
  image: null,
  onChange: null,
  onChangeAdd: null,
  onChangeRemove: null,
  price: 0,
  optionsCount: 1,
    calories: null,
};

export default CustomProductOption;
