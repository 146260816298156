/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */

import React, { Component, createRef } from 'react';


import _ from 'underscore';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import * as elementsActions from '../../redux/actions/elements';
import * as userActions from '../../redux/actions/user';
import * as orderActions from '../../redux/actions/order';
import * as checkoutActions from '../../redux/actions/checkout';
import * as brazeActions from '../../redux/actions/braze';
import * as paymentActions from '../../redux/actions/payment'
import * as ccDetailsActions from '../../redux/actions/ccDetails'
import * as productActions from '../../redux/actions/product'

import CheckBox from '../../components/CheckBox/CheckBox';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import ContactDetailsWidget from '../../components/ContactDetailsWidget/ContactDetailsWidget';
import ShippingMethods from './ShippingMethods/ShippingMethods';
import ShippingAddress from './ShippingAddress/ShippingAddress';
import GiftRecipient from './GiftRecipient/GiftRecipient';
import PaymentWidget from "../../components/PaymentWidget/PaymentWidget";
import Input from '../../components/Input/Input';
import SecondaryInput from '../../components/SecondaryInput/SecondaryInput';
import {orderTypeIds, orderMethods, orderCompleteRequirementMessages, orderCompleteRequirementClassNames} from '../../shared/constants/order';
import { openUnavailableOrderProductModal, openAddressMissingOrderModal} from "../../shared/utilities/modals";
import errorCodes from '../../shared/constants/errorCodes';
import {getTotalPrice} from "../../shared/utilities/renderings";
import {getErrorReasons, isMobile, routeCountryPath} from '../../shared/utilities/common';
import { isDoughProOrder, clearCart, checkoutOrderMinAmount, checkoutWithNoItems } from '../../shared/utilities/orders';
import endpoints from '../../shared/constants/endpoints';
import * as tagmanagerEvents from '../../shared/utilities/tagmanagerEvents';
import {getCustomerSubscriptions} from "../../shared/utilities/subscription";
import * as cms from "../../shared/repos/cms/cms";
import cmsKeys from "../../shared/constants/cms";
import subscription from "../../shared/constants/subscription";
import { defaultCurrency } from '../../shared/constants/currency';
import paymentMethods from "../../shared/constants/paymentMethods";

import {
  pickUpRenderings,
  deliveryRendering,
  returnLineCost
} from './rendering';

import * as orderRepo from "../../shared/repos/graphql/order";
import * as doughProRepo from '../../shared/repos/graphql/doughPro';

// STYLES
import styles from './ReviewOrder.module.css';
import orderTotals from '../../shared/constants/orderTotals';
import { isStoreClosed } from '../../shared/utilities/store';
import windowNames from '../../shared/constants/windowNames';
import profileStyles from '../Profile/Profile.module.css';
import messages from "../../shared/constants/messages";
import * as validations from "../../shared/utilities/validations";
import { store } from '../../redux/store';
import '@brainhubeu/react-carousel/lib/style.css';
import UpsellProduct from '../UpsellProduct/UpsellProduct';
import log from '../../shared/utilities/datadog/log';
import { OrderEvent } from '../../shared/constants/OrderEvent';
import UpsellProducts from '../../components/Header/UpsellProducts/UpsellProducts';
import { getPaymentMethodGroups, uiComponentTypes } from '../../components/PaymentWidget/shared';
import EmbeddedPaymentMethod from '../../components/PaymentWidget/EmbeddedPaymentMethod/EmbeddedPaymentMethodGroup';

const backIcon = require('./imgs/back-icon.svg');
const LightBackIcon = require('./imgs/light-back-icon.svg');

const moment = extendMoment(Moment);

class ReviewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      customAddress: null, // eslint-disable-line
      promoCode: '', // eslint-disable-line
      promoCodes: [], // eslint-disable-line
      promoError: null, // eslint-disable-line
      deliveryTime: null,
      order: null,
      selectedDeliveryDate: null,
      availableStoreHours: [],
      isGift: false,
      selectedDeliveryTime: null,
      total: 0,
      schoolCashNumber: '',
      orderOTP: null,
      sendingOTP: false,
      completeOrderErrorCode: null,
      completeOrderProcessing: false,
      orderCompleted: false,
      optInSelection: [],
      customerSubscriptions: [],
      deliveryAddressOpen: true,
      subscriptionsList: [],
      previousTotal: 0.0,
      embeddedPaymentMethod: null,
      isFormCompleted: false
    };
    this.smsDisclaimerRef = createRef();
    this.textDisclaimerRef = createRef();
  }

  componentDidMount() {
    const { match } = this.props;

    const orderCode = match.params.id
    this.setState({
      orderCode
    });

    this.getOrder(orderCode);
    cms.getContent(
      [
        cmsKeys.checkoutPage.disclaimerNote,
        cmsKeys.checkoutPage.disclaimerSMSNote,
        cmsKeys.placeholders.checkoutDeliveryAddressApartment,
        ...Object.values(cmsKeys.checkoutPage.payments.discounts.callouts)
      ],
      this
      );
    this.loadCustomerSubscriptions();
    this.updatePaymentCredentials(null);

    const { userCart, currency, userOrderMethod } = this.props;

    log.logInfo(
      "[checkout][review-order] ", {
        "orderCode": orderCode,
        "userCart": userCart,
        "currency": currency,
        "userOrderMethod": userOrderMethod,
      }
    );
    this.checkoutStarted(orderCode);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setOrderFromProps();
    }
  }

  componentWillUnmount() {
      this.clearCart();
      this.clearContentCardSession();
  }

  setOrderFromProps() {
    const { order } = this.props;
    this.setState(
        {
          order
        }
    )
  }

  getOrder(orderCode) {
    const {paymentCredentials} = this.props;
    if (!orderCode) {
      return;
    }

    let orderParams = null;
    if (paymentCredentials) {
      orderParams = {
        paymentCredential: paymentCredentials
      }
    }

    const { order } = store.getState();

    if (order === null) {

      this.setState({orderLoading: true});

      orderRepo.getOrder(orderCode, orderParams).then(response => this.setOrder(response.data.order));
    } else {
      this.setOrder(order)
    }
  }

  checkoutStarted = (orderCode) => {
    if(orderCode) {
      const { userCart, order } = this.props;
      const customerId =  order?.customer?.id
      const checkoutUrl = window.location.href;
      if(userCart && userCart.length > 0){
        tagmanagerEvents.beginCheckout(orderCode, userCart, customerId);
      }
      orderRepo.orderEvent(OrderEvent.CHECKOUT_STARTED, orderCode, {checkoutUrl}).then(() => {});
    }
  }

  clearCart = () => {
      const { orderCompleted } = this.state;

      if (orderCompleted) {
          clearCart();
      }
  }

  clearContentCardSession = () => {
    const {contentCardSession} = this.props;

    if (window.name === windowNames.CONTENT_CARD_SESSION) {
      window.name = '';
    }

    contentCardSession(false);
  }

  checkoutOrderMinAmount = (order) => {
    const { userCart, currency, userOrderMethod } = this.props;

    if((!userCart || userCart.length <= 0)) {
      if (userOrderMethod === orderMethods.delivery) {
        checkoutOrderMinAmount(order?.shippingMethodMinAmount, currency.symbol);
      } else {
        checkoutWithNoItems();
      }
    }
  }

  setOrder = (order) => {
    const { orderUpdated, checkoutUpdated, checkout } = this.props;
    const cartTotal = order.totals.find(item => item.title.toLowerCase() === 'total').value;
    const cartTip = order.totals.find(item => item.title.toLowerCase() === 'tip').value;

    this.checkoutOrderMinAmount(order);

    if (order.recipient) {
        this.setState({
            isGift: true
        });
    }

    this.setState({ order, total: cartTotal });

    if (order.shippingAddress2) {
      this.setState({ address2: order.shippingAddress2 });
    }

    orderUpdated(order);
    checkout.tip = cartTip;
    checkoutUpdated(checkout);

    orderRepo.getOrderEstimate(order.code).finally(() => {
      this.setState(
        {
          orderLoading: false
        }
      );
    });
  }

  getDisclaimerNotes = () => {
    return cms.getValueByKey(cmsKeys.checkoutPage.disclaimerNote, this);
  }

  getDisclaimerSMSNotes = () => {
    if (!this.shouldRenderPhoneOptInOptions()) {
      return '';
    }
    return cms.getValueByKey(cmsKeys.checkoutPage.disclaimerSMSNote, this);
  }

  taxes = () => {
    const { order } = this.state;
      const { currency } = this.props;
      const lineCostType = 'orderTotal';
      let serviceFeeTotal = null;
      return (
        <div>
          {order.totals.filter(total => {
              const isTax = total.title.toLowerCase() === orderTotals.tax.title.toLowerCase();
              if (isTax) {
                return true;
              }

              if (orderTotals.serviceFee.title.toLowerCase() === total.title.toLowerCase()) {
                serviceFeeTotal = total;

                return false;
              }

              return false;
          }).map(total => {
              if (
                orderTotals.tax.title.toLowerCase() === total.title.toLowerCase() &&
                serviceFeeTotal
              ) {
                return (
                  <details className={styles.returnLineCostDetails}>
                    <summary>
                      <p>Taxes &amp; Fees</p>
                      <p>
                        {currency.symbol}
                        {total.value.toFixed(2)}
                      </p>
                    </summary>
                    {returnLineCost(total.title, (total.value - serviceFeeTotal.value).toFixed(2), false, currency, lineCostType )}
                    {returnLineCost(serviceFeeTotal.title, serviceFeeTotal.value.toFixed(2), false, currency, lineCostType)}
                  </details>
                );
              }

              return returnLineCost(total.title, total.value, false, currency, lineCostType);
          })}
        </div>
      );
  }

  totals = () => {
      const { order } = this.state;
      const { checkout, currency, showTaxesAfterTotal } = this.props;
      const lineCostType = 'orderTotal';

      let serviceFeeTotal = null;

      const usedPoints = order?.orderRedeemables?.reduce(function(accumulator, currentValue) {
        return accumulator + currentValue.points;
      }, 0);

      return (
        <div className='bg-white dark:bg-dark px-4 shadow-md'>
          <div className='border-t-[1px]' />
          {order.totals.filter(total => {
              const isTotal = total.title.toLowerCase() === orderTotals.total.title.toLowerCase();
              const isCoupons = total.title.toLowerCase() === orderTotals.coupons.title.toLowerCase();
              const isTax = total.title.toLowerCase() === orderTotals.tax.title.toLowerCase();
              if (isTax && showTaxesAfterTotal) {
                return false;
              }
              let hasValue = total.value > 0;

              if (
                (total.value === 0 || total.value === 'Free') &&
                total.title.toLowerCase() === orderTotals.delivery.title.toLowerCase()
              ) {
                Object.assign(total, {value: 'Free'});

                hasValue = true;
              }

              if (orderTotals.serviceFee.title.toLowerCase() === total.title.toLowerCase()) {
                serviceFeeTotal = total;

                return false;
              }

              return !isTotal && !isCoupons && hasValue;
          }).map(total => {
              const isloyalty = total.title.toLowerCase() === orderTotals.loyalty.title.toLowerCase();
              if (
                orderTotals.tax.title.toLowerCase() === total.title.toLowerCase() &&
                serviceFeeTotal
              ) {
                return (
                  <details className={styles.returnLineCostDetails}>
                    <summary>
                      <p>Taxes &amp; Fees</p>
                      <p>
                        {currency.symbol}
                        {total.value.toFixed(2)}
                      </p>
                    </summary>
                    {returnLineCost(total.title, (total.value - serviceFeeTotal.value).toFixed(2), false, currency, lineCostType, true )}
                    {returnLineCost(serviceFeeTotal.title, serviceFeeTotal.value.toFixed(2), false, currency, lineCostType, true)}
                  </details>
                );
              }

              const isTip = total.title.toLowerCase() === orderTotals.tip.title.toLowerCase();
              if (isTip) {
                return returnLineCost(total.title, checkout.tip, isloyalty, currency, lineCostType, true);
              }

              return returnLineCost(total.title, total.value, isloyalty, currency, lineCostType, true);
          })}

          {order.discounts.map((cartTotal) => {
            return returnLineCost(cartTotal.title, cartTotal.value, true, currency, lineCostType, true);
          })}

          {usedPoints > 0 && (
            <>
              <div className="border-t-[1px]" />
              {returnLineCost(
                "Points Redeemed",
                usedPoints,
                false,
                currency,
                lineCostType,
                true,
                true
              )}
            </>
          )}
        </div>
      );
  };

  renderOtpInput = () => {
    const { completeOrderErrorCode } = this.state;
    const errors = [
      errorCodes.COMPLETE_ORDER_OTP_REQUIRED,
      errorCodes.COMPLETE_ORDER_OTP_INVALID,
      errorCodes.COMPLETE_ORDER_OTP_EXPIRED,
      errorCodes.COMPLETE_ORDER_TOO_MANY_OTP_ATTEMPTS
    ];

    if (errors.indexOf(completeOrderErrorCode) === -1) {
      return null;
    }

    return (
      <div className="OtpInputWrapper">
        <SecondaryInput
          component={this}
          state="orderOTP"
          label="Enter order OTP"
          isEdit
        />
      </div>
    );
  }

  renderOtpResendBtn = () => {
    const { completeOrderErrorCode, sendingOTP } = this.state;

    const errors = [
      errorCodes.COMPLETE_ORDER_OTP_INVALID,
      errorCodes.COMPLETE_ORDER_OTP_EXPIRED
    ];

    if (errors.indexOf(completeOrderErrorCode) === -1) {
      return null;
    }

    return (
      <button
        className="otpResendBtn"
        onClick={() => this.resendOTP()}
        type="button"
      >
        {sendingOTP ? 'Sending ...' : 'Resend OTP'}
      </button>
    )
  }

  backToMenu = () => {
    const { history, setModalObject, setUserCart, setUserCartId, setUserAddress, setSelectedStore, setUserOrderMethod } = this.props;
    setUserCart([]);
    setUserCartId(null);
    setModalObject(null);
    setUserAddress('');
    setSelectedStore(null);
    setUserOrderMethod('');
    history.push(routeCountryPath(endpoints.getMenuUrl(null)))
  }

  renderOrderProcessingModal = (param, message) => {
    const { setModalObject} = this.props;
    const { completeOrderProcessing } = this.state;
    if (param || completeOrderProcessing) {
      if (message){
        setModalObject(openUnavailableOrderProductModal(this.backToMenu, message));
      }
      else {
        setModalObject(
            {
              title: 'Please wait, processing order...',
              description: 'Hold tight while we create your order',
              exit: false
            }
        );
      }

    }
    else {
      setModalObject(null)
    }
  };

  setDefaultDeliveryTime = (deliveryTime) => {
    this.setState({selectedDeliveryTime: deliveryTime})
  }

  resendOTP = async () => {
    const { match } = this.props;
    const { sendingOTP } = this.state;
    const orderCode = match.params.id;

    if (sendingOTP) {
      return;
    }

    this.setState({
      sendingOTP: true
    });

    await orderRepo.generateOrderOtp(orderCode);

    this.setState({
      sendingOTP: false,
      completeOrderErrorCode: errorCodes.COMPLETE_ORDER_OTP_REQUIRED,
      completeOrderError: '',
      orderOTP: null
    })
  }

  /**
   *
   * @param {String} subscriptionId
   * @return {Promise<void>}
   */
  toggleOptInUpdate = (subscriptionId, keyField = null) => {
    const {optInSelection} = this.state;
    const arrayList = [...optInSelection];
    const array = (arrayList.includes(subscriptionId)) ? _.without(arrayList, subscriptionId): _.union(arrayList, [subscriptionId]);
    this.setState({
      optInSelection: array
    });

    if(keyField === 'phone') {
      this.smsDisclaimerRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: "nearest" })
    } else if(keyField === 'email') {
      this.textDisclaimerRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: "nearest" })
    }
  };

  /**
   *
   * @param {error} form error
   * @return {boolean}
   */
  setContactFormError = (error) => {

    if ((!_.isEmpty(error) && error )  || !this.checkOrderValidation()) {
      this.setState({
        isFormCompleted: false
      });
    } else {
      this.setState({
        isFormCompleted: true
      });
    }
  }

  /**
   * Load customer subscriptions
   * @return {Promise<void>}
   */
  loadCustomerSubscriptions = async () => {
    const { setAlertRecipientPhoneUpdated, setAlertSenderPhoneUpdated, alertRecipientPhone, alertSenderPhone } = this.props;
    const customerSubscriptions = await getCustomerSubscriptions();
    const optInSelection = [];

    _.forEach(customerSubscriptions, (item) => {
      if ((item.customerIdentifier === null && item.defaultValue === true) || item.customerIdentifier !== null) {
        optInSelection.push(item.subscriptionId);
      }
    });

    this.setState({
      optInSelection,
      customerSubscriptions
    });

    // Toggle gift recipient & sender order status checkbox state.
    const orderStatusUpdateSubscription = _.find(
      customerSubscriptions || [],
      { keyField: subscription.subscriptionKeyField.ORDER_NOTIFICATION }
    );

    // Set recipient order status checkbox to default value
    const alertRecipientSenderPhoneDefaultValue = orderStatusUpdateSubscription?.defaultValue;
    if (alertRecipientPhone !== alertRecipientSenderPhoneDefaultValue) {
      setAlertRecipientPhoneUpdated(alertRecipientSenderPhoneDefaultValue);
    }

    // Set sender order status checkbox to default value if not subscribed
    if (orderStatusUpdateSubscription) {
      if (orderStatusUpdateSubscription.customerIdentifier === null) {
        if (alertSenderPhone !== alertRecipientSenderPhoneDefaultValue) {
          setAlertSenderPhoneUpdated(alertRecipientSenderPhoneDefaultValue);
        }
      } else {
        setAlertSenderPhoneUpdated(true);
      }
    }
  }

  /**
   * Render opt-in section
   * @return {JSX.Element|null}
   */
  renderOptInSection = () => {
    const { customerSubscriptions, optInSelection } = this.state;
    const { isCountrySelectActive, selectedCountry } = this.props;
    const isOptInVisible = this.shouldRenderOptInOptions();
    const {optIn} = subscription.subscriptionType;
    const {titleLabel} = subscription;
    return(
        (isOptInVisible) ? (
          <div>
            <div className={profileStyles.optInContainer}>
              <h3 className='text-dark dark:text-white'>{titleLabel}</h3>
            </div>
            <div className={profileStyles.optInOptions}>
              {customerSubscriptions.map((item) => {
                  const hasCustomerOptedIn = (item.customerIdentifier === null && optIn === item.subscriptionTypeId);
                  return (
                      (hasCustomerOptedIn) ? (
                        <>
                          <CheckBox
                            onClick={() => this.toggleOptInUpdate(item.subscriptionId, item.keyField)}
                            label={`
                              ${item.name}${isCountrySelectActive ? ` (${selectedCountry.short_name})` : ''}
                              `}
                            isChecked={optInSelection.includes(item.subscriptionId)}
                          />
                          <p>{item.altDescription}</p>
                        </>
                      ) : null
                  );
                })}
            </div>
          </div>
        ): null
    );
  }

  onExpandDeliveryAddress = (expand) => {
    this.setState({ deliveryAddressOpen: expand });
  }

  /**
   * Check should render opt-in options
   * @return {boolean}
   */
  shouldRenderOptInOptions = () => {
    const { customerSubscriptions } = this.state;
    if (customerSubscriptions) {
      const alreadyOptInIds = customerSubscriptions.filter(item => {
        return item.customerIdentifier !== null && (item.keyField === 'phone' || item.keyField === 'email')
      });
      return alreadyOptInIds.length !== 2;
    }
  }

  shouldRenderPhoneOptInOptions = () => {
    const { customerSubscriptions } = this.state;
    if (customerSubscriptions) {
      const alreadyOptInIds = customerSubscriptions.filter(item => {
        return item.customerIdentifier !== null && (item.keyField === 'phone')
      });
      return alreadyOptInIds.length !== 1;
    }
  }

  renderExtraTaxBlock = () => {
    const { showTaxesAfterTotal } = this.props;
    if (showTaxesAfterTotal) {
      return (
        <div className={styles.extraTaxSection}>
          { this.taxes() }
        </div>
      );
    }
    return null;
  }

  getOrderItems = (order) => {
    const { currency } = this.props;
    const orderRedeemables = order?.orderRedeemables ? order.orderRedeemables : [];

    return order.items.map(item => {
      if (item.product){
        const isRedeemable = orderRedeemables.find(p => p.order_product_id === item.id);;
        let amount = parseFloat(
          (getTotalPrice(item) * item.quantity).toFixed(2)
        );
        let productOptionsPrice = 0;
        const productOptions = item.productOptions?.map(option => {
          productOptionsPrice += option.optionPrice ? (option.optionPrice * option.quantity) : 0;
          return (
            <div className='flex justify-between' key={option.id}>
              <p>{`${option.quantity} ${option.title}`}</p>
              <p>{`${option.optionPrice ? `${currency.symbol}${(option.quantity * option.optionPrice).toFixed(2)}` : ''}`}</p>
            </div>
          );
        });

        amount -= productOptionsPrice;

        if(isRedeemable) {
          amount = item.price;
        }
        return (
          <>
            <div
              className="cursor-pointer font-bold text-lg"
              onClick={() =>
                this.editUpsellProducts(item.product, item)}
            >
              {returnLineCost(
                `${item.quantity} ${item.product.title}`,
                amount,
                false,
                currency,
                "product",
                true
              )}

            </div>
            <div className={`${styles.breakdownItem} text-dark dark:text-white `}>
              {productOptions}
            </div>
          </>
        );
      }
      return null;
    })
  }

  /**
   * @param {boolean} value - The boolean value to set.
   * @returns {void}
  */
  updateEmbeddedPaymentMethod = (value) => {
    this.setState({ embeddedPaymentMethod: value });

    const paymentMethod = value?.storePaymentMethod?.paymentMethod ?? value?.paymentMethod;
    delete paymentMethod?.variables;
    if(paymentMethod){
      const { userCartId, order } = this.props;
      tagmanagerEvents.selectPaymentMethod(userCartId , paymentMethod, order?.customer?.id);
    }
    if (!this.checkOrderValidation()) {
      this.setState({
        isFormCompleted: false
      });
    } else {
      this.setState({
        isFormCompleted: true
      });
    }
  };


  editUpsellProducts = (product, item) => {
    if (item.productOptions.length > 0) {
      const {setUpsellProductModalActive} = this.props;
      setUpsellProductModalActive({openModal: true, product, item})
    }
  }

  reviewOrderContainer = () => {
    const {
      customerSubscriptions,
      optInSelection,
      address2,
      order,
      orderLoading,
      completeOrderError,
      completeOrderProcessing,
      selectedDeliveryTime,
      content: cmsContent,
      embeddedPaymentMethod,
      isFormCompleted,
      previousTotal
    } = this.state;
    const {
      userToken,
      userOrderMethod,
      history,
      userCart,
      paymentCredentials,
      orderUpdating,
      selectedStoreHours,
      doughProMember,
      currency,
      upsellEdit,
    } = this.props;

    if (orderLoading || !order || userCart.length <= 0) {
      return (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      );
    }
    const requirementMessage = this.getRequirementMessagesData().message;
    const clearRequirementMessage = Object.values(orderCompleteRequirementMessages).includes(completeOrderError) && completeOrderError !== requirementMessage;

    if(clearRequirementMessage) {
      this.setState(
          {
            completeOrderError: null
          }
      );
    }

    const total = previousTotal;
    const isShipping = userOrderMethod === orderMethods.shipping;
    const isPickup = order.orderTypeId === orderTypeIds.pickup;
    const isDelivery = order.orderTypeId === orderTypeIds.delivery;
    const requiredDeliveryTime = !selectedDeliveryTime && (isPickup || isDelivery);
    const isDoughPro = isDoughProOrder(order.orderTypeId);
    const isEmailGift = order.orderTypeId === orderTypeIds.eGiftCard;
    const isPlaceOrderDisabled = requiredDeliveryTime || !order.sender || (!paymentCredentials && total > 0) || completeOrderProcessing || orderUpdating || (!isDoughPro && !order.shippingMethod) || (!isDoughPro && !order.shippingMethod.id) || isStoreClosed(selectedStoreHours);
    const customContainerClasses = isPlaceOrderDisabled ? `${styles.buttonContainer} Button_isDisabled` : styles.buttonContainer;
    const itemQuantity = _.pluck(order.items, 'quantity').reduce((a, b) => a + b);
    const disclaimerNotes = this.getDisclaimerNotes() || '';
    const disclaimerSMSNotes = this.getDisclaimerSMSNotes() || '';
    const { goToCartLabel, reviewOrderSubtext2 } = messages.viewOrder;
    const orderStatusSubscription = _.find(customerSubscriptions, { keyField: subscription.subscriptionKeyField.ORDER_NOTIFICATION });
    const apartmentPlaceholder = (cms.getValueByKey(cmsKeys.placeholders.checkoutDeliveryAddressApartment, this) || 'Add apartment#/suite#/dorm#')
      .replace(/<\/?[^>]+(>|$)/g, "");
    const showUpsellProducts = !isMobile() && (isPickup || isDelivery) && userCart.length > 0 ;
    const paymentMethodGroups = getPaymentMethodGroups(
      order.store?.storePaymentMethods,
      typeof userToken === "string" ? order.customer?.cards : null
      );
    const isSavePaymentMethodCheckedAndDisabled = order.orderTypeId && isDoughProOrder(order.orderTypeId);
    const isEmbeddedPayment = embeddedPaymentMethod ? Object.values(embeddedPaymentMethod).some(item => {
      return item?.isEmbeddedHtml && item?.uiComponentType === uiComponentTypes.button;
    }) : false;

    return (
      <div
        className={`${styles.pageWrapper} lg:max-w-screen-desktop mx-auto py-10 lg:px-0 overflow-hidden `}
      >
        {upsellEdit?.openModal ? <UpsellProducts /> : null}
        <div className={`${styles.pageContainer} flex flex-col gap-4`}>
          {/* PAGE TITLE */}
          <h3
            onClick={() =>
              history.push(routeCountryPath(endpoints.checkoutViewOrderPage))}
            className={`
              ${styles.pointer}
              flex items-center font-congenialBlack text-4xl leading-9
              tracking-[-1px] gap-8 text-dark dark:text-white`}
          >
            <span>
              <img alt="back" src={backIcon} className="block dark:hidden" />
              <img
                alt="back"
                src={LightBackIcon}
                className="hidden dark:block"
              />
            </span>
            {goToCartLabel}
          </h3>

          <div
            className={`
            ${styles.reviewOrderSubtitle}
            font-filsonProRegular leading-5 text-dark dark:text-white`}
          >
            {reviewOrderSubtext2}
          </div>

          {/* SUGGESTED DELIVERY CAROUSEL */}
          {showUpsellProducts ? (
            <UpsellProduct order={order} />
          ) : null}

          {/* PAGE COLUMNS */}
          <div
            className={`${styles.columnsContainer} lg:grid grid-cols-3 gap-9 lg:gap-6 flex flex-col`}
          >
            {/* USER DETAILS */}
            <div>
              <div className={`${styles.detailsContainer} flex flex-col gap-9`}>
                <div className={`${styles.detailsSection}`}>
                  {/* CONDITIONALLY RENDERED HEADERS */}
                  <h3
                    className={`
                    ${isShipping ? "shippingDetailsLabel" : null}
                    border-b-[3px] border-borderLight h-9 dark:border-darkBorder
                    font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] text-dark dark:text-white
                  `}
                  >
                    {isShipping ? "Shipping Details" : null}
                    {isPickup ? "Pickup Details" : null}
                    {isDelivery ? "Delivery Details" : null}
                  </h3>

                  {isShipping ? (
                    <div>
                      <ShippingAddress />
                      <Input
                        inputId="addressApartment"
                        handleInput={e =>
                          validations.handleFormInput(e, this, "address2")}
                        value={address2}
                        customContainerStyles={styles.inputContainer}
                        placeholder=" "
                        label={apartmentPlaceholder}
                      />
                      {order.orderTypeId === orderTypeIds.shipping ? (
                        <ShippingMethods />
                      ) : null}
                      <GiftRecipient
                        orderStatusSubscription={orderStatusSubscription}
                      />
                    </div>
                  ) : null}

                  {/* PICKUP RENDERINGS */}
                  {isPickup ? pickUpRenderings(this) : null}

                  {/* DELIVERY RENDERINGS */}
                  {isDelivery ? deliveryRendering(this) : null}
                </div>
                {/* CONTACT DETAILS */}
                {isShipping || isDelivery || isDoughPro || isEmailGift ? (
                  <ContactDetailsWidget
                    orderStatusSubscription={orderStatusSubscription}
                    toggleSubscription={this.toggleOptInUpdate}
                    subscriptionSelections={optInSelection}
                    setContactFormError={this.setContactFormError}
                  />
                ) : null}
              </div>
            </div>

            <div>
              <div className={styles.detailsContainer}>
                <div className={styles.detailsSection}>
                  <h3
                    className="
                    border-b-[3px] border-borderLight dark:border-darkBorder h-9
                    font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] text-dark dark:text-white
                  "
                  >
                    Payment Info
                  </h3>
                  <PaymentWidget
                    sectionTitle="Payment Method"
                    userToken={userToken}
                    updatePaymentCredentials={credentials => {
                      return this.updatePaymentCredentials(credentials);
                    }}
                    order={order}
                    orderTypeId={order.orderTypeId}
                    paymentMethodsCustomerSaved={order.customer?.cards}
                    storePaymentMethods={order.store?.storePaymentMethods}
                    cmsContent={cmsContent}
                    isLoggedIn={typeof userToken === "string"}
                    isDoughProMember={doughProMember}
                    updateEmbeddedPaymentMethod={this.updateEmbeddedPaymentMethod}
                    orderTotal={total}
                  />
                </div>
              </div>
            </div>

            {/* COST DETAILS */}
            <div>
              <h3
                className="
                    border-b-[3px] border-borderLight dark:border-darkBorder h-9
                    font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] text-dark dark:text-white
                  "
              >
                Order Summary
              </h3>
              <div className={`${styles.costDetails}`}>
                <h3 className="text-dark dark:text-white bg-white dark:bg-dark px-6 py-4 shadow-md">
                  {itemQuantity}
                  {' '}
Item Order
                  <div className={`${styles.editButton} `}>
                    <span
                      onClick={() =>
                        history.push(
                          routeCountryPath(endpoints.checkoutViewOrderPage)
                        )}
                    >
                      Edit Order
                    </span>
                  </div>
                </h3>
                <div className={`${styles.totalsContainer}`}>
                  <div
                    className={[
                      styles.totalsSection,
                      styles.products,
                      "bg-white dark:bg-dark px-4  shadow-md"
                    ].join(" ")}
                  >
                    {this.getOrderItems(order)}
                  </div>

                  {this.totals()}

                  <div
                    id={styles.total}
                    className={`${styles.totalsSection} bg-white dark:bg-dark px-4 shadow-md`}
                  >
                    <div className="border-t-[1px]" />
                    {returnLineCost(
                      "Total",
                      this.getTotal(),
                      false,
                      currency,
                      "orderTotal"
                    )}
                  </div>

                  {this.renderExtraTaxBlock()}

                  <div className={styles.actionsSection}>
                    {/* OPT-IN SECTION */}
                    {this.renderOptInSection()}
                    {this.renderOtpInput()}
                    <p className={styles.errorMessage}>
                      {completeOrderError}
                      {this.renderOtpResendBtn()}
                    </p>
                    {isMobile() &&
                    (isPickup || isDelivery) &&
                    userCart.length > 0 ? (
                      <UpsellProduct order={order} />
                    ) : null}
                    {userCart && userCart.length > 0 && !isEmbeddedPayment?  (
                      <Button
                        handleSubmit={() => this.completeOrder()}
                        customContainerStyles={`${customContainerClasses}`}
                        label="Place Order"
                      />
                  ) : (
                    undefined
                  )}
                    {isEmbeddedPayment && paymentMethodGroups.map(pmcg => (
                      <div>
                        <EmbeddedPaymentMethod
                          paymentMethodComponentGroup={pmcg}
                          isSavePaymentMethodCheckedAndDisabled={isSavePaymentMethodCheckedAndDisabled}
                          isSavePaymentMethodAllowed={typeof userToken === "string"}
                          paymentMethodsCustomerSaved={order.customer?.cards}
                          updatePaymentCredentials={credentials => {
                          return this.updatePaymentCredentials(credentials);
                        }}
                          isDoughProMember={doughProMember}
                          isLoggedIn={typeof userToken === "string"}
                          cmsContent={cmsContent}
                          embeddedPaymentMethod={embeddedPaymentMethod}
                          onSuccessPayment={() => this.completeOrder()}
                        // checkFormSubmit={() => this.checkOrderValidation()}
                          isFormCompleted={isFormCompleted}
                        />
                      </div>
                  ))}
                    {/* eslint-disable-next-line react/no-danger */}
                    <p ref={this.textDisclaimerRef} className={`${styles.disclaimerNotes} text-darkElevationPrimary dark:text-darkElevationSecondary`} dangerouslySetInnerHTML={{__html:disclaimerNotes}} />
                    <p
                      ref={this.smsDisclaimerRef}
                      className={`${styles.disclaimerSMSNotes} text-darkElevationPrimary dark:text-darkElevationSecondary`}
                      dangerouslySetInnerHTML={{__html:disclaimerSMSNotes}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getTotal = () => {
    const { order, previousTotal } = this.state;
    const { checkout } = this.props;

    const tipsInOrder = order.totals.find(item => item.title === orderTotals.tip.title)?.value;
    const orderTotal = (order.totals.find(item => item.title === orderTotals.total.title)?.value - tipsInOrder) + (checkout.tip || 0);
    // Check if the total has changed before dispatching the event
    if (orderTotal !== previousTotal) {
      document.dispatchEvent(new CustomEvent('updateTotal', { detail: { total: orderTotal } }));
      this.setState({ previousTotal: orderTotal });
    }
    return orderTotal;
  }

  updateDoughProMember = async (callback) => {
    const {setDoughProMember} = this.props;
    const doughProMemberUpdated = await doughProRepo.doughProMember();

    if (!doughProMemberUpdated) {
      return;
    }

    setDoughProMember(doughProMemberUpdated.data.doughProMember);

    callback();
  }

  unavailableProductsFilter = (data) => {
    let completeProcess = true;
    const unavailableProducts = data.productAvailabilityCheck;

    const existUnavailableProducts = unavailableProducts.length > 0;
    if(existUnavailableProducts){
      let titles = '';
      unavailableProducts.forEach(item => {
        titles += `,${item.title }`;
      })

      const prodTitles = titles.substr(1,titles.length);
      this.renderOrderProcessingModal(completeProcess,prodTitles);
    }
    else {
      completeProcess = false;
      this.setState(
          {
            completeOrderProcessing: completeProcess
          }
      );
      this.renderOrderProcessingModal(completeProcess);

    }
  };

  checkRequiredContactFields = (sender) => {
    if (!sender) {
      return false;
    }

    const requiredFields = ['firstName', 'lastName', 'phone', 'email'];
    let isError = false;

    requiredFields.forEach((field) => {
      if (sender[field] == null || sender[field] === '') {
        isError = true;
      }
    });

    return !isError;
  }

  getRequirementMessagesData = () => {
    const { order, selectedDeliveryTime, previousTotal } = this.state;
    const { paymentCredentials, userOrderMethod, checkout } = this.props;
    const isPickup = order?.orderTypeId === orderTypeIds.pickup;
    const isDelivery = order?.orderTypeId === orderTypeIds.delivery;
    const isShipping = userOrderMethod === orderMethods.shipping;

    const requiredDeliveryTime = !selectedDeliveryTime &&  isDelivery;
    const requiredPickupTime = !selectedDeliveryTime && isPickup;
    const isDoughPro = isDoughProOrder(order.orderTypeId);
    const needDoughProCustomerPhoneVerified = order?.customer?.telephoneVerificationRequired && isDoughPro && order.customer && (!order.customer.telephoneVerified || !!order.customer.telephoneNew);
    const requiredShipping = ((!isDoughPro && !order.shippingMethod) || (!isDoughPro && !order.shippingMethod.id)) && isShipping;

    let message = '';
    let elementSelector = null;
    const total = previousTotal;
    const requiredPaymentCredentials =  !paymentCredentials && total > 0
    if (requiredPaymentCredentials) {
      message = orderCompleteRequirementMessages.PaymentMethod;
      elementSelector = orderCompleteRequirementClassNames.PaymentMethod;
    }
    if (!checkout?.sender || !this.checkRequiredContactFields(checkout.sender) || needDoughProCustomerPhoneVerified) {
      message = orderCompleteRequirementMessages.contactDetails;
      elementSelector = orderCompleteRequirementClassNames.contactDetails;
    }
    if (requiredPickupTime) {
      message = orderCompleteRequirementMessages.pickupTime;
      elementSelector = orderCompleteRequirementClassNames.pickupTime;
    }
    if (requiredDeliveryTime) {
      message = orderCompleteRequirementMessages.deliveryTime;
      elementSelector = orderCompleteRequirementClassNames.deliveryTime;
    }
    if (requiredShipping) {
      message = orderCompleteRequirementMessages.shippingDetails;
      elementSelector = orderCompleteRequirementClassNames.shippingDetails;
    }
    const errorsInCheckout = (checkout && checkout.errors)? Object.values(checkout.errors) : [];
    if (errorsInCheckout.length) {
      message = errorsInCheckout[0][0].message;
      elementSelector = errorsInCheckout[0][0].selector;
    }
    return {message, elementSelector};
  };

  getUnavailableProducts = () => {
    const { order } = this.props;
    orderRepo.orderProductsAvailability(order.code)
        .then((response)=>{
          this.unavailableProductsFilter(response.data);
        })
  }


  updatePaymentCredentials = (paymentCredentials) => {
    const { paymentCredentialsUpdated } = this.props;
    if(paymentCredentialsUpdated) {
      this.setState({
        completeOrderProcessing: false,
        completeOrderError: ''
    });
      paymentCredentialsUpdated(paymentCredentials);
    }
    return Promise.resolve(paymentCredentials);
  }

  resetInstructions = () => {
    const { checkout, checkoutUpdated } = this.props;
    checkout.includeSpoon = false;
    checkout.orderComment = '';
    checkoutUpdated(checkout);
  }

  checkRecipientFields = (checkout) => {
    if (checkout.isGift === true) {
      checkout.giftContact = {
        firstName: checkout.giftFirstName || '',
        lastName: checkout.giftLastName || '',
        phone: checkout.giftPhone || '',
        message: checkout.giftMessage || '',
      };
      if (checkout.giftContact.firstName === '' || checkout.giftContact.lastName === '' || checkout.giftContact.phone === '') {
        checkout.errors.gift_common = [{message: 'Please check all fields for gift recipient.'}];
        return false;
      } if (checkout.errors.gift_common) {
        delete checkout.errors.gift_common;
      }
    } else {
      delete checkout.giftContact;
      if (checkout.errors && typeof checkout.errors === 'object') {
        Object.keys(checkout.errors).forEach((key) => {
          if (/^gift_/.test(key)) {
            delete checkout.errors[key];
          }
        });
      }
    }
    return true;
  }

  checkOrderValidation() {
    const { order, completeOrderProcessing, selectedDeliveryTime, previousTotal } = this.state;
    const { paymentCredentials, orderUpdating, selectedStoreHours, checkout } = this.props;

    const total = previousTotal;
    const isPickup = order.orderTypeId === orderTypeIds.pickup;
    const isDelivery = order.orderTypeId === orderTypeIds.delivery;
    const requiredDeliveryTime = !selectedDeliveryTime &&  isDelivery;
    const requiredPickupTime = !selectedDeliveryTime && isPickup;
    const isDoughPro = isDoughProOrder(order.orderTypeId);
    const needDoughProCustomerPhoneVerified = (order?.customer?.telephoneVerificationRequired || false) && isDoughPro && order.customer && !order.customer.telephoneVerified;
    const isRecipientOk = this.checkRecipientFields(checkout);
    const errorsInCheckout = (checkout && checkout.errors)? Object.values(checkout.errors) : [];

    const isPlaceOrderDisabled = !isRecipientOk
      || requiredDeliveryTime
      || requiredPickupTime
      || !this.checkRequiredContactFields(checkout.sender)
      || (!paymentCredentials && total > 0)
      || completeOrderProcessing
      || orderUpdating
      || (!isDoughPro && !order.shippingMethod)
      || (!isDoughPro && !order.shippingMethod.id)
      || isStoreClosed(selectedStoreHours)
      || errorsInCheckout.length
      || (needDoughProCustomerPhoneVerified);

    return !isPlaceOrderDisabled;
  }

  completeOrder() {
    const { address2, order, completeOrderProcessing, selectedDeliveryTime, orderOTP, optInSelection, previousTotal } = this.state;
    const { match, history, paymentCredentials, orderUpdating, selectedStoreHours, alertSenderPhone, alertRecipientPhone, setModalObject, checkout, checkoutErrorsUpdated, saveCredentials, setSaveCredentials } = this.props;

    const total = previousTotal;
    const isPickup = order.orderTypeId === orderTypeIds.pickup;
    const isDelivery = order.orderTypeId === orderTypeIds.delivery;
    const requiredDeliveryTime = !selectedDeliveryTime &&  isDelivery;
    const requiredPickupTime = !selectedDeliveryTime && isPickup;
    const isDoughPro = isDoughProOrder(order.orderTypeId);
    const needDoughProCustomerPhoneVerified = order?.customer?.telephoneVerificationRequired && isDoughPro && order.customer && !order.customer.telephoneVerified;
    const isRecipientOk = this.checkRecipientFields(checkout);
    const errorsInCheckout = (checkout && checkout.errors)? Object.values(checkout.errors) : [];
    const isPlaceOrderDisabled = !isRecipientOk
      || requiredDeliveryTime
      || requiredPickupTime
      || !this.checkRequiredContactFields(checkout.sender)
      || (!paymentCredentials && total > 0)
      || completeOrderProcessing
      || orderUpdating
      || (!isDoughPro && !order.shippingMethod)
      || (!isDoughPro && !order.shippingMethod.id)
      || isStoreClosed(selectedStoreHours)
      || errorsInCheckout.length
      || (needDoughProCustomerPhoneVerified);

    const orderCode = match.params.id;
    if (isPlaceOrderDisabled) {
      const messagesData = this.getRequirementMessagesData();
      this.setState(
          {
            completeOrderError: messagesData.message,
            completeOrderProcessing: false
          }
      );
      if (isMobile()) {
        const element = document.getElementsByClassName(messagesData.elementSelector); // eslint-disable-line
        const yPos = element[0]?.offsetTop ? (element[0]?.offsetTop - 120) : null;
        if (yPos)  window.scrollTo({ top: yPos, left: 0, behavior: 'smooth'}); // eslint-disable-line
      }
      return;
    }
    let completeProcess = true;
    this.setState({
        completeOrderProcessing: completeProcess,
        completeOrderError: ''
    });
    this.renderOrderProcessingModal(completeProcess)

    const subscriptionIds = optInSelection || [];

    const { sender, giftContact, orderComment, tip, includeSpoon } = checkout;
    const today = moment().startOf('day');
    const asap = moment(selectedDeliveryTime).isSame(today);
    const deliveryTime = asap ? '0000-00-00 00:00:00' : selectedDeliveryTime;
    const checkCreditDebitEmbedded = paymentCredentials !== null && paymentCredentials.paymentMethodId === paymentMethods.creditdebitembedded;
    if(checkCreditDebitEmbedded) {
      paymentCredentials.saveCredentials = saveCredentials;
    }

    orderRepo.completeOrderV2(orderCode, deliveryTime, sender, giftContact, orderComment, paymentCredentials, orderOTP,
      address2, alertSenderPhone, alertRecipientPhone, subscriptionIds, tip, includeSpoon)
      .then(() => {
        this.setState(
            {
              completeOrderProcessing: false,
              orderCompleted: true
            }
        );

        tagmanagerEvents.purchase(order, false);

        const isCustomerOrder = !!order?.customer?.id;

        let historyUrl = isCustomerOrder
          ?  endpoints.checkoutConfirmationPage.replace(":id", order.code)
          : endpoints.orderLoyalty.replace(":id", order.code);


        if (isDoughProOrder(order.orderTypeId)) {
          historyUrl = endpoints.homePage;
          tagmanagerEvents.joinCookieMagic({
            id: order.customer.id,
            firstname: order.customer.firstname,
            lastname: order.customer.lastname,
            email: order.customer.email
          });

          this.updateDoughProMember(() => {
          });
        }

        log.logInfo(
          "[ReviewOrder][completeOrder]",
          {
            "Order": order,
            "orderCompleted": this.state?.orderCompleted,
          }
        );

        setSaveCredentials(false);
        this.resetInstructions();
        history.push(routeCountryPath(historyUrl));
      }).catch(reason => {

        log.logError(
          "[ReviewOrder][completeOrder] ",
          {
            "Order": order,
            "reason": reason,
          });
        const validationErrors = validations.getValidationErrorMessagesFromApiError(reason);
        const checkoutErrors = {};

        if (validationErrors.length) {
          validationErrors.forEach(err => {
            checkoutErrors[`${err.component}_${err.field}`] = [{message: err.message, selector: err.component}];
          });

          checkoutErrorsUpdated(checkoutErrors);
        }

        let { message } = reason.graphQLErrors && reason.graphQLErrors[0] ? reason.graphQLErrors[0] : reason;

        if (Object.values(checkoutErrors).length) {
          message = Object.values(checkoutErrors)[0][0].message;
        }

        const reasonType = getErrorReasons(reason,0);

        const unavailableProductReasonType = reasonType === errorCodes.COMPLETE_ORDER_UNAVAILABLE_PRODUCT;
        const missingAddressReasonType = reasonType === errorCodes.COMPLETE_ORDER_ADDRESS_MISS;
         if( unavailableProductReasonType){
            this.getUnavailableProducts();
         } else if(missingAddressReasonType) {
           setModalObject(openAddressMissingOrderModal(this.backToMenu, message));
         } else {
           completeProcess = false;
           this.setState(
               {
                 completeOrderErrorCode: getErrorReasons(reason, 0),
                 completeOrderError: message,
                 completeOrderProcessing: completeProcess
               }
           );
           this.renderOrderProcessingModal(completeProcess)
           document.dispatchEvent(new CustomEvent('onPaymentProcessingError', {detail: {message, errorCode: reasonType}}));
         }
    });
  }

  render() {
    return <this.reviewOrderContainer />;
  }
}

ReviewOrder.propTypes = {
  history: PropTypes.shape().isRequired,
  orderUpdated: PropTypes.func.isRequired,
  checkoutUpdated: PropTypes.func.isRequired,
  order: PropTypes.shape().isRequired,
  checkout: PropTypes.shape().isRequired,
  currency: PropTypes.objectOf(PropTypes.string),
  isCountrySelectActive: PropTypes.bool,
  selectedCountry: PropTypes.shape({
    short_name: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }) || null,
};

ReviewOrder.defaultProps = {
  currency: defaultCurrency,
  isCountrySelectActive: false,
  selectedCountry: null,
}

export const mapStateToProps = (state) => {
  const {
    userOrderMethod,
    userCart,
    userAddress,
    selectedStore,
    userCartId,
    userToken,
    selectedStoreHours,
    alertRecipientPhone,
    alertSenderPhone
  } = state.user;
  const { orderUpdating } = state.payment;
  const { currency } = state.currency;
  const { saveCredentials } = state.ccDetails;
  const { showTaxesAfterTotal } = state.orderTotalsTitles;
  const {
    selectedCountry,
    isCountrySelectActive
  } = state.country;
  return {
    userOrderMethod,
    userCart,
    userAddress,
    selectedStore,
    userCartId,
    userToken,
    order: state.order,
    checkout: state.checkout,
    paymentCredentials: state.payment.credentials,
    orderUpdating,
    selectedStoreHours,
    alertRecipientPhone,
    alertSenderPhone,
    currency,
    saveCredentials,
    showTaxesAfterTotal,
    selectedCountry,
    isCountrySelectActive,
    upsellEdit: state.product?.upsellEdit,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setUserOrderMethod: (value) => dispatch(userActions.setUserOrderMethod(value)),
  setUserAddress: (value) => dispatch(userActions.setUserAddress(value)),
  setSelectedStore: (value) => dispatch(userActions.setSelectedStore(value)),
  setModalObject: (value) => dispatch(elementsActions.setModalObject(value)),
  setUserCart: (value) => dispatch(userActions.setUserCart(value)),
  setUserCartId: (value) => dispatch(userActions.setUserCartId(value)),
  setOrderCompleted: (value) => dispatch(userActions.setOrderCompleted(value)),
  orderUpdated: (value) => dispatch(orderActions.orderUpdated(value)),
  checkoutUpdated: (value) => dispatch(checkoutActions.checkoutUpdated(value)),
  checkoutErrorsUpdated: (value) => dispatch(checkoutActions.checkoutErrorsUpdated(value)),
  setDoughProMember: (value) => dispatch(userActions.setDoughProMember(value)),
  setAlertRecipientPhoneUpdated: (value) => dispatch(userActions.setAlertRecipientPhone(value)),
  setAlertSenderPhoneUpdated: (value) => dispatch(userActions.setAlertSenderPhone(value)),
  contentCardSession: (value) => dispatch(brazeActions.contentCardSession(value)),
  paymentCredentialsUpdated: value => dispatch(paymentActions.paymentCredentialsUpdated(value)),
  setSaveCredentials: (value) => dispatch(ccDetailsActions.setSaveCredentials(value)),
  setSaveCheckoutDetails: (value) => dispatch(checkoutActions.setSaveCheckoutDetails(value)),
  setUpsellProductModalActive: (value) => dispatch(productActions.setUpsellProductModalActive(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewOrder);
