import { datadogRum } from '@datadog/browser-rum'
import _ from "lodash";
import siteSetting from "../../constants/siteSetting";
/**
 * Initialize the datadogRum.
 * @param settings
 */

const initialize = (settings) => {
    const {environment} = window;
    const rumSettings =  _.find(settings, {name: siteSetting.DATADOG_RUM_ENABLED});
    if (!rumSettings?.value || parseInt(rumSettings.value, 10) === 0 || !environment?.REACT_APP_DATADOG_APPLICATION_ID) {
        // eslint-disable-next-line no-console
        console.log('REACT_APP_DATADOG_APPLICATION_ID was not set in the environment or it is disabled. Datadog RUM will not run.');
        return;
    }

    datadogRum.init({
        applicationId: environment.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken:  environment.REACT_APP_DATADOG_CLIENT_TOKEN,
        site:  environment.REACT_APP_DATADOG_SITE,
        service: environment.REACT_APP_DATADOG_SERVICE,
        env: environment.REACT_APP_ENV|| 'unknown',
        version: environment.REACT_APP_BUILD_ID,
        sampleRate: 100,
        sessionReplaySampleRate: 100, // if not included, the default is 100
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
    });

    datadogRum.startSessionReplayRecording();
}

export default { initialize };
