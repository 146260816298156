/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import PropTypes from 'prop-types';

import styles from './FeaturedLocation.module.css';

const FeaturedLocation = ({ label, value, icon, onClick }) => {
  return (
    <div onClick={onClick} className={`${styles.featuredLocation} font-filsonProRegular bg-[var(--light-elevation-background-alt, #F6F3F7)] flex w-full`}>
      <img alt="work-location" src={icon} />
      <div className="ml-3">
        <p className="text-[#745881] ml-3">{label}</p>
        <h3 className="text-[#461D58] ml-3">{value}</h3>
      </div>
    </div>
  );
};

FeaturedLocation.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export default FeaturedLocation;
