import {store} from "../../redux/store";
import * as elementsActions from "../../redux/actions/elements";
import {backToHomeModal, backToMainModal, confirmToMenuModal, doughProOrderTypeChangeModal} from "./modals";
import {clearCart} from "./orders";
import {shippingMethods, storeIds} from "../constants/store";
import * as storeRepo from "../repos/graphql/store";
import * as orderRepo from "../repos/graphql/order";
import {orderMethods, orderTypeIds} from "../constants/order";
import endpoints from "../constants/endpoints";
import { routeCountryPath } from "./common";

export const isStoreClosed = (storeHours) => {
    if (!storeHours || storeHours.length === 0) {
        return false;
    }
  
    return storeHours
        .filter((storeHour) => {
            return storeHour.hours.join(' ').search('CLOSED') >= 0;
        }).length > 0;
};

export const  menuStoreChange = (action, ...args) => {
    return store.dispatch(elementsActions.setModalObject(backToHomeModal(action, ...args)));
};

export const  goBackToMain = (action, ...args) => {
    return store.dispatch(elementsActions.setModalObject(backToMainModal(clearCart, action, ...args)));
};

export const  confirmToMenuPage = (action1, action2, address, ...args) => {
    return store.dispatch(elementsActions.setModalObject(confirmToMenuModal(action1, action2, address, ...args)));
};

export const isShippingStoreProduct = (stores) => {
    const filteredStore = stores.filter(storeItem => (parseInt(storeItem.id, 10) === storeIds.shipping));
    return (filteredStore.length > 0);
}

/**
 * Start Pickup Orders on Direct Visit
 * @param component
 * @param storeId
 * @return {Promise<void>}
 */
export const handlePickupStoreSelectionWithDirectLink = async (component, storeId) => {
    const { userId, userCartId, userCart, setUserCartId, selectedStore, userOrderMethod, history, setSelectedStore, setUserOrderMethod } = component.props;

    const storeResponse = await storeRepo.getStore(storeId);
    const { data } = storeResponse;
    const cartHasItem = (userCart.length > 0);

    // If store is not valid
    if (!data.store) {
        history.push(routeCountryPath(endpoints.getMenuUrl(data.store)));
        return;
    }

    const orderData = {
        storeId,
        orderTypeId: orderTypeIds.pickup,
        shippingMethod: shippingMethods.pickup,
        customerId: userId || 0,
        address: null,
    };

    // If cart is created with different store
    const differentStoreCheck = (selectedStore && selectedStore.id !== storeId && cartHasItem);
    if (differentStoreCheck) {
        store.dispatch(
            elementsActions.setModalObject(
                doughProOrderTypeChangeModal(() => {
                    store.dispatch(elementsActions.setModalObject(null));
                    history.push(routeCountryPath(endpoints.getMenuUrl(selectedStore)));
                }, () => {
                    clearCart();
                    setSelectedStore(data.store);
                    setUserOrderMethod(orderMethods.pickup);
                    history.push(routeCountryPath(endpoints.getMenuUrl(data.store)));
                })
            )
        );
        return;
    }

    // set selected store
    const setStoreCheck = (!selectedStore || !cartHasItem)
    if (setStoreCheck) {
        setSelectedStore(data.store);
        setUserOrderMethod(orderMethods.pickup);
    }

    // Create cart if doesn't exists
    if (!userCartId) {
        const response = await orderRepo.createCart(orderData);
        const { createCart } = response.data;
        setUserCartId(createCart.code);
    }

    // Update store id in existing cart if selected store isn't delivery
    const updateCartCheck = (userCartId && userOrderMethod !== orderMethods.delivery);
    if(updateCartCheck) {
        orderData.address = [];
        await orderRepo.updateOrderLocation(userCartId, orderData);
    }

    history.push(routeCountryPath(endpoints.getMenuUrl(data.store)));
}
