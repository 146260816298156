import React from 'react';
import PropTypes from 'prop-types';

import styles from './Hero.module.css';

const Hero = ({ leftChild, rightChild }) => (
  <div className={styles.heroWrapper}>
    <div className={`${styles.heroContainer} lg:max-w-screen-desktop mx-auto`}>
      <div className={styles.heroLeft}>{leftChild}</div>
      <div />
      <div className={styles.heroRight}>{rightChild}</div>
    </div>
  </div>
);

Hero.propTypes = {
  leftChild: PropTypes.element.isRequired,
  rightChild: PropTypes.element.isRequired
};

export default Hero;
