import { SUBMIT_CONTACT_FORM_MUTATION, SUBMIT_NEWSLETTER_FORM_MUTATION } from '../../../graphql/contact';
import { client } from '../../utilities/config';

/* eslint-disable import/prefer-default-export */
/**
 * @param {Object} data {
 *     formType: String!,
 *     fullName: String,
 *     email: String,
 *     phone: String,
 *     comment: String,
 *     organizationName: String,
 *     eventLocation: String,
 *     eventDate: String,
 *     eventType: String
 * }
 */
export const submitContactForm = data => {
    return client.mutate({
        mutation: SUBMIT_CONTACT_FORM_MUTATION,
        variables: data
    });
};


/**
 * @param {Object} data {
 *     email: String,
 * }
 */
export const submitNewsletterForm = data => {
    return client.mutate({
        mutations: SUBMIT_NEWSLETTER_FORM_MUTATION,
        variables: data
    })
}
