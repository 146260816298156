/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import styles from "./SizeChart.module.css";

const SizeChart = ({ product }) => {
  if (product.options[0]?.sizeChart) {
    return (
      <div className={styles.sizeChart}>
        <h3>Size Chart</h3>
        <table className={styles.sizeChartTable}>
          <thead>
            <th aria-label="Sizing attribute label" />
            <th>XS</th>
            <th>S</th>
            <th>M</th>
            <th>L</th>
            <th>XL</th>
            <th>XXL</th>
          </thead>
          <tbody>
            {product.options[0].sizeChart.map(size => (
              <tr key={size}>
                <td className={styles.sizeChartTableLabel}>{size.label}</td>
                <td>{size.xs}</td>
                <td>{size.s}</td>
                <td>{size.m}</td>
                <td>{size.l}</td>
                <td>{size.xl}</td>
                <td>{size.xxl}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  return null;
};
SizeChart.propTypes = {
  product: PropTypes.shape({ 
    options: PropTypes.arrayOf(PropTypes.shape({ 
      sizeChart: PropTypes.array 
    })) 
  }).isRequired  
};
export default SizeChart;
