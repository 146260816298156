/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const TOGGLE_SUBSCRIPTION = gql`
  mutation TOGGLE_SUBSCRIPTION($subscriptionId: Int!, $enabled: Boolean!) {
      toggleSubscription(subscriptionId: $subscriptionId, enabled: $enabled)
  }
`;

export const CUSTOMER_SUBSCRIPTIONS = gql`
    query CUSTOMER_SUBSCRIPTIONS {
        customerSubscriptions {
            subscriptionId
            customerIdentifier
            name
            description
            subscriptionTypeId
            keyField
            defaultValue
            altDescription
            isRequired
        }
    }
`;
export const CUSTOMER_SUBSCRIPTIONS_BY_TYPE = gql`
    query CUSTOMER_SUBSCRIPTIONS ($subscriptionTypeIds: [Int!]){
        customerSubscriptions (subscriptionTypeIds: $subscriptionTypeIds){
            subscriptionId
            customerIdentifier
            name
            description
            subscriptionTypeId
            keyField
            defaultValue
            altDescription
            isRequired
        }
    }
`;
