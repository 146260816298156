import React, { Component } from 'react';

// GRAPHQL
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as giftCardRepo from "../../shared/repos/graphql/giftcard";

// COMPONENTS
import Input from '../../components/Input/Input';

// HELPERS
import { handleFormInput } from '../../shared/utilities/validations';

import endpoints from '../../shared/constants/endpoints';
import { isRecaptcha } from "../../shared/utilities/config";

import styles from './GiftCard.module.css'
import * as elementsActions from "../../redux/actions/elements";
import {setCaptchaToken} from "../../shared/utilities/captcha";
import captchaAction from "../../shared/constants/captchaAction";

class GiftCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: null,
      cardsArray: [],
      errorMessage: null,
      isLoading: false,
    };
  }

  checkBalance = async () => {
    if (isRecaptcha()) {
      const { setVerificationToken, setVerificationAction } = this.props;
      await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.checkGiftCardBalance);
    }

    this.handleSubmission();
  }

  handleSubmission = () => {
    const { isLoading, cardNumber, cardsArray } = this.state;
    this.setState({ isLoading: !isLoading });

    if (!cardNumber) {
        return this.setState({ errorMessage: 'Please enter value. ', isLoading: false })
    }

    giftCardRepo.checkGiftCardBalance(cardNumber, null).then(response => {
        const { checkGiftcardBalance } = response.data;
        const updatedCardsArray = cardsArray.concat({ number: cardNumber, remaining: checkGiftcardBalance })
        this.setState({ cardsArray: updatedCardsArray, cardNumber: '', isLoading: false, errorMessage: null })
      }
    ).catch(reason => {
      return this.setState({ errorMessage: reason.graphQLErrors[0].message, isLoading: false })
    });
    return true;
  }

  returnCardLineItem = (number, remaining) => (
    <div key={number.toString()} className={styles.cardContainer}>
      <p>{`Gift Card #${number}`}</p>
      <div>
        <p>Remaining</p>
        <p>{`$${remaining ? remaining.toFixed(2) : 0}`}</p>
      </div>
    </div>
  )

  giftCardContainer = () => {
    const { cardNumber, cardsArray, errorMessage, isLoading } = this.state;

    return(
      <div className={styles.pageWrapper}>
        <div className={styles.pageContainer}>
          <h3>Check gift card balance</h3>
          <Input
            isLoading={isLoading}
            value={cardNumber}
            handleInput={(e) => handleFormInput(e, this, 'cardNumber')}
            customContainerStyles={styles.inputWrapper}
            handleSubmit={() => this.checkBalance()}
            placeholder="Enter a gift card number"
            buttonLabel="Check balance"
          />
          <p className={styles.error}>{errorMessage}</p>
          <a href={endpoints.giftCardOrderPage}>Looking to purchase a gift card?</a>

          {cardsArray.length > 0 ? (
            <div className={styles.cardsWrapper}>
              {cardsArray.map(({number, remaining}) => (
                this.returnCardLineItem(number, remaining)
              ))}
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  render() {
    return(
      <this.giftCardContainer />
    )
  }
}

GiftCard.propTypes = {
  setVerificationToken: PropTypes.func.isRequired,
  setVerificationAction: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  setVerificationToken: value =>
    dispatch(elementsActions.setVerificationToken(value)),
  setVerificationAction: value =>
    dispatch(elementsActions.setVerificationAction(value))
});

export default connect(null, mapDispatchToProps)(GiftCard)
