import { newLevelUnlocked, newOfferAvailable } from "../../shared/utilities/loyalty";

const initialState = {
  loyaltyRedeemables: null,
  loyaltyDeals: null,
  loyaltyPoints: 0,
  activeLoyalty: null,
  referralEnabled: false,
  redeemables: {
    redeemableId: null,
    redeemableType: null
  },
  newRedeemableLvlUnlocked: false,
  newOfferAvailable: false
};

export default (state = initialState, { type, value }) => {
  switch (type) {
    case "LOYALTY_REDEEMABLES":
      return {
        ...state,
        loyaltyRedeemables: value
      };
    case "LOYALTY_DEALS":
      return {
        ...state,
        loyaltyDeals: value,
        newOfferAvailable: newOfferAvailable(value),
      };
    case "LOYALTY_POINTS":
      return {
        ...state,
        loyaltyPoints: value,
        newRedeemableLvlUnlocked: newLevelUnlocked(value, state.loyaltyRedeemables),
      };
    case "SET_ACTIVE_LOYALTY":
      return {
        ...state,
        activeLoyalty: value
      };
    case "SET_REDEEMABLES_ITEM":
      return {
        ...state,
        redeemables: value
      };
    case "SET_REFERRAL_ENABLED":
      return {
        ...state,
        referralEnabled: value
      };
    default:
      return state;
  }
};
