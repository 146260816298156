import React, { Component } from "react";
import { connect } from 'react-redux';

import PropTypes from "prop-types";

import { store } from "../../redux/store";

import * as loyaltyAction from '../../redux/actions/loyalty';

import * as loyaltyRepo from "../../shared/repos/graphql/loyalty";

import ProductCard from "./ProductCard";
import { PUNCHH_TYPE } from "../../shared/constants/loyaltyType";
import * as tagmanagerEvents from "../../shared/utilities/tagmanagerEvents";

class LoyaltyDeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deals: []
    };
  }

  async componentDidMount() {
    await this.getLoyaltyDeals();
  }

  openDeliveryWidget = (link, id) => {
    const { openDeliveryWidget, setRedeemableItem, userInfo } = this.props;
    setRedeemableItem({
      redeemableId: id,
      redeemableType: PUNCHH_TYPE.DEALS
    })

    tagmanagerEvents.selectPromo(id, userInfo?.user?.id);
    openDeliveryWidget(link, id);
  }

  getLoyaltyDeals = async () => {
    const response = await loyaltyRepo.getLoyaltyDeals();
    store.dispatch(loyaltyAction.loyaltyDeals(response.data.loyaltyDeals))
    this.setState({
      deals: response.data.loyaltyDeals
    })
  };

  render() {
    const { deals } = this.state;
    const notHaveLoyaltyDeals = !deals || deals.length <= 0;
    if (notHaveLoyaltyDeals) {
      return null;
    }

    return (
      <div className="mx-auto">
        <div>
          <p className="text-white text-2xl leading-6 tracking-[-0.3px] font-filsonProBold font-bold">
            Spotlight
          </p>
          <p className="text-white text-sm leading-4 font-filsonProRegular">
            Delicious deals just for you
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 mt-6">
          {deals.length > 0
            ? deals.map(item => {
                const isBox = item.product.hasAssortments;
                const isCustom =
                  item.product.options && item.product.options.length > 0;

                let link = `/products/single/${item.product.id}`;

                if (isBox) {
                  link = `/products/box/${item.product.id}`;
                } else if (isCustom) {
                  link = `/products/customize/${item.product.id}`;
                }
                return (
                  <ProductCard
                    expiredAt={item.expiredAt ? new Date(item.expiredAt * 1000) : null}
                    image={item.product.productImageSmall}
                    title={item.title}
                    description={item.description}
                    id={item.id}
                    link={link}
                    openDeliveryWidget={this.openDeliveryWidget}
                  />
                );
              })
            : null}
        </div>
      </div>
    );
  }
}

LoyaltyDeals.propTypes = {
  openDeliveryWidget: PropTypes.func.isRequired,
  deals:PropTypes.shape({
    length: PropTypes.func,
    map: PropTypes.func
  }),
  setRedeemableItem: PropTypes.func,
  userInfo: PropTypes.shape()
};

LoyaltyDeals.defaultProps = {
  deals: null,
  setRedeemableItem: () => {},
  userInfo: {}
}

export const mapStateToProps = state => {
  const {
    userInfo,
  } = state.user;
  return {
    userInfo,
  };
};

export default connect(mapStateToProps, null)(LoyaltyDeals);
