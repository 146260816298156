export default {
    faqPage: {
        faqSections: 'faq_section'
    },
    checkoutPage: {
        payments: {
            discounts: {
                callouts: {
                    guest: 'payment_callout_guest',
                    nonsubscriber: 'payment_callout_nonsubscriber',
                    subscriber: 'payment_callout_subscriber'
                }
            }

        },
        shippingNote:'shipping_note',
        disclaimerNote: 'disclaimer_note',
        disclaimerSMSNote: 'disclaimer_sms_note',
    },
    doughProPage:{
        cookieMagicSignUp:'cookie_magic_signup',
        section1:'section_1',
        section2:'section_2',
        section3:'section_3',
        section4: 'section_4',
        sectionDisclaimer:'section_disclaimer',
        banner: 'promo_banner',
        title: 'title',
        body: 'body',
        buttonText: 'button_text',
        bullets: 'bullets',
        bullet: 'bullet',
        link: 'link',
        bulletImg: 'bullet_img',
        sectionDescription: 'section_description'
    },
    termsConditionsPage: 'terms_conditions_page',
    insmoniaRewardsTermsConditionsPage: 'insomnia_rewards_terms_conditions_page',
    eventTermsConditionsPage: 'event_terms_conditions_page',
    doughProTermsConditionsPage: {
        doughProTermsMainPage: 'dough_pro_terms_conditions',
        title: 'title',
        body: 'body',
        sections:'sections',
        header: 'header',
        paragraph: 'paragraph',
        paragraph_1: 'paragraph_1',
        paragraph_desc: 'paragraph_desc',
        paragraph_list: 'paragraph_list',
    },
    customCakeTopperPage: {
        pageKey: 'custom_cake_topper_page',
        body: 'info_text'
    },
    aboutUsPage: 'about_us_page',
    allergenAndNutritionInfoPage: {
        allergenStatementSections: "allergen_statement_section"
    },
    privacyPolicy: 'privacy_policy_page',
    registrationPage: 'registration_page',
    placeholders: {
        enterDeliveryAddress: 'enter_delivery_address',
        checkoutDeliveryAddressApartment: 'checkout_delivery_address_apartment',
        enterShippingAddress: 'enter_shipping_address',
    },
  profilePage: {
    profileDisclaimerNote: 'profile_disclaimer_note',
    profileDisclaimerSMSNote: 'profile_disclaimer_sms_note',
    profileReferralCodeTitle: 'profile_referral_code_title',
    profileReferralCodeSubTitle: 'profile_referral_code_sub_title',
  },
  giftCardSection: {
    giftCardSectionButtonText: 'gift_card_section_button_text',
    giftCardSectionLinkText: 'gift_card_section_link_text',
    giftCardSectionBody: 'gift_card_section_body',
    giftCardSectionHeader: 'gift_card_section_header',
  },
  rewardPage: 'reward_page',
  homePage: 'home_page',
}
