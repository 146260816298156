import React, {useEffect, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import PropTypes from "prop-types";
import {ME_ACTIVE_ORDERS_QUERY} from "../../graphql/user";

import Loader from "../Loader/Loader";
import OrderTrackingWidget from "../OrderTrackingWidget/OrderTrackingWidget";

import styles from './ActiveOrders.module.css';

function ActiveOrders({ before }) {
  const [activeOrdersData, setActiveOrdersData] = useState([]);
  const [fetchedActiveOrders, setFetchedActiveOrders] = useState(false);

  const [fetchActiveOrders, { loading: fetchingActiveOrders, error }] = useLazyQuery(ME_ACTIVE_ORDERS_QUERY, {
    variables: {
      pagination: {
        limit: 100,
        offset: 0
      },
      orderBy: {
        column: 'created_at',
        order: 'DESC'
      }
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setActiveOrdersData(data?.me?.activeOrders?.orders || []);
      setFetchedActiveOrders(true);
    },
  });

  let pollingInterval;
  const pollActiveOrders = () => {
    if(!fetchingActiveOrders && !error) {
      fetchActiveOrders();
    }
  }

  useEffect( () => {
    if( !fetchedActiveOrders ) {
      fetchActiveOrders();
    } else if(!pollingInterval) {
      pollingInterval = setInterval(() => {
        pollActiveOrders();
      }, 30000); // 30 seconds
    }

    return () => clearInterval(pollingInterval); // Clear interval on unmount
  }, [fetchedActiveOrders]);

  if (!fetchedActiveOrders) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    )
  }

  if (!activeOrdersData.length) {
    return null;
  }

  return (
    <>
      {before}
      {activeOrdersData.map((order) => (
        <OrderTrackingWidget order={order} key={`activeOrder-${order.id}`} />
      ))}
    </>
  )
}

ActiveOrders.propTypes = {
  before: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.oneOf([null])
  ])
};

ActiveOrders.defaultProps = {
  before: null
};

export default ActiveOrders;
