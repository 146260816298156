import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as themeActions from '../../redux/actions/theme';

const LightIcon = require('./icons/light.svg');
const NightIcon = require('./icons/dark.svg');

function ThemeSwitch({switchTheme, isDark}) {
  const [isChecked, setIsChecked] = useState(true);
  const [theme, setTheme] = useState("light");

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);

    if (localStorage.getItem("theme") === "light") {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
      switchTheme('dark');
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
      switchTheme('light');
    }
  };

  useEffect(() => {
    if (localStorage.getItem("theme") === null) {
      localStorage.setItem("theme", "light");
      setTheme("light");
      switchTheme('light');
    }
  }, []);

  useEffect(() => {
    // select html elem
    const html = document.querySelector("html");
    
    // add or remove class dark in html elem according to theme in localstorage.
    if (localStorage.getItem("theme") === "dark") {
      html.classList.add("dark");
      setIsChecked(false);    
      setTheme("dark");
      switchTheme('dark');
    } else {
      html.classList.remove("dark");
      setTheme("light");
      setIsChecked(true);
      switchTheme('light');
    }
  }, [theme]);

  return (
    <>
      <label
        htmlFor="switchTheme"
        className="
        themeSwitcherTwo relative inline-flex cursor-pointer select-none items-center gap-2 text-sm"
      >
        <input
          type="checkbox"
          id="switchTheme"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="sr-only"
        />
        <span
          className={`hidden label md:flex items-center font-medium ${isDark ? 'text-dark dark:text-light' : 'text-light'}  ${
            isChecked ? "font-black" : "font-bold opacity-60"
          }`}
        >
          Day
        </span>
        <span
          className={`
            slider gap-4 flex h-7 w-[59px] items-center rounded-full 
            p-1 duration-200 bg-gradient-bl bg-gradient-to-br from-dark to-darkBlack bg-opacity-80 border-darkDry border-2`}
        >
          <span>
            <img
              src={NightIcon}
              alt="light"
              className="w-4 h-4 object-cover"
            />
          </span>
          <span
            className={`dot h-5 w-5 rounded-full bg-white duration-200 ${
              !isChecked ? "translate-x-[28px]" : ""
            }  absolute`}
          />
          <span>
            <img
              src={LightIcon}
              alt="dark"
              className="w-4 h-4 object-cover"
            />
          </span>
        </span>
        <span
          className={` hidden label md:flex items-center font-medium ${isDark ? 'text-dark dark:text-light' : 'text-light'} ${
            !isChecked ? "font-black" : "font-bold opacity-60"
          }`}
        >
          Night
        </span>
      </label>
    </>
  );
}

ThemeSwitch.propTypes = {
  switchTheme: PropTypes.func.isRequired,
  isDark:  PropTypes.bool,
}

ThemeSwitch.defaultProps = {
  isDark:  false,
}

export const mapStateToProps = state => {
  const { theme } = state;
  return {
    theme
  };
};

export const mapDispatchToProps = dispatch => ({
  switchTheme: (value) => dispatch(themeActions.switchTheme(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSwitch) ;
