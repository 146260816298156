/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import PropTypes from 'prop-types';
import styles from './UpsellProduct.module.css';
import ProductCard from '../../components/ProductCard/ProductCard';

const carouselArrowIcon = require('./imgs/carousel-icon.svg');

class UpsellProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            slideValue: 0,
            slidesPerPage: 3,
        }
    }

    componentDidMount() {
        this.handlePageResize();
        window.addEventListener('resize', this.handlePageResize);
    }

    mobileCarouselSlide = (descend) => {
        const { slideValue } = this.state;
        this.setState({ slideValue: descend ? slideValue - 1 : slideValue + 1 });
    };

    handlePageResize = () => {
        this.setState({ slidesPerPage: window.innerWidth <= 1000 ? 1.25 : 3 });
    };

    upsellProductContainer = () => {
        const {slideValue, slidesPerPage} = this.state;
        const {order} = this.props;

        return(
          <div className={styles.carouselWrapper}>
            <div className={styles.carouselHeader}>
              {order.upsellProducts.length ? (<p className='text-dark dark:text-white'>Suggested items</p>) : null}
              <div className={styles.carouselDots}>
                {slideValue !== 0 ? (
                  <img
                    onClick={() => this.mobileCarouselSlide(true)}
                    alt="arrow-left"
                    src={carouselArrowIcon}
                    aria-hidden="true"
                  />
                    ) : null}
                {order.upsellProducts.map((item, index) => {
                        const isChosen = slideValue === index;
                        return (
                          <div
                            aria-hidden="true"
                            key={index}
                            onClick={() => this.setState({ slideValue: index })}
                            className={[
                            styles.dot,
                            isChosen ? styles.activeDot : null
                            ].join(' ')}
                          />
                        );
                    })}
              </div>
            </div>
            <Carousel
              arrowLeft={(
                <img
                  alt="arrow-left"
                  className={styles.arrow}
                  src={carouselArrowIcon}
                />
                    )}
              arrowLeftDisabled={() => null}
              arrowRight={(
                <img
                  alt="arrow-right"
                  className={[styles.arrow, styles.arrowRight].join(' ')}
                  src={carouselArrowIcon}
                />
                    )}
              arrowRightDisabled={() => null}
              addArrowClickHandler
              slidesPerPage={slidesPerPage}
              onChange={(value) => this.setState({ slideValue: value })}
              value={slideValue}
              arrows
            >
              {order.upsellProducts.map((item) => {
                    const isBox = item.hasAssortments;
                    const isCustom = !isBox && (item.options && item.options.length > 0);
                    return (
                      <div className={styles.carouselSlide} key={item.id}>
                        <ProductCard
                          isCustom={isCustom}
                          isUpsale
                          isBox={isBox}
                          primaryImage={item.productImage}
                          type="small"
                          id={item.id}
                          title={item.title}
                          price={item.price}
                          categories={item.categories}
                          afterProductAdded={this.viewOrderDetails}
                        />
                      </div>
                    )
                    })}
            </Carousel>
          </div>
        );
    };

    render() {
        return <this.upsellProductContainer />;
    }
}

UpsellProduct.propTypes = {
    order: PropTypes.shape().isRequired
}

export default UpsellProduct;