/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint no-param-reassign:  [2, { "props": false }] */
import React from "react";
import styles from "./CustomProductOption.module.css";

const checkIcon = require("./imgs/check-icon.svg");
const checkLightIcon = require("./imgs/check-light-icon.svg");

function CustomSingleOption({
  image,
  name,
  onChange,
  isSelected,
  price,
  currency,
  renderPrice,
  description,
  oos
}) {
  const removePaddingClass =
    name.length > 12
      ? [styles.topMargin0, styles.bottomMargin0].join(" ")
      : styles.topMargin0;
  return (
    <div
      className={`${
        oos === 1 ? styles.oos : ""
      } flex md:flex-col md:items-center w-[343px] md:w-[216px] gap-2`}
    >
      <div className="w-[156px] h-[156px] md:w-[216px] md:h-[216px]">
        <img
          alt={name}
          src={image}
          onError={i => {
            i.target.style.display = "none";
            return null;
          }}
          className="w-full object-contain"
        />
      </div>
      <div className="flex flex-col md:items-center md:text-center gap-2 w-[179px]">
        <h3
          className={`${removePaddingClass} md:min-h-[46px] font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] text-dark dark:text-white`}
        >
          {name}
        </h3>
        <p className="text-darkElevationPrimary dark:text-[#B4A5BB] text-xs leading-[14px] md:min-h-[42px]">
          {description}
        </p>
        <div>
          <div className="w-[123px] flex flex-col items-center justify-center">
            {oos === 1 ? null : (
              <div className="flex gap-3 items-center">
                <div
                  onClick={onChange}
                  className={`${
                    isSelected
                      ? "bg-dark dark:bg-button border-none"
                      : "bg-white dark:bg-disabled dark:border-none border-2 border-borderLight"
                  } w-11 h-11 rounded-full flex items-center cursor-pointer`}
                >
                  <img
                    alt="check"
                    src={checkLightIcon}
                    className={`${isSelected ? "hidden" : "block"} m-auto `}
                  />
                  <img
                    alt="check"
                    src={checkIcon}
                    className={`${isSelected ? "block" : "hidden"} m-auto `}
                  />
                </div>
              </div>
            )}
            <p className="py-1">{renderPrice(price, currency)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomSingleOption;
