/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import Geocode from 'react-geocode';
import { store } from '../../redux/store';
import { setUserLocation } from '../../redux/actions/user';
import {getCurrentLocationCoordinates} from '../repos/graphql/locations';

Geocode.setApiKey(window.environment.REACT_APP_MAPS_API_KEY);
Geocode.setLanguage('en');
Geocode.setRegion('us');

let currentPositionResponse;

export const GeocodeInit = Geocode;

export const getMyAddressCoords = async (context, address) => {

  const response = await Geocode.fromAddress(address);

  const { lat, lng } = response.results[0].geometry.location;

  context.setState({
    myLocation: {
      lat,
      lng
    }
  });
};

export const handlePermissionDeniedGeoLocation = async (errResponse, callback, component) => {
  if (component === null) {
    callback(null, errResponse);
  }

  if(errResponse.code === errResponse.PERMISSION_DENIED && component !== null) {
    const res = await getCurrentLocationCoordinates();
    const { currentLocationCoordinates } = res.data;
    const { lat, lng } = currentLocationCoordinates;

    try {
      const response = await GeocodeInit.fromLatLng(lat, lng);
      callback(response);
    } catch (e) {
      callback(null, e);
    }
  }
};

export const getCurrentPosition = (callback, component = null) => {

  const location = window.navigator && window.navigator.geolocation;
  const fetchCurrentPosition = (location && !currentPositionResponse);

  if (!(location && fetchCurrentPosition)) {
    return callback(currentPositionResponse);
  }

  location.getCurrentPosition(
    async position => {
      const { latitude, longitude } = position.coords;

      store.dispatch(setUserLocation({
        lat: latitude,
        lng: longitude
      }));

      const response = await GeocodeInit.fromLatLng(latitude, longitude);

      response.position = position;
      currentPositionResponse = response;

      callback(response);
    },
    async err => handlePermissionDeniedGeoLocation(err, callback, component)
  );

  return null;
};
