/* eslint-disable import/prefer-default-export */
import _ from 'underscore';
import { gtmDataLayer } from './tagmanager';
import orderTotals from '../constants/orderTotals';

const createItem = (item) => {
  const categories = {
    'item_category': '',
    'item_category_2': '',
    'item_category_3': '',
    'item_category_4': '',
    'item_category_5': ''
  };

  if (item.categories) {
    for (let i = 0; i < item.categories.length; i += 1) {
      if (i > 4) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (i === 0) {
        categories.item_category = item.categories[i].title;
         // eslint-disable-next-line no-continue
        continue;
      }

      categories[`item_category_${(i + 1)}`] = item.categories[i].title
    }
  }

  const itemObj = {
    'item_name': item.title, // Name or ID is required.
    'item_id': item.id,
    'price': Number(item.price),
    'item_brand': 'Insomnia Cookies',
    'currency': 'USD',
    ...categories
  };

  if (item.quantity) {
    itemObj.quantity = Number(item.quantity)
  }

  return itemObj;
};

export const viewItem = (product) => {
  const eventData = {
    'event': 'view_item',
    'ecommerce': {
      'items': [createItem(product)]
    }
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const addToCart = (product) => {
  const eventData = {
    'event': 'add_to_cart',
    'ecommerce': {
      'items': [createItem(product)]
    }
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const viewCart = (orderCode, orderItems, customerId) => {
  const products = [];

  for (let i = 0; i < orderItems.length; i += 1) {
    if (!orderItems[i].product) {
      // eslint-disable-next-line no-continue
      continue;
    }

    products.push(createItem({
      ...orderItems[i].product,
      quantity: orderItems[i].quantity
    }))
  }
  const eventData = {
    'event': 'view_cart',
    'ecommerce': {
      'order_code': orderCode,
      'items': products,
      'customer_id' : customerId ?? 0
    }
  };
  gtmDataLayer({
    dataLayer: eventData
  });
}

export const beginCheckout = (orderCode, orderItems, customerId) => {
  const products = [];

  for (let i = 0; i < orderItems.length; i += 1) {
    if (!orderItems[i].product) {
      // eslint-disable-next-line no-continue
      continue;
    }

    products.push(createItem({
      ...orderItems[i].product,
      quantity: orderItems[i].quantity
    }))
  }
  const eventData = {
    'event': 'begin_checkout',
    'ecommerce': {
      'order_code': orderCode,
      'items': products,
      'customer_id' : customerId ?? 0
    }
  };
  gtmDataLayer({
    dataLayer: eventData
  });
}

export const selectPaymentMethod = (orderCode, paymentMethod, customerId) => {

  const eventData = {
    'event': 'payment_info',
    'ecommerce': {
      'order_code': orderCode,
      'payment_method': paymentMethod,
      'customer_id' : customerId ?? 0
    }
  };
  gtmDataLayer({
    dataLayer: eventData
  });
}

export const removeFromCart = (orderCode, productId, customerId) => {

  const eventData = {
    'event': 'remove_from_cart',
    'ecommerce': {
      'order_code': orderCode,
      'product_id': productId[0] ?? 0,
      'customer_id' : customerId ?? 0
    }
  };
  gtmDataLayer({
    dataLayer: eventData
  });
}

export const clickQuickCheckout = (product) => {
  const eventData = {
    'event': 'quick_checkout_click',
    'ecommerce': {
      'items': [createItem(product)]
    }
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const purchase = (order, quickCheckout = false) => {
  const products = [];

  for (let i = 0; i < order.items.length; i += 1) {
    if (!order.items[i].product) {
      // eslint-disable-next-line no-continue
      continue;
    }

    products.push(createItem({
      ...order.items[i].product,
      quantity: order.items[i].quantity
    }))
  };

  const eventData = {
    'event': quickCheckout? 'quick_checkout_purchase' : 'purchase',
    'ecommerce': {
      'transaction_id': order.code,
      'currency': 'USD',
      'items': products,
    }
  };

  const shippingTotal = _.findWhere(order.totals || [], {title: orderTotals.shipping.title});
  if (shippingTotal) {
    eventData.ecommerce.shipping = Number(shippingTotal.value);
  }

  const taxTotal = _.findWhere(order.totals || [], {title: orderTotals.tax.title});
  let tax = 0;
  if (taxTotal) {
    eventData.ecommerce.tax = Number(taxTotal.value);
    tax = eventData.ecommerce.tax;
  }

  const deliveryTotal = _.findWhere(order.totals || [], {title: orderTotals.delivery.title});
  if (deliveryTotal) {
    eventData.ecommerce.delivery = Number(deliveryTotal.value);
  }

  const couponDiscounts = (order.discounts || []).filter((d) => {
    return d.title.search('Coupon: ') >= 0;
  });

  if (couponDiscounts.length > 0) {
    eventData.ecommerce.coupon = couponDiscounts
      .map(d => d.title.replace('Coupon: ', '')).join(', ');
  }
  const totalObject = _.findWhere(order.totals || [], {title: orderTotals.total.title});
  const total = totalObject ? Number(totalObject.value) : 0;
  const tipObject = _.findWhere(order.totals || [], {title: orderTotals.tip.title});
  const tip = tipObject ? Number(tipObject.value) : 0;
  eventData.ecommerce.value = Math.round((total - tip - tax) * 100) / 100;
  gtmDataLayer({
    dataLayer: eventData
  });
};

export const abandonCart = (orderCode, orderItems) => {
  const products = [];

  for (let i = 0; i < orderItems.length; i += 1) {
    if (!orderItems[i].product) {
      // eslint-disable-next-line no-continue
      continue;
    }

    products.push(createItem({
      ...orderItems[i].product,
      quantity: orderItems[i].quantity
    }))
  }

  const eventData = {
    'event': 'ic_abandon_cart',
    'ecommerce': {
      'order_code': orderCode,
      'items': products
    }
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}


export const outOfDeliveryRange = (addressSearched, addressSelected) => {
  const eventData = {
    'event': 'ic_out_of_delivery_range',
    'order_delivery_address_searched': addressSearched,
    'order_delivery_address_selected': addressSelected,
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const viewMenu = (id) => {
  const eventData = {
    'event': 'ic_view_store_menu',
    'store_id': id
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const signup = (method) => {
  const eventData = {
    'event': 'sign_up',
    method
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const signIn = (method, customer) => {
  const eventData = {
    'event': 'sign_in',
    method,
    customer
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const beginSignIn = (method, data) => {
  const eventData = {
    'event': 'begin_sign_in',
    method,
    data
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const joinLoyalty = (customer) => {
  const eventData = {
    'event': 'ic_join_loyalty',
    'loyalty_customer': customer
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const joinCookieMagic = (customer) => {
  const eventData = {
    'event': 'ic_join_cookiemagic',
    'cookiemagic_customer': customer
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const searchMenu = (keyword) => {
  const eventData = {
    'event': 'search_menu',
    'keyword': keyword
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const shippingInfo = (orderCode, contactInfo, customerId) => {
  const eventData = {
    'event': 'shipping_info',
    'ecommerce': {
      'order_code': orderCode,
      'contact_info': contactInfo,
      'customer_id': customerId ?? 0,
    }
  };

  gtmDataLayer({
    dataLayer: eventData
  });
}

export const selectPromo = (productId, customerId) => {
  const eventData = {
    'event': 'select_promo',
    'ecommerce': {
      'product_id': productId,
      'customer_id': customerId ?? 0,
    }
  };
  gtmDataLayer({
    dataLayer: eventData
  });
}

export const applyPromo = (orderCode, PromoInfo, customerId) => {
  const eventData = {
    'event': 'apply_promo',
    'ecommerce': {
      'order_code': orderCode,
      'promo_info': PromoInfo,
      'customer_id': customerId ?? 0,
    }
  };
  gtmDataLayer({
    dataLayer: eventData
  });
}
