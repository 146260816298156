/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

import { client } from "../../utilities/config";
import { store } from "../../../redux/store";
import { orderUpdated } from "../../../redux/actions/order";
import { setUserCart } from "../../../redux/actions/user";
import { checkoutUpdated } from "../../../redux/actions/checkout";

let orderData = {
  id: null,
  code: null,
  items: [],
  shippingWithouTax: 0,
  totals: [],
  upsellProducts: []
}

/**
 * Updates the order redux store with updated order info
 *
 * @param {Promise} promise
 * @param {Array<String>} resultPath
 */
 function updateOrder(promise, resultPath) {
  promise
    .then((result) => {
      let { data } = result;
      const { order } = store.getState();

      resultPath.forEach((path) => {
        data = data[path];
      });

      const newOrder = { ...order, ...data };

      orderData = {...orderData, ...newOrder};
      store.dispatch(orderUpdated(orderData));
    })
    .catch(() => {});
}

// GraphQL Queries start
export const CHECKOUT_ORDER_ITEM_QUERY = gql`
  query CHECKOUT_ORDER_ITEM_QUERY($orderCode: String!, $orderParams: OrderParamsInput) {
    order(orderCode: $orderCode, orderParams: $orderParams) {
      id
      code
      deliveryTime
      loyaltyMultiplier
      createdAt
      comment
      includeSpoon
      useCookieDough
      orderTypeId
      orderRedeemables {
        id,
        order_id,
        type,
        loyalty_provider_id,
        order_product_id,
        external_id,
        amount,
        points,
        redemption_group
      }
      items {
        id
        quantity
        price
        product {
          id
          title
          categories {
            id
            title
            slug
            parentId
          }
          hasAssortments
          price
        }
        boxOptions {
          id
          title
          price
        }
        productOptions {
          id
          title
          quantity
          optionPrice
          options {
            price
          }
          productOption {
            id
            price
          }
        }
      }
    }
  }
`;

export function getOrderItems(orderCode, orderParams) {
  const promise = client.query({
    fetchPolicy: "no-cache",
    query: CHECKOUT_ORDER_ITEM_QUERY,
    variables: {
      orderCode,
      orderParams,
    },
  });

  updateOrder(promise, ["order"]);

  return promise;
}

export const CHECKOUT_ORDER_CUSTOMER_QUERY = gql`
  query CHECKOUT_ORDER_CUSTOMER_QUERY($orderCode: String!, $orderParams: OrderParamsInput) {
    order(orderCode: $orderCode, orderParams: $orderParams) {
      id
      code
      contactDetails {
        id
        firstName
        lastName
        phone
        email
      }
      giftRecipientContactDetails {
        id
        firstName
        phone
        email
      }
      sender {
        firstName
        lastName
        phone
        email
      }
      recipient {
        firstName
        lastName
        phone
      }
      customer {
        id
        cards {
          transactionId
          paymentMethodId
          last4
          exp
          cardType
          favorite
        }
        contacts {
          id
          firstName
          lastName
          phone
        }
        firstname
        lastname
        telephone
        telephoneNew
        telephoneVerified
        email
        telephoneVerificationRequired
        loyalty {
          rewards
        }
      }
    }
  }
`;

export function getOrderCustomerData(orderCode, orderParams) {
  const promise = client.query({
    fetchPolicy: "no-cache",
    query: CHECKOUT_ORDER_CUSTOMER_QUERY,
    variables: {
      orderCode,
      orderParams,
    },
  });

  updateOrder(promise, ["order"]);

  return promise;
}

export const CHECKOUT_ORDER_STORE_QUERY = gql`
  query CHECKOUT_ORDER_STORE_QUERY($orderCode: String!, $orderParams: OrderParamsInput) {
    order(orderCode: $orderCode, orderParams: $orderParams) {
      id
      code
      store {
        id
        address
        name
        pickupEstimate
        deliveryEstimate
        currency {
            name,
            code,
            symbol
        }
        storePaymentMethods {
          id
          paymentMethodId
          storeId
          active
          paymentMethod {
            id
            title
            description
            variables
          }
          iconUrlPath
          isEmbeddedHtml
          embeddedJs
          embeddedHtml
          embeddedLink
          groupTitle
          uiComponentType
          theOrder
          variables {
            key
            value
          }
        }
      }
    }
  }
`;

export function getOrderStoreData(orderCode, orderParams) {
  const promise = client.query({
    fetchPolicy: "no-cache",
    query: CHECKOUT_ORDER_STORE_QUERY,
    variables: {
      orderCode,
      orderParams,
    },
  });

  updateOrder(promise, ["order"]);

  return promise;
}

export const CHECKOUT_ORDER_TRANSACTION_QUERY = gql`
  query CHECKOUT_ORDER_TRANSACTION_QUERY($orderCode: String!, $orderParams: OrderParamsInput) {
    order(orderCode: $orderCode, orderParams: $orderParams) {
      id
      code
      shippingMethod {
        id
        title
      }
      shippingAddress2
      shippingAddress {
        address1
        city
        state
        postcode
      }
      isGift
      paymentMethod {
        id
        title
      }
    }
  }
`;

export function getOrderTransactionData(orderCode, orderParams) {
  const promise = client.query({
    fetchPolicy: "no-cache",
    query: CHECKOUT_ORDER_TRANSACTION_QUERY,
    variables: {
      orderCode,
      orderParams,
    },
  });

  updateOrder(promise, ["order"]);

  return promise;
}

export const CHECKOUT_ORDER_TOTALS_QUERY = gql`
  query CHECKOUT_ORDER_TOTALS_QUERY($orderCode: String!, $orderParams: OrderParamsInput) {
    order(orderCode: $orderCode, orderParams: $orderParams) {
      id
      code
      tax
      tip
      subtotal
      total
      priceWithTax
      totals {
        title
        value
      }
      discounts {
        title
        value
      }
    }
  }
`;

export function getOrderTotalsData(orderCode, orderParams) {
  const promise = client.query({
    fetchPolicy: "no-cache",
    query: CHECKOUT_ORDER_TOTALS_QUERY,
    variables: {
      orderCode,
      orderParams,
    },
  });

  updateOrder(promise, ["order"]);

  return promise;
}

export const CHECKOUT_ORDER_UPSELL_PRODUCTS_QUERY = gql`
  query CHECKOUT_ORDER_UPSELL_PRODUCTS_QUERY($orderCode: String!, $orderParams: OrderParamsInput) {
    order(orderCode: $orderCode, orderParams: $orderParams) {
      id
      code
      upsellProducts {
        id
        title
        productImage
        price
        hasAssortments
        options {
          id
        }
      }
    }
  }
`;

export function getOrderUpsellProductsData(orderCode, orderParams) {
  const promise = client.query({
    fetchPolicy: "no-cache",
    query: CHECKOUT_ORDER_UPSELL_PRODUCTS_QUERY,
    variables: {
      orderCode,
      orderParams,
    },
  });

  updateOrder(promise, ["order"]);

  return promise;
}


export const UPDATE_LOYALTY_MULTIPLIER = gql`
  mutation UPDATE_LOYALTY_MULTIPLIER($orderCode: String!) {
    updateLoyaltyMultiplier(orderCode: $orderCode) {
      id
      code
    }
  }
`;

export const SET_DEFAULT_TIP = gql`
  mutation SET_DEFAULT_TIP($orderCode: String!) {
    setDefaultTip(orderCode: $orderCode) {
      id
      code
    }
  }
`;

export const getOrder = async (orderCode, orderParams) => {
  const items = await getOrderItems(orderCode, orderParams);
  store.dispatch(setUserCart(items.data.order.items));

  await client.mutate({
    mutation: UPDATE_LOYALTY_MULTIPLIER,
    variables: { orderCode }
  });

  await client.mutate({
    mutation: SET_DEFAULT_TIP,
    variables: { orderCode }
  });

  const totals = await getOrderTotalsData(orderCode, orderParams);

  const customer = await getOrderCustomerData(orderCode, orderParams);

  store.dispatch(checkoutUpdated({...customer.data.order, ...totals.data.order}));

  const storeData = await getOrderStoreData(orderCode, orderParams);
  const transaction = await getOrderTransactionData(orderCode, orderParams);
  const upsellProducts = await getOrderUpsellProductsData(orderCode, orderParams);  

  const newOrder = {...items.data.order, ...customer.data.order, ...storeData.data.order, ...transaction.data.order, ...totals.data.order, ...upsellProducts.data.order}

  return newOrder;
}


