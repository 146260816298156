import { useSelector} from "react-redux";
import React, { useState } from 'react';
import * as elementsActions from "../../redux/actions/elements";
import { store } from '../../redux/store';

const StarRating = () => {

const rating =  useSelector((state) => state.elements.orderExperienceRating)
const [hover, setHover] = useState(0);

const clickHandler = (index) => {
  store.dispatch(elementsActions.setOrderExperienceRating(index))
}

return (
  <div className="star-rating">
    {[...Array(5)].map((_, index) => {
      // eslint-disable-next-line no-param-reassign
      index += 1;
      return (
        <button
          type="button"
          // eslint-disable-next-line react/no-array-index-key
          key={`star_${index}`}
          className={index <= (hover || rating) ? "on" : "off"}
          onClick={() => clickHandler(index)}
          onMouseEnter={() => setHover(index)}
          onMouseLeave={() => setHover(rating)}
        >
          <span className="star">&#9733;</span>
        </button>
      );
    })}
  </div>
);
};

export default StarRating;
