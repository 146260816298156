import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

function OtpInput({ label, classInput, length, onComplete }) {
  const inputRef = useRef(Array(length).fill(null));

  const [Otp, setOtp] = useState(Array(length).fill(""));

  const handleTextChange = (input, index) => {
    const newPin = [...Otp];
    newPin[index] = input;
    setOtp(newPin);

    if (input.length === 1 && index < length - 1) {
      const inputField = inputRef.current[index + 1];
      if (inputField) {
        inputField.focus();
      }
    }

    if (input.length === 0 && index > 0) {
      const inputField = inputRef.current[index - 1];
      if (inputField) {
        inputField.focus();
      }
    }

    if (newPin.every(digit => digit !== "")) {
      onComplete(newPin.join(""));
    }
  };

  return (
    <>
      <div className="flex flex-col gap-7 items-center font-filsonProBold">
        <h4 className="text-white text-xl leading-6 tracking-[-0.3px]">{label}</h4>
        <div className="flex gap-4">
          {Array.from(Array(length)).map((data, index) => (
            <input
              key={`otp-${data}`}
              maxLength={1}
              type="text"
              value={Otp[index]}
              onChange={e => handleTextChange(e.target.value, index)}
              ref={ref => {
                inputRef.current[index] = ref;
              }}
              style={{ marginRight: index === length - 1 ? "0" : "10px" }}
              className={`${classInput} w-[66px] h-[66px] border-2 border-light rounded-xl text-[#F6F3F7] hover:border-[#F6F3F7]`}
            />
          ))}
        </div>
      </div>
    </>
  );
}

OtpInput.propTypes = {
  label: PropTypes.string,
  classInput: PropTypes.string,
  length: PropTypes.number,
  onComplete: PropTypes.func
};

OtpInput.defaultProps = {
  label: "",
  classInput: "",
  length: 0,
  onComplete: () => {}
};

export default OtpInput;
