import React, { Component } from 'react';

import Link from '../../components/LinkWithCountry/LinkWithCountry';

import Input from '../../components/Input/Input';

import { handleFormInput, isValid } from '../../shared/utilities/validations';

import styles from './ResetPassword.module.css';

import * as userRepo from '../../shared/repos/graphql/user';
import Button from '../../components/Button/Button';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            alertMessage: null,
            errorMessage: null,
            loading: false,
            mailSent: false
        };
    }

    handleSubmit = email => {
        if (!email) {
            return this.setState({
                errorMessage: 'Please enter an email address.'
            });
        }

        if (email && isValid(email, 'email')) {
            return this.resetPassword(email);
        }

        return this.setState({
            errorMessage: 'Email address is not valid.'
        });
    };

    resetPassword = async email => {
        this.setState({
            loading: true
        });

        const alertMessage = 'If the account exists, an email has been sent.';

        try {
            await userRepo.resetPassword(email);

            return this.setState({
                loading: false,
                alertMessage,
                mailSent: true
            });
        } catch (exception) {
            return this.setState({
                loading: false,
                alertMessage: 'There was an error'
            });
        }
    };

    resetPasswordContainer = () => {
        const { mailSent } = this.state;
        if (!mailSent) {
            return this.renderResetPassword();
        } 
            return this.renderPasswordLinkConfirmation();
        
    };

    renderResetPassword = () => {
        const { alertMessage, errorMessage, email, loading } = this.state;
        return (
          <>
            <div className={`${styles.pageWrapper} text-dark dark:text-white`}>
              <div className={styles.pageContainer}>
                <h1>
                            Reset your
                  <br />
                            password
                </h1>
                <p>
                            Enter your email and we&apos;ll send you
                  <br />
                            a reset link.
                </p>
                <Input
                  handleInput={e => handleFormInput(e, this, 'email', 'email')}
                  handleSubmit={() => this.handleSubmit(email)}
                  value={email}
                  isLoading={loading}
                  alertMessage={alertMessage}
                  errorMessage={errorMessage}
                  placeholder="Enter your email address"
                  buttonLabel="Submit"
                />
              </div>
            </div>
          </>
        );
    }

    renderPasswordLinkConfirmation = () => {
        const { email, loading } = this.state;
        return (
          <>
            <div className={styles.pageWrapper}>
              <div className={styles.pageContainer}>
                <p>
                            Success! Password link has
                  <br />
                            been sent.
                  <small>
                    <br />
                                You should recieve an email within 10
                    <br />
                                minutes to 
                    {' '}
                    {email}
                    <br />
                                with a link to reset your password
                  </small>
                </p> 
                <p>
                  <Link to="/login">Continue to login</Link>
                </p>
                <p className={styles.text}>Didn&#39;t receive link? </p>
                <Button 
                  isLoading={loading}                                 
                  customContainerStyles={styles.buttonContainer}
                  label="Send again"
                  handleSubmit={() => { this.handleSubmit(email) }}
                />                        
              </div>
            </div>
          </>
        );
    }

    render() {
        return <this.resetPasswordContainer />;
    }
}

export default ResetPassword;
