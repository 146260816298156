import React from 'react';

import PropTypes from 'prop-types'
import styles from './Loader.module.css';

const loadingIcon = require('./imgs/loading-icon.png');

const Loader = ({ height }) => (
  <div className={styles.loaderWrapper}>
    <img style={{ height }} alt='loader-icon' src={loadingIcon} />
  </div>
)

Loader.propTypes = {
  height: PropTypes.number
}

Loader.defaultProps = {
  height: 50
}

export default Loader;
