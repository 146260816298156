import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

// COMPONENTS
import Button from '../Button/Button';

import styles from './Error.module.css';
import { clearStorage, redirectToOnError } from '../../shared/utilities/common';


const Error = ({ errorType }) => {
  const errorAssetDesktop = require(`./imgs/error_${errorType}.svg`); // eslint-disable-line
  const errorAssetMobile = require(`./imgs/error_${errorType}_mobile.svg`); // eslint-disable-line

  const reload = () => {
    clearStorage();
    
    redirectToOnError(true);
  };

  return(
    <div className={`max-w-screen-desktop mx-auto ${styles.errorWrapper} text-dark dark:text-white`}>
      <div className={styles.errorContainer}>
        <div className={styles.leftHero}>
          {errorType === "400" ? (
            <h1>Oops, something went wrong</h1>
          ) : (
            <h1>Oops, no cookies on this page.</h1>
          )}
          {errorType === "400" ? (
            <p>
              There was a network error, try refreshing your browser.
            </p>
          ) : (
            <p>
              The requested URL/404 was not found on the server.
              Let&apos;s go back home to find some cookies.
            </p>
          )}
          <Button
            handleSubmit={() => reload()} // eslint-disable-line
            customContainerStyles={styles.customButtonContainer}
            label={errorType === "400" ? 'Reload' : 'Go back home'}
          />
        </div>
        <img className={styles.desktopAsset} alt="error" src={errorAssetDesktop} />
        <img className={styles.mobileAsset} alt="error" src={errorAssetMobile} />
      </div>
    </div>
  )
};

Error.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  errorType: PropTypes.string.isRequired,
}

export default withRouter(Error);
