import React from 'react';
import PropTypes from "prop-types";

const DAY = 10;

const DateTimeDisplay = ({ value, type }) => {
  return (
    <label htmlFor="timer">
      {(value < DAY) ? `0${value}`: value}
      {' '}
      {type}
    </label>
  );
};

DateTimeDisplay.propTypes = {
  value: PropTypes.number.isRequired,
  type: PropTypes.string
}

DateTimeDisplay.defaultProps = {
  type: null
}

export default DateTimeDisplay;
