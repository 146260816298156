const initialState = {};

export default(state = initialState, { type, value }) => {
    switch (type) {
        case 'CHECKOUT_UPDATED':
            return value || state;
        case 'CHECKOUT_ERRORS_UPDATED':
            return {
                ...state,
                errors: value
            };
        case 'SAVE_DELIVERY_DATE':
            return {
                ...state,
                saveDeliveryDate: value
            }
        case 'SAVE_DELIVERY_TIME':
            return {
                ...state,
                saveDeliveryTime: value
            }
        case 'SAVE_CHECKOUT_DETAILS':
            return {
                ...state,
                saveCheckoutDetails: value
            }
        default:
            return state;
    }
}
