import { datadogLogs } from '@datadog/browser-logs';

/**
* Initialize the logger (datadogLogs). This logs unhandled execptions and network errors throughout the application.
* @param {object} environment - the object that stores client-facing environment variables, set in /app/public/index.html
 */
const initialize = (environment) => {
    if (!environment?.REACT_APP_DATADOG_CLIENT_TOKEN) {
        // eslint-disable-next-line no-console
        console.log('REACT_APP_DATADOG_CLIENT_TOKEN was not set in the environment. Datadog Logger will not run.');
        return;
    }
    datadogLogs.init({
        clientToken: environment.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: environment.REACT_APP_DATADOG_SITE,
        service: environment.REACT_APP_DATADOG_SERVICE,
        env: environment.REACT_APP_ENV|| 'unknown',
        version: environment.REACT_APP_BUILD_ID,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: "all",
        sampleRate: 100
    });

}

/**
 *
 * @param {object} error - the Apollo error from the API
 * @param {object} error.networkError - the network error from Apollo. Usually this has the details of a 4xx or 5xx error.
 * @param {object} error.operation - the attempted (but failed) GraphQL operation
 * @param {object} error.response - will have even more error detail like the errors from graphQL.
 * @param {object} [reduxState] - state of the application used to enhance metadata
 */
const logApiError = ({ networkError, operation, response }, reduxState) => {

    const marketingUserId = window.localStorage.getItem('marketingUserId') ?? null;

    /*
        Error cases:
        1. Network error - client cannot reach endpoint at all. The web facing servers (nginx) are down OR the client has spotty internet. Only networkError.message will most likely be set, no statusCode.
        2. Network error - client can reach endpoint, web facing servers (nginx) are fine, but nginx cannot reach the backing servers. networkError.statusCode will be available which is the status of nginx's attempt to communicate with backing servers
        3. Network error - backend code is throwing an error that is not a graphql error
        3. GraphQL error in an endpoint - code executing in the graphql endpoint threw an error. "errors" should be populated
    */

    try {
        const { statusCode, bodyText, message: networkErrorMessage } = networkError || {};
        const { operationName: graphQlOperationName } = operation || {};
        const { data: responseData } = response || {};
        let { errors } = response || {};
        errors = errors || []; // not using default in above destructing in case 'errors' is null (defaults only apply when variable is undefined)
        const statusCodeString = statusCode ? `[Status Code ${statusCode}]` : '';
        let errorStrings = errors.map(e => `${e.message}${e.debugMessage ? (` -> ${  e.debugMessage}`) : ''}`);
        errorStrings = ['API Error', graphQlOperationName, statusCodeString, networkErrorMessage, ...errorStrings].filter(Boolean);
        const errorMessage = errorStrings.join(' - ');
        datadogLogs.logger.error(errorMessage, {
            errorStrings,
            networkErrorMessage,
            statusCode,
            graphQlOperationName,
            bodyText,
            responseData,
            errors,
            reduxState,
            marketingUserId
        });

    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('logApiError -> Tried to log to datadog log, but failed. See error: ', e)
    }

}

/**
 * Log an Error instance or a string
 * Note: if you use string, a new Error instance will be created for datadog, and the stack trace will always point to this logger.logError method.
 * @param {(Error|string)} e - Instance of an Error or a string.
 * @returns
 */
    
const logError = (e) => {
    const marketingUserId = window.localStorage.getItem('marketingUserId') ?? null;


    let errorToLog;
    const isError = e instanceof Error || (e && e.stack && e.message);
    const isString = typeof e === 'string';

    if (isString) {
        errorToLog = new Error(e);
    } else if (isError) {
        errorToLog = e;
    } else {
        errorToLog = JSON.stringify(e);
    }

    try {
        datadogLogs.logger.error('logError', { errorToLog,  marketingUserId});
    } catch (exception) {
        // eslint-disable-next-line no-console
        console.error('logError -> Tried to log to datadog log, but failed. See error: ', exception)
    }
}

const logInfo = (message, params) => {
    const marketingUserId = window.localStorage.getItem('marketingUserId') ?? null;

    let infoToLog;
    const isError = message instanceof Error || (message && message.stack && message.message);
    const isString = typeof message === 'string';

    if (isString) {
        infoToLog = message;
    } else if (isError) {
        infoToLog = message;
    } else {
        infoToLog = JSON.stringify(message);
    }


    const param = {
        ...params,
        marketingUserId
    }

    try {
        datadogLogs.logger.info(infoToLog, param);
    } catch (exception) {
        // eslint-disable-next-line no-console
        console.error('logError -> Tried to log to datadog log, but failed. See error: ', exception)
    }
}

export default { initialize, logApiError, logError, logInfo };