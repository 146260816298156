export const setIsCaloriesActive = value => ({
    type: "CALORIES_ACTIVE",
    value
  });
  
export const setUpsellProductModalActive = value => ({
  type: "UPSELL_PRODUCT_MODAL_ACTIVE",
  value
});

export const setIsShowOosProducts = value => ({
  type: "SHOW_OOS_PRODUCTS",
  value
});
  