/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import {
  Marker,
} from '@react-google-maps/api';
import DeliveryTracker from './images/DeliveryTracker.png';
import * as orderRepo from "../../shared/repos/graphql/order";



class MarkerComponent extends Component {
  interval = null;

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const driverTrackerTtl = Number(window.environment.ORDER_TRACKER_DRIVER_LOCATION_TTL ?? 30000);
    this.getDriverLocation();
    this.interval = setInterval(() => {
      this.getDriverLocation();
    }, driverTrackerTtl);
  }

  getDriverLocation = () => {
    const { order } = this.props;
      orderRepo.getDriverLocation(order.id).then( ({data}) => {
        this.setState({
          driverLocation: data.driverLocation,
        });
      }).catch(() => {})
}

  render() {
    const { driverLocation } = this.state;
    return (
        driverLocation?.lat && driverLocation?.lng ?
            (
              <Marker
                icon={DeliveryTracker}
                position={driverLocation}
              />
          ) : ''
    );
  }
}

export default MarkerComponent;
