import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import Loader from '../../components/Loader/Loader';
import * as cms from '../../shared/repos/cms/cms';
import cmsKeys from '../../shared/constants/cms';
import {getKeyValue} from '../../shared/utilities/cms';

import styles from './InsomniaRewardsTerms.module.css'


class InsomniaRewardsTerms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            content: []
        };
    }

   componentDidMount() {
       cms.getContent([cmsKeys.insmoniaRewardsTermsConditionsPage], this);
    }

    render() {
        const {content, loading} = this.state;

        if (loading) {
            return (
              <div className={styles.pageWrapper}>
                <div className={styles.pageContainer}>
                  <Loader id="loader" />
                </div>
              </div>
            );
        }
      
        if (!loading && !content.length) {
          return (
            <div className={styles.pageWrapper}>
              <div className={`${styles.pageContainer} text-dark dark:text-white`}>
                <h1>Oops, No data found for this page</h1>
              </div>
            </div>
          );
        }

        return (
          <div className={styles.pageWrapper}>
            <div className={`${styles.pageContainer} text-dark dark:text-white`}>
              {content.map(termsConditionsPage => {
                        const sections = getKeyValue(termsConditionsPage.children, 'sections', true);
                        const mainTitle = getKeyValue(termsConditionsPage.children, 'title');
                        const mainBody = getKeyValue(termsConditionsPage.children, 'body');
                        return (
                          <div>
                            <div className={styles.pageSection}>
                              <h1>{mainTitle}</h1>
                              <p><ReactMarkdown>{mainBody}</ReactMarkdown></p>
                            </div>

                            {sections.children.map(termsConditionsSection => {
                                    const header = getKeyValue(termsConditionsSection.children, 'header');
                                    const body = getKeyValue(termsConditionsSection.children, 'body');
                                     return (
                                       <div>
                                         { (termsConditionsSection.children.length > 0) ? (
                                           <div className={styles.pageSection}>
                                             <h3>{header}</h3>
                                             <p><ReactMarkdown>{body}</ReactMarkdown></p>
                                           </div>
                                        ): '' }
                                       </div>
                                     )
                              })}
                          </div>
                        )
                    })}
            </div>
          </div>
        );
    }
}

export default InsomniaRewardsTerms
