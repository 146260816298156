import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import Link from "../../components/LinkWithCountry/LinkWithCountry";
import styles from "./TopBanner.module.css";
import {
  getContentsByKey,
  getContentValueByKey
} from "../../shared/utilities/common";

function TopBanner({ contents }) {
  const heading = getContentValueByKey(contents, "heading");
  const description = getContentValueByKey(contents, "description");
  const link = getContentsByKey(contents, "link");
  const linkTitle = getContentValueByKey(link, "title");
  const linkUrl = getContentValueByKey(link, "url");
  const image = getContentValueByKey(contents, "image");
  const mobileImage = getContentValueByKey(contents, "mobile_image");
  return (
    <>
      <div
        className={`${styles.bannerBackground} mx-auto flex items-center justify-around relative max-w-full`}
      >
        <div
          className=" w-[343px] md:w-[600px] h-[259px] md:h-[194px] pl-4 py-[72px] pr-0 flex 
          flex-col items-center md:items-start gap-3 absolute md:left-[10%] md:top-[10%] text-center md:text-left"
        >
          <h1 className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-white">
            {heading}
          </h1>
          <p className="font-filsonProRegular text-lg leading-5 md:leading-[22px] tracking-[-0.1px] text-white">
            <ReactMarkdown>{description}</ReactMarkdown>
          </p>
          {linkUrl && (
            <Link to={linkUrl}>
              <button
                type="button"
                className="
            bg-button text-lg leading-[22px] tracking-[-0.1px] 
            font-filsonProBold text-white h-[52px] w-[245px] md:w-[254px] rounded-[40px] px-5 py-[15px]"
              >
                {linkTitle}
              </button>
            </Link>
          )}
        </div>
        <ReactMarkdown className="absolute right-[10%] top-[-128px] md:block hidden">
          {image}
        </ReactMarkdown>

        <ReactMarkdown className="absolute top-[-67px] md:hidden">
          {mobileImage}
        </ReactMarkdown>
      </div>
    </>
  );
}

TopBanner.propTypes = {
  contents: PropTypes.shape({}).isRequired
};

export default TopBanner;
