/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import React, { Component } from "react";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { withRouter } from 'react-router-dom';
import {connect} from "react-redux";

import DatePickerComponent from "../../components/DatePickerComponent/DatePickerComponent";
import DropDown from '../../components/Dropdown/Dropdown';
import Input from "../../components/Input/Input";
import styles from './DoughProGiftCardPurchase.module.css'

import * as userActions from '../../redux/actions/user';
import * as elementActions from '../../redux/actions/elements';
import * as orderRepo from "../../shared/repos/graphql/order";

import { getProduct } from "../../shared/repos/graphql/product";

import {shippingMethods, storeIds} from "../../shared/constants/store";
import endpoints from "../../shared/constants/endpoints";
import {giftCard} from '../../shared/constants/giftCard';
import {orderMethods, orderTypeIds} from '../../shared/constants/order'
import { ADD_TO_ORDER_TEXT, UPDATE_PRODUCT_TEXT} from "../../shared/constants/product";
import errorCodes from "../../shared/constants/errorCodes";
import {handleFormInput} from "../../shared/utilities/validations";
import {getErrorMessages, getErrorReasons, routeCountryPath} from "../../shared/utilities/common";
import {
    openCartCreateFailModal,
    giftCardOrderTypeModal
} from "../../shared/utilities/modals";
import {clearCart} from "../../shared/utilities/orders";
import CheckoutCartButton from "../../components/CheckoutCartButton/CheckoutCartButton";
import Loader from "../../components/Loader/Loader";
import * as tagmanagerEvents from "../../shared/utilities/tagmanagerEvents";

const giftCardImg = require('./images/giftCard.png');

const moment = extendMoment(Moment);
class DoughProGiftCardPurchase extends Component
{
    constructor(props){
        super(props);
        this.state = {
            product: null,
            amount: ' ',
            recipientName: '',
            email: '',
            emailConfirm: '',
            message: '',
            date: '',
            formattedDate:null,
            amountDuration: '',
            amountOptions: [] ,
            quantity: null,
            giftCardLoading : false,
            addError: '',
            updateCard: false,
        }
    }

    async componentDidMount() {
        const { selectedStore,setModalObject } = this.props;
        const isOtherStore = selectedStore && parseInt(selectedStore.id, 10) !== storeIds.giftCard;
        if (isOtherStore) {
            return setModalObject(giftCardOrderTypeModal(this.clearStates));

        }
        this.setGiftProduct();
    }

    setGiftProduct = async ()=> {
        const { userCart, match, location, setUserOrderMethod,setSelectedStore} = this.props;
        const { params } = match;
        const response = await getProduct(giftCard.productId);
        const {product} = response.data;
        const giftOptions = [];
        product.amountOptions.forEach(item => {
            giftOptions.push(
                {duration: item.duration, amount: item.amount}
            )
        });

        this.setState({
            product,
            amountOptions: giftOptions,
            amountDuration: giftOptions[0].duration,
            amount: giftOptions[0].amount
        });

        setSelectedStore({id: storeIds.giftCard})
        setUserOrderMethod(orderMethods.eGiftCard);
        if (userCart.length > 0 && location?.state?.updateCard ) {
            const orderProduct = userCart.find(item => item.id === params.poid);
            const options = orderProduct.productOptions;
            const data = {
                recipientName: this.getOptionValueByLabel(options, giftCard.optionLabels.recipientName),
                email: this.getOptionValueByLabel(options, giftCard.optionLabels.recipientEmail),
                emailConfirm: this.getOptionValueByLabel(options, giftCard.optionLabels.recipientEmail),
                message: this.getOptionValueByLabel(options, giftCard.optionLabels.message),
                formattedDate:  this.getOptionValueByLabel(options, giftCard.optionLabels.deliveryDate),
                date: this.getOptionValueByLabel(options, giftCard.optionLabels.deliveryDate),
                amount: orderProduct.price,
                amountDuration: this.getKeyByValue(giftOptions, orderProduct.price),
                updateCard: true,
                quantity: orderProduct.quantity
            };
            this.setState(data);
        }

    }

    getKeyByValue = (options, value) => {
        const { duration } = options.find(item => item.amount === value.toString())
        return duration;
    }

    getOptionValueByLabel = (options, label) => {
        const optionId = this.getProductOptionsByLabel(label)[0].id;
        const option = options.find(item => (parseInt(item.productOption.id,10)  === parseInt(optionId, 10)))
        return option.value;
    }

    setChangedDate = (date) => {
        const formatted = moment(date).format('YYYY/MM/DD');
        this.setState({date});
        this.setState({formattedDate: formatted})
    };

    renderInputs = (label, value, state, className, errorMessage,  type = 'text') => {
        const {updateCard} = this.state;
        // eslint-disable-next-line react/destructuring-assignment
        const stateInput = this.state[state];

        const customClassName = updateCard ? [className, styles.disabledInputs].join(" ") : className;
        return (
          <Input
            placeholder=" "
            label={label}
            type={type}
            value={stateInput}
            disabled={updateCard}
            errorMessage={errorMessage}
            customContainerStyles={customClassName}
            handleInput={(e)=> handleFormInput(e, this, state, type)}
          />
        )
    }

    changeAmount = (duration)=> {
        const { amountOptions} = this.state;
        const { amount } = amountOptions.find(item => item.duration === duration);
        this.setState({amountDuration: duration });
        this.setState({amount});
    };

    validateStates = () => {
        const {recipientName,email,emailConfirm, emailErrorMessage} = this.state;

        let isValid = true;
        if (email.length <= 0 || emailErrorMessage ) {
            isValid = false;
            const errMessage = emailErrorMessage || 'Email is empty';
            this.setState({emailErrorMessage: errMessage})

        } else {
            this.setState({emailErrorMessage: ''})
        }

        if (email !== emailConfirm) {
            isValid =false;
            this.setState({emailConfirmErrorMessage: 'Emails dont match' });
        } else {
            this.setState({emailConfirmErrorMessage: '' });
        }
        const isFullName = recipientName.includes(' ');
        if (!isFullName) {
            isValid = false;
            this.setState({recipientNameErrorMessage: `Please enter recipient's full name`});
        } else {
            this.setState({recipientNameErrorMessage: ''});
        }
        return isValid
    }

    clearStates = () => {
        const {history} = this.props;

        clearCart();
        history.push(routeCountryPath(endpoints.getMenuUrl(null)));

    }

    handleErrorModal = (e) => {
        const {setModalObject} = this.props;
        const message = getErrorMessages(e);
        const reason = getErrorReasons(e);
        const cartCreateReason = reason[0] === errorCodes.CART_CREATE_ERROR;

        if (cartCreateReason) {
           this.setState({giftCardLoading: false})
           return setModalObject(openCartCreateFailModal(message, this.clearStates))
        }
    }

    getProductOptionsByLabel = (label) => {
        const {product} = this.state;
         return product.options.filter(item =>
                item.label === label
            );
    }

    createGiftCartData = () => {
        const  {amount, recipientName, email, message, formattedDate, updateCard, quantity} = this.state;



        const {match } = this.props;
        const { id, poid } = match.params;

        const labels = [
            {
                label: giftCard.optionLabels.deliveryDate,
                value: formattedDate
            },
            {
                label:giftCard.optionLabels.recipientName,
                value: recipientName
            },
            {
                label: giftCard.optionLabels.recipientEmail,
                value: email
            },
            {
                label: giftCard.optionLabels.design,
                value: id
            },
            {
                label: giftCard.optionLabels.message,
                value: message
            }
        ];
        const productOptions = [];
        labels.forEach(item => {
            const stat = this.getProductOptionsByLabel(item.label);
            const data = {
                productId: parseInt(stat[0].id, 10),
                label: item.label,
                value: item.value
            }
            productOptions.push(data)
        })


        return {
            product: updateCard ? parseInt(poid, 10) : giftCard.productId,
            quantity: updateCard ? quantity : 1,
            productOptions,
            price: parseFloat(amount),
            storeId: storeIds.giftCard
        }
    }

    addGiftCart = async(code) => {
        const {history, setUserCart} = this.props;
        const {product, amount} = this.state;
        const data = this.createGiftCartData();
        const { quantity } = data;
        tagmanagerEvents
            .addToCart({...product, quantity, price: (parseFloat(amount) * quantity).toFixed(2)});
        try {
            const resp = await orderRepo.addGiftProductToOrder(code, data);
            const localStorageCart = resp.data.addGiftProductToOrder.items.map((item) => item);
            setUserCart(localStorageCart);

            history.push(routeCountryPath(endpoints.checkoutViewOrderPage))
        } catch (e) {
            if (!e.graphQLErrors) {
                this.setState({
                    addError:'Something went wrong! Please try again.',
                })

                return
            };
            const errorMessage = e.graphQLErrors[0].message;

            this.setState({
                addError: errorMessage,
                giftCardLoading: false
            })
        }
    }

    handleUpdateProduct = async () => {
        const { history, userCartId, setUserCart} = this.props;
        const { quantity, product, amount } = this.state;

        const data = this.createGiftCartData();
        tagmanagerEvents
            .addToCart({...product, quantity, price: (parseFloat(amount) * quantity).toFixed(2)});
        try {
            const response = await orderRepo.updateOrderGiftProduct(userCartId, data);
            const localStorageCart = response.data.updateOrderGiftProduct.items.map((item) => item);
            setUserCart(localStorageCart);
            history.push(routeCountryPath(endpoints.checkoutViewOrderPage))
        } catch(e) {
            const message = getErrorMessages(e);
            this.setState({addError:message,
            giftCardLoading:false
            });
        }
    }

    handleGiftCart = async() => {
        const {userCartId,setUserCartId,  setUserOrderMethod, setSelectedStore} = this.props;
        const { updateCard } = this.state;
        const valid = this.validateStates();

        const data = {
            orderTypeId: orderTypeIds.eGiftCard,
            shippingMethod: shippingMethods.eGiftCard,
            storeId: storeIds.giftCard
        };

        if (valid) {
            this.setState({giftCardLoading: true})

            if (updateCard) {
                return this.handleUpdateProduct();
            }
            if(!userCartId ) {
              setSelectedStore({id: storeIds.giftCard})
              setUserOrderMethod(orderMethods.eGiftCard);
              try {
                 const response =  await orderRepo.createCart(data);
                 const { createCart } = response.data;
                 const { code}  = createCart;
                 setUserCartId(code);
                 return this.addGiftCart(code)
              } catch (err) {
                 this.handleErrorModal(err)
              }
            } else {
              this.addGiftCart(userCartId)
            }
        }
    }


    giftCardPurchaseContainer = () => {
        const {date, amount, amountDuration, recipientName, email, emailConfirm, message, amountOptions, emailConfirmErrorMessage,
        emailErrorMessage, recipientNameErrorMessage, addError, updateCard, quantity, giftCardLoading, product } = this.state;

        if (!product) {

            return (
              <div className={styles.pageLoaderWrapper}>
                <Loader />
              </div>
            )
        }

        const months = amountOptions.map(item => item.duration);
        const buttonLabel = updateCard ? UPDATE_PRODUCT_TEXT : ADD_TO_ORDER_TEXT
        return (
          <div className={styles.pageWrapper}>
            <div className={styles.pageContainer}>
              <div className={styles.giftCardContainer}>
                <div className={styles.pageLeftContainer}>
                  <div className={styles.giftCardImg}>
                    <img src={giftCardImg} alt="giftCard" />
                  </div>
                  <div className={styles.giftCardDescriptions}>
                    <h4>Hope you enjoy this CookieMagic™ Gift Card!</h4>
                    <h2>{`$${amount}`}</h2>
                    <p>CookieMagic™ Gift Card</p>
                  </div>

                </div>

                <div className={styles.pageRightContainer}>
                  <div className={styles.inputsWrapper}>
                    <DropDown
                      className={styles.customDropDown}
                      onChange={(e) => this.changeAmount(e.value)}
                      options={months}
                      value={amountDuration}
                      customDropDownStyles={styles.dropDownPlaceHolder}
                    />
                    {this.renderInputs('Recipient Name', recipientName, 'recipientName', styles.mediumInputs, recipientNameErrorMessage)}
                  </div>

                  <div className={styles.inputsWrapper}>
                    {this.renderInputs('Recipient Email',  email, 'email', styles.mediumInputs, emailErrorMessage, 'email')}
                    {this.renderInputs('Recipient Email Confirmation',  emailConfirm, 'emailConfirm', styles.mediumInputs, emailConfirmErrorMessage, 'email')}
                  </div>

                  {this.renderInputs('Message', message, 'message', styles.largeInputs,'','textarea')}

                  <div className={styles.datePickerWrapper}>
                    <DatePickerComponent
                      selected={date}
                      onDateChange={this.setChangedDate}
                      customContainerStyles={updateCard ? [styles.pickerLabel, styles.disabledInputs].join(" ") : styles.pickerLabel}
                      disable={updateCard}
                    />
                  </div>

                  <div className={styles.customButton}>
                    {giftCardLoading ? (
                      <div className={styles.loaderWrapper}>
                        <Loader />
                      </div>
                    ) : null}
                    <CheckoutCartButton

                      id="#addGiftCartButton"
                      label={buttonLabel}
                      isDisabled={giftCardLoading}
                      onClick={this.handleGiftCart}
                      quantity={updateCard ? quantity : null}
                      onQuantityChange={(value) => this.setState({quantity: value})}
                    />
                  </div>

                  <div className={styles.errorWrapper}>{addError}</div>
                </div>
              </div>
            </div>
          </div>
        )
    }

    render(){
        return <this.giftCardPurchaseContainer />
    }

}

export const mapStateToProps = (state)=> {
    const {
        userCart,
        userCartId,
        selectedStore,
        userOrderMethod,
    } = state.user;

    return {
        userCart,
        userCartId,
        selectedStore,
        userOrderMethod,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setUserCartId: (value) => dispatch(userActions.setUserCartId(value)),
    setSelectedStore: (value) => dispatch(userActions.setSelectedStore(value)),
    setUserOrderMethod: (value) => dispatch(userActions.setUserOrderMethod(value)),
    setModalObject: (value) => dispatch(elementActions.setModalObject(value)),
    setUserCart: (value) => dispatch(userActions.setUserCart(value))
});

 export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DoughProGiftCardPurchase));