import React, { Component } from 'react';

import PropTypes from 'prop-types';

// COMPONENTS
import Input from '../Input/Input';

// VALIDATIONS
import { handleFormInput } from '../../shared/utilities/validations';

import styles from './CallOut.module.css';

import * as contactRepo from '../../shared/repos/graphql/contact';

const newsLetterAsset = require('./imgs/newsletter-asset.png');
const downloadAsset = require('./imgs/download-asset.png');
const appleStoreIcon = require('./imgs/app-store-icon.png');
const googlePlayIcon = require('./imgs/google-play-icon.png');

class CallOut extends Component {
    constructor(props){
        super(props);

        this.state = {
            errorMessage: null,
            successMessage: null
        }
    }

    newsletterMutation = async (email) => {

      const response = await contactRepo.submitNewsletterForm({email});

          if (response.submitNewsletterForm) {
              this.setState({
                  successMessage: 'Form successfully submitted!'
              });

              setTimeout(() => this.setState({ successMessage: false }), 2500);
          } else {
              this.setState({
                  errorMessage: 'Error occurred. Please try again.'
              });
          }
      
    }

    renderCallout = () => {
        const { isNewsLetter, leftChild, rightChild, isDownload, isShipping, isCookieLab } = this.props;
        const { successMessage, errorMessage, email } = this.state;

        if(isCookieLab) {
            return null;
        }

        return(
          <div
            className={[
                    styles.callOutWrapper,
                    leftChild && rightChild ? styles.callOutWrapperDefault : null,
                    isNewsLetter ? styles.callOutWrapperNewsLetter : null,
                    isDownload ? styles.callOutWrapperDownload: null,
                    isShipping ? styles.shippingCallOutWrapper: null
            ].join(' ')}
          >

            {/* PROP CHILD ELEMENTS */}
            { leftChild && rightChild ? (
              <div className={styles.callOutContainer}>
                <div className={styles.leftChildWrapper}>
                  {leftChild}
                  { isDownload ? (
                    <div className={styles.downloadsContainer}>
                      <a href='https://apps.apple.com/us/app/insomnia-cookies/id891379973'>
                        <img alt='apple-icon' src={appleStoreIcon} />
                      </a>
                      <a href='https://play.google.com/store/apps/details?id=com.insomniacookies.insomnia&hl=en_US'>
                        <img alt='apple-icon' src={googlePlayIcon} />
                      </a>
                    </div>
                            ) : null}
                </div>
                <div className={styles.rightChildWrapper}>
                  {isDownload ? (
                    <div id={styles.downloadAssetContainer}>
                      <img id={styles.downloadAsset} alt="download-asset" src={downloadAsset} />
                    </div>
                            ) : null}
                  {rightChild}
                </div>
              </div>
                ) : null}


            {/* NEWS LETTER ELEMENTS */}
            { isNewsLetter ? (
              <div className={[
                        styles.newsLetterContainer,
                        errorMessage ? styles.newsLetterContainerError : null,
                        successMessage ? styles.newsLetterContainerSuccess : null
                    ].join(' ')}
              >
                <h1>Never miss a deal</h1>
                <img alt='newsletter_asset' src={newsLetterAsset} />
                <Input
                  handleSubmit={() => this.newsletterMutation(email)}
                  id="input_newsletter_signup"
                  buttonLabel='Sign me up!'
                  handleInput={e => handleFormInput(e, this, 'email', 'email')}
                  errorMessage={errorMessage}
                  placeholder='Enter your email address'
                  value={email}
                />
                {<p>{successMessage}</p> || null}
              </div>
                ) : null}
          </div>
        )
    }

    render() {
        return(
          <this.renderCallout />
        )
    }
}

CallOut.propTypes = {
    isNewsLetter: PropTypes.bool,
    leftChild: PropTypes.node,
    rightChild: PropTypes.node,
    isDownload: PropTypes.bool,
    isShipping:PropTypes.bool,
    isCookieLab:PropTypes.bool
}

CallOut.defaultProps = {
    isNewsLetter: false,
    leftChild: null,
    rightChild: null,
    isDownload: null,
    isShipping:null,
    isCookieLab:null
}

export default CallOut;
