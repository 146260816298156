import React from 'react';
import styles from './Careers.module.css'

const Careers = () => (
  <div className={styles.careers}>
    <p className={styles.placeholder}>Careers</p>
  </div>
);

export default Careers
