export const setIsDoughProActive = value => ({
    type: 'DOUGH_PRO_ACTIVE',
    value
});

export const selectModal = value => ({
    type: 'DOUGH_PRO_SELECT_MODAL',
    value
});

export const profileUpdatePaymentMethod = value => ({
    type: 'DOUGH_PRO_PROFILE_UPDATE_PAYMENT_METHOD',
    value
});

export const profileUpdateCancelSubscription = value => ({
    type: 'DOUGH_PRO_PROFILE_UPDATE_CANCEL_SUBSCRIPTION',
    value
});
