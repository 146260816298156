import gql from "graphql-tag";

const response = `
    id
    title
    image
    optionGroups{
      groupId
      id
      options{
        productId
        quantity
        priority
        groupId
      }
    }
`;

export const PRESETS_QUERY = gql`
  query PRESETS($productId: Int!, $storeId: Int) {
    presets(productId: $productId, storeId: $storeId) {
        ${response}
    }
  }
`;
