/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./OrderTotalsWidget.module.css";
import CookieSVG from "./imgs/cookie.svg";
import CreditCardSVG from "./imgs/creditcard.svg";
import orderTotals from "../../shared/constants/orderTotals";

class OrderTotalsWidget extends Component {

  castTitle = (title) => {
    const { orderTotalsTitles } = this.props;
    const orderTotalsLabels = orderTotalsTitles ? JSON.parse(orderTotalsTitles) : null;
    if (orderTotalsLabels && orderTotalsLabels[title] !== undefined) {
      return orderTotalsLabels[title];
    }
    return title;
  }

  renderHeader = () => {
    const { order } = this.props;

    const itemsCountText = order.items.length > 1 ? `${order.items.length} Items` : `${order.items.length} Item`;
    return (
      <div className={[styles.orderTotalsWidgetHeader, styles.orderTotalItem, styles.borderBottom].join(' ')}>
        <div className={styles.orderTotalIcon}>
          <img className="dark:image-negative" src={CookieSVG} alt="cookie" />
        </div>
        <div className={styles.orderTotalContentContainer}>
          <div className={styles.orderTotalContentTitle}>Order</div>
          <div className={styles.orderTotalContent}>{itemsCountText}</div>
        </div>
      </div>
    );
  }

  renderItem = (item, currency = "$") => {

    const isHasOptions = item.productOptions && item.productOptions.length > 0;

    let optionPrice = 0;
    const options = isHasOptions ? item.productOptions.map((option) => {
      optionPrice += option.optionPrice;
      return (
        <div className={styles.orderTotalContent}>{`${option.quantity} ${option.title}`}</div>
      );
    }) : null;

    const itemPrice = `${currency}${(item.price + optionPrice).toFixed(2)}`;

    return (
      <div className={[styles.orderTotalItem, styles.borderBottom].join(' ')}>
        <div className={styles.orderTotalIconBig}>
          <img src={item?.product?.productImageSmall} alt="" />
        </div>
        <div className={styles.orderTotalContentContainer}>
          <div className={styles.orderTotalContentTitle}>{item.product.title}</div>
          <div className={styles.orderTotalContent}>{itemPrice}</div>
          { isHasOptions ? options : null }
        </div>
      </div>
    );
  }

  renderItems = () => {
    const { order } = this.props;
    const currency = order.store?.currency?.symbol || "$";
    return order.items.map((item) => {
      return this.renderItem(item, currency);
    });
  }

  renderOrderTotal = (total, currency = "$") => {
    const isNotZero = total.value !== 0;
    const isTotal = total.title.toLowerCase() === orderTotals.total.title.toLowerCase();

    const containerClasses = [
      styles.totalValueItem,
    ];

    if (isTotal) {
      containerClasses.push(styles.totalItemTotal);
    }
    return isNotZero ? (
      <div className={containerClasses.join(' ')}>
        <div>
          { this.castTitle(total.title) }
        </div>
        <div className={styles.orderTotalValuePrice}>
          { `${currency}${total.value.toFixed(2)}` }
        </div>
      </div>
    ) : null;
  }

  renderOrderTotals = () => {
    const { order, showTaxesAfterTotal } = this.props;
    const currency = order.store?.currency?.symbol || "$";
    return order.totals.filter((total) => {
      const isTotal = total.title.toLowerCase() === orderTotals.total.title.toLowerCase();
      const isTax = total.title.toLowerCase() === orderTotals.tax.title.toLowerCase();
      const isCoupons = total.title.toLowerCase() === orderTotals.coupons.title.toLowerCase();
      if (isTotal || isCoupons) {
        return false;
      } if (isTax) {
        return !showTaxesAfterTotal;
      }
      return true;
    }).map((total) => {
      return this.renderOrderTotal(total, currency);
    });
  }

  renderPaymentBlock = () => {
    const { order } = this.props;
    return (
      <div className={[ styles.orderTotalItem, styles.card].join(' ')}>
        <div className={styles.orderTotalIcon}>
          <img className="dark:image-negative" src={CreditCardSVG} alt="card" />
        </div>
        <div className={styles.orderTotalContentContainer}>
          Payment
        </div>
        <div className={styles.cardInfo}>{order?.paymentMethod?.title}</div>
      </div>
    );
  }

  renderLoyaltyPoints = () => {
    const {order} = this.props;
    const usedPoints = order?.orderRedeemables?.reduce(function(accumulator, currentValue) {
      return accumulator + currentValue.points;
    }, 0);

    return usedPoints > 0 ? (
      <div className={styles.totalValueItem}>
        <div>
          { this.castTitle("Points Redeemed") }
        </div>
        <div className={styles.orderTotalValuePrice}>
          { `${usedPoints} pts` }
        </div>
      </div>
    ) : null;
  }

  renderOrderTotalTotal = () => {
    const { order } = this.props;
    const currency = order.store?.currency?.symbol || "$";
    return order.totals.filter((total) => {
      const isTotal = total.title.toLowerCase() === orderTotals.total.title.toLowerCase();
      return isTotal;
    }).map((total) => {
      return this.renderOrderTotal(total, currency);
    });
  }

  renderTaxesAfterTotal = () => {
    const { order, showTaxesAfterTotal } = this.props;
    const currency = order.store?.currency?.symbol || "$";
    return order.totals.filter((total) => {
      const isTax = total.title.toLowerCase() === orderTotals.tax.title.toLowerCase();
      return isTax && showTaxesAfterTotal;
    }).map((total) => {
      return this.renderOrderTotal(total, currency);
    });
  }

  renderDiscounts = () => {
    const { order } = this.props;
    const currency = order.store?.currency?.symbol || "$";

    if (order.discounts && order.discounts.length > 0) {
      return order.discounts.map((discount) => {
        if (discount.value > 0) {
          const negativeDiscount = { ...discount, value: discount.value * -1 };
          return this.renderOrderTotal(negativeDiscount, currency);
        }
        return null;
      })
    }
    return null;
  }

  render() {
    return (
      <div className={`${styles.orderTotalsWidgetContainer}
        p-[20px]
        rounded-[20px] border-[1px] border-lightDry dark:border-darkBorder
        bg-white dark:bg-dark
        font-filsonProRegular text-dark dark:text-white`}
      >
        { this.renderHeader() }
        { this.renderItems() }
        { this.renderOrderTotals() }
        { this.renderDiscounts()}
        { this.renderLoyaltyPoints() }
        { this.renderOrderTotalTotal() }
        { this.renderTaxesAfterTotal()}
        { this.renderPaymentBlock() }
      </div>
    );
  }
}

OrderTotalsWidget.propTypes = {
  order: PropTypes.object.isRequired,
  showTaxesAfterTotal: PropTypes.bool,
  orderTotalsTitles: PropTypes.string,
};
  
OrderTotalsWidget.defaultProps = {
  showTaxesAfterTotal: false,
  orderTotalsTitles: "",
};

export const mapStateToProps = (state) => {
  const { showTaxesAfterTotal, orderTotalsTitles } = state.orderTotalsTitles;
  return {
    showTaxesAfterTotal,
    orderTotalsTitles,
  };
};
  
export default connect(mapStateToProps)(OrderTotalsWidget);
