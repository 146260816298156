/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import _ from 'underscore';

// REDUX
import { connect } from 'react-redux';
import * as elementsActions from '../../redux/actions/elements';

import styles from './ProductsCategoryFilter.module.css';



class ProductsCategoryFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.categoryElement = React.createRef();
    }

    componentDidMount() {
        const { isStickyCategories } = this.props;
        if (!isStickyCategories) setTimeout(this.getComponentPosition, 500);
        window.addEventListener('resize', this.getComponentPosition) // eslint-disable-line
    }


    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.getComponentPosition();
        }
    }

    /**
     * Retrieves  component position based
     * on local and absolute positions
     */
    getComponentPosition = () => {
        const { setCategoriesPosition } = this.props;
        if (this.categoryElement.current) {
            const { top } = this.categoryElement.current.getBoundingClientRect();
            setCategoriesPosition(top + window.pageYOffset); // eslint-disable-line
        }
    }

    /**
     * Handles filter asset icon formatting and returns fully formatted image element
     * @param {String} product - product category unique id string
     * @returns {React.Element} returns formatted <img/> element
     */
    returnIconAsset = (product) => {
        const iconAlt = `${product}-icon`;
        let iconAsset = null;

        try {
            iconAsset = require(`./imgs/${product}.png`) || null; //eslint-disable-line
        } catch (e) {
            iconAsset = require('./imgs/boxes.png'); //eslint-disable-line
        }

        return (<img alt={iconAlt} src={iconAsset} className='hidden lg:block' />);
    }

    /**
     * Handles category value setting for redux and callback values
     * @param {String} product - selected product string
     */
    handleCategoryValueSetting = (anchor) => {
        const { onChange, setProductCategory } = this.props;
        // set value to redux
        setProductCategory(anchor);
        // return value to parent callback
        onChange(anchor);
        // scroll to position
        const element = document.getElementById(anchor); // eslint-disable-line
        let scrollDelta = this.categoryElement.current ? this.categoryElement.current.getBoundingClientRect().height : 10;
        scrollDelta = window.innerWidth > 900 ? scrollDelta : 92;
        const yPos = element ? (element.offsetTop - scrollDelta) : null;
         if (yPos) window.scrollTo({ top: yPos, left: 0, behavior: 'smooth' }); // eslint-disable-line
    }

    render() {
        const { productCategory, mobileDarkTheme, menuCategories } = this.props;
        // removes duplicates navTitles (ie. "traditional cookies" and "deluxe cookies" both have a nav title of "cookies", only show this once)
        const alteredMenucategories = _.uniq(menuCategories, item => {
            return item.navTitle;
        })

        return (
          <div
            ref={this.categoryElement}
            className={`${styles.categoryFilterWrapper} ${mobileDarkTheme ? styles.mobileDarkTheme : null} flex items-center m-auto`}
          >
            <div className={`
                ${styles.categoryFilterContainer} 
                p-0 m-0 flex justify-between w-full font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px]
                `}
            >
              {alteredMenucategories ? alteredMenucategories.map((mc, index) => {
                        const customIcon = index === 4 || index === 5;
                        const isChosen = mc.anchor === productCategory;
                        return (
                          <div
                            key={`$product${Math.random()}`}
                            id={`menuCategorySelector-${mc.anchor}`}
                            onClick={() => this.handleCategoryValueSetting(mc.anchor)}
                            className={`
                                hover:border-b-[3px]  rounded-none
                                ease-in
                                ${mobileDarkTheme ?
                                    'text-white dark:border-[#F6F3F7]' :
                                    'dark:text-white text-dark dark:border-[#F6F3F7] border-dark'}
                                px-2
                              
                                ${styles.category} 
                                ${customIcon ? styles.customIcon : null} 
                                ${isChosen ? styles.isChosen : null}
                            `}
                          >
                            {/* {this.returnIconAsset(mc.anchor)} */}
                            <p className={`  ${isChosen ? 'font-bold dark:font-bold' : "font-[425] dark:font-[425] opacity-60"}`}>{mc.navTitle}</p>
                          </div>
                        )
                    }) : null}
            </div>
          </div>
        )
    }
}

ProductsCategoryFilter.propTypes = {
    productCategory: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    setProductCategory: PropTypes.func.isRequired,
    mobileDarkTheme: PropTypes.bool,
    setCategoriesPosition: PropTypes.func,
    isStickyCategories: PropTypes.bool,
    menuCategories: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        cardSize: PropTypes.string,
        navTitle: PropTypes.string,
        menuTitle: PropTypes.string,
        anchor: PropTypes.string
    }))
}

ProductsCategoryFilter.defaultProps = {
    mobileDarkTheme: false,
    setCategoriesPosition: null,
    isStickyCategories: false,
    productCategory: null,
    menuCategories: []
}

export const mapStateToProps = state => {
    const { productCategory, isStickyCategories, menuCategories } = state.elements;
    return { productCategory, isStickyCategories, menuCategories };
}

export const mapDispatchToProps = dispatch => ({
    setProductCategory: (value) => dispatch(elementsActions.setProductCategory(value)),
    setCategoriesPosition: (value) => dispatch(elementsActions.setCategoriesPosition(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCategoryFilter);
