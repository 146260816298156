export const defaultLabels = {
  email: "Email address",
  password: "Password",
  LOGIN_ROUTE: '/login'
};

export const loginType = {
  phone: "phone",
  email: "email",
};

