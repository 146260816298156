/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
// REDUX
import {connect} from 'react-redux';
// GRAPHQL
import {extendMoment} from "moment-range";
import Moment from "moment";
import Link from '../LinkWithCountry/LinkWithCountry';
import * as orderRepo from '../../shared/repos/graphql/order';
// REDUX
import * as elementsActions from '../../redux/actions/elements';
// COMPONENTS
import Loader from '../Loader/Loader';
// HELPERS
import * as tagmanagerEvents from '../../shared/utilities/tagmanagerEvents';
import {clearCart} from '../../shared/utilities/orders';
import styles from './QuickCheckoutDetails.module.css';
import endpoints from "../../shared/constants/endpoints";
import * as userActions from "../../redux/actions/user";
import {dateTimeFormat, orderMethods} from "../../shared/constants/order";
import * as userRepo from "../../shared/repos/graphql/user";
import {getStoreHours} from "../../shared/repos/graphql/store";
import {getOrderTotals} from "../../shared/utilities/renderings";
import { routeCountryPath } from '../../shared/utilities/common';

const moment = extendMoment(Moment);

export class QuickCheckoutDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeOrderLoading: false,
      renderLoading: true,
      isAsapAvailable: true,
      order: {store: {}},
      me: {},
      storeHours: [],
      userCard: {},
      errorMessage: "",
      orderCompleted: false,
    };
  }

  async componentDidMount() {
    const { orderCode, selectedStore, userOrderMethod } = this.props;

    const [me, storeHoursResult, orderResult] = await Promise.all([
      this.getUser(),
      getStoreHours(orderCode, Moment().format(dateTimeFormat.yearMonthDay)),
      orderRepo.getOrder(orderCode)
    ]);

    const { order } = orderResult.data;
    const { storeHours } = storeHoursResult.data;

    const userCard = this.getCardForStore(me ? me.customerCards : [], selectedStore);

    let isAsapAvailable = true;
    if (userOrderMethod === orderMethods.delivery) {
      isAsapAvailable = this.checkIsAsapAvailable(storeHours);
    }

    if (!isAsapAvailable) {
      this.setState({
        errorMessage: "ASAP order is not available, please choose a delivery time on checkout page."
      });
    }

    this.setState({
      renderLoading: false,
      isAsapAvailable,
      order,
      me,
      storeHours,
      userCard
    });
  }

  componentWillUnmount() {
    const {setModalObject} = this.props;
    setModalObject(null);
    this.clearCart();
  }

  getCardForStore = (cards, selectedStore) => {
    if (!selectedStore || !cards) {
      return null;
    }
    for (let i = 0; i < cards.length; i+=1) {
      if (cards[i].store.id === selectedStore.id) {
        return cards[i];
      }
    }
    return null;
  }

  getUser = async () => {
    const response = await userRepo.getUser();
    const {me} = response.data;
    return me;
  }

  getValueFromTotals = (totals, title) => {
    if (!totals) {
      return '';
    }

    const totalItem = totals.find(item => {
      return item.title.toLowerCase() === title;
    });

    if (totalItem) {
      return `$${totalItem.value.toFixed(2)}`;
    }

    return '';
  }

  clearCart = () => {
    const {orderCompleted} = this.state;
    if (orderCompleted) {
      clearCart();
    }
  }

  getPaymentMethodTextFromCard = (card) => {
    if (!card) {
      return "";
    }
    return `Card ending in ${card.last4}`;
  }

  getFirstTimeSlotForPickup = (storeHours) => {
    if (storeHours.length && storeHours[0].hours && storeHours[0].hours.length) {
      const dateTime = moment(`${storeHours[0].date  } ${  storeHours[0].hours[0]}`, dateTimeFormat.yearMonthDayHourMinuteAMPM).format(dateTimeFormat.monthDayTime);
      return dateTime;
    }

    return '';
  }

  checkIsAsapAvailable = (storeHours) => {
    if (storeHours && storeHours.length && storeHours[0].hours && storeHours[0].hours.length) {
      return storeHours[0].hours[0].includes("ASAP");
    }

    return false;
  }

  getFirstItemInOrder = () => {
    const { order } = this.state;
    if (order && order.items && order.items[0]) {
      return order.items[0];
    }

    return {};
  }

  calculateSubtotal = () => {
    const orderItem = this.getFirstItemInOrder();
    const price = getOrderTotals(orderItem);

    return (price * orderItem.quantity).toFixed(2);
  }

  renderDeliverySection = (props) => {
    const orderItem = this.getFirstItemInOrder();

    return (
      <div className={styles.detailFields}>
        <p>
          <span className={styles.title}>Delivering to:</span>
          <span className={styles.text}>{props.userAddress.address1}</span>
        </p>
        <p>
          <span className={styles.title}>Payment Method:</span>
          <span className={styles.text}>{this.getPaymentMethodTextFromCard(props.card)}</span>
        </p>
        {props.isAsapAvailable? (
          <p>
            <span className={styles.title}>Delivery Time:</span>
            <span className={styles.text}>{props.order.deliveryEstimate}</span>
          </p>
              ) : ''}
        <p>
          <span className={styles.title}>
            {props.product.title}
            {' '}
(
            {orderItem.quantity}
):
          </span>
          <span className={styles.number}>
$
            {this.calculateSubtotal()}
          </span>
        </p>
        <p>
          <span className={styles.title}>Delivery:</span>
          <span className={styles.number}>{this.getValueFromTotals(props.order.totals, 'delivery')}</span>
        </p>
        <p>
          <span className={styles.title}>Tax:</span>
          <span className={styles.number}>{this.getValueFromTotals(props.order.totals, 'tax')}</span>
        </p>
        <p>
          <span className={styles.title}>Tip:</span>
          <span className={styles.number}>{this.getValueFromTotals(props.order.totals, 'tip')}</span>
        </p>
        <p>
          <span className={styles.title}>Order Total:</span>
          <span className={styles.number}>{this.getValueFromTotals(props.order.totals, 'total')}</span>
        </p>
      </div>
    );
  };

  renderPickUpSection = (props) => {
    const orderItem = this.getFirstItemInOrder();

    return (
      <div className={styles.detailFields}>
        <p>
          <span className={styles.title}>Pick Up From:</span>
          <span className={styles.text}>{props.selectedStore.address}</span>
        </p>
        <p>
          <span className={styles.title}>Pick Up Time:</span>
          <span className={styles.text}>{this.getFirstTimeSlotForPickup(props.storeHours)}</span>
        </p>
        <p>
          <span className={styles.title}>
            {props.product.title}
            {' '}
(
            {orderItem.quantity}
):
          </span>
          <span className={styles.number}>
$
            {this.calculateSubtotal()}
          </span>
        </p>
        <p>
          <span className={styles.title}>Tax:</span>
          <span className={styles.number}>{this.getValueFromTotals(props.order.totals, 'tax')}</span>
        </p>
        <p>
          <span className={styles.title}>Order Total:</span>
          <span className={styles.number}>{this.getValueFromTotals(props.order.totals, 'total')}</span>
        </p>
      </div>
    );
  };

  placeOrder = async () => {
    this.setState({placeOrderLoading: true});
    const {
      history,
      userOrderMethod
    } = this.props;
    const { order, me, storeHours, userCard } = this.state;

    let selectedDeliveryTime = '0000-00-00 00:00:00';
    if (userOrderMethod === orderMethods.pickup) {
      const firstPickupHours = (storeHours.length && storeHours[0])? storeHours[0] : {};
      const firstPickupHour = (firstPickupHours.hours && firstPickupHours.hours.length)? firstPickupHours.hours[0] : '';
      selectedDeliveryTime = moment(`${firstPickupHours.date  } ${firstPickupHour}`, dateTimeFormat.yearMonthDayHourMinuteAMPM)
              .format(dateTimeFormat.yearMonthDayHourMinuteSecond);
    }

    await Promise.all([
      orderRepo.addSender(order.code, {
        firstName: me.firstname,
        lastName: me.lastname,
        email: me.email,
        phone: me.telephone,
      }),
      orderRepo.setDeliveryPickupTime(order.code, selectedDeliveryTime)
    ]);

    const paymentCredentials = {
      paymentMethodId: 1,
      credential: userCard.transactionId,
      saveCredentials: false,
      securityCode: null
    };

    try {
      await orderRepo.completeOrder(order.code, paymentCredentials, null, undefined, true, false, []);
      this.setState({orderCompleted: true});
      tagmanagerEvents.purchase(order, true);

      this.clearCart();

      const historyUrl = endpoints.checkoutConfirmationPage.replace(':id', order.code);
      history.push(routeCountryPath(historyUrl));
    } catch (e) {
      const errorMessage = e.graphQLErrors && e.graphQLErrors[0] ? e.graphQLErrors[0] : e;
      this.setState({placeOrderLoading: false, errorMessage: errorMessage.message});
    }
  }

  getLinkToCheckout = (userCartId) => {
    return endpoints.checkoutOrderPage.replace(':id', userCartId);
  }

  goToCheckout = () => {
    const {setModalObject} = this.props;
    setModalObject(null);
  }

  renderQuickCheckoutDetails = () => {
    const {placeOrderLoading, errorMessage, storeHours, order, userCard, isAsapAvailable} = this.state;
    const {product, selectedStore, userOrderMethod, userAddress} = this.props;
    return (
      <div className={styles.widgetWrapper}>
        <div className={styles.widgetContainer}>
          {/* WIDGET HEADER */}
          <h2>Review Your Order</h2>
          {/* WIDGET BODY */}
          <hr />
          {(userOrderMethod === orderMethods.delivery) ? (
            <this.renderDeliverySection
              selectedStore={selectedStore}
              product={product}
              isAsapAvailable={isAsapAvailable}
              order={order}
              card={userCard}
              userAddress={userAddress}
            />
                ) : (
                  <this.renderPickUpSection
                    selectedStore={selectedStore}
                    isAsapAvailable={isAsapAvailable}
                    storeHours={storeHours}
                    product={product}
                    order={order}
                  />
                )}

          <div className={styles.footerButtonWrapper}>
            {placeOrderLoading ? (
              <div>
                <Loader />
              </div>
                  ) : (
                    <div>
                      <Link
                        onClick={this.goToCheckout}
                        to={this.getLinkToCheckout(order.code)}
                        className={[styles.actionButton, styles.editDetailsButton].join(' ')}
                      >
                        <div>
                          <p>
                            {isAsapAvailable? 'Edit Details' : 'Checkout'}
                          </p>
                        </div>
                      </Link>

                      {isAsapAvailable? (
                        <div
                          onClick={this.placeOrder}
                          className={[styles.actionButton, styles.placeOrderButton].join(' ')}
                        >
                          <div><p>Place Order</p></div>
                        </div>
                            ) : ''}

                      <p className={styles.errorMessage}>{errorMessage}</p>
                    </div>
                  )}
          </div>
        </div>
      </div>
    );
  }

  renderLoadingMessage = () => {
    return (
      <div className={styles.widgetWrapper}>
        <div className={styles.widgetContainer}>
          {/* WIDGET HEADER */}
          <h2 className={styles.loadingHeaderMessage}>Getting Your Order Details</h2>
          <div>
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { renderLoading } = this.state;
    return renderLoading? this.renderLoadingMessage() : this.renderQuickCheckoutDetails();
  }
}

QuickCheckoutDetails.propTypes = {
  userOrderMethod: PropTypes.string,
  orderCode: PropTypes.string,
  product: PropTypes.shape({}).isRequired,
  setModalObject: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  selectedStore: PropTypes.shape(
      {
        id: PropTypes.oneOfType(
            [
              PropTypes.number,
              PropTypes.string
            ]
        )
      })
};

QuickCheckoutDetails.defaultProps = {
  selectedStore: { id: '' },
  userOrderMethod: '',
  orderCode: '',
  setModalObject: () => null
};

export const mapStateToProps = (state) => {
  const {selectedStore, userAddress, userOrderMethod} = state.user;
  return {selectedStore, userAddress, userOrderMethod};
};

export const mapDispatchToProps = (dispatch) => ({
  setModalObject: (value) => dispatch(elementsActions.setModalObject(value)),
  setOrderCompleted: (value) => dispatch(userActions.setOrderCompleted(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickCheckoutDetails);