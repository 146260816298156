import React from 'react';

// ROUTER
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Link from '../../components/LinkWithCountry/LinkWithCountry';

// REDUX

// STYLES
import styles from './GiftCardLanding.module.css';
import endpoints from '../../shared/constants/endpoints';
import { giftCard } from '../../shared/constants/giftCard';

// IMAGES
const cookieCardFpoImg = require('./images/cookie-card_FPO.png');
const cmCardFpoImg = require('./images/cm-card_FPO.png');
const bulletStarImg = require('./images/bullet-star.png');
const biteMeImg = require('./images/biteme.jpg');
const hp2Img = require('./images/Happy-Birthday-2.jpeg');
const love2Img = require('./images/love_2.jpg');

const GiftCardLanding = () => {
  const renderHeroSection = () => {
    return (
      <section className={`${styles.hero} ${styles.pageSection}`}>
        <div className={styles.heroTextContainer}>
          <div className={`${styles.heroText} ${styles.spacing}`}>
            <h1>Yummy Gift Cards</h1>
            <p>Sometimes the best way to say I love you is with delicious, freshly-baked yumminess.</p>
            <a href="https://api.insomniacookies.com/gift-cards/email-gift-card" target="_blank" rel="noopener noreferrer">Check Out Our Gift Cards</a>
          </div>
        </div>
        <div className={styles.heroImageContainer}>
          <img src={cookieCardFpoImg} alt="Insomnia Cookies giftcard PLACEHOLDER" />
        </div>
      </section>
    )
  };

  const renderCookieMagicSection = () => {
    const cookieMagicGiftCardRoute = endpoints.cookieMagicGiftCardProduct
      .replace(':id', giftCard.productId);
    return (
      <section className={`${styles.cookieMagic} ${styles.spacing} ${styles.pageSection}`}>
        <img src={cmCardFpoImg} alt="CookieMagic Giftcard PLACEHOLDER" />
        <h2>
          Gift a CookieMagic™
          <br />
          Membership
        </h2>            
        <div className={styles.cmFeatures}>
          <ul className={styles.bullets}>
            <li>
              <div className={styles.bulletStarLeft}>
                <img src={bulletStarImg} alt="" />
              </div>
              <div>
                <h5>
                  FREE
                  <br />
                  Local Delivery
                </h5>
              </div>
            </li>
            <li>
              <div className={styles.bulletStarLeft}>
                <img src={bulletStarImg} alt="" />
              </div>
              <div>
                <h5>
                  20% Off
                  <br />
                  Nationwide Shipping
                </h5>
              </div>
            </li>
            <li>
              <div className={styles.bulletStarLeft}>
                <img src={bulletStarImg} alt="" />
              </div>
              <div>
                <h5>
                  One Free
                  <br />
                  Cookie Per Day
                </h5>
              </div>
            </li>
            <li>
              <div className={styles.bulletStarLeft}>
                <img src={bulletStarImg} alt="" />
              </div>
              <div>
                <h5>
                  Mind-blowing
                  <br />
                  Deals
                </h5>
              </div>
            </li>
          </ul>
        </div>
        <div role="button" aria-label="Gift a CookieMagic giftcard" className={styles.buttonContainer}>
          <Link to={cookieMagicGiftCardRoute}>Gift this card</Link>
        </div>
        <Link to={endpoints.doughProPage}>Learn More About CookieMagic™</Link>
      </section>
    )
  };

  const renderGiftCardsSection = () => {
    return (
      <section className={`${styles.giftcards} ${styles.pageSection}`}>
        <div className={`${styles.giftcardSpread}`}>
          <img src={biteMeImg} alt="Bite Me gift card" className={`${styles.giftcardSpreadImage} ${styles.giftcardSpreadBack}`} />
          <img src={hp2Img} alt="Happy birthday giftcard" className={`${styles.giftcardSpreadImage} ${styles.giftcardSpreadMiddle}`} />
          <img src={love2Img} alt="Love giftcard" className={`${styles.giftcardSpreadImage}`} />
        </div>
        <div className={`${styles.giftcardTextContainer}`} id={styles.giftcards}>
          <div className={`${styles.giftcardText} ${styles.spacing}`}>
            <h2>
              <strong>Know someone with a sweet tooth? </strong>
              Our Gift Cards make for an amazing gift!
            </h2>
            <a href="https://api.insomniacookies.com/gift-cards/email-gift-card" target="_blank" rel="noopener noreferrer" role="button" aria-label="Check out our giftcards" className={`${styles.buttonContainer} ${styles.longButton}`}>
              Check out our Gift Cards
            </a>
          </div>
        </div>
      </section>
    )
  };

  return (
    <div className={styles.sectionsWrapper}>
      { renderHeroSection() }
      { renderCookieMagicSection() }
      { renderGiftCardsSection() }
    </div>
  );
};


export default withRouter(connect()(GiftCardLanding));
