/* eslint-disable react/prefer-stateless-function, no-shadow, react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class CookiemagicRoute extends Component {
  render() {
    const { component: Component, isDoughProActive, ...props } = this.props

    return (
      <Route 
        {...props} 
        render={props => (
          isDoughProActive ?
            <Component {...props} /> :
            <Redirect to='/404' />
        )} 
      />
    )
  }
}

CookiemagicRoute.propTypes = {
  component: PropTypes.instanceOf(Component).isRequired,
  isDoughProActive: PropTypes.bool.isRequired,
}

export const mapStateToProps = (state) => {
  const { isDoughProActive } = state.doughPro;
  return {
    isDoughProActive
  };
};

export default connect(mapStateToProps)(CookiemagicRoute);
