import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from '../../components/LinkWithCountry/LinkWithCountry';
import * as userActions from '../../redux/actions/user';
import * as userRepo from '../../shared/repos/graphql/user';
import * as orderRepo from "../../shared/repos/graphql/order";

// COMPONENT
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

import styles from './Login.module.css';

// FORMS
import { handleFormInput } from '../../shared/utilities/validations';

// FACEBOOK LOGIN
import { loginWithFacebook } from '../../shared/utilities/facebook';
import * as tagmanagerEvents from '../../shared/utilities/tagmanagerEvents';
import { isRecaptcha } from "../../shared/utilities/config";
import { defaultLabels, loginType } from "../../shared/constants/login";
import { routeCountryPath } from '../../shared/utilities/common';
import * as elementsActions from "../../redux/actions/elements";
import {setCaptchaToken} from "../../shared/utilities/captcha";
import captchaAction from "../../shared/constants/captchaAction";

const ssoIcon = require('../../shared/icons/facebook-icon.png');

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      usernameErrorMessage: null,
      passwordErrorMessage: null,
      responseErrorMessage: null,
      loading: false
    };
  }

  /**
   * Handles form submit
   */
    handleSubmit = async () => {
        const { username, password } = this.state;
        const  {order, setVerificationToken, setVerificationAction } = this.props;

        this.state = {
            usernameErrorMessage: null,
            passwordErrorMessage: null,
            responseErrorMessage: null,
        };
        if (!username) {
            return this.setState({
                usernameErrorMessage: 'Please enter a username. '
            });
        }
        tagmanagerEvents.beginSignIn(loginType.email, {username});
        if (!password) {
            return this.setState({
                passwordErrorMessage: 'Please enter a password. '
            });
        }
        if (isRecaptcha()) {
          await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.loginWithEmail);
        }
        this.setState({
            loading: true
        });
        const response = await userRepo.login(username, password,null, null)
        .catch(error => {
          return this.setState({
            responseErrorMessage: error.message,
            loading: false
          });
        });

        if (response && response.data) {
          const { login } = response.data;
          const { token } = login;
          const { setUserToken, history, setUserId } = this.props;
          await setUserToken(token);
          const userData = await userRepo.getUser();
          await setUserId(userData.data.me.id);
          tagmanagerEvents.signIn(loginType.email, userData);
          if ( order ) {
            orderRepo.getOrder(order.code, null)
              .then(() => {});
          }
          return history.push(routeCountryPath('/'));
        }
        this.setState({
            loading: false
        });
      return null;
    };

  loginWithFacebook = () => {
      loginWithFacebook(async response => {
          if (response.status === 'connected') {
              const { setUserToken, history, setUserId, setVerificationToken, setVerificationAction } = this.props;
              if (isRecaptcha()) {
                await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.loginWithFacebook);
              }

              const loginResponse = await userRepo.login(null, null, response.authResponse.userID, null);
              if (loginResponse && loginResponse.data) {
                  const { login } = loginResponse.data;
                  const { token } = login;
                  await setUserToken(token);
                  const userData = await userRepo.getUser();
                  await setUserId(userData.data.me.id);
                  return history.push(routeCountryPath('/'));
              }
              return null;
          }
          return null;
      });
  };

  /**
   * Renders Login
   */
  loginContainer = () => {

    const {
      usernameErrorMessage,
      passwordErrorMessage,
      username,
      password,
      loading,
      responseErrorMessage
    } = this.state;

    const { history, userToken } = this.props;

    if (userToken) {
      history.push(routeCountryPath('/user/profile'));
    }
    const isDisabled = loading;
    return (
      <div className={`${styles.pageWrapper} text-dark dark:text-white`}>
        <div className={styles.pageContainer}>
          <h1>Login</h1>
          <Input
            handleInput={(e) => {
              handleFormInput(e, this, 'username');
            }}
            placeholder=" "
            value={username}
            label={defaultLabels.email}
            errorMessage={usernameErrorMessage}
          />
          <Input
            handleInput={(e) => {
              handleFormInput(e, this, 'password');
            }}
            placeholder=" "
            value={password}
            errorMessage={passwordErrorMessage}
            type="password"
            label={defaultLabels.password}
          />
          {responseErrorMessage && (
          <p className={styles.hasError}>
            {responseErrorMessage}
          </p>
) }
          <div className={styles.forgotPassword}>
            <p>
              Forgot Password?
              <Link to="/reset-password">Reset Here</Link>
            </p>
          </div>
          <div className={styles.actionsContainer}>
            <Button
              disabled={isDisabled}
              handleSubmit={
                    isDisabled
                        ? null
                        : () =>
                            this.handleSubmit()
                }
              customContainerStyles={styles.buttonContainer}
              label="Login"
            />
            <p>
              Don&apos;t have an account?
              <Link to="/create-account">Sign Up</Link>
            </p>
            <Button
              isLoading={loading}
              disabled={isDisabled}
              handleSubmit={
                  isDisabled
                      ? null
                      : () =>
                      this.handleSubmit()
              }
              customContainerStyles={styles.buttonContainer}
              label="Login"
            />
          </div>
          {/* SSO CONTAINER */}
          <div className={styles.ssoContainer}>
            <div className={styles.divider}>
              <div className='border-t-[1px] border-darkElevationPrimary dark:border-darkElevationSecondary border-solid w-full' />
              <span>or</span>
              <div className='border-t-[1px] border-darkElevationPrimary dark:border-darkElevationSecondary border-solid w-full' />
            </div>

            <button
              onClick={this.loginWithFacebook}
              type="button"
            >
              <p>
                <span>
                  <img alt="facebook-icon" src={ssoIcon} />
                </span>
                Login with Facebook
              </p>
            </button>

            <p className={`${styles.privacyTermsCopy} mb-4`}>
              <Link to="/privacy-policy">Privacy Policy</Link>
              &nbsp; & &nbsp;
              <Link to="/terms">Terms and Conditions</Link>
            </p>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <this.loginContainer />;
  }
}

Login.propTypes = {
  setUserToken: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  userToken: PropTypes.string.isRequired,
  order: PropTypes.shape({
    code: PropTypes.string
  }),
  setVerificationToken: PropTypes.func.isRequired,
  setVerificationAction: PropTypes.func.isRequired,
};


Login.defaultProps = {
  order: null
}


export const mapStateToProps = (state) => {
  const {  userToken  } = state.user;
  const { order } =  state;
  return {  userToken, order };
};

export const mapDispatchToProps = (dispatch) => ({
  setUserToken: (value) => dispatch(userActions.setUserToken(value)),
  setUserId: (value) => dispatch(userActions.setUserId(value)),
  setVerificationToken: value =>
    dispatch(elementsActions.setVerificationToken(value)),
  setVerificationAction: value =>
    dispatch(elementsActions.setVerificationAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
