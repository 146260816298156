// eslint-disable-next-line import/prefer-default-export

export const scriptSrc = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
export const scriptType = 'text/javascript';

export const initChat = () => {
    const chatActive = (window.environment.REACT_APP_FRONT_CHAT_ACTIVE === 'true');
    if (!chatActive) {
        return false;
    }

    window.FCSP = window.environment.REACT_APP_FRONT_CHAT_APP_ID;

    const body = document.getElementsByTagName('body');
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.type = scriptType;
    body[0].appendChild(script);

    return true;
};
