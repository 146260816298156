const initialState = {
    saveCredentials: false
};

export default(state = initialState, { type, value }) => {
    switch (type) {
        case 'SET_SAVE_CREDENTIALS':
            return {...state, saveCredentials: value};
        default:
            return state;
    }
}
