import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// DATA
import states from './states';

import styles from './Dropdown.module.css';

const dropDownIcon = require('./imgs/dropdown-icon.png');

/**
 * Returns image element with dropdown icon asset
 */
const returnDropDownIcon = () => (
  <img className={styles.dropDownIcon} alt='dropdown-icon' src={dropDownIcon} />
)

const DropdownComponent = ({ disabled, value, onChange, theme, isDate, isTime, options, customDropDownStyles, className }) => (
  <Dropdown
    onChange={onChange}
    value={value}
    disabled={disabled}
    arrowClosed={returnDropDownIcon()}
    arrowOpen={returnDropDownIcon()}
    menuClassName={`${styles.dropDownMenuContainer} dropDownOverride font-filsonProRegular  text-dark dark:text-white
    w-full border-[1px]
    border-borderLight dark:border-none bg-transparent dark:bg-darkDry placeholder-dark dark:placeholder-white`}
    placeholderClassName={[
      styles.dropDownPlaceholder,
      isDate ? styles.dropDownPlaceholderDate : null,
      isTime ? styles.dropDownPlaceholderTime : null,
      customDropDownStyles,
      'dropDownPlaceholderOverride'
    ].join(' ')}
    controlClassName={styles.dropDownControl}
    className={`
      rounded-[40px] dark:placeholder-white placeholder-dark
      bg-transparent
      ${styles.dropDownContainer}
      ${className}
      ${disabled ? styles.isDisabled : ""}
      ${theme === 'Dark' ? styles.isDark : styles.isLight} dropdownThemeOverride`}
    options={options}
  />
)

DropdownComponent.propTypes = {
  value:  PropTypes.any, // eslint-disable-line
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  customDropDownStyles: PropTypes.string, // eslint-disable-line
  theme: PropTypes.string,
  isDate: PropTypes.bool,
  isTime: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  })),
  className: PropTypes.string
};

DropdownComponent.defaultProps = {
  disabled: false,
  theme: 'Dark',
  isDate: false,
  isTime: false,
  options: states,
  className: null,
}

export default DropdownComponent;
