import React from 'react';

import PropTypes from 'prop-types';
import { defaultCurrency } from '../../shared/constants/currency';

import styles from './WidgetItem.module.css';


const WidgetItem = ({ id, amount, title, type, price, currency, unavailableProducts }) => {
  const isUnavailable = !!unavailableProducts.includes(parseInt(id, 10));
  const customContainerClasses = isUnavailable ? `${styles.widgetItemContainer} ${styles.unavailableProduct}` : styles.widgetItemContainer;
  return (
    <div className={`
      ${customContainerClasses} 
      flex items-center justify-between md:py-[17px] mb-3
      border-[1px] border-borderLight
      `}
    >
      <div className={`${styles.orderTitle} text-dark dark:text-white`}>
        <p>
          <span>{amount}</span>
          {title}
        </p>
      </div>
      <div className={`${styles.orderType} text-dark dark:text-white`}>
        {type ? <p>{type.toLowerCase().replace('_',' ')}</p> : undefined}
      </div>
      <div className={`${styles.orderPrice} text-dark dark:text-white`}>
        {price ? <p>{`${currency.symbol}${price}`}</p> : undefined}
      </div>
    </div>
  );
};

WidgetItem.propTypes = {
  amount: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  price: PropTypes.oneOfType( [
    PropTypes.number, 
    PropTypes.string
   ]),
  currency: defaultCurrency,
  id: PropTypes.number,
  unavailableProducts: PropTypes.arrayOf(PropTypes.string)
};

WidgetItem.defaultProps = {
  amount: null,
  type: null,
  price: null,
  currency: defaultCurrency,
  id: null,
  unavailableProducts: []
}


export default WidgetItem;
