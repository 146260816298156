import React, { Component } from "react";
import ReactMarkdown from 'react-markdown';
import Loader from '../../components/Loader/Loader';
import * as cms from '../../shared/repos/cms/cms';
import cmsKeys from '../../shared/constants/cms';

import styles from './AllergenAndNutritionInfo.module.css'

class AllergenAndNutritionInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: []
        };
    }

    componentDidMount() {
        cms.getContent([cmsKeys.allergenAndNutritionInfoPage.allergenStatementSections], this);
    }

    render() {
        const { content } = this.state;
        if (!content.length) {
            return (
              <div className={styles.pageWrapper}>
                <div className={styles.pageContainer}>
                  <Loader id="loader" />
                </div>
              </div>
            );
        }

        return (
          <div className={styles.pageWrapper}>
            <div className={`${styles.pageContainer} text-dark dark:text-white`}>
              {content.map(allergen => {
                    return (
                      <div>
                        <div className={styles.pageSection}>
                          <h1>{allergen.value}</h1>
                        </div>

                        {allergen.children.map(allergenStatement => {
                                return (
                                  <div className={styles.pageSection}>
                                    <p><ReactMarkdown>{allergenStatement.value}</ReactMarkdown></p>
                                  </div>
                                )
                            })}
                      </div>
                    )
                })}
            </div>
          </div>
        );
    }
}

export default AllergenAndNutritionInfo;