import * as Sentry from "@sentry/react";

/**
* @author Aghunik Babayan <aghunik@somepage.com>
* @return {void}
*/
// eslint-disable-next-line import/prefer-default-export
export const reInitAudioEye = () => {
    try{
        window.AudioEye.ClientElementApi.init();
    }catch(err){
        Sentry.captureException(err);
    }
};


