const initialState = {
    credentials: null,
    useFullyQualifiedAddressForDelivery: true,
    redirectUrl: ''
};

export default (state = initialState, { type, value }) => {
    switch (type) {
        case 'ADDRESS_UPDATED':
            return {
                ...state,
                addressComponents: value
            };
        case 'SET_USE_FULLY_QUALIFIED_ADDRESS_FOR_DELIVERY':
            return {
                ...state,
                useFullyQualifiedAddressForDelivery: value
            };
        case 'SET_REDIRECT_URL':
            return {
                ...state,
                redirectUrl: value
            };
        default:
            return state;
    }
};

