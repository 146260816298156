import React, { useState } from 'react';
import { propTypesComponentPaymentInput } from '../../shared.propTypes';
import stylesShared from '../../shared.module.css';
import styles from '../PaymentInputCreditCardLegacy/PaymentInputCreditCardLegacy.module.css';
import Button from '../../../Button/Button';
import Input from '../../../Input/Input';
import { keyDownA11y } from '../../shared';

const useCreditCardLegacyForm = (storePaymentMethod, callback) => {
  const [inputs, setInputs] = useState({
    schoolCashPin: '',
    schoolCashNumber: ''
  });

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }

    const paymentCredentialSubmitted = {
      summary: `Ending in ${inputs.schoolCashNumber
        .trimEnd()
        .substr(inputs.schoolCashNumber.trimEnd().length - 4)}`,
      storePaymentMethod,
      paymentCredential: {
        paymentMethodId: storePaymentMethod.paymentMethodId,
        credential: inputs.schoolCashNumber,
        securityCode: inputs.schoolCashPin,
        saveCredentials: false
      }
    };
    callback(paymentCredentialSubmitted);
  };

  const handleInputChange = event => {
    if (event.persist) {
      event.persist();
    }
    const newInputs = { ...inputs, [event.target.name]: event.target.value };
    setInputs(newInputs);
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs
  };
};

const PaymentInputSchoolCash = props => {
  const { storePaymentMethod, onSubmitPaymentCredentials, onCancel, hideCancel } = props;
  const { inputs, handleInputChange, handleSubmit } = useCreditCardLegacyForm(
    storePaymentMethod,
    onSubmitPaymentCredentials
  );
  const schoolCashLabel =
    storePaymentMethod.variables?.find(variable => variable.key === 'name')?.value || 'School Cash';
  const requiresPin =
    storePaymentMethod.variables?.find(variable => variable.key === 'pin')?.value || false;
  const formFilled = requiresPin
    ? inputs.schoolCashNumber.length && inputs.schoolCashPin.length
    : inputs.schoolCashNumber.length;

  return (
    <div className={styles.secondaryAddressContainer}>
      <p>Save School Cash</p>
      <span className={styles.selectionMinor}>*Indicates required fields</span>
      <Input
        name="schoolCashNumber"
        value={inputs.schoolCashNumber}
        isTextArea
        handleInput={handleInputChange}
        customContainerStyles={styles.inputContainer}
        placeholder=" "
        label={`${schoolCashLabel}#`}
      />

      {requiresPin ? (
        <Input
          name="schoolCashPin"
          isTextArea
          value={inputs.schoolCashPin}
          handleInput={handleInputChange}
          customContainerStyles={styles.inputContainer}
          placeholder=" "
          label="Pin"
        />
      ) : null}
      <div className={styles.buttonContainer}>
        <Button label="Add School Cash" disabled={!formFilled} handleSubmit={handleSubmit} />

        {!hideCancel ? (
          <div
            className={stylesShared.footerActions}
            onClick={onCancel}
            hidden={hideCancel}
            onKeyDown={keyDownA11y(onCancel)}
            role="button"
            tabIndex={0}
          >
            Change Payment Method
          </div>
        ) : null}
      </div>
    </div>
  );
};

PaymentInputSchoolCash.propTypes = propTypesComponentPaymentInput;

export default PaymentInputSchoolCash;
