/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
import React from 'react';

// COMPONENTS
import Link from '../../components/LinkWithCountry/LinkWithCountry';
import Input from '../../components/Input/Input';
import styles from './ConfirmOrder.module.css';
// eslint-disable-next-line import/named
import {orderTypeIds, recipientMessageLength} from '../../shared/constants/order';
import CheckBox from "../../components/CheckBox/CheckBox";
import Loader from "../../components/Loader/Loader";
import PhoneInputWidget from '../../components/PhoneInputWidget/PhoneInputWidget';
import messages from '../../shared/constants/messages';

const arrowLeft = require('./imgs/arrow-left.svg');
const confirmationCookie = require('./imgs/confirmation-cookie.svg');
const confirmationPrinter = require('./imgs/confirmation-printer.svg');

/* eslint-disable import/prefer-default-export */
export const renderGiftHeroLeft = (component) => {
  const { history } = component.props;
  return (
    <div className={styles.topLeft}>
      <div onClick={() => history.goBack()} className={styles.goBack}>
        <img alt="go-back" src={arrowLeft} />
        <span>Go Back</span>
      </div>
      <h1>
        We won&apos;t spoil
        <br />
        the surprise
      </h1>

      <p>
        We&apos;ll let your recipient know something sweet is coming their way.
      </p>
    </div>
  );
};

export const renderGiftHeroRight = () => (
  <div className={styles.topRight}>
    <img alt="confirmation" src={confirmationCookie} />
  </div>
);

const toggleCheckboxHandler = (component) => {
    const { alertRecipientPhone, setAlertRecipientPhoneUpdated } = component.props;
    setAlertRecipientPhoneUpdated(!alertRecipientPhone);
}

const handleGiftInput = (e, component, messageLength) => {
    component.setState({giftMessage: e.target.value})
    const {checkout, checkoutUpdated} = component.props;
    checkout.giftMessage = e.target.value;
    checkoutUpdated(checkout);
    const messageLabel = `Type a message for the recipient ( max ${messageLength} characters )`;
    const label =  component.state.giftMessage?.length > 0 ?  '' : messageLabel;
    component.setState({giftMessageLabel:label})
};

const checkRequiredFields = (component) => {
  const { checkoutErrorsUpdated, checkout } = component.props;

  if (!checkout.errors) {
    checkout.errors = {};
  }

  let isError = false;

  const requiredFields = {
    giftFirstName: 'firstname',
    giftLastName: 'lastname',
    giftPhone: 'phone',
  }

  Object.entries(requiredFields).forEach((entry) => {
    const [stateField, field] = entry;
    if (!component.state[stateField]) {
      isError = true;
      checkout.errors[`gift_${field}`] = [{message: `${field.charAt(0).toUpperCase() + field.slice(1)} is required`, selector: 'giftArea'}];
    }
  });

  if (isError) {
    checkoutErrorsUpdated(checkout.errors);
  }

  return !isError;
}

const handleSave = (component) => {
  const { checkoutUpdated, checkout } = component.props;

  if (!checkRequiredFields(component)) {
    return;
  }

  checkout.giftContact = {
    firstName: component.state.giftFirstName,
    lastName: component.state.giftLastName,
    phone: component.state.giftPhone,
    message: component.state.giftMessage || ' '
  };
  checkoutUpdated(checkout);
};

const handleGiftBlur = (component, stateVal, stateKey, e, mask = '(###) ###-####') => {
    const { checkoutUpdated, checkout } = component.props;

    const errState = `${stateKey}Err`;
    let errMessage;
    const isPhoneInput = stateKey === 'phone';
    if(isPhoneInput) {
        const maskLength = mask.match(/#/g).length;
        const rawPhone = stateVal.match(/\d+$/g);
        const phoneLength = rawPhone ? rawPhone[0].length : 0;

        if (stateVal.length <= 4) {
            errMessage = `${stateKey.charAt(0).toUpperCase() + stateKey.slice(1)} is required`;
            component.setState({
                [errState]: errMessage,
                isValidData:false
            })
        } else if(phoneLength !== maskLength) {
            errMessage = messages.phoneInput.maskValidation.replace('%s', mask);
            component.setState({
                [errState] : errMessage,
                isValidData: false
            })
        } else if (!/^\+\d+/.test(stateVal)) {
          errMessage = `Please select phone country code`;
            component.setState({
                [errState] : errMessage,
                isValidData: false
            })
        } else if (e.additionalValidations!==true && e.additionalValidations.length > 0) {
            errMessage = e.additionalValidations;
            component.setState({
              [errState] : errMessage,
              isValidData: false
          })
        } else {
            component.setState({
                [errState] : ``,
                isValidData: true
            })
        }
    } else if (!stateVal) {
        errMessage = `${stateKey.charAt(0).toUpperCase() + stateKey.slice(1)} is required`;
        component.setState({
            [errState]: errMessage,
            isValidData:false
        })
    } else {
        component.setState({
            [errState] : ``,
            isValidData: true
        })
    }

    if (!checkout.errors) {
      checkout.errors = {};
    }

    if (errMessage) {
      checkout.errors[`gift_${stateKey}`] = [{message: errMessage, selector: 'giftArea'}];
      checkoutUpdated(checkout);
    } else {
      delete checkout.errors[`gift_${stateKey}`];
      handleSave(component);
    }
};

const handleRecipentFirstName = (e, component) => {
  const {value} = e.target;
  const {checkout, checkoutUpdated} = component.props;
  checkout.giftFirstName = value;
  component.setState({ giftFirstName: value });
  checkoutUpdated(checkout);
}

const handleRecipentLastName = (e, component) => {
  const {value} = e.target;
  const {checkout, checkoutUpdated} = component.props;
  checkout.giftLastName = value;
  component.setState({ giftLastName: value });
  checkoutUpdated(checkout);
}

const handleRecipentPhone = (e, component) => {
  const {checkout, checkoutUpdated} = component.props;
  checkout.giftPhone = e.telephone;
  component.setState({ giftPhone: e.telephone });
  checkoutUpdated(checkout);
}

export const renderGiftBottom = (component, contactInfoContainer = null, values={}) => {
  const messageMaxLength = component.props.order && component.props.order.orderTypeId === orderTypeIds.shipping ? recipientMessageLength.shippingOrder : recipientMessageLength.anotherOrder;

  const isDelivery = (component.props.order?.orderTypeId === orderTypeIds.delivery);
  const isRequired = !component.state.edit;
  const { loading } = component.state;

  return (
    <div className={contactInfoContainer || styles.detailsContainer}>
      {loading ? (<div className={styles.createContactLoaderWrapper}><Loader /></div>) : null}
      <p className={styles.sectionHeader}>Recipient Contact Info</p>
      {isRequired ? <span className={styles.requiredContainer}> *Indicates required fields </span> : null}
      <Input
        customContainerStyles={[
          styles.inputWrapper,
          styles.inputWrapperDark
        ].join(' ')}
        value={component.props.checkout.giftFirstName || component.state.giftFirstName || values.giftFirstName}
        handleInput={(e) => handleRecipentFirstName(e, component)}
        placeholder=" "
        isRequired={isRequired}
        label="Recipient First Name"
        handleOnBlur={()=>handleGiftBlur(component,values.giftFirstName ?? component.state.giftFirstName, 'firstname')}
      />
      <p className={styles.errorContainer}>{component.state.firstnameErr}</p>

      <Input
        customContainerStyles={[
          styles.inputWrapper,
          styles.inputWrapperDark
        ].join(' ')}
        value={component.props.checkout.giftLastName || component.state.giftLastName}
        handleInput={(e) => handleRecipentLastName(e, component)}
        handleOnBlur={() => handleGiftBlur(component, component.state.giftLastName, 'lastname')}
        placeholder=" "
        label="Recipient Last Name"
        isRequired={isRequired}
      />
      <p className={styles.errorContainer}>{component.state.lastnameErr}</p>

      <PhoneInputWidget
        onInput={e => handleRecipentPhone(e, component)}
        handleOnBlur={(e) => handleGiftBlur(component, component.state.giftPhone, 'phone', e, e.mask)}
        label='Recipient Phone'
        placeholder=" "
        telephone={component.state.giftPhone}
        isNumberFormat
        isRequired={isRequired}
        className={styles.phoneInputContainer}
        customSelectStyles={styles.phoneSelectStyle}
        customInputStyles={[
          styles.inputWrapper,
          styles.inputWrapperDark,
          styles.phoneInputStyles
        ].join(' ')}
      />
      <p className={styles.errorContainer}>{component.state.phoneErr}</p>

      {
        (isDelivery) ? (
          <CheckBox
            onClick={() => toggleCheckboxHandler(component)}
            isChecked={component.props.alertRecipientPhone}
            label={
              component.props.orderStatusSubscription
              ? component.props.orderStatusSubscription.description
              : "Text order status updates"
            }
          />
        ) : null
      }
      {
        (isDelivery) ?
        (
          <p className={styles.detailsNote}>
            With the recipient&#x27;s cell number, we can confirm the most convenient
            time for them to receive warm, delicious cookies.
          </p>
 ) : null
      }


      <div className={styles.secondaryContainer}>
        <p className={styles.sectionHeader}>Add a Message</p>
        <Input
          customContainerStyles={[
                styles.inputWrapper,
                styles.giftMessageWrapper
            ].join(' ')}
          value={component.state.giftMessage}
          handleInput={(e) => handleGiftInput(e,component,messageMaxLength)}
          handleOnBlur={() => handleGiftBlur(component, component.state.giftMessage, 'message')}
          placeholder=" "
          label={component.state.giftMessageLabel}
          maxLength={messageMaxLength}
        />
      </div>
    </div>
  );
};

export const renderConfirmationHeroRight = () => {
  return (
    <div className={styles.topRightConfirmation}>
      <img alt="confirmation" src={confirmationPrinter} />
    </div>
  );
};

export const renderLoggedInConfirmation = component => {
  if (!component.props.order) {
    return null;
  }

  return (
    <div className={`${styles.loggedInBottomContainer} text-dark dark:text-white mt-10`}>
      <h2>Your points are on their way!<br/>Your points balance will be updated within 24 hours.</h2>
      <p>Check out the Rewards page tomorrow to see how close you are to earning your next reward. Stay tuned!</p>
      <Link to="/user/profile">View Profile</Link>
    </div>
  );
};

