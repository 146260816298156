import React, { Component } from 'react';

import PropTypes from 'prop-types';

import * as userRepo from '../../shared/repos/graphql/user';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

import { handleFormInput } from '../../shared/utilities/validations';

import styles from './ChangePassword.module.css';
import { routeCountryPath } from '../../shared/utilities/common';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newPassword: null,
            confirmPassword: null,
            errorMessage: null
        };
    }

    async handleSubmit(key) {

        const { history } = this.props;
        const { newPassword, confirmPassword } = this.state;

        this.setState({
            loading: true
        });

        try {
            await userRepo.changePassword(key, newPassword, confirmPassword);

            history.push(routeCountryPath('/login'));
        } catch (exception) {

            const { extensions } = exception.graphQLErrors[0];
            const { validation } = extensions;

            const errorMessage = validation[Object.keys(validation)[0]][0];

            this.setState({
                errorMessage,
                loading: false
            });
        }
    }

    renderForm = () => {
        const { errorMessage, newPassword, confirmPassword, loading } = this.state;

        const { match } = this.props;
        const { params } = match;
        const { key } = params;

        return (
          <div className='my-10'>
            <Input
              handleInput={(e) => handleFormInput(e, this, 'newPassword')}
              placeholder=" "
              type="password"
              label="New Password"
              isRequired
              value={newPassword}
              errorMessage={errorMessage}
            />

            <Input
              handleInput={(e) => handleFormInput(e, this, 'confirmPassword')}
              placeholder=" "
              type="password"
              label="Confirm Password"
              isRequired
              value={confirmPassword}
            />

            <Button
              id="changePassSubmit"
              isLoading={loading}
              handleSubmit={() => this.handleSubmit(key)}
              label="Change Password"
              customContainerStyles={styles.buttonContainer}
            />
          </div>
        );
    }

    render() {

        return (
          <div className={styles.pageWrapper}>
            <div className={styles.pageContainer}>
              <h1 className={styles.isLight}>
                    Change your
                <br />
                    password
              </h1>

              <p>* Indicates required fields </p>

              {this.renderForm()}
            </div>
          </div>
        );
    }
}

ResetPassword.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            key: PropTypes.string.isRequired
        })
    }).isRequired
};

export default ResetPassword;
