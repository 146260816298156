import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from '../ReviewOrder.module.css';
import CheckBox from '../../../components/CheckBox/CheckBox';
import {renderGiftBottom} from "../../ConfirmOrder/rendering";
import * as checkoutActions from "../../../redux/actions/checkout";
import * as paymentActions from "../../../redux/actions/payment";
import { routeCountryPath } from '../../../shared/utilities/common';
import * as userActions from "../../../redux/actions/user";

class GiftRecipient extends Component {
    constructor(props) {    
        super(props);    

        const { checkout } = props;

        this.state = {
            isGift: false,
            openForm: false,
        };

        if (checkout && checkout.giftContact) {
            this.state.giftFirstName = checkout.giftContact.firstName;
            this.state.giftLastName = checkout.giftContact.lastName;
            this.state.giftPhone = checkout.giftContact.phone;
            this.state.giftMessage = checkout.giftContact.message;
        }
    }

    goToForm = () => {
        const { history, order } = this.props;

        history.push({
            pathname: routeCountryPath(`/checkout/gift/${order.code}`),
            state: {
                recipient: order.recipient,
                message: order.comment
            }
        });
    }

    toggleCheckbox = () => {
        const { isGift } = this.state;
        const { checkout, checkoutUpdated } = this.props;

        this.setState({
            isGift: !isGift
        });

        checkout.isGift = !isGift;
        checkoutUpdated(checkout);
    }

    renderContactOptions = () => {
        const values = {...this.state}
        return (
          <div className={styles.secondaryAddressContainer}>
            {renderGiftBottom(this, styles.contactInfoContainer, values)}
          </div>
        );
    };

    onExpand = (expand) => {
        const { checkout, checkoutUpdated } = this.props;
        this.setState({ openForm: expand, isGift: expand });

        checkout.isGift = expand;
        checkoutUpdated(checkout);
    }

    render() {
        const { checkout } = this.props;

        return (
          <div>
            <div className={[styles.giftCheckboxWrapper, 'giftArea'].join(' ')}>
              <CheckBox
                onClick={() => this.toggleCheckbox()}
                isChecked={checkout.isGift}
                label="This is a gift for someone else"
              />
            </div>

            {checkout.isGift ? (this.renderContactOptions()) : null}
          </div>
        );
    }
}

GiftRecipient.defaultProps = {
    checkout: {},
    orderStatusSubscription: null,
};

GiftRecipient.propTypes = {
    order: PropTypes.shape({
        code: PropTypes.string.isRequired,
        comment: PropTypes.string,
        recipient: PropTypes.shape({
            firstName: PropTypes.string,
            phone: PropTypes.string
        })
    }).isRequired,
    checkout: PropTypes.shape({
        tip: PropTypes.number,
        giftContact: PropTypes.shape(),
        isGift: PropTypes.bool
    }),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    orderStatusSubscription: PropTypes.shape(),

    // eslint-disable-next-line react/no-unused-prop-types
    checkoutUpdated: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    checkoutErrorsUpdated: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    orderUpdating: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    setAlertRecipientPhoneUpdated: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
    return {
        order: state.order,
        checkout: state.checkout,
        alertRecipientPhone: state.user.alertRecipientPhone
    };
};

export const mapDispatchToProps = (dispatch) => ({
    checkoutUpdated: (value) => dispatch(checkoutActions.checkoutUpdated(value)),
    checkoutErrorsUpdated: (value) => dispatch(checkoutActions.checkoutErrorsUpdated(value)),
    orderUpdating: (value) => dispatch(paymentActions.orderUpdating(value)),
    setAlertRecipientPhoneUpdated: (value) => dispatch(userActions.setAlertRecipientPhone(value)),
    setSaveCheckoutDetails: (value) => dispatch(checkoutActions.setSaveCheckoutDetails(value))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GiftRecipient));

