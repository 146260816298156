import React, { Component } from 'react';
import * as wooboxSDK from '../../shared/utilities/wooboxSDK'

class CouponsAndDeals extends Component {
    constructor(props) {
        super(props);
        wooboxSDK.initWooBox();
    }

    componentDidMount() {
        wooboxSDK.reInitWooBox();
    }


    render() {
        return (
          <div className="woobox-offer" data-offer="e7jk3q" data-trigger="enter" />
        );
    }
}


export default CouponsAndDeals;
