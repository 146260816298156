import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import moment from "moment";
import {withRouter} from "react-router-dom";
import styles from './RibbonBanner.module.css';
import {ContentCardBase} from "../ContentCards/Carousel/Templates/ContentCardBase";
import {filterContentCards} from "../../shared/utilities/braze";
import * as contentCardConstants from "../../shared/constants/contentCards";
import CountdownTimer from '../CountDownTimer/CountdownTimer';
import {useCountdown} from '../CountDownTimer/hooks/useCountdown';
import * as elementsActions from "../../redux/actions/elements";
import elementConstant from "../../shared/constants/element";
import * as brazeActions from '../../redux/actions/braze';
import MOBILE_SIZE from "../../shared/constants/size";

class RibbonBanner extends ContentCardBase {

  constructor(props) {
    super(props);
      this.state = {
        cardData: [],
      };
    }

    componentDidMount() {
      this.getContentCards();
    }

    componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        this.getContentCards();
      }
    }

    getContentCards = () => {
      const {selectedStore, contentCards} = this.props;
      const storeId = selectedStore?.id !== undefined ? parseInt(selectedStore?.id, 10) : null;
      if (contentCards === null) {
        return;
      }
      const templates = [contentCardConstants.RIBBON_PROMO_TEMPLATE]
      const filteredCards = filterContentCards(
        contentCards,
        templates,
        storeId
      );

      const cardData = filteredCards?.length > 0 ? filteredCards.slice(0, 1)[0] : [];
      const currentState = this.state;
      this.setState({
        ...currentState,
        cardData,
       });
    }

    ribbonPromoContentCardTemplate = () => {
      // eslint-disable-next-line no-shadow
      const {topHeaderOffsetDivHeight, setTopHeaderOffsetDivHeight, location} = this.props;
      const {cardData} = this.state;
      if (cardData.length === 0) {
        setTopHeaderOffsetDivHeight(elementConstant.topHeaderOffsetDivHeightDefault);
        return null;
      }

      const {extras, expiresAt} = cardData;
      const {pathname} = location;
      const excludePages = extras.excludePages || "";
      const hasPageExcluded = excludePages.split(',').filter(item => item !== '' && pathname.indexOf(item) !== -1).length !== 0;
      if (hasPageExcluded) {
        setTopHeaderOffsetDivHeight(elementConstant.topHeaderOffsetDivHeightDefault);
        return null;
      }

      const targetDate = moment(expiresAt).format();
      const [days, hours, minutes, seconds] = useCountdown(targetDate);
      const totalLeft = days + hours + minutes + seconds;
      if (totalLeft <= 0) {
        setTopHeaderOffsetDivHeight(elementConstant.topHeaderOffsetDivHeightDefault);
        return null;
      }

      if (topHeaderOffsetDivHeight === elementConstant.topHeaderOffsetDivHeightDefault) {
        setTopHeaderOffsetDivHeight(elementConstant.topHeaderOffsetDivHeight);
      }

      const timerContent = parseInt(extras.hasTimer, 10) === 1 ? (
        <span style={{fontFamily: extras.timerFont}}>
          ONLY
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="timer" style={{color: extras.timerTextColor}}>
            <CountdownTimer targetDate={targetDate} showSeconds />
          </label>
            REMAINING
        </span>
      ) : null;

        return (
          <div
            className={`${styles.ribbonHeader} h-9 lg:h-10`}
            style={{backgroundColor: extras.mainBackgroundColor}}
            onClick={() => this.handleClick(cardData)}
            aria-hidden="true"
          >
            <div className={styles.row}>
              <p>
                <span className='text-xs font-semibold' style={{color: extras.mainTextColor,fontFamily: extras.mainTextFont}}>
                  {extras.mainText}
                </span>
                <label htmlFor="content-card">
                  <span>
                    <label htmlFor="promo-code" style={{ color: extras.promoCodeTextColor, fontFamily: extras.promoCodeFont, cursor: 'pointer' }}>
                      {extras.promoCode}
                    </label>
                  </span>
                  {timerContent}
                </label>
              </p>
            </div>
          </div>
        );
    }

    render() {
      return (
        <this.ribbonPromoContentCardTemplate />
      )
    }

    /**
     * Handles deeplink click to set scroll on product category section
     * @param {String} anchor - selected product string
     */
    handleDeepLink = (anchor) => {
      const { setProductCategory } = this.props;
      setProductCategory(anchor);
      const element = document.getElementById(anchor);
      let scrollDelta = this.categoryElement.current ? this.categoryElement.current.getBoundingClientRect().height : elementConstant.scrollDeltaDefaultHeight;
      scrollDelta = window.innerWidth > MOBILE_SIZE ? scrollDelta : elementConstant.scrollDeltaDefault;
      const yPos = element ? (element.offsetTop - scrollDelta) : null;
      if (yPos) window.scrollTo({ top: yPos, left: 0, behavior: 'smooth'}); // eslint-disable-line
    }
}

RibbonBanner.propTypes = {
  cardData: PropTypes.shape(
    {
      extras: PropTypes.shape(
        {
          promoCodeFont: PropTypes.string,
          timerFont: PropTypes.string,
          mainTextFont: PropTypes.string,
          mainBackgroundColor: PropTypes.string,
          template: PropTypes.string,
          promoCodeTextColor: PropTypes.string,
          timerTextColor: PropTypes.string,
          hasTimer: PropTypes.bool,
          promoCode: PropTypes.string,
          mainText: PropTypes.string,
          sort: PropTypes.number,
          mainTextColor: PropTypes.string,
        }
      )
    }
  ).isRequired,
}

export const mapStateToProps = (state) => {
  const {
    selectedStore
  } = state.user;

  const {
    contentCards
  } = state.braze;

  const {
    topHeaderOffsetDivHeight
  } = state.elements;

    return {
      contentCards,
      selectedStore,
      topHeaderOffsetDivHeight
    };
}

export const mapDispatchToProps = (dispatch) => ({
  setTopHeaderOffsetDivHeight: (value) => dispatch(elementsActions.setTopHeaderOffsetDivHeight(value)),
  contentCardSession: (value) => dispatch(brazeActions.contentCardSession(value)),
  setProductCategory: (value) => dispatch(elementsActions.setProductCategory(value)),
  setCategoriesPosition: (value) => dispatch(elementsActions.setCategoriesPosition(value))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RibbonBanner));
