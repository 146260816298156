/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { defaultCurrency } from "../../shared/constants/currency";
// REDUX
import * as elementsActions from "../../redux/actions/elements";
import styles from "./CheckoutButton.module.css";
import { getTotalPrice } from "../../shared/utilities/renderings";

const cartImage = require("./imgs/cart-icon-light.png");

export class CheckoutButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  render() {
    const { label, onClick, currency, order, userCart } = this.props;
    
    const hasRedeemables = order && order?.orderRedeemables?.length > 0;
    let redeemables = [];
    
    if (hasRedeemables) {
      redeemables = order?.orderRedeemables?.map((item) => {
        return item.order_product_id
      })
    }

    const cartItem = userCart.map(item => {
      return {
        quantity: item.quantity,
        price: redeemables.includes(item.id) ? 0 : getTotalPrice(item) * item.quantity
      };
    });

    const cartQuantity = cartItem
      .map(item => item.quantity)
      .reduce((a, b) => a + b, 0);

    const cartPrice = cartItem
      .map(item => item.price)
      .reduce((a, b) => a + b, 0);

    const orderPrice = `${currency.symbol}${cartPrice.toFixed(2)}`;

    return (
      <div
        className={`${styles.checkoutCartWrapper} flex items-center w-full bg-button text-white rounded-[30px]`}
        onClick={onClick}
      >
        <div className={`${styles.checkoutCartButton} flex items-center justify-between px-4 mx-auto`}>
          <p className={styles.checkoutCartLabel}>{label}</p>
          
          <div className={`${styles.quantityContainer} flex justify-between items-center`}>
            <div className={`${styles.quantityLabelContainer} relative`}>
              <img alt="quantity" src={cartImage} />
              <span className="text-dark">{cartQuantity}</span>
            </div>
            <p>{`${orderPrice}`}</p>
          </div>
        </div>
      </div>
    );
  }
}

CheckoutButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  currency: PropTypes.objectOf(PropTypes.string),
  order: PropTypes.shape({}),
  userCart: PropTypes.shape([{}]),
};

CheckoutButton.defaultProps = {
  currency: defaultCurrency,
  order: null,
  userCart: null
};

export const mapStateToProps = state => {
  const { selectedStore, userAddress, userOrderMethod, userCart } = state.user;
  const { currency } = state.currency;
  const { order } = state;
  return { selectedStore, userAddress, userOrderMethod, currency, order, userCart };
};

export const mapDispatchToProps = dispatch => ({
  setModalObject: value => dispatch(elementsActions.setModalObject(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutButton);
