/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OrderExperience from '../../../components/OrderExperience/OrderExperience';
import { orderTypeIds, timeDefaults } from '../../../shared/constants/order';

import styles from '../ConfirmOrder.module.css';
import { getTimeRangeString, normalizeDate } from '../../../shared/utilities/common';

class HeroLeft extends Component {

  eta = () => {
    const { order } = this.props;

    const isEtaShouldBeHidden = !order || !order.status || order.status.status >= 5;
    if (isEtaShouldBeHidden) {
      return null;
    }

    let deliveryTimeString = timeDefaults.unknown;

    if (order.deliveryStatus?.estimatedPickupTime) {
      deliveryTimeString = getTimeRangeString(order.deliveryStatus.estimatedPickupTime, order.deliveryStatus?.estimatedPickupTimeMax);
    } else if (order.deliveryStatus?.estimatedDropoffTime) {
      deliveryTimeString = getTimeRangeString(order.deliveryStatus.estimatedDropoffTime, order.deliveryStatus?.estimatedDropoffTimeMax);
    } else if (order.deliveryTime === timeDefaults.asap) {
      deliveryTimeString = timeDefaults.asapText;
    } else {
      deliveryTimeString = normalizeDate(moment(order.deliveryTime));
    }

    if (deliveryTimeString === timeDefaults.asapText) {
      return `will be ready ${deliveryTimeString}`;
    }

    return deliveryTimeString === timeDefaults.invalidDate
      ? ''
      : `will be ready by ${deliveryTimeString}`;
  }

  shippingAddress = () => {
    const { order } = this.props;

    if (!order) {
      return null;
    };

    let shippingAddress = order.shippingAddress.address1;

    if (order.shippingAddress2) {
      shippingAddress += ` ${order.shippingAddress2}`;
    }

    return shippingAddress;
  }

  render() {
    const { order } = this.props;

    const isShipping = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.shipping : false;
    const isDelivery = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.delivery : false;
    const isPickUp = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.pickup : false;
    const shippingAddress = this.shippingAddress();
    const eta = this.eta();

    const renderOrderId = () => {
      if (!order) {
        return null;
      }

      return (
        <p id={styles.orderConfirmation}>
          Order #
          {order.id}
        </p>
      );
    };

    return (
      <div data-ddog-id="order_confirmation" className={`${styles.topLeftConfirmation} md:pt-24`}>
        {renderOrderId()}

        <h1> Your order has been confirmed </h1>

        {isShipping ? (
          <p>
            Shipping
            {' '}
            to
            {' '}
            {shippingAddress}
            .
          </p>
        ) : null}

        {isDelivery ? (
          <p>
            Delivery to
            {' '}
            {order.shippingAddress.address1}
            {' '}
            {eta}
          </p>
        ) : null}

        {isPickUp ? (
          <p>
            Order from
            {' '}
            {order.store.address}
            {' '}
            {eta}
          </p>
        ) : null}

        <OrderExperience />
      </div>
    );

  };
}

HeroLeft.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  order: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    shipment: PropTypes.shape({
      estimatedDeliveryTime: PropTypes.string
    }),
    trackingID: PropTypes.string.isRequired,
    shippingAddress2: PropTypes.string,
    orderTypeId: PropTypes.number.isRequired,
    deliveryTime: PropTypes.string.isRequired,
    status: PropTypes.shape({
      status: PropTypes.number.isRequired
    }),
    deliveryStatus: PropTypes.shape({
      status: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      lon: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      estimatedPickupTime: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      estimatedDropoffTime: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      estimatedDropoffTimeMax: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      estimatedPickupTimeMax: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    }),
    store: PropTypes.shape({
      address: PropTypes.string.isRequired
    }).isRequired,
    shippingAddress: PropTypes.shape({
      address1: PropTypes.string.isRequired
    })
  }).isRequired
};

export const mapStateToProps = state => {
  const { order } = state;

  return {
    order
  };
};

export default withRouter(connect(mapStateToProps)(HeroLeft));
