import React, { Component } from "react";
import PropTypes from "prop-types";

import * as presetsRepo from "../../../shared/repos/graphql/presets";

class PresetBoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presets: [],
      selected: null
    };

    this.presetBoxRef = React.createRef(); // create self reference
  }

  async componentDidMount() {
    const { productId, storeId } = this.props;
    const presets = await presetsRepo.getPresets(+productId,+storeId);
    this.setState({
      presets: presets?.data?.presets
    });
    const el = this.presetBoxRef.current;
    if (el) {
      el.addEventListener("wheel", this.onScroll);
    }
  }

  componentWillUnmount() {
    const el = this.presetBoxRef.current;
    if (el) {
      el.removeEventListener("wheel", this.onScroll);
    }
  }

  onScroll = e => {
    const el = this.presetBoxRef.current;
    if (e.deltaY === 0) return;
    e.preventDefault();
    el.scrollTo({
      left: el.scrollLeft + e.deltaY,
      behavior: "smooth"
    });
  };

  onSelect = preset => {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(preset);
    }

    this.setState({
      selected: preset.id
    });
  };

  onClear = (e) => {
    e.preventDefault()
    const { onClear } = this.props;
    onClear();
    this.setState({
      selected: null
    });
  };

  render() {
    const { presets, selected } = this.state;

    if (presets.length <= 0) {
      return null;
    }

    return (
      <div>
        <div className="flex justify-between items-center mt-2">
          <h1 className="text-dark dark:text-white font-filsonProRegular font-bold text-lg leading-[22px] tracking-[-0.1px]">
            Start with one of our most popular assortments
          </h1>
          <div
            role="button"
            tabIndex={0}
            onClick={this.onClear}
            onKeyPress={this.onClear}
            className="text-[#983992] font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] cursor-pointer"
          >
            clear selections
          </div>
        </div>
        <section
          className="no-scrollbar flex flex-nowrap gap-2 overflow-x-scroll max-w-max snap-x mt-2"
          ref={this.presetBoxRef}
        >
          {presets.map(preset => {
            const active =
              selected === preset.id ? "bg-light dark:bg-dark" : "";
            return (
              <div
                className={`flex-none w-[171px] h-[139px] snap-always snap-center 
                 text-dark dark:text-white
                hover:bg-light
                dark:hover:bg-dark
                cursor-pointer border-[1px] ${active} rounded-xl px-1 py-2 border-[#E6DFE8]`}
              >
                <div
                  className="flex flex-col gap-1 items-center justify-center"
                  onClick={() => this.onSelect(preset)}
                  onKeyPress={() => this.onSelect(preset)}
                  role="button"
                  tabIndex={preset.id}
                >
                  <h2
                    className="text-dark dark:text-white leading-5 font-bold
              font-filsonProRegular"
                  >
                    {preset.title}
                  </h2>
                  <div className="h-[67px]">
                    {preset.image && (
                      <img
                        src={preset.image}
                        alt={preset.title}
                        className="h-[67px] object-contain"
                      />
                    )}
                  </div>
                  <p className="text-border dark:text-[#FF70A8] text-sm leading-4 font-filsonProRegular cursor-pointer font-bold">
                    {selected === preset.id ? "Selected" : "Select"}
                  </p>
                </div>
              </div>
            );
          })}
        </section>
      </div>
    );
  }
}

PresetBoxes.propTypes = {
  productId: PropTypes.number.isRequired,
  storeId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired
};

export default PresetBoxes;
