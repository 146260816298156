import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import DropDown from "../Dropdown/Dropdown";
import styles from "./PhoneInputWidget.module.css";
import Input from "../Input/Input";
import { handleFormInput } from "../../shared/utilities/validations";
import { phoneNumberFormatValidation } from "../../shared/utilities/common";
import * as countryRepo from "../../shared/repos/graphql/country";
import * as phoneService from "../../shared/utilities/phone";
// REDUX
import * as countryActions from '../../redux/actions/country';
import * as checkoutActions from '../../redux/actions/checkout';

const defaultMask = "(###) ###-####";

class PhoneInputWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneCodes: [],
      selectOptions: [],
      phoneCode: "",
      phonePhone: "",
      telephone: "",
      currentPhoneMask: defaultMask,
      currentValidator: [],
    };
  }

  componentDidMount = () => {
    this.getPhoneCodes().then(() => {
      const {code, phone, telephone} = this.props;
      const {phoneCodes} = this.state;
      if (code || phone) {
        this.setUpPhoneData(code, phone);
      } else if (telephone) {
        this.setupTelephone(telephone);
      } else if (phoneCodes.length > 0) {
        this.setupDefaultPhoneCode();
      }
    });
  };

  setUpPhoneData = (code, phone) => {
    const {phoneCodes} = this.state;
    const mask = this.castMaskFormat(
      phoneService.detectMask(code, phoneCodes)
    );
    const validator = phoneService.detectValidator(code, phoneCodes);
    this.setState({
      phoneCode: code,
      phonePhone: phone,
      currentPhoneMask: mask,
      currentValidator: validator,
    });
  }

  setupDefaultPhoneCode = () => {
    const {phoneCodes} = this.state;
    const {selectedCountry, isCountrySelectActive} = this.props;
    if (phoneCodes.length > 0) {
      let isPhoneCodeFound = false;
      if (isCountrySelectActive && selectedCountry && selectedCountry.phone_prefix_id) {
        const phoneCode = _.find(phoneCodes, { id: selectedCountry.phone_prefix_id.toString() });
        if (phoneCode) {
          const mask = this.castMaskFormat(phoneCode.mask);
          this.setState({
            currentPhoneMask: mask,
            phoneCode: phoneCode.code,
            currentValidator: phoneCode?.validators,
          });
          isPhoneCodeFound = true;
        }
      }
      if (isPhoneCodeFound === false) {
        const mask = this.castMaskFormat(phoneCodes[0].mask);
        const validator = phoneCodes[0]?.validators;
        this.setState({
          currentPhoneMask: mask,
          phoneCode: phoneCodes[0].code,
          currentValidator: validator,
        });
      }
    }
  }

  setupTelephone = (phone) => {
    const rawPhone = phoneService.detectPhone(phone);
    const code = phoneService.detectCountryCode(phone);
    const {phoneCodes} = this.state;
    let mask = defaultMask;
    let validator = [];
    if (code) {
      mask = this.castMaskFormat(
        phoneService.detectMask(code, phoneCodes)
      );
      validator = phoneService.detectValidator(code, phoneCodes);
    }
    this.setState({
      telephone: phone,
      phoneCode: code,
      phonePhone: rawPhone,
      currentPhoneMask: mask,
      currentValidator: validator,
    });
  };

  getPhoneCodes = () => {
    const {setPhonePrefixes, phonePrefixes} = this.props;
    const promise = new Promise((resolve) => {
      if (phonePrefixes.length > 0 && phonePrefixes[0].validators) {
        this.updatePhonePrefixes(phonePrefixes, resolve);
      } else {
        countryRepo.getPhonePrefixes().then((res) => {
          setPhonePrefixes(res.data.phonePrefixes);
          this.updatePhonePrefixes(res.data.phonePrefixes, resolve);
        });
      }
    });

    return promise;
  };

  updatePhonePrefixes = (prefixes, resolve) => {
    const options = _.map(prefixes, (optionCode) => {
      return {
        value: optionCode.code,
        label: `${optionCode.code}`,
      };
    });
    this.setState({
      selectOptions: options,
      phoneCodes: prefixes,
    }, () => {
      const {phoneCodes} = this.state;
      resolve(phoneCodes);
    });
  }

  validateMask = (mask, phone) => {
    const maskLength = mask.split("#").length - 1;
    return phone.length === maskLength;
  }

  additionalValidations = (validators, phone) => {
    let success = [];
    if (validators?.length) {
      success = _.map(validators, (validator) => {
        switch (validator.type) {
          case 'regex':
            return this.checkRegExp(validator, phone);
          default:
            return true;
        }
      });
    }
    success = _.filter(success, (suc) => {
      return suc !== true;
    })
    return (success.length > 0) ? success[0] : true;
  }

  checkRegExp = (validator, phone) => {
    const pattern = new RegExp(validator.rule);
    return pattern.test(phone) ? true : validator.error
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {phonePhone, phoneCode, telephone, currentPhoneMask, currentValidator} = this.state;
    const {onInput} = this.props;
    if (
      prevState.phonePhone !== phonePhone ||
      prevState.phoneCode !== phoneCode
    ) {
      this.updatePhoneNumber(phoneCode, phonePhone);
    }
    if (prevState.telephone !== telephone) {
      const phone = phonePhone.replace(/\D/g, "");
      onInput({
        telephone: (phone && phone.trim() !== '') ? telephone : '',
        phone,
        code: phoneCode,
        mask: currentPhoneMask,
        validator: currentValidator,
        maskValidation: this.validateMask(currentPhoneMask, phonePhone.replace(/\D/g, "")),
        additionalValidations: this.additionalValidations(currentValidator, phone),
      });
    }
    this.onPropsTelephoneUpdate(prevProps.telephone);
  };

  onPropsTelephoneUpdate = (prevPhone) => {
    const {telephone} = this.props;
    if (prevPhone && prevPhone !== telephone) {
      const prefix = phoneService.detectCountryCode(telephone);
      const postfix = phoneService.detectPhone(telephone);
      if (prefix) {
        this.setState({
          phonePhone: postfix,
          phoneCode: prefix,
        });
      } else {
        this.setState({
          phonePhone: postfix,
        });
      }
    }
  }

  updatePhoneNumber = (prefix, phone) => {
    this.setState({
      telephone: prefix ? `${prefix} ${phone.replace(/\D/g, "")}` : `${phone.replace(/\D/g, "")}`,
    });
  };

  dropDownChange = (e) => {
    const {phoneCodes, telephone, phoneCode, phonePhone, currentPhoneMask} = this.state;
    const {handleOnBlur, checkoutUpdated, checkout} = this.props;
    const mask = this.castMaskFormat(
      phoneService.detectMask(e.value, phoneCodes)
    );
    const validator = phoneService.detectValidator(e.value, phoneCodes);

    checkout.phoneCode = e.value;
    checkoutUpdated(checkout);

    this.setState({
      phoneCode: e.value,
      currentPhoneMask: mask,
      currentValidator: validator,
    }, () => {
      const phone = phonePhone.replace(/\D/g, "");
      handleOnBlur({
        telephone: (phone && phone.trim() !== '') ? telephone : '',
        phone,
        code: phoneCode,
        validator,
        mask: currentPhoneMask,
        maskValidation: this.validateMask(currentPhoneMask, phonePhone.replace(/\D/g, "")),
        additionalValidations: this.additionalValidations(validator, phone),
      })
    });
  };

  castMaskFormat = (mask) => {
    return mask ? mask.replace(/\d/g, "#") : defaultMask;
  };

  handleOnBlur = () => {
    const {handleOnBlur} = this.props;
    const {telephone, phoneCode, phonePhone, currentPhoneMask, currentValidator} = this.state;
    const phone = phonePhone.replace(/\D/g, "");
    handleOnBlur({
      telephone: (phone && phone.trim() !== '') ? telephone : '',
      phone,
      code: phoneCode,
      mask: currentPhoneMask,
      validator: currentValidator,
      maskValidation: this.validateMask(currentPhoneMask, phonePhone.replace(/\D/g, "")),
      additionalValidations: this.additionalValidations(currentValidator, phone),
    });
  }

  phoneInputWidgetContainer = () => {
    const { phonePhone, currentPhoneMask, selectOptions, phoneCode, currentValidator } = this.state;
    const { className, customSelectStyles, label, customInputStyles, customInputContainerStyles, errorMessage, isEdit, isRequired, checkout, disabled } = this.props;

    return (
      <div className={[styles.dropDownRow, className].join(" ")}>
        <DropDown
          disabled={disabled}
          onChange={(e) => this.dropDownChange(e)}
          options={selectOptions}
          value={checkout.phoneCode || phoneCode}
          isRequired={isRequired}
          theme={!isEdit ? 'Dark' : 'Light'}
          customDropDownStyles={[styles.dropDownPlaceholder].join(" ")}
          className={[styles.selectStyle, customSelectStyles, disabled ? "isDisabled" : ""].join(
            " "
          )}
        />
        <Input
          disabled={disabled}
          customInputContainerStyles={customInputContainerStyles}
          customContainerStyles={[
            styles.inputStyle,
            styles.customInputWrapperLight,
            customInputStyles,
          ].join(" ")}
          handleInput={(e) => handleFormInput(e, this, "phonePhone")}
          label={label}
          handleOnBlur={(e) => this.handleOnBlur(e)}
          placeholder=" "
          theme={!isEdit ? 'Dark' : 'Light'}
          value={phonePhone}
          inputMask={currentPhoneMask}
          validator={currentValidator}
          isNumberFormat
          isAllowed={phoneNumberFormatValidation}
          errorMessage={errorMessage}
          isRequired={isRequired}
        />
      </div>
    );
  };

  render = () => {
    return <this.phoneInputWidgetContainer />;
  };
}
PhoneInputWidget.defaultProps = {
  telephone: "",
  code: "",
  phone: "",
  label: "",
  customInputStyles: "",
  customInputContainerStyles: "",
  customSelectStyles: "",
  errorMessage: "",
  className: "",
  isEdit: false,
  onInput: () => {},
  handleOnBlur: () => {},
  setPhonePrefixes: () => [],
  phonePrefixes: [],
  isRequired: false,
  isCountrySelectActive: false,
  disabled: false
};

PhoneInputWidget.propTypes = {
  disabled: PropTypes.bool,
  telephone: PropTypes.string,
  code: PropTypes.string,
  phone: PropTypes.string,
  label: PropTypes.string,
  customInputStyles: PropTypes.string,
  customInputContainerStyles: PropTypes.string,
  customSelectStyles: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  onInput: PropTypes.func,
  handleOnBlur: PropTypes.func,
  isEdit: PropTypes.bool,
  setPhonePrefixes: PropTypes.func,
  phonePrefixes: PropTypes.arrayOf(PropTypes.object),
  isRequired: PropTypes.bool,
  isCountrySelectActive: PropTypes.bool,
  checkout: PropTypes.shape().isRequired,
  checkoutUpdated: PropTypes.func.isRequired,
  selectedCountry: PropTypes.shape(
    {
      id: PropTypes.number,
      name: PropTypes.string,
      path_url: PropTypes.string,
      api_url: PropTypes.string,
      short_name: PropTypes.string,
      url: PropTypes.string,
      zone_id: PropTypes.string,
      phone_prefix_id: PropTypes.number,
      currency: {
          code: PropTypes.string,
          name: PropTypes.string,
          symbol: PropTypes.string,
        }
    }
  ).isRequired,

};

export const mapStateToProps = (state) => {
  const { phonePrefixes, selectedCountry, isCountrySelectActive } = state.country;
  return { phonePrefixes, selectedCountry, isCountrySelectActive, checkout: state.checkout };
};

export const mapDispatchToProps = (dispatch) => ({
  setPhonePrefixes: (value) => dispatch(countryActions.setPhonePrefixes(value)),
  checkoutUpdated: (value) => dispatch(checkoutActions.checkoutUpdated(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneInputWidget);
