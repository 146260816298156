/* eslint-disable import/prefer-default-export */

export const orderUpdated = value => ({
    type: 'ORDER_UPDATED',
    value
});

export const voidOrder = () => ({
    type: 'VOID_ORDER'
});

