import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from "prop-types";
// PAGES
import Menu from '../../pages/Menu/Menu';
import AboutUs from '../../pages/AboutUs/AboutUs';
import Blog from '../../pages/Blog/Blog';
import Careers from '../../pages/Careers/Careers';
import Contact from '../../pages/Contact/Contact';
import CouponsAndDeals from '../../pages/CouponsAndDeals/CouponsAndDeals';
import TermsAndConditions from '../../pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
import Accessibility from '../../pages/Accessibility/Accessibility';
import FAQ from '../../pages/FAQ/FAQ';
import Locations from '../../pages/Locations/Locations';
import NoMatch from '../../pages/PageContainer/NoMatch';
import Loyalty from '../../pages/Loyalty/Loyalty';
import GiftCard from '../../pages/GiftCard/GiftCard';
import GiftCardLanding from '../../pages/GiftCardLanding/GiftCardLanding';
import Events from '../../pages/Events/Events';
import DoughProPayments from '../../pages/DoughProPayments/DoughProPayments';
import DoughProFreeCookie from '../../pages/DoughProFreeCookie/DoughProFreeCookie';
import DoughProTermsConditions from "../../pages/DoughProTermsConditions/DoughProTermsConditions";
import Product from '../../pages/Product/Product';
import VeganGlutenFree from '../../pages/VeganGlutenFree/VeganGlutenFree';
import DoughProGiftCardPurchase from "../../pages/DoughProGiftCardPurchase/DoughProGiftCardPurchase";
import Countries from "../../pages/Countries/Countries";
import AllergenAndNutritionInfo from "../../pages/AllergenAndNutritionInfo/AllergenAndNutritionInfo";
// CONSTANTS
import endpoints from '../../shared/constants/endpoints';
import CookiemagicRoute from '../ProtectedRoutes/CookiemagicRoute';
import Homepage from '../../pages/Homepage/Homepage';

import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import Login from '../../pages/Login/Login';
import CreateAccount from '../../pages/CreateAccount/CreateAccount';

import PasswordlessLogin from '../../pages/PasswordLessAuth/Login/Login';
import PasswordlessCreateAccount from '../../pages/PasswordLessAuth/CreateAccount/CreateAccount';
import InsomniaRewards from '../../pages/InsomniaRewards/InsomniaRewards';
import InsomniaRewardsTerms from '../../pages/InsomniaRewardsTerms/InsomniaRewardsTerms';

const IndexRoutes = (props) => {
  const { passwordlessEnabled } = props?.location;

  return (
    <Switch>
      <Route path="/shop-gift-cards" component={Menu} />
      <Route path="/menu/:id" component={Menu} />
      <Route path="/menu" component={Menu} />
      <Route path="/shipping" component={Menu} />
      <Route path="/home/address" component={Homepage} />
      <Route path="/about" component={AboutUs} />
      <Route path="/blog" component={Blog} />
      <Route path="/allergen-and-nutrition-info" component={AllergenAndNutritionInfo} />
      <Route path="/careers" component={Careers} />
      <Route path="/contact" component={Contact} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/faq" component={FAQ} />
      <Route path="/accessibility" component={Accessibility} />
      <Route path="/terms" render={() => <TermsAndConditions path="terms" />} />
      <Route path="/cookielab-event-space-terms-and-conditions" render={() => <TermsAndConditions path="cookielab" />} />
      <Route path="/change-password/:key" component={ChangePassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/login" component={passwordlessEnabled ? PasswordlessLogin : Login} />
      <Route path="/create-account" component={passwordlessEnabled ? PasswordlessCreateAccount : CreateAccount} />
      <Route path={`${endpoints.locations}/store/:store_id`} component={Locations} />
      <Route path={`${endpoints.locations}/:store_id`} component={Locations} />
      <Route path={endpoints.locations} component={Locations} />
      <Route path="/single-product" component={Product} />
      <Route path="/loyalty" component={Loyalty} />
      <Route path="/insomniarewards" exact component={InsomniaRewards} />
      <Route path="/insomniarewards/terms" component={InsomniaRewardsTerms} />
      <Route path="/gift-card" component={GiftCard} />
      <CookiemagicRoute path={endpoints.cookieMagicGiftCardPurchase} component={GiftCardLanding} />
      <Route path="/events" component={Events} />
      <Route path="/events" component={Events} />
      <Route path="/coupons-and-deals" component={CouponsAndDeals} />
      {/* <CookiemagicRoute path={endpoints.doughProPage} component={DoughPro} /> */}
      <CookiemagicRoute path={endpoints.doughProPaymentsPage} component={DoughProPayments} />
      <CookiemagicRoute path={endpoints.doughProFreeCookiePage} component={DoughProFreeCookie} />
      <CookiemagicRoute path={endpoints.doughProTermsConditions} component={DoughProTermsConditions} />
      <Route path={endpoints.veganGlutenFreePage} component={VeganGlutenFree} />
      <CookiemagicRoute path="/cookiemagic-egift-product/:id" component={DoughProGiftCardPurchase} />
      <Route path="/goto/store/:store_id" component={Menu} />
      <Route path="/countries" component={Countries} />
      <Route path="/" exact component={Homepage} />
      <Route path="/">
        <Redirect to="/" />
      </Route>
      <Route path="/404" component={NoMatch} />

      <Redirect to="/404" />
    </Switch>
  );
};

IndexRoutes.propTypes = {
  location: PropTypes.shape({
    passwordlessEnabled: PropTypes.bool
  }).isRequired
}

export default IndexRoutes;
