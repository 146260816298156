/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

// COMPONENTS
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import PhoneInputWidget from '../../components/PhoneInputWidget/PhoneInputWidget';

// HELPERS 
import { handleFormInput } from '../../shared/utilities/validations';

import styles from './Events.module.css';

const loyaltyAsset = require('./imgs/loyalty-asset.svg');

/* eslint-disable import/prefer-default-export */
export const renderGiftHeroLeft = () => {
  return(
    <div className={styles.topLeft}>
      <h1>
        Book an event
        <br />
        with us
      </h1>

      <p>
        Get in touch and let&apos; see how we can
        help make your event even sweeter.
      </p>
    </div>
  )
}

export const renderGiftHeroRight = () => (
  <div className={styles.topRight}>
    <img alt='confirmation' src={loyaltyAsset} />
  </div>
)

const renderInput = (component, label, state, validationType, inputMask = '') => {
  const text = component.state[state];

  return (
    <Input
      theme='dark'
      label={label}
      placeholder=" "
      handleInput={(e) => handleFormInput(e, component, state, validationType)}
      customContainerStyles={styles.inputWrapper}
      value={text}
      inputMask={inputMask}
    />
  )
}

export const renderForm = (component, action) => {
  const { comment, errorMessage, successMessage } = component.state;
  return(
    <div className={styles.pageBottom}>
      <p>Contact Details</p>
      <span className={[
          errorMessage ? styles.error : null,
          successMessage ? styles.success : null
        ].join(' ')}
      >
        {errorMessage || successMessage}
      </span>
      {renderInput(component, 'Full Name', 'fullName')}
      {renderInput(component, 'Email address', 'email', 'email')}
      {renderInput(component, 'Organization name', 'organizationName')}
      <PhoneInputWidget
        onInput={(e) => {component.setState({phone: e.telephone, phoneData: e})}}
        label='Mobile number'
        placeholder=" "
        errorMessage={component.state.phoneErrorMessage}
        telephone={component.state.phone}
        isNumberFormat
      />
      <p>Event Details</p>
      {renderInput(component, 'Event location', 'eventLocation')}
      {renderInput(component, 'Event date', 'eventDate', '', '99/99/9999')}
      {renderInput(component, 'Event type', 'eventType')}
      {/* TEXT AREA CUSTOM UI HANDLING */}
      <Input
        isTextArea
        theme='dark'
        customContainerStyles={styles.inputWrapper}
        handleInput={(e) => e.target.value.length <= 1000 ? handleFormInput(e, component, 'comment') : null}
        placeholder=" "
        value={comment}
        label="Leave a comment"
      />
      <span>{`${comment.length}/1000 Characters`}</span>
      <Button handleSubmit={action} customContainerStyles={styles.submitButton} label="Submit" />
    </div>
  )
};
