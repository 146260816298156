import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { propTypesComponentPaymentInput } from '../../shared.propTypes';
import styles from './PaymentInputCreditCardLegacy.module.css';
import stylesShared from '../../shared.module.css';
import CheckBox from '../../../CheckBox/CheckBox';
import Button from '../../../Button/Button';
import Input from '../../../Input/Input';
import { cardExpirationFormatValidation } from '../../../../shared/utilities/common';
import PaymentInputCreditCardSavedList from '../PaymentInputCreditCardSavedList/PaymentInputCreditCardSavedList';
import { keyDownA11y } from '../../shared';

const inputMasks = {
  cc19: '#### #### #### #######',
  cc15: '#### ###### #####',
  ccCvv: '####',
  ccExpiration: '##/##',
  ccZip: '#####',
};

const useCreditCardLegacyForm = (
  storePaymentMethod,
  callback,
  isSavePaymentMethodCheckedAndDisabled,
  inputsInitial
) => {
  const [inputs, setInputs] = useState(inputsInitial || {});

  const handleSubmit = event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    const paymentCredentialSubmitted = {
      summary: `Card ending in ${inputs.cardNumber
        .trimEnd()
        .substr(inputs.cardNumber.trimEnd().length - 4)}`,
      storePaymentMethod,
      paymentCredential: {
        paymentMethodId: storePaymentMethod.paymentMethodId,
        credential: inputs.cardNumber.trimEnd(),
        securityCode: inputs.cvv,
        data: {
          cardHolderName: inputs.cardHolderName,
          expirationDate: inputs.expirationDate,
          postcode: inputs.postcode
        },
        saveCredentials: inputs.saveCard || isSavePaymentMethodCheckedAndDisabled
      }
    };
    callback(paymentCredentialSubmitted);
  };

  const handleInputChange = event => {
    if (event.persist) {
      event.persist();
    }
    const newInputs = { ...inputs, [event.target.name]: event.target.value };
    setInputs(newInputs);
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs
  };
};

const views = {
  newCard: 1,
  savedCards: 2
};

const PaymentInputCreditCardLegacy = props => {
  const {
    storePaymentMethod,
    paymentCredentialSubmitted,
    isSavePaymentMethodAllowed,
    isSavePaymentMethodCheckedAndDisabled,
    onSubmitPaymentCredentials,
    onCancel,
    hideCancel,
    paymentMethodsCustomerSaved,
    inputsInitial
  } = props;

  const hasSavedCards = paymentMethodsCustomerSaved && paymentMethodsCustomerSaved.length > 0;
  const [maskCc] = useState(inputMasks.cc19);
  const [view, setView] = useState(hasSavedCards ? views.savedCards : views.newCard);
  const { inputs, handleInputChange, handleSubmit } = useCreditCardLegacyForm(
    storePaymentMethod,
    onSubmitPaymentCredentials,
    isSavePaymentMethodCheckedAndDisabled,
    inputsInitial
  );
  const isEdit =
    inputs.cardNumber &&
    paymentCredentialSubmitted?.paymentCredential?.credential === inputs.cardNumber;
  const theme = isEdit ? 'Light' : 'Dark';
  const isRequired = !paymentCredentialSubmitted;
  const onCancelCc = () => {
    if (onCancel) {
      if (hasSavedCards) {
        setView(views.savedCards);
      }
      onCancel();
    }
  };
  return (
    <>
      <PaymentInputCreditCardSavedList
        storePaymentMethod={storePaymentMethod}
        paymentCredentialSubmitted={paymentCredentialSubmitted}
        onCancel={onCancelCc}
        hideCancel={hideCancel}
        paymentMethodsCustomerSaved={paymentMethodsCustomerSaved}
        hidden={view !== views.savedCards}
        onSubmitPaymentCredentials={onSubmitPaymentCredentials}
        onAddNewCardClick={() => {
          setView(views.newCard);
        }}
      />
      <div hidden={view !== views.newCard} className={styles.secondaryAddressContainer}>
        {!paymentCredentialSubmitted ? (
          <span className={styles.selectionMinor}>*Indicates required fields</span>
        ) : null}
        <form>
          <Input
            theme={theme}
            label="Cardholder Name"
            name="cardHolderName"
            value={inputs.cardHolderName}
            isRequired={isRequired}
            handleInput={handleInputChange}
            customContainerStyles={styles.inputContainer}
          />
          <Input
            theme={theme}
            label="Card Number"
            name="cardNumber"
            value={inputs.cardNumber}
            isRequired={isRequired}
            inputMask={maskCc}
            isNumberFormat
            handleInput={handleInputChange}
            customContainerStyles={styles.inputContainer}
          />
          <div className={styles.secondaryInputRow}>
            <Input
              theme={theme}
              label="Expiration Date (MM/YY)"
              name="expirationDate"
              value={inputs.expirationDate}
              isRequired={isRequired}
              inputMask={inputMasks.ccExpiration}
              isNumberFormat
              handleInput={handleInputChange}
              isAllowed={cardExpirationFormatValidation}
              customContainerStyles={styles.customExpirationDate}
            />
            <Input
              theme={theme}
              label="CVV"
              name="cvv"
              value={inputs.cvv}
              isRequired={isRequired}
              inputMask={inputMasks.ccCvv}
              isNumberFormat
              handleInput={handleInputChange}
              customContainerStyles={styles.customCVV}
            />
          </div>

          <Input
            theme={theme}
            label="Billing Zipcode"
            name="postcode"
            value={inputs.postcode}
            isRequired={isRequired}
            inputMask={inputMasks.ccZip}
            isNumberFormat
            handleInput={handleInputChange}
            customClass={styles.billingZipContainer}
            customContainerStyles={styles.inputContainer}
          />
          {isSavePaymentMethodAllowed ? (
            <CheckBox
              label="Save my credit card info"
              onClick={() => {
                handleInputChange({
                  target: { name: 'saveCard', value: !inputs.saveCard }
                });
              }}
              name="saveCard"
              disabled={isSavePaymentMethodCheckedAndDisabled}
              isChecked={inputs.saveCard || isSavePaymentMethodCheckedAndDisabled}
            />
          ) : null}

          <div className={styles.buttonContainer}>
            <Button
              name="btnSubmit"
              disabled={
                !(
                  inputs.cardHolderName &&
                  inputs.expirationDate &&
                  inputs.postcode &&
                  inputs.cvv &&
                  inputs.cardNumber
                )
              }
              handleSubmit={e => {
                handleSubmit(e);
                if (hasSavedCards) {
                  setView(views.savedCards);
                }
              }}
              label={paymentCredentialSubmitted ? 'Save Changes' : 'Add card'}
            />
          </div>
          {!hideCancel ? (
            <div
              className={stylesShared.footerActions}
              onClick={onCancelCc}
              onKeyDown={keyDownA11y(onCancelCc)}
              role="button"
              tabIndex={0}
              hidden={hideCancel}
            >
              Change Payment Method
            </div>
          ) : null}
        </form>
      </div>
    </>
  );
};

PaymentInputCreditCardLegacy.propTypes = {
  ...propTypesComponentPaymentInput,
  inputsInitial: PropTypes.shape({
    securityCode: PropTypes.string,
    cardHolderName: PropTypes.string,
    expirationDate: PropTypes.string,
    postcode: PropTypes.string,
    saveCredentials: PropTypes.bool,
    cardNumber: PropTypes.string
  })
};

PaymentInputCreditCardLegacy.defaultProps = {
  inputsInitial: null
};
export default PaymentInputCreditCardLegacy;
