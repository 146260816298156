/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-empty */
import React, { Component } from 'react';
import _ from 'underscore';
import DropDown from '../../../components/Dropdown/Dropdown';
import styles from '../Contact.module.css';
import * as storeRepo from '../../../shared/repos/graphql/store';

class StoreField extends Component {
    constructor(props) {

        super(props);

        this.state = {
            stores: []
        };
    }

    componentDidMount() {

        this.getStores();
    }

    async getStores() {

        try {
            const response = await storeRepo.regularStores();

            const stores = _.pluck(response.data.regularStores, 'name').sort();

            this.setState({ 
                stores
            });
        } catch (exception) {}
    }

    setStore(store) {

        const { component } = this.props;

        component.setState({ 
            store
        });
    }

    render() {

        const { component } = this.props;
        const { store } = component.state;
        const { stores } = this.state;

        return (
          <div className={styles.dropDownWrapper}>
            <DropDown
              value={store}
              onChange={({value}) => this.setStore(value)}
              options={stores}
              customDropDownStyles={styles.storeWrapper}
              theme="dark"
            />
          </div>
        );
    }
}

export default StoreField;
