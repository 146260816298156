import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _, {escapeRegExp} from 'lodash';
import Loader from '../../../components/Loader/Loader';
import Input from '../../../components/Input/Input';
import * as checkoutActions from '../../../redux/actions/checkout';
import * as paymentActions from '../../../redux/actions/payment';
import { returnRadioOptions } from '../rendering';
import styles from './Tip.module.css';
import { defaultCurrency } from '../../../shared/constants/currency';
import siteSetting from "../../../shared/constants/siteSetting";
import tipsTypes from "../../../shared/constants/tipsTypes";

class Tip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tipIndex: 0,
            tip: 0.0,
            customIndex: 0,
            tipsVariants: [],
        }
    }

    componentDidMount = async () => {
        await this.initTipOptions();
    }

    setTip = async (orderCode, cartTip, newTipIndex) => {
        const { orderUpdating, checkoutUpdated, checkout } = this.props;
        const { tipIndex } = this.state;

        if (checkout.tip !== parseFloat(cartTip)) {
            if (parseFloat(cartTip) < 0) {
                return;
            }

            orderUpdating(true);
            checkout.tip = parseFloat(cartTip || '0');

            checkoutUpdated(checkout);
            orderUpdating(false);
        }

        if (newTipIndex !== tipIndex) {
            this.setState({
                tipIndex: newTipIndex,
            });
        }
    };

    setCustomTip = (tip, eventType, order) => {
        const { currency } = this.props;
        const { customIndex } = this.state;
        const tipString = tip.replace(currency.symbol, '');
        const tipBlank = Number.isNaN(parseFloat(tipString)) || tipString === undefined || tipString === null;
        const isOnBlur = eventType === 'blur';

        if (tipBlank) {
            this.setState({
                tip: tipString,
                tipIndex: customIndex
            });
            return this.setTip(order.code, 0, customIndex);
        }

        this.setState({
            tip: tipString
        });

        return isOnBlur ? this.setTip(order.code, tipString, customIndex) : null;
    };

    getTipsVariants() {
        const { tipsSettings, currency } = this.props;
        const variants = _.map(tipsSettings[siteSetting.TIPS_VALUES], (item) => {
            if (tipsSettings[siteSetting.TIPS_TYPE] === tipsTypes.TYPE_AMOUNT) {
                return currency.symbol + item;
            } if (tipsSettings[siteSetting.TIPS_TYPE] === tipsTypes.TYPE_PERCENT) {
                return `${item  }%`;
            }
                return `${item}`;

        });
        variants.push('Custom');
        return variants;
    }

    handleSelectedOption = (selectedKey, order) => {
        const { checkout, tipsSettings } = this.props;
        const { customIndex } = this.state;

        let cartTip;
        const totalSum = order.priceWithTax;
        if (selectedKey === customIndex) {
            cartTip = checkout.tip ? checkout.tip : order.tip;
            this.setState({
                tipIndex: selectedKey,
                tip: cartTip
            });
        } else {
            const tip = tipsSettings[siteSetting.TIPS_VALUES][selectedKey];
            cartTip = !Number.isNaN(tip) ? parseFloat(this.calculateTipAmount(totalSum, tip)).toFixed(2) : order.tip;
        }
        return this.setTip(order.code, cartTip, selectedKey);
    };

    initTipOptions = async () => {
        const { order, tipsSettings } = this.props;
        let tipAmount = 0.0;
        order.totals.forEach(total => {
            let title = total.title.toLowerCase();
            title = title.replace(/\s/g, '');
            if (title === 'tip') {
                tipAmount = total.value;
            }
        });

        const tipsVariants = this.getTipsVariants();
        const customIndex = tipsVariants.length - 1;

        const sumTotals = order.priceWithTax;
        const indexByTipAmount = _.map(tipsSettings[siteSetting.TIPS_VALUES], (val, index) => {
            return {
                amount: order.tip.toFixed(2) === parseFloat(this.calculateTipAmount(sumTotals, val)).toFixed(2),
                index,
            }
        });


        const index = indexByTipAmount.find(item => item.amount === true);
        const newIndex = index ? index.index : customIndex;
        await this.setState({
            customIndex,
        });

        this.handleSelectedOption(newIndex, order);

        await this.setState({
            tipIndex: newIndex,
            tip: newIndex === customIndex ? tipAmount : 0.0,
            tipsVariants,
        });
    };

    calculateTipAmount = (price, tipValue) => {
        const { tipsSettings } = this.props;
        if (tipsSettings[siteSetting.TIPS_TYPE] === tipsTypes.TYPE_PERCENT) {
            return price / 100 * tipValue;
        } if (tipsSettings[siteSetting.TIPS_TYPE] === tipsTypes.TYPE_AMOUNT) {
            return tipValue;
        }
        return tipValue;
    }

    tipMaskFunction = (newState, oldState, userInput) => {
        const { currency } = this.props;
        // Fix for canadian dollar regex
        const tipRegex = new RegExp(`^${ escapeRegExp(currency.symbol) }\\d*\\.?\\d{0,2}$`);
        const isPrefill = !userInput && !newState.selection;
        const isValid = isPrefill ||
            oldState.value === newState.value ||
            tipRegex.test(newState.value);

        if (isValid) {
            return newState;
        }

        return oldState;
    };

    render() {
        const { loading, tipIndex, tip, tipErrorMessage, tipsVariants, customIndex } = this.state;
        const { currency } = this.props
        const maskPermanents = [0];
        const maskFormat = {
            '*': '[A-Za-z0-9.]'
        }

        const { order } = this.props;
        const isDelivery = order.orderTypeId === 2;

        const component = isDelivery ? (
          <div className={styles.tipContainer}>
            <h3>Add a tip</h3>

            {!loading ? returnRadioOptions(tipIndex, tipsVariants, tipVal => this.handleSelectedOption(tipVal, order)) : (
              <div className={styles.tipLoaderWrapper}>
                <Loader />
              </div>
            )}

            {tipIndex === customIndex ? (
              <div className={styles.customTipWrapper}>
                <Input
                  handleInput={e => this.setCustomTip(e.target.value, e.type, order)}
                  value={`${tip}`}
                  placeholder=" "
                  label="Add a tip"
                  inputMask={`${currency.symbol}*****************`}
                  maskFunction={this.tipMaskFunction}
                  maskPermanents={maskPermanents}
                  maskFormat={maskFormat}
                  eventsConfig={{onChangeEnabled: false}}
                  errorMessage={tipErrorMessage}
                />
              </div>
            ) : null}
          </div>
        ) : null;

        return component;
    }
}

Tip.propTypes = {
    orderUpdating: PropTypes.func.isRequired,
    checkoutUpdated: PropTypes.func.isRequired,
    order: PropTypes.shape({
        totals: PropTypes.array.isRequired,
        tip: PropTypes.number.isRequired,
        orderTypeId: PropTypes.number.isRequired,
        priceWithTax: PropTypes.number.isRequired,
    }).isRequired,
    checkout: PropTypes.shape({
        tip: PropTypes.number,
    }),
    currency: PropTypes.objectOf(PropTypes.string),
    tipsSettings: PropTypes.shape({
        [siteSetting.TIPS_TYPE]: PropTypes.string,
        [siteSetting.TIPS_VALUES]: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
};

Tip.defaultProps = {
    checkout: {},
    currency: defaultCurrency,
};

export const mapStateToProps = state => {
    const { currency } = state.currency;
    const { tipsSettings } = state.tips;
    return {
        order: state.order,
        checkout: state.checkout,
        currency,
        tipsSettings
    };
};

export const mapDispatchToProps = (dispatch) => ({
    orderUpdating: (value) => dispatch(paymentActions.orderUpdating(value)),
    checkoutUpdated: (value) => dispatch(checkoutActions.checkoutUpdated(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tip);
