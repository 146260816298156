/* eslint-disable react/prop-types */

import React, { Component }from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Input from '../../../components/Input/Input';
import styles from '../ReviewOrder.module.css';
import CheckBox from '../../../components/CheckBox/CheckBox';
import * as elementActions from '../../../redux/actions/elements';
import { handleFormInput } from '../../../shared/utilities/validations';
import { recipientMessageLength, orderTypeIds, instructionsLength } from '../../../shared/constants/order';
import {clearCart} from "../../../shared/utilities/orders";
import endpoints from "../../../shared/constants/endpoints";
import CheckoutContainer from "../../../components/CheckOutContainer/CheckoutContainer";
import * as checkoutActions from "../../../redux/actions/checkout";
import { routeCountryPath } from '../../../shared/utilities/common';

export class Instructions extends Component {
    constructor(props) {
        super(props);

        const { order } = this.props;

        this.state = {
            orderInstructions: order.comment,
            includeSpoon: order.includeSpoon,
            deliveryNotesOpen: true
        }
    }

    componentDidMount() {
        this.loadInstructions();
    }

    loadInstructions = () => {
        const { checkout, checkoutUpdated, order } = this.props;
        checkout.orderComment = order.comment;
        checkoutUpdated(checkout);
    }

    handleInput = (e, maxLength) => {

        const {value} = e.target;
        const {checkout, checkoutUpdated} = this.props;

        checkout.orderInstructions = value;
        checkoutUpdated(checkout);
        if (value.length <= maxLength) {
            handleFormInput(e, this, 'orderInstructions')
        }
    };

    setFailedOrder = () => {
    const { component } = this.props;
        clearCart();
        component.props.history.push(routeCountryPath(endpoints.getMenuUrl(null)))
    }

    onExpandDeliveryNotes = (expand) => {
        this.setState({ deliveryNotesOpen: expand });
    }


    setOrderComments = async () => {
        const { orderInstructions } = this.state;
        const { checkout, checkoutUpdated } = this.props;

        checkout.orderComment = orderInstructions;
        checkoutUpdated(checkout);
    }

    toggleIncludeSpoon = () => {
        const { includeSpoon } = this.state;
        const { checkout, checkoutUpdated } = this.props;

        this.setState({
            includeSpoon: !includeSpoon
        });

        checkout.includeSpoon = !includeSpoon;
        checkoutUpdated(checkout);
    }

    render () {
        const { orderInstructions, deliveryNotesOpen, includeSpoon } = this.state;
        const { order, checkout } = this.props;
        const instructionLength = orderInstructions ? orderInstructions.length : 0;
        const isDelivery = parseInt(order.orderTypeId, 10) === orderTypeIds.delivery;
        const maxInstructLength = isDelivery? recipientMessageLength.anotherOrder : instructionsLength.defaultLength;

        return (
          <CheckoutContainer
            header="Delivery notes"
            subHeader="Enter delivery notes (e.g. security code, building details)"
            subHeaderClass={styles.deliveryNotesSubTitle}
            onExpand={(val) => this.onExpandDeliveryNotes(val)}
            expanded={deliveryNotesOpen}
            className={styles.minHeight}
          >
            <p className={[styles.checkoutSectionHeader, styles.sectionHeader, "text-dark dark:text-white"].join(' ')}>Delivery notes</p>
            <Input
              isTextArea
              value={checkout.orderInstructions || orderInstructions}
              handleInput={e => this.handleInput(e, maxInstructLength)}
              handleOnBlur={this.setOrderComments}
              customContainerStyles={styles.inputContainer}
              placeholder=" "
              label="Add instructions for your order"
            />
            <span className={styles.selectionMinor}>
              {`${instructionLength}/${maxInstructLength} characters`}
            </span>
            <div className={`${styles.floatRight} w-full flex items-end justify-end`}>
              <CheckBox
                onClick={() => this.toggleIncludeSpoon()}
                isChecked={checkout.includeSpoon || includeSpoon}
                label="Include Spoon"
              />
            </div>
          </CheckoutContainer>
        );
    };
}

Instructions.defaultProps = {
    checkout: {},
};

Instructions.propTypes = {
    order: PropTypes.shape().isRequired,
    checkout: PropTypes.shape(),
    checkoutUpdated: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
    return {
        order: state.order,
        checkout: state.checkout,
    }
}

export const mapDispatchToProps = (dispatch) => ({
    checkoutUpdated: (value) => dispatch(checkoutActions.checkoutUpdated(value)),
    setModalObject: (value) => dispatch(elementActions.setModalObject(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Instructions);