/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import Carousel from '@brainhubeu/react-carousel';
import { withRouter } from 'react-router-dom'

// REDUX
import { connect } from 'react-redux';
import Appboy from '@braze/web-sdk';
import styles from './ContentCardCarousel.module.css';
import * as contentCardConstants from "../../../shared/constants/contentCards";
import { filterContentCards } from "../../../shared/utilities/braze";
import HeroPromoContentCardTemplate from "./Templates/HeroBanner/HeroPromoContentCardTemplate";
import { routeCountryPath } from '../../../shared/utilities/common';

class ContentCardCarousel extends Component {

    intervalResult = null;

    constructor(props) {
      super(props);

      this.state = {
        cardData: [],
        initialized: false,
        carouselIndex: 0,
      };

      this.setSlideIndex = this.setSlideIndex.bind(this);
    }

    componentDidMount() {
      const {delay} = this.props;
      setTimeout(() => {
        this.initializeCarousel();
      }, delay);
      this.initializeCarouselInterval();
    }

    componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        this.initializeCarousel();
        this.initializeCarouselInterval();
      }
    }

    initializeCarousel = () => {
      const { selectedStore, templates, contentCards, defaultHeroBanner } = this.props;
      const storeId = selectedStore?.id !== undefined ? parseInt(selectedStore?.id, 10): null;
      if (contentCards === null) {
        return;
      }
      const filteredCards = filterContentCards(contentCards, templates, storeId);
      const cardData = filteredCards.length > 0 ? filteredCards : [defaultHeroBanner];

      this.setState(
        {
          cardData,
          initialized: true
        }
      );
    }

    clearCarouselInterval = () => {

      window.clearInterval(this.intervalResult);
    }

    initializeCarouselInterval = () => {

      this.clearCarouselInterval();

      const {
        carouselSpeed,
      } = this.props;

      this.intervalResult = window.setInterval(() => {

        const {
          cardData,
          carouselIndex,
        } = this.state;

        const isLastCard = (cardData.length - 1) === carouselIndex;
        const newSlideIndex = isLastCard ? 0 : carouselIndex + 1;

        this.setSlideIndex(newSlideIndex);
      }, carouselSpeed);
    }

    setSlideIndex = (carouselIndex) => {
      this.setState(
        { carouselIndex }
      );

      const { cardData } = this.state;

      const card = cardData[carouselIndex];
      if (card) {
        Appboy.logCardImpressions([card], true);
      }
    }

    handleClick = () => {

      const { cardData, carouselIndex } = this.state;
      const { history } = this.props;

      const card = cardData[carouselIndex];

      if (card) {
        Appboy.logCardClick(card, true);

        const isExternal = card.url.startsWith(contentCardConstants.EXTERNAL_URL_PREFIX);
        if (isExternal) {
          window.open(card.url);
        } else {
          history.push(routeCountryPath(card.url));
        }
      }
    }

    selectCard = (index) => {

      this.setSlideIndex(index);
      this.initializeCarouselInterval();
    }

    getTemplateName = (card, templates) => {
      const hasTemplateExists = templates.includes(card?.extras?.template);
      if (!hasTemplateExists) {
        return false;
      }

      let template = null;

      // eslint-disable-next-line default-case
      switch (card?.extras?.template) {
      case contentCardConstants.HERO_PROMO_TEMPLATE:
        template = HeroPromoContentCardTemplate;
        break;
      }
      return template;
    }

    renderCarouselContainer = () => {
      const {
        cardData,
        carouselIndex
      } = this.state;

      const {openDeliveryWidget} = this.props;

      const {templates, carouselControlStyles} = this.props;

      if (!cardData.length) {
        return (null);
      }

      const dots = cardData.length > 1 ? (
        <div className={carouselControlStyles.dotsContainer}>
          <div className={carouselControlStyles.cardDots}>
            {cardData.map(card => {
              const cardIndex = cardData.indexOf(card);
              const isSelected = cardIndex === carouselIndex;
              const className = isSelected ? styles.selectedIndex : null;

              return (
                <button
                  type="button"
                  onClick={() => this.selectCard(cardIndex)}
                  className={className}
                  aria-label={`carouselIndex_${carouselIndex}`}
                />
              )
            })}
          </div>
        </div>
      ) : null;

      return (
        <div className={`${styles.carouselContainer} w-full flex flex-col items-center`}>
          <div
            className={`${styles.cardContainer} w-full shadow-md rounded-[30px]`}
            onMouseEnter={this.clearCarouselInterval}
            onMouseLeave={this.initializeCarouselInterval}
          >
            <Carousel
              value={carouselIndex}
              onChange={this.setSlideIndex}
            >
              {cardData.map(card => {
                const TemplateName = this.getTemplateName(card, templates);

                return (
                  <TemplateName cardData={card} openDeliveryWidget={openDeliveryWidget} />
                )
              })}
            </Carousel>
          </div>
          { dots }
        </div>
      );
    }

    render() {
      const {
        initialized
      } = this.state;

      if (!initialized) {
        return null;
      }

      return (
        <this.renderCarouselContainer />
      )
    }
}

ContentCardCarousel.propTypes = {
  selectedStore: PropTypes.shape(
    {
      id: PropTypes.oneOfType(
        [
          PropTypes.number,
          PropTypes.string
        ]
      )
    }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  templates: PropTypes.arrayOf(PropTypes.string),
  carouselControlStyles: PropTypes.shape(PropTypes.object),
  contentCards: PropTypes.shape(PropTypes.object),
  defaultHeroBanner: PropTypes.shape(PropTypes.object).isRequired,
  carouselSpeed: PropTypes.number.isRequired,
  delay: PropTypes.number,
  openDeliveryWidget: PropTypes.func
}

ContentCardCarousel.defaultProps = {
  selectedStore: {
    id: null
  },
  templates: [],
  carouselControlStyles: {},
  contentCards: null,
  delay: 0,
  openDeliveryWidget: null
}

export const mapStateToProps = (state) => {
  const {
    selectedStore
  } = state.user;

  const {
    contentCards,
    defaultHeroBanner
  } = state.braze;

  const {
    carouselSpeed
  } = state.elements;

  return {
    contentCards,
    selectedStore,
    defaultHeroBanner,
    carouselSpeed,
  };
}

export default withRouter(connect(mapStateToProps)(ContentCardCarousel));
