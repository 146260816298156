import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "../Input/Input";

class InputSearchBox extends Component {
  constructor(props) {
    super(props);
    const { selectedItem } = this.props;
    this.state = {
      inputText: selectedItem,
      isSearch: false
    };
    this.wrapperRef = React.createRef(null);
  }

  componentDidMount = () => {
    window.addEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    if (this.wrapperRef?.current) {
      const ref = this.wrapperRef?.current      
      if(!ref.contains(e?.target)){
        this.setState({
          isSearch: false
        });
      }     
    }
  };

  handleSearchItem = inputText => {
    this.setState({
      inputText,
      isSearch: true
    });
  };

  handleItemSearchSelect = item => {
    const { onSelectItem } = this.props;

    onSelectItem(item.value, item.label);

    this.setState({
      isSearch: false,
      inputText: item.label
    });
  };

  render() {
    const { label, items } = this.props;
    const { inputText, isSearch } = this.state;
    return (
      <div ref={this.wrapperRef}>
        <Input
          label={label}
          type="text"
          handleInput={e => this.handleSearchItem(e?.target?.value)}
          value={inputText}
          name='inputSearchBox'
        />
        {Array.isArray(items) && items.length > 0 && isSearch && (
          <div
            className="font-filsonProRegular text-dark dark:text-white
                w-full border-[1px]
                border-borderLight dark:border-none bg-white dark:bg-darkDry overflow-y-scroll max-h-[212px] mb-12 md:mb-0"
          >
            {items
              .filter(
                item =>
                  item.label.toLowerCase().indexOf(inputText.toLowerCase()) !==
                  -1
              )
              .map(item => (
                <div
                  key={item.value}
                  onClick={() => this.handleItemSearchSelect(item)}
                  onKeyPress={e => {
                    if (e.key === "Enter" || e.key === " ") {
                      this.handleItemSearchSelect(item);
                    }
                  }}
                  role="button"
                  aria-label={item.label}
                  tabIndex={0}
                  className="cursor-pointer p-2 hover:bg-light dark:hover:bg-darkDry suggestions"
                >
                  {item.label}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}

InputSearchBox.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.shape(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  onSelectItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.string
};

InputSearchBox.defaultProps = {
  selectedItem: ""
};

export default InputSearchBox;
