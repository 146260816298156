/* eslint-disable import/prefer-default-export */
import { SHIPPING_OPTIONS, SHIPPING_UNAVAILABLE_PRODUCTS } from '../../../graphql/shipping';

import { client } from "../../utilities/config";

export const shippingOptions = (zipcode, orderCode) => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: SHIPPING_OPTIONS,
        variables: {
            zipcode,
            orderCode
        }
    });
};

export const shippingUnavailableProducts = (zipcode, orderCode) => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: SHIPPING_UNAVAILABLE_PRODUCTS,
        variables: {
            zipcode,
            orderCode
        }
    });
};
