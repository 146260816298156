import React from 'react';
import PropTypes from 'prop-types';
import { keyDownA11y } from '../shared';
import styles from './PaymentMethodGroupExpander.module.css';

const expandIcon = require('./imgs/expand-icon.svg');
const expandLightIcon = require('./imgs/expand-light-icon.svg');
const alertIcon = require('./imgs/alert-icon.svg');

const PaymentMethodGroupExpander = props => {
  const {
    children,
    header,
    subHeader,
    subHeaderAsset,
    isAlert,
    navigateTo,
    subHeaderClass,
    isExpanded,
    setIsExpanded,
    isSingleItem
  } = props;

  if (isSingleItem) {
    return <>{children}</>;
  }
  return (
    <div className={styles.checkoutContainerWrapper}>
      <div
        className={[styles.checkoutContainer, isExpanded ? styles.containerExpanded : null].join(
          ' '
        )}
      >
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          className={[styles.checkoutContainerCollapsedContainer].join(' ')}
          onKeyDown={keyDownA11y(() => {
            setIsExpanded(!isExpanded);
          })}
          role="button"
          tabIndex={0}
        >
          <div className={styles.checkoutContainerLeft}>
            <p className='font-filsonProBold leading-5 text-dark dark:text-white'>
              {' '}
              {subHeaderAsset ? (
                <span>
                  <img alt="Subheader" src={subHeaderAsset} />
                </span>
              ) : null}
              {header}
            </p>

            <span className={`${subHeaderClass} ${isAlert ? styles.alert : null} font-filsonProRegular text-xs leading-[14px]`}>
              {subHeader}
            </span>
          </div>
          {/* COMPONENT CONTAINER RIGHT SIDE */}
          <div className={styles.checkoutContainerRight}>
            {children && isAlert ? (
              <img id={styles.alertIcon} alt="alert-icon" src={alertIcon} />
            ) : null}
            {children || navigateTo ? (
              <>
                <img
                  className={`${navigateTo ? styles.navigate : null} block dark:hidden`}
                  id={styles.expandIcon}
                  alt="expand-icon"
                  src={expandIcon}
                />

                <img
                  className={`${navigateTo ? styles.navigate : null} hidden dark:block`}
                  id={styles.expandLightIcon}
                  alt="expand-icon"
                  src={expandLightIcon}
                />
              </>

              
            ) : null}
          </div>
        </div>
        {/* COMPONENT BODY */}
        <div hidden={!isExpanded} className={styles.containerDropDownChildren}>
          {children}
        </div>
      </div>
    </div>
  );
};

PaymentMethodGroupExpander.propTypes = {
  children: PropTypes.element,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  subHeaderAsset: PropTypes.string,
  isAlert: PropTypes.bool,
  navigateTo: PropTypes.string,
  subHeaderClass: PropTypes.string,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  isSingleItem: PropTypes.bool
};

PaymentMethodGroupExpander.defaultProps = {
  children: null,
  header: '',
  subHeader: '',
  subHeaderAsset: '',
  isAlert: false,
  navigateTo: '',
  subHeaderClass: '',
  isExpanded: true,
  setIsExpanded: () => {},
  isSingleItem: false
};

export default PaymentMethodGroupExpander;
