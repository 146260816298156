import React, { Component } from "react";
import PropTypes from "prop-types";

import * as userRepo from "../../../shared/repos/graphql/user";
import Button from "../../../components/Button/Button";
import PhoneWidget from "../../../components/PhoneWidget/PhoneWidget";
import styles from "./Login.module.css";

export default class VerifyPhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseErrorMessage: null,
      loading: false,
      phoneNumber: "",
    };
    this.recaptchaComponentRef = React.createRef();
  }

  handleVerify = async() => {
    const { phoneNumber } = this.state;
    const {handleLoginWithPhone, token, user, password } = this.props;
    try {
      if (phoneNumber) {
        this.setState({loading: true})
        const verifiedUser = await userRepo.verifyPhoneNumber(
          user?.email,
          password,
          phoneNumber?.code,
          phoneNumber?.phone,
        );

        if (verifiedUser) {
          handleLoginWithPhone(token, user, phoneNumber);
        }
        this.setState({loading: false})
      }
    } catch (e) {
      if (!e?.message) {
        this.setState({
          responseErrorMessage: "Something went wrong! Please try again.",
          loading: false
        });
        return;
      }

      this.setState({
        responseErrorMessage: e.message,
        loading: false
      });
    }
  };

  handleInput = (fieldName, value) => {
    this.setState({
      [fieldName]: value
    });
  };

  render() {
    const { responseErrorMessage, phoneNumber, loading } = this.state;
    const isDisabled = !phoneNumber.maskValidation;
    return (
      <div className="w-[343px]">
        <p className="font-filsonProBold text-[18px] leading-[22px] tracking-[-0.1px] mb-2">
          As part of our new rewards program, we need all customers to verify their phone number.
        </p>
        <p className="font-filsonProRegular text-[14px] leading-[16px] mb-2">
          Free cookies are just a couple digits away
          {' '}
          <span role="img" aria-label="">😎</span>
.
        </p>
        <PhoneWidget
          onInput={this.handleInput}
          fieldName="phoneNumber"
        />

        {responseErrorMessage && (
          <p className={`${styles.hasError} text-[#F79393]`}>{responseErrorMessage}</p>
        )}

        <div className={styles.actionsContainer}>
          <Button
            isLoading={loading}
            disabled={isDisabled}
            handleSubmit={
              isDisabled ? null : () => this.handleVerify()
            }
            customContainerStyles={`${styles.buttonContainer} ${
              isDisabled ? "!bg-disabled" : ""
            } !block w-full bg-button text-white text-center py-2 h-11`}
            label="Continue"
          />
        </div>
      </div>
    );
  }
}

VerifyPhoneNumber.propTypes = {
  handleLoginWithPhone: PropTypes.func.isRequired,
  token: PropTypes.string,
  user: PropTypes.string,
  password: PropTypes.string,
};

VerifyPhoneNumber.defaultProps = {
  token: null,
  user: null,
  password: null,
};