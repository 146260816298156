/* eslint-disable import/prefer-default-export */

export const checkoutUpdated = value => ({
    type: 'CHECKOUT_UPDATED',
    value
});

export const checkoutErrorsUpdated = value => ({
    type: 'CHECKOUT_ERRORS_UPDATED',
    value
});

export const setSaveDeliveryDate = value => ({
    type: 'SAVE_DELIVERY_DATE',
    value
});

export const setSaveDeliveryTime = value => ({
    type: 'SAVE_DELIVERY_TIME',
    value
});

export const setSaveCheckoutDetails = value => ({
    type: 'SAVE_CHECKOUT_DETAILS',
    value
});