import React, { useState } from 'react';

// ROUTER
import { withRouter } from 'react-router-dom';

// REDUX
import { connect } from 'react-redux';

// GRAPHQL
import * as doughProRepo from '../../shared/repos/graphql/doughPro';

// STYLES
import styles from './DoughProPayments.module.css';

const DoughProPayments = () => {
  const [payments, setPayments] = useState({
    orders: [],
    total: 0
  });

  doughProRepo.doughProPayments().then((res) => {
    setPayments(res.data.doughProPayments || payments);
  });

  const maskGiftcardNumber = (value) => {
    const nums = Number(value).toString().split('');

    for (let i = 0; i < nums.length; i += 1) {
      if (i < nums.length - 2) {
        nums[i] = '*';
      }
    }

    return nums.join('');
  }

  const maskCoupon = (value) => {
    const chars = value.split('');

    for (let i = 0; i < chars.length; i += 1) {
      if (i < chars.length - 2) {
        chars[i] = '*';
      }
    }

    return chars.join('');
  }

  return (
    <div className={styles.doughProPayments}>
      <h2>CookieMagic Payments</h2>
      <table className={styles.table}>
        <thead>
          <tr>
            <td>
              #
            </td>
            <td>
              Date / Time
            </td>
            <td>
              Payment Method
            </td>
            <td>
             Gift Cards
            </td>
            <td>
             Coupons
            </td>
            <td>
              Amount
            </td>
          </tr>
        </thead>
        <tbody>
          {
            payments.orders.map((order) =>
              (
                <tr key={order.code}>
                  <td>
                    {order.code}
                  </td>
                  <td>
                    {order.createdAt}
                  </td>
                  <td>
                    {order.paymentMethod}
                  </td>
                  <td>
                    {order.giftcardIds.length > 0 ? order.giftcardIds.map(num => maskGiftcardNumber(num)).join(', ') : '-'}
                  </td>
                  <td>
                    {order.coupons.length > 0 ? order.coupons.map(coupon => maskCoupon(coupon)).join(', ') : '-'}
                  </td>
                  <td>
                    $
                    {order.total}
                  </td>
                </tr>
            ))
          }
        </tbody>
        {
          payments.orders.length ?
          (
            <tfoot>
              <tr>
                <td colSpan="5">
                  Total
                </td>
                <td>
                  $
                  {payments.total}
                </td>
              </tr>
            </tfoot>
          ) : null
        }
      </table>
    </div>
  );
};

export default withRouter(connect(null, null)(DoughProPayments));
