/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "react-router";
import TagManager from "react-gtm-module";

import { connect } from "react-redux";

import { LoadScript } from "@react-google-maps/api";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import logger from "./shared/utilities/logger";
import ddLog from "./shared/utilities/datadog/log";

import * as elementsActions from "./redux/actions/elements";

import PageContainer from "./pages/PageContainer/PageContainer";

import "./App.css";
import { store } from "./redux/store";
import { GOOGLE_LIBS } from "./shared/constants/locations";
import { dayModePages, nightModePages, passwordlessNightModeScreen } from "./shared/constants/themePages";

class App extends Component {
  constructor(props) {
    super(props);

    const {
      user,
      showMobileMenu,
      showHeaderWidget,
      categoriesPosition,
      isStickyCategories,
      showDeliveryPickupWidget
    } = this.props;

    this.state = {
      showMobileMenu, // eslint-disable-line
      showHeaderWidget, // eslint-disable-line
      categoriesPosition, // eslint-disable-line
      isStickyCategories, // eslint-disable-line
      showDeliveryPickupWidget // eslint-disable-line
    };

    this.page = React.createRef(); // create self reference

    const scriptEnvironments = ["local", "dev", "production", "staging"];

    if (scriptEnvironments.includes(window.environment.REACT_APP_ENV)) {
      const tagManagerArgs = {
        gtmId: window.environment.REACT_APP_GOOGLE_TAG_MANAGER_ID
      };
      TagManager.initialize(tagManagerArgs);

      logger.initialize(window.environment, user, store.getState());
      ddLog.initialize(window.environment);
    }
  }

  componentDidMount() {
    // set document title
    document.title = "Insomnia"; // eslint-disable-line
    const { history } = this.props;
    // route change listener
    history.listen(() => {
      const { showHeaderWidget, toggleHeaderWidget } = this.props;
      // 1. header widget is rendered, then turn it off
      if (showHeaderWidget) toggleHeaderWidget();
      // 2. scroll to the top of the page on every route change
      window.scrollTo(0, 0); // eslint-disable-line
    });
  }

  render() {
    const {
      showMobileMenu,
      errorMessage,
      history,
      location,
      passwordlessEnabled
    } = this.props;
    const isNightModePage =
    nightModePages.includes(location.pathname) ||
      (passwordlessEnabled &&
        passwordlessNightModeScreen.some(screenPath => {
          const regex = new RegExp(`^${screenPath}$`);
          return regex.test(location.pathname);
      }));

    const isDayModePage = dayModePages.includes(location.pathname)

    let cssClass = isNightModePage
    ? "bg-dark to-darkBlack from-dark bg-gradient-to-br dark:bg-gradient-from-tr dark:bg-gradient-to-b"
    : "dark:from-darkBlack dark:to-dark bg-light dark:bg-gradient-from-tr dark:bg-gradient-to-b";

    cssClass = isDayModePage ? 'bg-light' : cssClass;

    return (
      <LoadScript
        googleMapsApiKey={window.environment.REACT_APP_MAPS_API_KEY}
        libraries={GOOGLE_LIBS}
      >
        <GoogleReCaptchaProvider
          useEnterprise
          reCaptchaKey={window.environment.REACT_APP_ENTERPRISE_RECAPTCHA_SITE_KEY}
          scriptProps={{
            async: true,
            defer: true,
          }}
        >
          <div
            className={`App ${cssClass}`}
          >
            <PageContainer
              showMobileMenu={showMobileMenu}
              errorMessage={errorMessage}
              history={history}
            />
          </div>
        </GoogleReCaptchaProvider>
      </LoadScript>
    );
  }
}

export const mapStateToProps = state => {
  const {
    showMobileMenu,
    showHeaderWidget,
    categoriesPosition,
    isStickyCategories,
    showDeliveryPickupWidget
  } = state.elements;

  return {
    showMobileMenu,
    showHeaderWidget,
    categoriesPosition,
    isStickyCategories,
    showDeliveryPickupWidget,
    user: state.user,
    passwordlessEnabled: state.footerSettings.passwordlessEnabled
  };
};

export const mapDispatchToProps = dispatch => ({
  toggleHeaderWidget: () => dispatch(elementsActions.toggleHeaderWidget())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
