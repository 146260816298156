import React, { useEffect, useState } from 'react';
import { propTypesComponentPaymentInput } from '../../shared.propTypes';
import styles from '../PaymentInputCreditCardLegacy/PaymentInputCreditCardLegacy.module.css';
import SelectionItem from '../../../SelectionItem/SelectionItem';
import stylesShared from '../../shared.module.css';
import Button from '../../../Button/Button';
import { keyDownA11y } from '../../shared';

const ccIcon = require('../../imgs/cc-icon.svg');

const handleSubmit = (card, storePaymentMethod, callback) => {
  const paymentCredentialSubmitted = {
    summary: `Card ending in ${card.last4}`,
    storePaymentMethod,
    paymentCredential: {
      paymentMethodId: Number(card.paymentMethodId),
      credential: card.transactionId,
      securityCode: null,
      saveCredentials: false,
      paymentProcessorId: Number(card.paymentProcessorId),
    }
  };

  callback(paymentCredentialSubmitted);
};

const PaymentInputCreditCardSavedList = props => {

  const {
    storePaymentMethod,
    onSubmitPaymentCredentials,
    paymentCredentialSubmitted,
    onCancel,
    hideCancel,
    paymentMethodsCustomerSaved,
    hidden,
    onAddNewCardClick
  } = props;

  const [selectedCard, setSelectedCard] = useState(
    paymentMethodsCustomerSaved && paymentMethodsCustomerSaved.length === 1 ? paymentMethodsCustomerSaved[0] : null
  );

  useEffect(() => {
    // if the submitted payment credential changed, and it's not a saved card in the saved card list,
    // then unselect the selected saved card
    if (!(paymentCredentialSubmitted && paymentMethodsCustomerSaved && setSelectedCard)) return;

    const {
      paymentCredential: { credential }
    } = paymentCredentialSubmitted;
    const isCredentialInCustomerSavedList = paymentMethodsCustomerSaved.some(
      card => card.transactionId === credential
    );
    if (!isCredentialInCustomerSavedList) {
      setSelectedCard(null);
    }
  }, [paymentCredentialSubmitted, paymentMethodsCustomerSaved, setSelectedCard]);

  return (
    <div hidden={hidden}>
      <p className={styles.sectionSubHeader}>Saved Payment Methods</p>
      {paymentMethodsCustomerSaved && paymentMethodsCustomerSaved.length > 0
        ? paymentMethodsCustomerSaved.map(card => (
          <SelectionItem
            key={card.last4}
            main={false}
            asset={ccIcon}
            label={card.cardType}
            header={`Ending ${card.last4}`}
            isSelected={card.last4 === selectedCard?.last4}
            onSelect={() => setSelectedCard(card)}
          />
          ))
        : null}
      <div
        className={[stylesShared.footerActions, stylesShared.footerActionLeft].join(' ')}
        onClick={onAddNewCardClick}
        hidden={hideCancel}
        onKeyDown={keyDownA11y(onAddNewCardClick)}
        role="button"
        tabIndex={0}
      >
        Add New Card
      </div>
      <div className={styles.buttonContainer}>
        <Button
          disabled={!selectedCard}
          handleSubmit={() => {
            handleSubmit(selectedCard, storePaymentMethod, onSubmitPaymentCredentials);
          }}
          label="Save Changes"
        />
        {!hideCancel ? (
          <div
            className={stylesShared.footerActions}
            hidden={hideCancel}
            onClick={onCancel}
            onKeyDown={keyDownA11y(onCancel)}
            role="button"
            tabIndex={0}
          >
            Change Payment Method
          </div>
        ) : null}
      </div>
    </div>
  );
};

PaymentInputCreditCardSavedList.propTypes = propTypesComponentPaymentInput;

export default PaymentInputCreditCardSavedList;
