// eslint-disable import/prefer-default-export
import { createTransform } from 'redux-persist';
import moment from 'moment';
import * as userReducer from '../../../redux/reducers/userReducer';

delete userReducer.cleanState.userToken;
delete userReducer.cleanState.userId;

export const expireKeys = () => {
    return {
        user: userReducer.cleanState
    };
};

export const expireStateTransform = createTransform(
    (inboundState) => {
        return inboundState;
    },
    (outboundState, key) => {

        const expirationSeconds = parseInt(window.environment.REACT_APP_STORAGE_EXPIRATION_SECONDS, 10);
        const expirationTime = moment(outboundState.persistedAt).add(expirationSeconds, 'seconds');
        const stateHasExpired = moment().isSameOrAfter(expirationTime);

        const state = outboundState;
        if (stateHasExpired) {
            const expireKey = expireKeys()[key];

            Object.keys(expireKey).forEach(index => {
                state[index] = expireKey[index];
            });
        }

        return state;
    },
    { 
        whitelist: Object.keys(expireKeys())
    }
);
