import siteSetting from '../../shared/constants/siteSetting';

const cleanState = {
    [siteSetting.IS_CONTACT_US_FEEDBACK_ENABLE]: false,
    [siteSetting.IS_CONTACT_US_GENERAL_ENABLE]: false,
    [siteSetting.IS_CONTACT_US_EVENTS_ENABLE]: false,
    [siteSetting.IS_CONTACT_US_REAL_ESTATE_ENABLE]: false,
    [siteSetting.IS_CONTACT_US_VENDOR_ENABLE]: false,
    [siteSetting.IS_CONTACT_US_PRESS_ENABLE]: false,
    [siteSetting.IS_CONTACT_US_MERCH_ENABLE]: false,

    [siteSetting.CONTACT_US_FEEDBACK_EMAILS]: 'feedback_emails',
    [siteSetting.CONTACT_US_GENERAL_EMAILS]: 'general_emails',
    [siteSetting.CONTACT_US_EVENTS_EMAILS]: 'events_emails',
    [siteSetting.CONTACT_US_REAL_ESTATE_EMAILS]: 'real_estate_emails',
    [siteSetting.CONTACT_US_VENDOR_EMAILS]: 'vendor_emails',
    [siteSetting.CONTACT_US_PRESS_EMAILS]: 'press_emails',
};
const initialState = {
    ...cleanState
};

export default (state = initialState, { type, value }) => {
    switch (type) {
        case 'SET_CONTACT_US_SETTINGS':
            return {...state, contactUsSettings: value};
        default:
            return state;
    }
}
