import React from 'react';
import ReactMarkdown from 'react-markdown';
import { propTypesComponentPaymentInput } from '../../shared.propTypes';
import cmsKeys from '../../../../shared/constants/cms';
import { getValueByKeyFromCmsContent } from '../../../../shared/repos/cms/cms';
import styles from './PaymentInputGiftCard.module.css';

import Loader from '../../../Loader/Loader';
import Discounts from '../../../../pages/ReviewOrder/Discounts/Discounts';

const { callouts } = cmsKeys.checkoutPage.payments.discounts;

const getCallout = (cmsContent, isLoggedIn, isDoughProMember) => {
  switch (true) {
    case isDoughProMember:
      return getValueByKeyFromCmsContent(callouts.subscriber, cmsContent);
    case isLoggedIn:
      return getValueByKeyFromCmsContent(callouts.nonsubscriber, cmsContent);
    default:
      return getValueByKeyFromCmsContent(callouts.guest, cmsContent);
  }
};

const PaymentInputGiftCard = ({
  cmsContent,
  isLoggedIn,
  isDoughProMember,
  onSubmitPaymentCredentials,
  storePaymentMethod
}) => {
  const callout = getCallout(cmsContent, isLoggedIn, isDoughProMember);
  const setPaymentCredentials = pc => {
    if (!pc || !pc.credential) {
      return;
    }

    const paymentCredentialSubmitted = pc? {
      summary: `Gift card ending in ${pc.credential
        .trimEnd()
        .substr(pc.credential.trimEnd().length - 4)}`,
      storePaymentMethod,
      paymentCredential: {
        paymentMethodId: storePaymentMethod.paymentMethodId,
        credential: pc.credential.trimEnd()
      }
    } : null;

    onSubmitPaymentCredentials(paymentCredentialSubmitted);
  };

  return (
    <div className={[styles.detailsSection, 'paymentMethodArea'].join(' ')}>
      <div className={styles.discountContainer}>
        <Discounts setPaymentCredentials={setPaymentCredentials} />
        {!cmsContent.length ? <Loader /> : null}
        {cmsContent.length && callout ? (
          <p className={styles.callout}>
            <ReactMarkdown>{callout}</ReactMarkdown>
          </p>
        ) : null}
      </div>
    </div>
  );
};

PaymentInputGiftCard.propTypes = propTypesComponentPaymentInput;

export default PaymentInputGiftCard;
