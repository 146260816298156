import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from "prop-types";
import styles from './HeroBannerIntro.module.css';
import elementConstant from "../../../../../shared/constants/element";

const HeroBannerIntro = (props) => {
  const {introHeroBanner, contentCardRefreshDelay} = props;
  const { extras } = introHeroBanner;
  const {desktopVideoUrl, mobileVideoUrl, mime} = extras;
  const propWebKitPlaysInline = {"webkit-playsinline": "webkit-playsinline"};

  const [videoUrl, setVideoUrl] = useState(desktopVideoUrl);
  const [fadeOutClass, setFadeOutClass] = useState(null);
  useEffect(() => {
    const updateVideo = () => {
      setVideoUrl(window.innerWidth <= elementConstant.introHeroBannerWidthThreshold ? mobileVideoUrl : desktopVideoUrl);
    }
    updateVideo();
    setTimeout(() => {
      setFadeOutClass(styles.fadeOut);
    },parseInt(contentCardRefreshDelay, 10) - elementConstant.introBannerReduceTimeoutOffset)
  },[]);

  return (
    <div className={[styles.videoContainer, fadeOutClass].join(' ')}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption,react/jsx-props-no-spreading */}
      <video autoPlay muted loop playsInline {...propWebKitPlaysInline}>
        <source src={videoUrl} type={mime} />
      </video>
    </div>
  );
}

HeroBannerIntro.propTypes = {
  introHeroBanner: PropTypes.shape(
    {
      url: PropTypes.string,
      extras: PropTypes.shape(
        {
          template: PropTypes.string,
          desktopVideoUrl: PropTypes.string,
          mobileVideoUrl: PropTypes.string,
          mime: PropTypes.string,
        })
    }).isRequired,
  contentCardRefreshDelay: PropTypes.number.isRequired
}

export const mapStateToProps = (state) => {
  const {
    introHeroBanner,
    contentCardRefreshDelay
  } = state.braze;

  return {
    introHeroBanner,
    contentCardRefreshDelay
  };
}

export default connect(mapStateToProps)(HeroBannerIntro);