export default {
    subtotal: {
        title:  'Subtotal',
    },
    tax: {
        title: 'Tax',
    },
    shipping: {
        title: 'Shipping',
    },
    coupons: {
        title: 'Coupons',
    },
    loyalty: {
        title: 'Cookie Dough',
    },
    tip: {
        title: 'Tip',
    },
    total: {
        title: 'Total',
    },
    delivery: {
        title: 'Delivery'
    },
    serviceFee: {
        title: 'Service Fee',
    },
}
