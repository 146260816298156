export const storeIds = {
    shipping: 4,
    sampleMenu: 2000,
    doughPro: 2010,
    testStore: 1166,
    cookieLab: 2033,
    giftCard: 28,
};

export const shippingMethods = {
    pickup: 3,
    delivery: 4,
    shipping: 0,
    eGiftCard: 10,
};


export const storeTypes = {
    COMINGSOON: 'COMINGSOON'
}

export const isSampleStore = (storeId) => {
    return storeId && storeIds.sampleMenu === storeId;
}