import Appboy from "@braze/web-sdk";
import { store } from "../../redux/store";

export const filterContentCards  = (contentCards, templates = null, storeId = null) => {
  const { selectedCountry } = store.getState().country;
  return contentCards?.cards.filter(card => {
    const showCountryIds = (card.extras?.showCountryIds || "")
      .split(',')
      .filter(item => item)
      .map(item => {
        return parseInt(item.trim(), 10);
      })

    const hideCountryIds = (card.extras?.hideCountryIds || "")
      .split(',')
      .filter(item => item)
      .map(item => {
        return parseInt(item.trim(), 10);
      })

    const selectedCountryId = parseInt(selectedCountry.id, 10);
    if (
      showCountryIds.length > 0 &&
      !showCountryIds.includes(selectedCountryId)
    ) {
      return false;
    }

    if (
      hideCountryIds.length > 0 &&
      hideCountryIds.includes(selectedCountryId)
    ) {
      return false;
    }

    const showStoreIds = (card.extras?.showStoreIds || "")
      .split(',')
      .filter(item => item)
      .map(item => {
        return parseInt(item.trim(), 10);
      })

    const hideStoreIds = (card.extras?.hideStoreIds || "")
      .split(',')
      .filter(item => item)
      .map(item => {
        return parseInt(item.trim(), 10);
      })

    const hasShowStoreIds = showStoreIds.length > 0;
    const hasHideStoreIds = hideStoreIds.length > 0;
    const shouldShowForSelectedStore = showStoreIds.includes(storeId);
    const shouldHideForSelectedStore = hideStoreIds.includes(storeId);

    const template = templates.includes(card?.extras?.template);

    if (!template) {
      return false;
    }
    if (hasShowStoreIds) {
      return shouldShowForSelectedStore;
    }
    if (hasHideStoreIds) {
      return !shouldHideForSelectedStore;
    }

    return true;
  }).sort((a, b) => {
    const aSort = a?.extras?.sort;
    const bSort = b?.extras?.sort;

    if (aSort < bSort)
      return -1;
    if (aSort > bSort)
      return 1;
    return 0;
  })
}

export const sendGeolocationToBraze = () => {
  navigator.geolocation.getCurrentPosition((position) => {
    Appboy.getUser().setLastKnownLocation(
      position.coords.latitude,
      position.coords.longitude,
      position.coords.accuracy,
      position.coords.altitude,
      position.coords.altitudeAccuracy
    );
  });
}
