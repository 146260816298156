/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Loader from '../../components/Loader/Loader';
import styles from './Countries.module.css'
import {getCountries} from "../../shared/repos/graphql/country";
import * as countryActions from '../../redux/actions/country';
import * as userActions from '../../redux/actions/user';
import * as customCakeTopperActions from '../../redux/actions/customCakeTopper';
import * as currencyActions from '../../redux/actions/currency';
import { clearStorage } from '../../shared/utilities/common';

class Countries extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allCountries: []
        };
    }

    componentDidMount() {
        const { setAllCountries } = this.props;
        const countries = async () => {
            await getCountries().then((res) => {
              if (!res || !res.data || !res.data.countries) {
                  return;
              }
              const { all } = res.data.countries;
              this.setState({
                  allCountries: all
              });
            });
        };
        setAllCountries(countries());

    }

    async handleChangeCountry(country) {
      const {selectedCountry, setSelectedCountry, resetUserData, resetCustomCakeTopperData, resetCurrencyData} = this.props;
      if (selectedCountry === undefined || selectedCountry.id === null || country.id !== selectedCountry.id) {
        /* eslint-disable camelcase */
        if (selectedCountry?.zone_id !== country.zone_id) {
          resetCustomCakeTopperData();
          resetCurrencyData();
          resetUserData();
          clearStorage();
        }
        await setSelectedCountry(country);

        window.location.href = `/${country.path_url ?? ''}`;
      }
    }

    render() {

        const { allCountries } = this.state;
        const { selectedCountry } = this.props;
        if (!allCountries.length) {
            return (
              <div className={styles.pageWrapper}>
                <div className={styles.pageContainer}>
                  <Loader id="loader" />
                </div>
              </div>
            );
        }
        return (
          <div className={`${styles.pageWrapper} text-dark dark:text-white`}>
            <div className={styles.pageContainer}>
              <h1 className='mb-8'>Choose Your Country or Region</h1>
              {allCountries.map((country) =>
                  (
                    <div key={country.id}>
                      <div className={styles.pageSection}>
                        <p>
                          {/* eslint-disable jsx-a11y/interactive-supports-focus */}
                          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                          <span
                            role="button"
                            className={[selectedCountry && selectedCountry.id === country.id ? styles.currentCountry : ''].join(',')}
                            onClick={() => this.handleChangeCountry(country)}
                          >
                            {country.name}
                          </span>
                        </p>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        );
    }
};

Countries.propTypes = {
    setSelectedCountry: PropTypes.func.isRequired,
    setAllCountries: PropTypes.func.isRequired,
    resetUserData: PropTypes.func.isRequired,
    resetCustomCakeTopperData: PropTypes.func.isRequired,
    resetCurrencyData: PropTypes.func.isRequired,
    selectedCountry: PropTypes.shape(
      {
        id: PropTypes.number,
        name: PropTypes.string,
        path_url: PropTypes.string,
        api_url: PropTypes.string,
        short_name: PropTypes.string,
        url: PropTypes.string,
        zone_id: PropTypes.string,
        locale_id: PropTypes.string,
        phone_prefix_id: PropTypes.number,
        currency: {
            code: PropTypes.string,
            name: PropTypes.string,
            symbol: PropTypes.string,
          }
      }
    ),
}

Countries.defaultProps = {
  selectedCountry: undefined
}

export const mapStateToProps = (state) => {
    const {
        selectedCountry,
        allCountries
    } = state.country;
    return {
        selectedCountry,
        allCountries
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setAllCountries: (value) => dispatch(countryActions.setAllCountries(value)),
    setSelectedCountry: (value) => dispatch(countryActions.setSelectedCountry(value)),
    resetUserData: () => dispatch(userActions.resetUserData()),
    resetCustomCakeTopperData: () => dispatch(customCakeTopperActions.resetCustomCakeTopperData()),
    resetCurrencyData: () => dispatch(currencyActions.resetCurrencyData()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps) (Countries));
