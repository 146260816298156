import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DeliveryPickUpWidget from "../../DeliveryPickUpWidget/DeliveryPickUpWidget";

const DeliveryIcon = require("./icons/delivery.svg");
const MapPinIcon = require("./icons/map-pin.svg");
const PickupIcon = require("./icons/pickup.svg");

function Delivery(props) {
  const { userAddress, showWidget, setShowDeliveryWidget } = props;
  const [addressType, setAddresType] = useState("delivery");
  const disableCssClass =
    " opacity-60 bg-opacity-60 border-opacity-60 text-opacity-60";
  const hoverCssClass =
    " hover:opacity-100 hover:bg-opacity-100 hover:border-opacity-100 hover:text-opacity-100";

  const [showModal, setShowModal] = useState(showWidget);

  useEffect(() => {
    setShowModal(showWidget);
  }, [showWidget]);

  const onCloseWidget = () => {
    setShowModal(false);
    setShowDeliveryWidget(false);
  };

  return (
    <>
      {!userAddress ? (
        <div className="md:flex items-center flex-col gap-4 lg:w-2/5 w-full mx-auto mt-14 hidden">
          <div className="flex gap-4 w-full lg:w-96 px-4 lg:px-0  item-center">
            <button
              type="button"
              onClick={() => setAddresType("delivery")}
              className={`
              flex flex-row justify-center items-center 
              lg:w-[183px] w-full px-4 py-1 gap-2 h-11 bg-transparent border 
              border-[#B9A9C0] dark:border-darkBorder border-solid rounded-[54px] 
              text-dark dark:text-white
              font-filsonProBold
              ${addressType === "delivery" ? "opactity-100" : disableCssClass}
              ${hoverCssClass}
            `}
            >
              <img src={DeliveryIcon} alt="delivery" />
              Delivery
            </button>
            <button
              type="button"
              onClick={() => setAddresType("pickup")}
              className={`
            flex flex-row justify-center items-center 
            lg:w-[183px] w-full px-4 py-1 gap-2 h-11 bg-transparent border 
            border-[#B9A9C0] dark:border-darkBorder border-solid rounded-[54px] 
            text-dark dark:text-white
            font-filsonProBold
              ${addressType === "pickup" ? "opactity-100" : disableCssClass}
              ${hoverCssClass}
            `}
            >
              <img src={PickupIcon} alt="pickup" />
              Pickup
            </button>
          </div>
          <div
            id="input_delivery_address"
            className="w-full px-4 lg:px-0 cursor-pointer"
            onClick={() => setShowModal(true)}
            onKeyDown={() => setShowModal(true)}
            role="button"
            tabIndex={0}
          >
            <div
              className="flex flex-row items-center 
            gap-3 h-11 border border-pink-50 dark:border-mainDark 
            rounded-[54px] py-3 px-4 w-full bg-white text-dark dark:text-white 
            leading-5 dark:bg-gradient-r dark:bg-gradient-to-l dark:from-darkBlack dark:to-darkBlack"
            >
              <img src={MapPinIcon} alt="map-pin" className="w-5 h-5" />
              <p id="input_placeholder_text">{`Enter ${addressType} address`}</p>
            </div>
          </div>
          <div className="w-full px-4 lg:px-0 text-center">
            <p className="w-full text-center text-darkElevationPrimary dark:text-darkElevationSecondary ">
              {addressType === "pickup"
                ? "Fresh, warm cookies ready for pickup"
                : "Fresh, warm cookies delivered right to your door"}
            </p>
          </div>
        </div>
      ) : null}
      <div id="deliveryPickUpWidget">
        {showModal ? (
          <DeliveryPickUpWidget
            onClose={onCloseWidget}
            onAddressType={setAddresType}
            addressType={addressType}
          />
        ) : null}
      </div>
    </>
  );
}

Delivery.propTypes = {
  productId: PropTypes.any, // eslint-disable-line
  userOrderMethod: PropTypes.string, // eslint-disable-line
  userAddress: PropTypes.shape({ address1: PropTypes.string }),
  showWidget: PropTypes.bool,
  setShowDeliveryWidget: PropTypes.func
};

Delivery.defaultProps = {
  productId: null,
  userOrderMethod: "",
  userAddress: null,
  showWidget: false,
  setShowDeliveryWidget: null
};

export const mapStateToProps = state => {
  const {
    userToken,
    userAddress,
    userCartId,
    userOrderMethod,
    userCart,
    selectedStore
  } = state.user;
  const { isShippingActive } = state.shipping;
  const {
    selectedCountry,
    allCountries,
    isCountrySelectActive
  } = state.country;
  return {
    userToken,
    userAddress,
    userCartId,
    userOrderMethod,
    userCart,
    selectedStore,
    isShippingActive,
    selectedCountry,
    allCountries,
    isCountrySelectActive
  };
};

export default withRouter(connect(mapStateToProps, null)(Delivery));
