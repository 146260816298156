export const nightModePages = [
    '/user/loyalty-rewards'
]


export const passwordlessNightModeScreen = [
    '/login',
    '/create-account',
    '/welcome',
    '/checkout/loyalty-rewards/([^/]+)',
]

export const dayModePages = [
    '/insomniarewards'
]