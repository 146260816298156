/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';

import PropTypes from 'prop-types';
// COMPONENTS
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';

// SUB PAGE COMPONENTS
import SingleProduct from './SingleProduct/SingleProduct';
import CustomizeProduct from './CustomizeProduct/CustomizeProduct';
import BoxProduct from './BoxProduct/BoxProduct';

// HELPERS
import { checkPath, routeCountryPath } from '../../shared/utilities/common';

import styles from './Product.module.css';
import { PRODUCT_QUERY } from '../../graphql/product';
import * as tagmanagerEvents from '../../shared/utilities/tagmanagerEvents';
import * as siteSettingRepo from '../../shared/repos/graphql/siteSetting';
import * as orderRepo from "../../shared/repos/graphql/order";
import { OrderEvent } from '../../shared/constants/OrderEvent';
import { isSampleStore } from '../../shared/constants/store';

import * as loyaltyAction from '../../redux/actions/loyalty';
import { PUNCHH_TYPE } from '../../shared/constants/loyaltyType';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { order, userToken, setRedeemableItem, match} = this.props;
    if (userToken) {
      const orderRedeemable = order?.orderRedeemables?.find(p => {
        return p.order_product_id === match?.params?.poid
      });

      if (orderRedeemable) {
        setRedeemableItem({
          redeemableId: orderRedeemable?.external_id, // eslint-disable-line
          redeemableType: orderRedeemable?.redemption_group === "redeemable"  //eslint-disable-line
          ? PUNCHH_TYPE.REDEEMABLE
          : PUNCHH_TYPE.DEALS
        })
      }
    }
  }

  componentWillUnmount() {
    const { setRedeemableItem } = this.props;
    setRedeemableItem({
      redeemableType: null,
      redeemableId: null
    })
  }

  /**
   * Trigger Custom Cake Topper Conditionally
   *
   * @param productID
   * @param redirectProduct
   * @returns {Promise<boolean>}
   */
  triggerCustomCakeTopper = async (productID, redirectProduct) => {
    const {history, cakeTopperProductID, location} = this.props;
    const { displayProduct } = location.state || {displayProduct: false};
    const { isCartUpdate } = location.state || {isCartUpdate: false};

    const response = await siteSettingRepo.getSiteSettings(['topperoo']);
    const siteSettings = response.data.siteSettings[0];

    if ((siteSettings!=null && siteSettings.value === '1') && (productID === cakeTopperProductID) && (!displayProduct && !isCartUpdate)) {
      return history.push(routeCountryPath(`/products/custom-cake-topper/${cakeTopperProductID}`), {redirectProduct});
    }
    return true;
  };

  productContainer = () => {
    const isSinglePath = checkPath(this, '/products/single');
    const isCustomizePath = checkPath(this, '/products/customize');
    const isBoxPath = checkPath(this, '/products/box');
    const isReviewPath = checkPath(this, '/checkout/view-order/');

    const { match, redeemables, selectedStore, order, userCartId, isShowOosProducts } = this.props;
    const { params } = match;
    const { id } = params;
    const { data, loading, error } = useQuery(PRODUCT_QUERY, {
      variables: {
        id,
        isShowOosProducts,
      },
      skip: !id,
    });

    // NETWORK ERROR UI
    if (error) {
      return(
        <Error errorType="400" />
      )
    }
    // LOADING UI
    if (loading || !data || !data.product ) {
      return (
        <div className={styles.loadingPageContainer}>
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        </div>
      );
    }
    const { product } = data;

    tagmanagerEvents.viewItem(product);

    // Load Topperoo Widget conditionally when
    // Enabled from site settings backend
    let redirectProduct = 'customize';
    if (isBoxPath) {
        redirectProduct = 'box';
    }
    if (isSinglePath) {
      redirectProduct = 'single';
    }
    const isStoreSelected = product.id && selectedStore?.id && !isSampleStore(selectedStore.id);
    const orderCode = order?.code || userCartId;

    if (isStoreSelected && orderCode) {
      const selectedStoreId = selectedStore.id.toString();
      orderRepo.orderEvent(OrderEvent.PRODUCT_VIEWED, orderCode, {productId: product.id, storeId: selectedStoreId})
    }

    this.triggerCustomCakeTopper(product.id, redirectProduct);
    return (
      <div
        className={`${styles.productPageWrapper} w-full dark:bg-gradient-to-b dark:from-darkBlack dark:to-dark bg-white border-0`}
      >
        {/* <div className={styles.productBackground} /> */}
        {/* <div className={styles.solidBackground} /> */}
        {/* <div className={styles.tearSheetBackground} /> */}
        <div
          className={`${styles.productPageContainer} lg:max-w-screen-desktop w-full lg:py-8 m-auto`}
        >
          {isSinglePath ? (
            <SingleProduct
              updateProduct={isReviewPath}
              loyalty={redeemables?.redeemableType}
            />
          ) : null}
          {isCustomizePath ? (
            <CustomizeProduct
              updateProduct={isReviewPath}
              loyalty={redeemables?.redeemableType}
            />
          ) : null}
          {isBoxPath ? (
            <BoxProduct
              updateProduct={isReviewPath}
              loyalty={redeemables?.redeemableType}
            />
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    return <this.productContainer />;
  }
}

Product.propTypes = {
  cakeTopperProductID: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isShowOosProducts: PropTypes.bool,
};

Product.defaultProps = {
  cakeTopperProductID: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_PRODUCT_ID,
  isShowOosProducts: false,
};

export const mapStateToProps = (state) => {
  const {
    userCartId,
    userCart,
    userToken,
    selectedStore
  } = state.user;
  const { order } = state;
  const { isShowOosProducts } = state.product;
  const {redeemables} = state.loyalty;
  return { userCartId, userCart, redeemables, order, userToken, selectedStore, isShowOosProducts };
};

export const mapDispatchToProps = dispatch => ({
  setRedeemableItem: value => dispatch(loyaltyAction.setRedeemableItem(value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Product);

