/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import _ from "underscore";
import * as userActions from "../../../redux/actions/user";
import * as elementActions from "../../../redux/actions/elements";
import * as orderRepo from '../../../shared/repos/graphql/order';
import * as productRepo from "../../../shared/repos/graphql/product";
import * as rendering from '../../../shared/utilities/renderings';
import * as productConstants from '../../../shared/constants/product';
import { defaultCurrency } from '../../../shared/constants/currency';

import { shippingMethods, storeIds } from '../../../shared/constants/store';
import { orderTypeIds, orderMethods } from '../../../shared/constants/order';

import DeliveryPickUpWidget from '../../../components/DeliveryPickUpWidget/DeliveryPickUpWidget';
import Loader from '../../../components/Loader/Loader';
// COMPONENTS
import CheckoutCartButton from "../../../components/CheckoutCartButton/CheckoutCartButton";
import QuickCheckoutButton from "../../../components/QuickCheckoutButton/QuickCheckoutButton";

import ProductOptions from "../../../components/ProductOptions/ProductOptions";
import SizeChart from "../../../components/SizeChart/SizeChart";

import * as tagmanagerEvents from '../../../shared/utilities/tagmanagerEvents';

import styles from "./CustomizeProduct.module.css";
import endpoints from "../../../shared/constants/endpoints";
import { historyAction } from "../../../shared/constants/history";
import { handleProductWithDirectLink } from "../../../shared/utilities/product";

import { getErrorMessages, getErrorReasons, routeCountryPath } from "../../../shared/utilities/common";
import errorCodes from "../../../shared/constants/errorCodes";
import { clearCart, isDeliveryOrPickup } from "../../../shared/utilities/orders";
import { openOrderUpdateFailModal } from "../../../shared/utilities/modals";
import { store } from "../../../redux/store";
import * as loyaltyAction from "../../../redux/actions/loyalty";
import { calculateLoyaltyPoints } from "../../../shared/utilities/loyalty";
import { PUNCHH_TYPE } from "../../../shared/constants/loyaltyType";
import PresetBoxes from "../PresetBoxes/PresetBoxes";

const backIcon = require('../imgs/back-icon.svg');
const lightBackIcon = require('../imgs/light-back-icon.svg');

class CustomizeProduct extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    this.state = {
      item: null,
      customizeProducts: null,
      productPrice: 0.0,
      quantity: null,
      updateCartLoading: false,
      updatePriceLoading: false,
      productAddError: '',
      locationKey: history.location.key,
      points: 0,
      presetSelectionId: 0
    };

  }

  async componentDidMount() {
    await this.setProductData();
    handleProductWithDirectLink(this);
  }

  async componentDidUpdate(prevProps) {
    const { selectedStore, history, location } = this.props;
    const { locationKey } = this.state;

    if (selectedStore && selectedStore !== prevProps.selectedStore) {
      await this.setProductData();
    }
    if (history.action === historyAction.pop && location.key !== locationKey) {
      history.goBack()
    }
  }

  setProductData = async () => {
    const { match, userCart, loyalty, redeemables, isShowOosProducts } = this.props;
    const { params } = match;
    const { id, poid } = params;
    const selectedItem = userCart.find(item => item.id === poid);
    const { customizeProducts, quantity } = this.state;
    let selectedOptions = customizeProducts
    this.setState({
      updatePriceLoading: true,
      updateCartLoading: true,
      item: selectedItem,
    });
    const redeemableId = redeemables?.redeemableId ? redeemables?.redeemableId : null;
    const { data } = await productRepo.getProduct(id, redeemableId, isShowOosProducts);
    const { product } = data;
    this.setState({
      product,
      productPrice: product.price,
      updatePriceLoading: false,
      updateCartLoading: false,
    })

    if (!selectedOptions) {
      selectedOptions = rendering.getSelectedOptions(product, selectedItem);
    }
    if (!quantity) {
      this.setState({
        quantity: selectedItem ? selectedItem.quantity : 1
      });
    }
    const options = rendering.checkSelectedOptions(product, selectedOptions);
    this.setState({ customizeProducts: options });
    if (loyalty) {
      this.getProductLoyaltyPoints(loyalty, id, poid);
    }
  }

  getProductLoyaltyPoints = (loyalty, id, poid) => {
    const {order, loyaltyPoints, loyaltyDeals, loyaltyRedeemables, history} = this.props;
    const loyaltyProducts = loyalty === PUNCHH_TYPE.REDEEMABLE ? loyaltyRedeemables : loyaltyDeals
    try {
      const points = calculateLoyaltyPoints(
        loyalty,
        id,
        poid,
        loyaltyPoints,
        loyaltyProducts,
        order
      );
      this.setState({
        points
      })
    } catch (error) {
      history.push(endpoints.loyalty);
    }
  }

  /**
   * Handler for when an option is added or remove.
   *
   * @author Elizabeth Clouser <elizabeth@somepage.com>
   */
  updateOptions = customizeProducts => {
    this.setState({ customizeProducts });
  };

  /**
   * Check to see if obj/param has data/value
   *
   * @param {any} obj
   */
  isEmpty = obj => {
    if (obj == null) return true;
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;
    if (typeof obj !== "object") return true;

    return true;
  }

  handleProductUpdate = async () => {

    const { userCartId, history, setUserCart, loyalty, redeemables } = this.props;
    const { item, product, quantity, customizeProducts, presetSelectionId } = this.state;

    const customizeProductsArray = [];
    Object.values(customizeProducts).forEach(array =>
      array.forEach(el => customizeProductsArray.push(el))
    );

    tagmanagerEvents
      .addToCart({ ...product, quantity, price: (product.price * quantity).toFixed(2) });

    const productArrayWithCategoryOptionId = [];
    Object.entries(customizeProducts).forEach(([key, value]) => {
      productArrayWithCategoryOptionId.push({
        productIds: value,
        groupOptionId: key
      });
    });
    
    const updateRes = await orderRepo.addUpdateOrderProduct(userCartId, {
      orderProductId: parseInt(item.id, 10),
      product: product.id, quantity,
      price: product.price,
      productOptions: productArrayWithCategoryOptionId,
      redeemableId: loyalty === PUNCHH_TYPE.REDEEMABLE ? redeemables?.redeemableId: null,
      dealId: loyalty === PUNCHH_TYPE.DEALS ? redeemables?.redeemableId: null,
      presetSelectionId: presetSelectionId,
    });

    const order = updateRes.data.updateProductOrder;

    const localStorageCart = order.items.map(item1 => item1);
    setUserCart(localStorageCart);

    history.push(routeCountryPath(`/checkout/view-order/`));
  };

  handleAddItemToCart = async (orderCode, quickCheckout = false) => {
    const {
      setUserCart,
      history,
      userOrderMethod,
      loyalty,
      loyaltyPoints,
      redeemables,
    } = this.props;

    const { product, quantity, customizeProducts, points, presetSelectionId } = this.state;

    const customizeProductsArray = [];
    const productArrayWithCategoryOptionId = [];
    Object.values(customizeProducts).forEach(array =>
      array.forEach(el => customizeProductsArray.push(el))
    );

    Object.entries(customizeProducts).forEach(([key, value]) => {
      productArrayWithCategoryOptionId.push({
        productIds: value,
        groupOptionId: key
      });
    });
    // Assign cake topper image ID to specific it's product
    const urlParams = new URLSearchParams(window.location.search);
    const isUpsaleString = urlParams.get("isUpsale");
    const isUpsale = isUpsaleString === "true";
    const data = {
      product: product.id,
      quantity,
      productOptions: productArrayWithCategoryOptionId,
      isUpsale,
      redeemableId: loyalty === PUNCHH_TYPE.REDEEMABLE ? redeemables?.redeemableId: null,
      dealId: loyalty === PUNCHH_TYPE.DEALS ? redeemables?.redeemableId: null,
      presetSelectionId: presetSelectionId,
    }; // correct order

    if (quickCheckout) {
      tagmanagerEvents.clickQuickCheckout({
        ...product,
        quantity,
        price: (product.price * quantity).toFixed(2)
      });
    } else {
      tagmanagerEvents.addToCart({
        ...product,
        quantity,
        price: (product.price * quantity).toFixed(2)
      });
    }

    let result;
    try {
      result = await orderRepo.addProductToOrder(orderCode, data);
      if ( loyalty ) {
        store.dispatch(loyaltyAction.loyaltyPoints(loyaltyPoints - points));
      }
    } catch (reason) {
      this.setState({ updateCartLoading: false });
      const isReason = reason.graphQLErrors ? getErrorReasons(reason, 0) : "";
      const pickupUnavailable =
        isReason === errorCodes.PICKUP_PRODUCT_UNAVAILABLE_REASON;
      const defaultErrorMessage = reason.graphQLErrors
        ? reason.graphQLErrors[0].message
        : reason.message;
      const errorMessage = pickupUnavailable
        ? productConstants.PICKUP_UNAVAILABLE_PRODUCT_MESSAGE
        : defaultErrorMessage;
      this.setState({
        updateCartLoading: false,
        productAddError: errorMessage
      });

      return {};
    }

    const localStorageCart = result.data.addProductToOrderV2.items.map(
      isItem => isItem
    );
    setUserCart(localStorageCart);

    if (quickCheckout) {
      return result;
    }
    const addLink =
      userOrderMethod === orderMethods.shipping
        ? endpoints.shippingPage
        : endpoints.menu;
    history.push(routeCountryPath(addLink));

    return {};
  };

  setCartStates = () => {
    const { history } = this.props;

    clearCart();
    history.push(routeCountryPath(endpoints.getMenuUrl(null)))
  }

  handleCartCreateError = (reason) => {
    const { setModalObject } = this.props;
    const message = getErrorMessages(reason, 0);
    const reasonType = getErrorReasons(reason, 0);
    const createReason = reasonType === errorCodes.CART_CREATE_ERROR;
    if (createReason) {
      setModalObject(openOrderUpdateFailModal(message, this.setCartStates));
    }
  }


  handleShipping = async () => {

    const { userCartId, setUserCartId } = this.props;
    const { customizeProducts } = this.state;
    const customizeProductsArray = [];

    Object.values(customizeProducts).forEach(array => array.forEach(el => customizeProductsArray.push(el)));

    if (userCartId) {
      return this.handleAddItemToCart(userCartId, false);
    }

    const data = {
      orderTypeId: orderTypeIds.shipping,
      shippingMethod: shippingMethods.shipping,
      storeId: storeIds.shipping
    };

    try {
      const response = await orderRepo.createCart(data);

      const { createCart } = response.data;
      setUserCartId(createCart.code);

      return this.handleAddItemToCart(createCart.code, false);
    } catch (reason) {
      this.handleCartCreateError(reason);
      return null;
    }
  };

  handlePickupDelivery = async (quickCheckout = false) => {

    const {
      userOrderMethod,
      selectedStore,
      userCartId,
      setUserCartId,
    } = this.props;
    const { customizeProducts } = this.state;

    const customizeProductsArray = [];
    Object.values(customizeProducts).forEach(array =>
      array.forEach(el => customizeProductsArray.push(el))
    );

    if (selectedStore) {
      if (!userCartId) {
        let orderTypeId = null;
        let shippingMethod = null;

        if (userOrderMethod === orderMethods.delivery) {
          orderTypeId = 2;
          shippingMethod = 4;
        } else if (userOrderMethod === orderMethods.pickup) {
          orderTypeId = 3;
          shippingMethod = 3;
        }
        let storeId = null;
        if (selectedStore && selectedStore.id) {
          storeId = selectedStore.id;
        }

        const data = {
          orderTypeId,
          shippingMethod,
          storeId
        };

        try {
          const res = await orderRepo.createCart(data);
          const { createCart } = res.data;
          setUserCartId(createCart.code);
          return this.handleAddItemToCart(createCart.code, quickCheckout);
        } catch (reason) {
          this.handleCartCreateError(reason)
        }

      }
      return this.handleAddItemToCart(userCartId, quickCheckout);
    }

    this.setState({ updateCartLoading: false });
    return true;
  };

  handleAddItem = () => {
    const { setModalObject, userOrderMethod, updateProduct } = this.props;
    const { product } = this.state;
    if (product.oos === 1) {
      return {};
    }

    this.setState({
      updateCartLoading: true
    });

    if (updateProduct) {
      return this.handleProductUpdate();
    }

    if (userOrderMethod === 'SHIPPING') {
      return this.handleShipping();
    }

    if (isDeliveryOrPickup(userOrderMethod)) {
      return this.handlePickupDelivery();
    }

    return setModalObject({
      children: (
        <div className={styles.widgetWrapper}>
          <DeliveryPickUpWidget isModal />
        </div>
      )
    });
  };

  handleQuickCheckout = async () => {
    const { userOrderMethod, userCart, userCartId } = this.props;
    const { product } = this.state;
    if (product.oos === 1) {
      return {};
    }
    this.setCartLoading(true);

    if (isDeliveryOrPickup(userOrderMethod)) {
      const orderProductIds = _.pluck(userCart, 'id');
      if (orderProductIds.length) {
        await orderRepo.removeProducts(userCartId, orderProductIds);
      }

      const [res] = await Promise.all([
        this.handlePickupDelivery(true)
      ]);
      const { addProductToOrderV2 } = res.data;

      return { addProductToOrderV2 };
    }

    return {};
  }

  setCartLoading = (value) => {
    this.setState({ updateCartLoading: value });
  }


  getOptionTotals = () => {
    const { product, customizeProducts } = this.state;

    return rendering.getOptionTotal(product, customizeProducts)
  }

  clearPresets = () => {
    this.setState({ customizeProducts: {}, presetSelectionId: 0 });
  }

  onSelect = (preset) => {
    const { productOptionElement } = this.state;

    const boxProducts = {};
    const customizeProducts = {};

    preset.optionGroups.forEach(optionGroup => {
      customizeProducts[optionGroup.id] = [];

      optionGroup.options.forEach(option => {
        boxProducts[option.productId] = [];

        for (let i = 1; i <= option.quantity; i++) {
          const productOption = {
            id: option.productId.toString(),
            productId: option.productId.toString(),
            quantity: option.quantity
          };
          boxProducts[productOption.productId].push(productOption);
          customizeProducts[optionGroup.id].push(productOption.productId);
        }
      });
    });

    window.scrollTo({
      top: productOptionElement.current.offsetTop,
      left: 0
    })

    this.setState({
      customizeProducts,
      presetSelectionId: +preset.id
    });
  };

  setElement = element => {
    this.setState({
      productOptionElement: element
    });
  };

  customizeProductContainer = () => {

    const {
      history,
      updateProduct,
      selectedStore,
      designId,
      cakeTopperProductId,
      cakeTopperUrl,
      isCaloriesActive,
      currency,
      loyalty
    } = this.props;
    const {
      product,
      quantity,
      customizeProducts,
      updateCartLoading,
      productAddError,
      productPrice,
      updatePriceLoading,
      points,
    } = this.state;
    if (!product) {
      return (
        <div className={styles.pageLoaderWrapper}>
          <Loader />
        </div>
      )
    }

    const storeId = selectedStore ? selectedStore.id : storeIds.sampleMenu;

    const checkoutEnabled = this.isCheckoutEnabled();
    const isDisabled = (!checkoutEnabled || updateCartLoading);
    const totalCost = selectedStore && !updatePriceLoading ? (productPrice + this.getOptionTotals()).toFixed(2) : null;
    const addProductText = selectedStore ?
      productConstants.ADD_TO_ORDER_TEXT :
      productConstants.ADD_TO_ORDER_NO_ADDRESS_TEXT;
    const outOfStockText = productConstants.OUT_OF_STOCK_TEXT;
    const addUpdateProductText = updateProduct ?
      productConstants.UPDATE_PRODUCT_TEXT :
      addProductText;
    const productPriceItem = selectedStore && !updatePriceLoading ? `${currency.symbol}${productPrice.toFixed(2)}` : null;
    const isShippingStore = selectedStore && selectedStore.id === storeIds.shipping;
    const calories = isCaloriesActive && product.calories != null ? ` ${product.calories} kCal` : '';
    return (
      <div
        className={`${styles.customizePageContainer} flex justify-between gap-8`}
      >
        <div className={styles.customizeImageContainer}>
          {cakeTopperProductId === product.id && designId ? (
            <img alt="placeholder" src={`${cakeTopperUrl}/${designId}`} />
          ) : (
            <img alt="placeholder" src={product.productImage} />
          )}
        </div>
        <div className={`${styles.customizeDetailsContainer}`}>
          <div
            className={`${styles.detailsHeader} flex flex-col gap-4 items-start`}
          >
            <div className={`${styles.backMenu}`}>
              <h3
                onClick={() =>
                  history.push(
                    routeCountryPath(
                      isShippingStore
                        ? endpoints.shippingPage
                        : endpoints.getMenuUrl(selectedStore)
                    )
                  )}
                className="w-full flex text-dark dark:text-white items-center gap-4 font-filsonProBold"
              >
                <span>
                  <img
                    alt="back"
                    src={backIcon}
                    className="block dark:hidden"
                  />
                  <img
                    alt="back"
                    src={lightBackIcon}
                    className="hidden dark:block"
                  />
                </span>
                Back to Menu
              </h3>
            </div>
            {loyalty ? (
              <span className=" text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
                {points}
                {' pts'}
                {rendering.renderLoyaltyPrice(this)}
              </span>
            ) : productPriceItem ? (
              product.strike_price ? (
                <span className="text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
                  <strike>
                    {currency.symbol}
                    {parseFloat(product.strike_price).toFixed(2)}
                  </strike>
                  &nbsp;
                  {productPriceItem}
                </span>
              ) : (
                <span className="text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
                  {productPriceItem}
                </span>
              )
            ) : null}
            <h2 className="font-congenialRegular font-bold text-[44px] leading-[46px] tracking-[-1px] text-dark dark:text-white">
              {product.title}
            </h2>
          </div>
          <p className="text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
            {product.description}
            {' '}
            {calories}
          </p>
          <div>
            {product.nutritionInfoLink ? (
              <a
                href={product.nutritionInfoLink}
                target="blank"
                className={`${styles.nutritionalInfo} text-[#983992] font-filsonProBold text-lg leading-[22px] tracking-[-0.1px]`}
              >
                Nutritional info
              </a>
            ) : null}
          </div>
          <SizeChart product={product} />

          <PresetBoxes
            productId={product.id}
            onSelect={this.onSelect}
            onClear={this.clearPresets}
            storeId={storeId}
          />

          <ProductOptions
            options={product.options}
            onOptionChange={this.updateOptions}
            customizeProducts={customizeProducts}
            setElement={this.setElement}
            isCaloriesActive={isCaloriesActive}
            currency={currency}
          />

          <div className={styles.cartWrapper}>
            {updateCartLoading ? (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            ) : null}
            <CheckoutCartButton
              onClick={() => {
                this.handleAddItem();
              }}
              isDisabled={isDisabled}
              label={product.oos === 1 ? outOfStockText : addUpdateProductText}
              price={loyalty ? points : totalCost}
              onQuantityChange={value => this.setState({ quantity: value })}
              quantity={loyalty ? null : quantity}
              loyalty={loyalty}
            />

            <QuickCheckoutButton
              addProductToCart={this.handleQuickCheckout}
              setCartLoading={this.setCartLoading}
              isDisabled={isDisabled}
              product={product}
              history={history}
            />
            {productAddError.length ? (
              <p className={styles.errorMessage}>{productAddError}</p>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  isCheckoutEnabled() {
    const { product, customizeProducts } = this.state;
    let checkoutEnabled = true;

    if (product && Array.isArray(product.options)) {
      product.options.forEach(el => {
        const isRequired = el.minOptions > 0;
        const selectionMade = customizeProducts ? customizeProducts[el.id] : null;
        if (selectionMade === null && isRequired) {
          checkoutEnabled = false;
          return null;
        }

        const requiredNotSelected = isRequired && customizeProducts[el.id] === undefined;
        const minSelectionsNotMet = (
          el.minOptions &&
          (customizeProducts[el.id] && customizeProducts[el.id].length !== el.minOptions) &&
          el.OptionGroupType !== 'SIZE_SELECT'
        );
        const selectionsExceedMax = selectionMade && el.maxOptions && customizeProducts[el.id].length > el.maxOptions;
        const sizeSelectInvalid = (
          el.optionGroupType === 'SIZE_SELECT' && selectionMade && customizeProducts[el.id].length !== 1
        );

        if (
          requiredNotSelected ||
          minSelectionsNotMet ||
          selectionsExceedMax ||
          sizeSelectInvalid
        ) {
          checkoutEnabled = false;
        }

        return null;
      });
    }

    return checkoutEnabled;
  }

  render() {
    return <this.customizeProductContainer />;
  }
}

CustomizeProduct.propTypes = {
  cakeTopperProductId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  cakeTopperUrl: PropTypes.string,
  isCaloriesActive: PropTypes.bool,
  isShowOosProducts: PropTypes.bool,
  currency: PropTypes.objectOf(PropTypes.string)
};

CustomizeProduct.defaultProps = {
  cakeTopperProductId: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_PRODUCT_ID,
  cakeTopperUrl: window.environment.REACT_APP_CUSTOM_CAKE_TOPPER_URL,
  isCaloriesActive: false,
  isShowOosProducts: false,
  currency: defaultCurrency,
};

export const mapDispatchToProps = dispatch => ({
  setUserCart: value => dispatch(userActions.setUserCart(value)),
  setUserCartId: value => dispatch(userActions.setUserCartId(value)),
  setModalObject: value => dispatch(elementActions.setModalObject(value)),
  setStoreId: (value) => dispatch(userActions.setSelectedStore(value)),
  setUserOrderMethodId: (value) => dispatch(userActions.setUserOrderMethod(value)),
  setUserAddress: (value) => dispatch(userActions.setUserAddress(value)),
});

export const mapStateToProps = state => {
  const {
    userCart,
    userOrderMethod,
    selectedStore,
    userCartId,
    userInfo,
  } = state.user;
  const { designId } = state.customCakeTopper;
  const { isCaloriesActive, isShowOosProducts } = state.product;
  const { currency } = state.currency;
  const { loyaltyRedeemables, loyaltyDeals, loyaltyPoints, redeemables } = state.loyalty;
  const { order } = state;
  return {
    userCart,
    userOrderMethod,
    selectedStore,
    userCartId,
    userInfo,
    designId,
    isCaloriesActive,
    isShowOosProducts,
    currency,
    loyaltyRedeemables,
    loyaltyDeals,
    loyaltyPoints,
    order,
    redeemables
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomizeProduct)
);
