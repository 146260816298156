import React, {Component} from 'react';

import ReactMarkdown from 'react-markdown';
import Loader from "../../components/Loader/Loader";
import * as cms from '../../shared/repos/cms/cms';
import keys from '../../shared/constants/cms';
import styles from './DoughProTermsConditions.module.css';

import {getKeyValue} from "../../shared/utilities/cms";

class DoughProTermsConditions extends Component {
    constructor(props){
        super(props);
        this.state = {
            content: []
        }
    }

    componentDidMount(){
      cms.getContent(keys.doughProTermsConditionsPage.doughProTermsMainPage, this);
    }

    render() {
        const { content } = this.state;

        if(!content.length) {
          return (
            <div className={styles.pageWrapper}>
              <div className={styles.pageContainer}>
                <Loader id="loader" />
              </div>
            </div>
          )
        }

        return (
          <div>
            {
              content.map(item => {
                const pageTitle = getKeyValue(item.children, keys.doughProTermsConditionsPage.title);
                const pageBody = getKeyValue(item.children, keys.doughProTermsConditionsPage.body);
                const pageSections = getKeyValue(item.children, keys.doughProTermsConditionsPage.sections, true);

                return (
                  <div className={styles.termsConditionsContainer}>
                    <div className={styles.termsConditionsContainerTitle}>
                      <p>{pageTitle}</p>
                    </div>

                    <div className={styles.termsConditionsContainerBody}>
                      <div className={styles.termsConditionsSections}>
                        <p>{pageBody}</p>
                      </div>
                      {
                        pageSections.children.map(sectionItem => {
                          const sectionHeader = getKeyValue(sectionItem.children, keys.doughProTermsConditionsPage.header);
                          const sectionBodyObjWithValue = getKeyValue(sectionItem.children, keys.doughProTermsConditionsPage.body, true);
                          let sectionBodyValue = '';
                          if(sectionBodyObjWithValue.value) {
                            sectionBodyValue = sectionBodyObjWithValue.value;
                          }
                          const hasSubSections = sectionBodyObjWithValue.children && sectionBodyObjWithValue.children.length > 0;
                            return (
                              <div className={styles.termsConditionsSections}>
                                <p className={styles.termsConditionsSectionsTitles}>{sectionHeader}</p>
                                { hasSubSections ? (
                                  <div>
                                    <p>{sectionBodyValue}</p>
                                    {
                                      sectionBodyObjWithValue.children.map(section => {
                                       const subTitle = getKeyValue(section.children, keys.doughProTermsConditionsPage.title );
                                       const subParagraph = getKeyValue(section.children, keys.doughProTermsConditionsPage.paragraph);
                                       const subDesc = getKeyValue(section.children, keys.doughProTermsConditionsPage.paragraph_1);
                                       if(subParagraph) {
                                         return (
                                           <div className={styles.termsConditionsSections}>
                                             <p>
                                               <span className={styles.termsConditionsSectionsTitles}>
                                                 {subTitle}
                                               </span>
                                               &nbsp;
                                               <ReactMarkdown>
                                                 {subParagraph}
                                               </ReactMarkdown>
                                             </p>
                                             <p>{subDesc}</p>
                                           </div>
                                         )
                                       } 
                                           const subParagraphObj = getKeyValue(section.children, keys.doughProTermsConditionsPage.paragraph, true);
                                           const pDesc = getKeyValue(subParagraphObj.children, keys.doughProTermsConditionsPage.paragraph_desc);
                                           const pList = getKeyValue(subParagraphObj.children, keys.doughProTermsConditionsPage.paragraph_list, true);
                                           const desc =  getKeyValue(section.children, keys.doughProTermsConditionsPage.paragraph_1);
                                           return (
                                             <div className={styles.termsConditionsSections}>
                                               <p>
                                                 <span className={styles.termsConditionsSectionsTitles}>
                                                   {subTitle}
                                                 </span>
                                                 &nbsp;
                                                 <ReactMarkdown>
                                                   {pDesc}
                                                 </ReactMarkdown>
                                               </p>
                                               <ul className={styles.termsConditionsSectionLists}>
                                                 {
                                                   pList.children.map(list => {
                                                     return (
                                                       <li>{list.value}</li>
                                                     )
                                                   })
                                                 }
                                               </ul>

                                               <p><ReactMarkdown>{desc}</ReactMarkdown></p>
                                             </div>
                                           )
                                      })
                                    }
                                  </div>
                                ) :(
                                  <p>{sectionBodyValue}</p>
                                )}
                              </div>
                            )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        )
    }
}

export default DoughProTermsConditions