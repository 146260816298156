import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import styles from "./DeliciousRewards.module.css";
import {
  getContentsByKey,
  getContentValueByKey
} from "../../shared/utilities/common";

function DeliciousRewards(props) {
  const { contents } = props;

  const heading = getContentValueByKey(contents, "heading");
  const description = getContentValueByKey(contents, "description");
  const steps = getContentsByKey(contents, "steps");

  return (
    <div className="px-4">
      <div className="flex flex-col items-center justify-center gap-3">
        <h1 className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-dark">
          {heading}
        </h1>
        <p className="text-darkElevationPrimary">{description}</p>
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-6">
        {steps &&
          steps.map(step => {
            const stepHeading = getContentValueByKey(step?.children, "heading");
            const stepDescription = getContentValueByKey(
              step?.children,
              "description"
            );
            const image = getContentValueByKey(step?.children, "image");
            return (
              <div className="md:w-[288px] max-h-[344px] flex flex-col md:items-center md:justify-center gap-3">
                <ReactMarkdown className={styles.image}>{image}</ReactMarkdown>
                <h1 className="font-filsonProRegular font-bold text-xl leading-[22px] tracking-[-0.3px] text-dark md:text-center">
                  {stepHeading}
                </h1>
                <p className="font-filsonProRegular text-base leading-5 text-darkElevationPrimary md:text-center">
                  {stepDescription}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
}

DeliciousRewards.propTypes = {
  contents: PropTypes.shape({}).isRequired
};

export default DeliciousRewards;
