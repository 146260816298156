export const giftCard = {
    productId: 84,
    title: 'Gift Card',
    optionLabels :{
        recipientName: 'Recipient Name',
        recipientEmail: 'Recipient Email',
        message: 'Message',
        deliveryDate: 'Delivery Date',
        design: 'Design'
    }
};