import React from 'react';
import { propTypesComponentPaymentInputs } from '../shared.propTypes';
import { uiComponentTypes } from '../shared';
import PaymentInputEmbeddedExternal from './PaymentInputEmbeddedExternal/PaymentInputEmbeddedExternal';
import logger from '../../../shared/utilities/logger';
import ddLog from '../../../shared/utilities/datadog/log';
import styles from '../shared.module.css';

const getPaymentMethodInputComponent = props => {
  const { storePaymentMethod } = props;
  const {
    paymentMethodId,
    isEmbeddedHtml,
    embeddedJs,
    embeddedHtml,
    componentMap
  } = storePaymentMethod;
  try {
    if (isEmbeddedHtml) {
      if (!(embeddedJs || embeddedHtml)) {
        throw new Error(
          `Payment Method ID "${paymentMethodId}" is supposed to be an embedded external payment method but it does not have embeddedJs or embeddedHtml. Note: Web does not support embeddedLink.`
        );
      }
      return (
        <PaymentInputEmbeddedExternal
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      );
    }
    if (!componentMap) {
      throw new Error(
        `Payment Method ID "${paymentMethodId}" does not have corresponding components. See PaymentWidget -> shared for component mapping of payment methods to components.`
      );
    }

    if (!componentMap.componentPaymentInput) {
      throw new Error(
        `Payment Method ID "${paymentMethodId}" has corresponding components, but has no mapped PaymentInput component. See PaymentWidget -> shared for component mapping of payment methods to components.`
      );
    }
    return (
      <componentMap.componentPaymentInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  } catch (error) {
    logger.logError(error);
    ddLog.logError(error);
    return null;
  }
};

const PaymentInputs = props => {
  const {
    // parent props only used for display logic
    storePaymentMethods,
    storePaymentMethodSelected,
    hidden: isPaymentInputsComponentHidden,

    // child
    paymentCredentialSubmitted,
    isSavePaymentMethodCheckedAndDisabled,
    isSavePaymentMethodAllowed,
    onSubmitPaymentCredentials,
    onCancel,
    paymentMethodsCustomerSaved,
    hideCancel,
    isDoughProMember,
    isLoggedIn,
    cmsContent,
    displayError
  } = props;

  const propsForChildren = {
    isSavePaymentMethodAllowed,
    isSavePaymentMethodCheckedAndDisabled,
    onSubmitPaymentCredentials,
    onCancel,
    paymentMethodsCustomerSaved,
    hideCancel,
    isDoughProMember,
    isLoggedIn,
    cmsContent,
    displayError
  };

  return (
    <>
      {storePaymentMethods.map(spm => {
        const isSpmIdAvailable = !!spm.paymentMethodId;
        const isSpmSelected =
          storePaymentMethodSelected?.paymentMethodId &&
          // eslint-disable-next-line eqeqeq
          spm.paymentMethodId == storePaymentMethodSelected?.paymentMethodId;
        const isSpmPaymentCredentialSubmitted =
          paymentCredentialSubmitted &&
          // eslint-disable-next-line eqeqeq
          paymentCredentialSubmitted.storePaymentMethod.paymentMethodId == spm.paymentMethodId;
        const isSpmHidden =
          isPaymentInputsComponentHidden ||
          !isSpmIdAvailable ||
          (!isSpmSelected && spm.uiComponentType === uiComponentTypes.detailView);
        const spmPaymentCredentialSubmitted = isSpmPaymentCredentialSubmitted
          ? paymentCredentialSubmitted
          : null;
        const spmPaymentInputProps = {
          ...propsForChildren,
          paymentCredentialSubmitted: spmPaymentCredentialSubmitted,
          storePaymentMethod: spm,
          isHidden: isSpmHidden
        };

        return (
          <div
            key={`paymentInputPaymentMethod${spm.paymentMethodId}`}
            id={`paymentInputPaymentMethod${spm.paymentMethodId}`}
            hidden={isSpmHidden}
            className={[
              spm.isEmbeddedHtml ? styles.optionEmbeddedHtml : '',
              isSpmSelected && spm.isEmbeddedHtml ? styles.chosen : ''
            ].join(' ')}
          >
            {getPaymentMethodInputComponent(spmPaymentInputProps)}
          </div>
        );
      })}
    </>
  );
};

PaymentInputs.propTypes = propTypesComponentPaymentInputs;

export default PaymentInputs;
