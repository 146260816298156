/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "underscore";

import PropTypes from "prop-types";
import Link from "../LinkWithCountry/LinkWithCountry";
import * as productRepo from "../../shared/repos/graphql/product";
import * as orderRepo from "../../shared/repos/graphql/order";

import removeProductModal from "../RemoveProductModal/RemoveProductModal";

import Button from "../Button/Button";
import FeaturedLocation from "../FeaturedLocation/FeaturedLocation";

import {
  guardOrderChange,
  guardOrderChangeToPickup,
  clearCart,
} from "../../shared/utilities/orders";
import { orderMethods, orderTypeIds } from "../../shared/constants/order";
import { shippingMethods, storeTypes } from "../../shared/constants/store";
import * as tagmanagerEvents from "../../shared/utilities/tagmanagerEvents";

import * as countryActions from "../../redux/actions/country";
import styles from "./LocationsListItem.module.css";
import * as userActions from "../../redux/actions/user";
import * as elementsActions from "../../redux/actions/elements";
import { store as mainStore } from "../../redux/store";
import {
  getErrorMessages,
  getErrorReasons,
  routeCountryPath
} from "../../shared/utilities/common";
import errorCodes from "../../shared/constants/errorCodes";
import {
  openCartCreateFailModal,
  openOrderUpdateFailModal
} from "../../shared/utilities/modals";
import endpoints from "../../shared/constants/endpoints";
import * as phoneService from "../../shared/utilities/phone";
import * as loyaltyAction from '../../redux/actions/loyalty';

const infoIcon = require("./imgs/info-icon.png");
const DeliveryIcon = require("./imgs/delivery.svg");
const workIcon = require("./imgs/work-icon.png");


class LocationsListItem extends Component {
  componentDidUpdate(prevProps) {
    const { selectedMapStore } = this.props;
    if (prevProps !== this.props && selectedMapStore) {
      this.checkSelected();
    }
  }

  checkSelected = () => {
    const { selectedMapStore, store, onClick } = this.props;

    const matchingStore = store.id === selectedMapStore.id;

    if (matchingStore) {
      onClick();
    }
  };

  createCart = async (address, store) => {
    const {
      setUserCartId,
      setModalObject,
      setSelectedStore,
      setUserAddress,
      changeAddress,
      history,
    } = this.props;
    const { userCartId } = mainStore.getState().user;

    if (userCartId) {
      return;
    }

    const addressComponents = address
      ? {
          address1: address.address1,
          city: address.city,
          state: address.state,
          postcode: address.postcode,
          countryId: address.countryId,
          lat: address.lat,
          lng: address.lng
        }
      : null;

    const data = {
      storeId: store.id,
      orderTypeId: 3,
      shippingMethod: 3,
      address: addressComponents
    };

    try {
      const response = await orderRepo.createCart(data);
      const { createCart } = response.data;

      setUserCartId(createCart.code);
      setSelectedStore(store);
      setUserAddress(addressComponents);

      if (!changeAddress) {
        history.push(
          this.redirectToPdp(routeCountryPath(endpoints.getMenuUrl(store)))
        );
      }

      setModalObject(null);
    } catch (reason) {
      const message = getErrorMessages(reason, 0);
      const reasonType = getErrorReasons(reason, 0);
      const isCartCreateErrorReason =
        errorCodes.CART_CREATE_ERROR === reasonType;
      if (isCartCreateErrorReason) {
        setModalObject(openCartCreateFailModal(message, clearCart));
      }
    }
  };

  updateOrdersLocation = async (userCartId, data) => {
    const {
      addressComponents,
      store,
      setUserOrderMethod,
      changeAddress,
      history,
      setModalObject,
      setSelectedStore,
      setUserAddress
    } = this.props;

    try {
      await orderRepo.updateOrderLocation(userCartId, data);

      setUserOrderMethod(orderMethods.pickup);

      if (!changeAddress) {
        history.push(
          this.redirectToPdp(routeCountryPath(endpoints.getMenuUrl(store)))
        );
      }
      setSelectedStore(store);
      setUserAddress(addressComponents);
      setModalObject(null);
    } catch (reason) {
      const message = getErrorMessages(reason, 0);
      const reasonType = getErrorReasons(reason, 0);
      const isCartCreateErrorReason =
        errorCodes.ORDER_UPDATE_ERROR === reasonType;
      if (isCartCreateErrorReason) {
        setModalObject(openOrderUpdateFailModal(message));
      }
    }
  };

  callbackHandler = async productIds => {
    const { addressComponents, store, setUserCart } = this.props;
    const { userCartId } = mainStore.getState().user;

    const removeProductResponse = await orderRepo.removeProducts(
      userCartId,
      productIds
    );

    setUserCart(removeProductResponse.data.removeOrderItemsFromOrder.items);

    const address = {
      address1: addressComponents.address1,
      city: addressComponents.city,
      state: addressComponents.state,
      postcode: addressComponents.postcode
    };

    const data = {
      orderTypeId: orderTypeIds.pickup,
      storeId: store.id,
      address,
      shippingMethod: shippingMethods.pickup
    };

    await this.updateOrdersLocation(userCartId, data);
  };

  redirectToPdp = location => {
    const { redeemables, setRedeemableItem } = this.props;
    if (redeemables?.redeemableType) {
      setRedeemableItem({
        redeemableType: null,
        redeemableId: null
      })
      return routeCountryPath(endpoints.loyalty);
    }

    return location;
  };

  selectStore = async (e, countryChange = false) => {
    const {
      allCountries,
      setSelectedCountry,
      addressComponents,
      store,
      setUserOrderMethod,
      setModalObject
    } = this.props;
    const { userCartId } = mainStore.getState().user;
    e.stopPropagation();
    const addressCountry = allCountries.find(
      country => +country.id === +store.countryId
    );

    if (countryChange) {
      setSelectedCountry(addressCountry);
    }

    if (userCartId) {
      const response = await productRepo.checkStoreProductStock(
        userCartId,
        store.id
      );
      const oosProducts = response.data.storeProductStock.filter(
        item => parseInt(item.oos, 10) === 1
      );

      if (oosProducts.length) {
        const productIds = _.pluck(oosProducts, "orderProductId");

        const callback = async () => {
          await this.callbackHandler(productIds);
        };

        return removeProductModal(setModalObject, store, oosProducts, callback);
      }
      const address = addressComponents
        ? {
            address1: addressComponents.address1,
            city: addressComponents.city,
            state: addressComponents.state,
            countryId: addressComponents.countryId,
            postcode: addressComponents.postcode
          }
        : null;

      const data = {
        orderTypeId: orderTypeIds.pickup,
        storeId: store.id,
        address,
        shippingMethod: shippingMethods.pickup
      };

      await this.updateOrdersLocation(userCartId, data);
      this.closeDeliveryPickupWidget();
    } else {
      await setUserOrderMethod("PICKUP");
      await this.createCart(addressComponents, store);
      this.closeDeliveryPickupWidget();
    }
  };

  showCountryChange = (e, store) => {
    const {
      setModalObject,
      changeAddress,
      userCart,
      userOrderMethod
    } = this.props;

    const currentOrderNotPickup = userOrderMethod
      ? userOrderMethod !== orderMethods.pickup
      : false;
    const showOrderGuardModal = userCart.length > 0 || currentOrderNotPickup;
    setModalObject({
      title:
        "You are searching for an address in another country, would you like to change your country selection?",
      description:
        "If you remain on the current country website, you may still proceed with ordering, however, all purchases will be completed in the local store currency, and your transaction and your visit to the website will be governed by the local laws.",
      action1: async () => {
        tagmanagerEvents.viewMenu(store.id);

        if (!changeAddress) {
          guardOrderChange(showOrderGuardModal, this.selectStore, e, true);
          return;
        }

        this.selectStore(e, true);

        setModalObject(null);
      },
      action2: async () => {
        tagmanagerEvents.viewMenu(store.id);

        if (!changeAddress) {
          guardOrderChange(showOrderGuardModal, this.selectStore, e, false);
          return;
        }

        this.selectStore(e, false);
        setModalObject(null);
      },
      action1Label: "Yes",
      action2Label: "No"
    });
    return true;
  };

  closeDeliveryPickupWidget(){
    const {
      closePopup
    } = this.props;
    if(closePopup) {closePopup() }
  }

  render() {
    const {
      onClick,
      name,
      storeImage,
      isOpen,
      distanceFrom,
      store,
      mailingAddress,
      city,
      state,
      zip,
      telephone,
      phonePrefixes,
      changeAddress,
      isDeliveryInRange,
      isCountrySelectActive,
      selectedCountry,
      onViewMenuClick,
      userCart,
      featuredLocation
      } = this.props;

    const showOrderGuardModal = userCart.length > 0; // || currentOrderNotPickup;

    const viewMenu = async e => {
      if (
        isCountrySelectActive === true &&
        +store.countryId !== +selectedCountry.id
      ) {
        this.showCountryChange(e, store);
      } else {
        tagmanagerEvents.viewMenu(store.id);

        if (!changeAddress) {
          guardOrderChangeToPickup(
            showOrderGuardModal,
            this.selectStore,
            e,
            false
          );
          return;
        }

        await this.selectStore(e, false);
      }
    };


    const handleSubmit = (e) => {
      e.stopPropagation();
      if (onViewMenuClick) {
        onViewMenuClick(store, e);
      } else {
        viewMenu(e);
      }
    }

    const handleClick = (e) => {
      if (onClick) {
        onClick()
      } else {
        viewMenu(e);
      }
    }

    return (
      featuredLocation ?
        <FeaturedLocation
          onClick={handleSubmit}
          icon={workIcon}
          label=""
          value={store.name}
        />:
        <div
          className={`${styles.listItemWrapper}
                hover:bg-light dark:hover:bg-darkDry
                border-b-[1px] border-light dark:border-darkDry
              `}
        >
          <div
            onClick={handleClick}
            className={`
                ${styles.listItemContainer}
                md:py-6 py-2 px-4`}
          >
            <div className={`${styles.listItemHeader} text-dark dark:text-white`}>
              <h3 className="flex items-center gap-2 w-[64%] md:w-full">
                <span>{name}</span>
                <span>
                  <img alt="info" src={storeImage || infoIcon} />
                </span>
                <div className="has-tooltip">
                  <span className="tooltip rounded shadow-lg p-1 bg-[#ce4396] text-white mt-6 px-2 py-1">
                    This address is in range of delivery for this store.
                  </span>
                  {isDeliveryInRange ? (
                    <img src={DeliveryIcon} alt="delivery" />
                  ) : (
                    ""
                  )}
                </div>
              </h3>

              {store.storeType === storeTypes.COMINGSOON ? (
                <p className={`${styles.isClosed} `}>
                  <span>{"Coming Soon "}</span>
                  {`${distanceFrom !== "" ? "|" : ""}`}
                  {` ${distanceFrom}`}
                </p>
              ) : (
                <p className={!isOpen ? styles.isClosed : null}>
                  <span>{`${isOpen ? "Open " : "Closed "}`}</span>
                  {`${distanceFrom !== "" ? "|" : ""}`}
                  {` ${distanceFrom}`}
                </p>
              )}
            </div>

            {store.note ? <p>{store.note}</p> : null}

            <div
              className={`${styles.listItemLocation} text-darkElevationPrimary dark:text-darkElevationSecondary flex flex-col gap-2`}
            >
              <p className="truncate overflow-ellipsis overflow-hidden w-[250px]">
                {mailingAddress}
                <br />
                {city}
  ,
                {state}
  ,
                {zip}
              </p>
              <p>
                <Link to="/contact" className={styles.listContactLink}>
                  {phoneService.applyMask(telephone, phonePrefixes)}
                </Link>
              </p>
            </div>

            {store.isDeliveryOpen && store.storeType !== storeTypes.COMINGSOON ? (
              <Button
                handleSubmit={handleSubmit}
                customContainerStyles={`
                      ${styles.customButtonContainer}
                      text-dark dark:text-white
                      py-1
                      border-[1px] border-dark dark:border-light
                      absolute bottom-[30%] md:bottom-[25%]
                      right-[1%]
                      `}
                label={!changeAddress ? "View Menu" : "Selected Store"}
              />
            ) : null}
          </div>
        </div>
    );
  }
}

LocationsListItem.propTypes = {
  name: PropTypes.string.isRequired,
  distanceFrom: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  storeImage: PropTypes.string.isRequired,
  mailingAddress: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  telephone: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  changeAddress: PropTypes.bool,
  setSelectedStore: PropTypes.func.isRequired,
  store: PropTypes.shape().isRequired,
  onViewMenuClick: PropTypes.func,
  closePopup: PropTypes.func,
  featuredLocation: PropTypes.bool
};

LocationsListItem.defaultProps = {
  changeAddress: false,
  onViewMenuClick: null,
  closePopup: null,
  featuredLocation: false,
};

export const mapDispatchToProps = dispatch => ({
  setUserCart: value => dispatch(userActions.setUserCart(value)),
  setUserCartId: value => dispatch(userActions.setUserCartId(value)),
  setUserOrderMethod: value => dispatch(userActions.setUserOrderMethod(value)),
  setUserAddress: value => dispatch(userActions.setUserAddress(value)),
  setSelectedStore: value => dispatch(userActions.setSelectedStore(value)),
  setModalObject: value => dispatch(elementsActions.setModalObject(value)),
  setSelectedCountry: value =>
    dispatch(countryActions.setSelectedCountry(value)),
  setRedeemableItem: value => dispatch(loyaltyAction.setRedeemableItem(value)),
});

export const mapStateToProps = (state,props) => {
  const {
    userCartId,
    selectedMapStore,
    userOrderMethod,
    userCart
  } = state.user;
  const addressComponents = props?.addressComponents && Object.keys(props.addressComponents).length > 0
    ? props.addressComponents
    : state.address.addressComponents;
  const {
    phonePrefixes,
    selectedCountry,
    allCountries,
    isCountrySelectActive
  } = state.country;
  const { isShippingActive } = state.shipping;
  const {redeemables} = state.loyalty;
  return {
    userCartId,
    addressComponents,
    selectedMapStore,
    userOrderMethod,
    userCart,
    phonePrefixes,
    selectedCountry,
    allCountries,
    isCountrySelectActive,
    isShippingActive,
    redeemables,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationsListItem)
);
