import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import PropTypes, { number } from "prop-types";
import ReactMarkdown from "react-markdown";

import styles from "./Collections.module.css";

import {
  getContentsByKey,
  getContentValueByKey
} from "../../../shared/utilities/common";

const DarkLeftArrowIcon = require("./icons/dark-left-arrow.png");
const LeftArrowIcon = require("./icons/left-arrow.png");
const DarkRightArrowIcon = require("./icons/dark-right-arrow.png");
const RightArrowIcon = require("./icons/right-arrow.png");

function CollectionSlider(props) {
  const { data, onChange, selectedIndex = 0, openDeliveryWidget } = props;
  const [currentIndex, setcurrentIndex] = useState(selectedIndex);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? data?.length - 1 : currentIndex - 1;
    setcurrentIndex(newIndex);
    /* eslint-disable-next-line */
    onChange && onChange(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === data.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setcurrentIndex(newIndex);
    /* eslint-disable-next-line */
    onChange && onChange(newIndex);
  };

  useEffect(() => {
    setcurrentIndex(selectedIndex);
  }, [selectedIndex]);

  const setSlide = (items, index) => {
    return items?.map((item, i) => {
      const heading = getContentValueByKey(item.children, "heading");
      const description = getContentValueByKey(item.children, "description");
      const image = getContentValueByKey(item.children, "image");
      const link = getContentsByKey(item.children, "link");
      const linkTitle = getContentValueByKey(link, "title");
      const linkUrl = getContentValueByKey(link, "url");

      return (
        <div
          className={`rounded-2xl bg-center bg-cover duration-500 animate-slide lg:h-[450px] h-[541px]
              ${i === index ? "" : "hidden"}
            `}
        >
          <div className="lg:grid grid-flow-col gap-0 place-items-center lg:w-[96%] lg:[70%]">
            <div className="w-full lg:w-[545px] m-0">
              <ReactMarkdown className={`${styles.productImage} lg:w-[90%] w-3/4 m-auto max-h-[290px] lg:max-h-[400px]`}>
                {image}
              </ReactMarkdown>
            </div>
            <div className="lg:px-[62px] px-4 text-center lg:text-left col-span-1 w-full lg:w-[545px] mt-9 mr-5">
              <h1 className="text-dark dark:text-white font-normal text-[28px] tracking-[-1px] font-congenialBlack transform uppercase">
                {heading}
              </h1>
              <p className="text-dark dark:text-white font-filsonProBook text-lg leading-6 tracking-[-0.1px] my-2">
                {parse(description)}
              </p>
              {linkTitle && linkUrl ? (
                <button
                  onClick={() => openDeliveryWidget(linkUrl)}
                  type="button"
                  className="h-[52px] px-4 text-center font-filsonProBookBold border border-solid border-dark dark:border-darkBorder bg-transparent  rounded-[54px]"
                >
                  <p className="text-dark outline-none text-lg dark:text-white">
                    {linkTitle}
                  </p>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="m-auto w-full px-4 lg:px-0 relative md:max-w-screen-desktop my-8">
      {data && setSlide(data, currentIndex)}

      {/* Left Arrow */}
      <div
        role="button"
        tabIndex={0}
        onClick={prevSlide}
        onKeyPress={nextSlide}
        className="absolute zIndex lg:top-[40%] top-[10%] -translate-x-0 traslate-y-[-50%] left-0 text-2xl rounded-full p-2 cursor-pointer"
      >
        <img
          src={LeftArrowIcon}
          alt="shodow-left"
          className="dark:hidden block"
        />
        <img
          src={DarkLeftArrowIcon}
          alt="shodow-left"
          className="dark:block hidden"
        />
      </div>

      {/* Right Arrow  */}
      <div
        role="button"
        tabIndex={0}
        onClick={nextSlide}
        onKeyPress={nextSlide}
        className="absolute zIndex lg:top-[40%] top-[10%] -translate-x-0 traslate-y-[-50%] right-0 text-2xl rounded-full p-2 cursor-pointer"
      >
        <img
          src={RightArrowIcon}
          alt="shodow-right"
          className="dark:hidden block"
        />
        <img
          src={DarkRightArrowIcon}
          alt="shodow-right"
          className="dark:block hidden"
        />
      </div>
    </div>
  );
}

CollectionSlider.propTypes = {
  data: PropTypes.shape({}) || null,
  onChange: PropTypes.func,
  selectedIndex: number,
  openDeliveryWidget: PropTypes.func
};

CollectionSlider.defaultProps = {
  data: null,
  onChange: null,
  selectedIndex: 0,
  openDeliveryWidget: null
};

export default CollectionSlider;
