/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';

/**
 * Handles Facebook login. 
 *
 * @param {Function} callback - callback function to handle response/err
 *
 * @author Elizabeth Clouser <elizabeth@somepage.com>
 * @author Aghunik Babayan <aghunik@somepage.com>
 * @return {void}
 */
export const loginWithFacebook = (callback = null) => {
  try{
    FB.getLoginStatus(response => {
          if (response.status === 'connected') {
            if (callback) {
              callback(response);
            }
          } else {
            FB.login(authResponse => {
              if (callback) {
                callback(authResponse);
              }
            });
          }
        },
        {
          scope: 'email,public_profile'
        });
  }catch(err){
      Sentry.captureException(err);
  }

};

/**
 * Log user out of Facebook.
 *
 * @param {Function} callback - callback function to handle response/err
 *
 * @author Elizabeth Clouser <elizabeth@somepage.com>
 * @author Aghunik Babayan <aghunik@somepage.com>
 * @return {void}
 */
export const facebookLogOut = (callback = null) => {
  try{
    FB.getLoginStatus(response => {
      if (response.status === 'connected') {
        FB.logout(authResponse => {
          if (callback) {
            callback(authResponse);
          }
        });
      }
    });
  }catch(err){
      Sentry.captureException(err);
  }

};

