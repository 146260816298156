/* eslint-disable import/prefer-default-export */

export const addressUpdated = value => ({
    type: 'ADDRESS_UPDATED',
    value
});

export const setUseFullyQualifiedAddressForDelivery = value => ({
    type: 'SET_USE_FULLY_QUALIFIED_ADDRESS_FOR_DELIVERY',
    value
});

export const setRedirectUrl = value => ({
    type: 'SET_REDIRECT_URL',
    value
});
