import Appboy from "@braze/web-sdk";
import {store} from "../../../redux/store";
import {contentCardsUpdated} from "../../../redux/actions/braze";

export const subscribeToContentCardUpdates = () => {
    try {
        Appboy.subscribeToContentCardsUpdates(cardData => {
            store.dispatch(contentCardsUpdated(cardData));
        });
        /* eslint-disable no-empty */
    } catch (exception) {

    }

}

export const logEvent = (eventName, eventProperties) => {
    try {
        Appboy.logCustomEvent(
            eventName,
            eventProperties
        );
        Appboy.requestImmediateDataFlush(() => {
            Appboy.requestContentCardsRefresh();
        });
        /* eslint-disable no-empty */
    } catch (exception) {

    }
}
