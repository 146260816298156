/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

export const SCHOOLS = gql`
  query SCHOOLS {
    schools {
      id
      name
    }
  }
`;