// POLYFILL CONFIGURATIONS
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'airbnb-js-shims';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// APOLLO GRAPHQL PACKAGES
import { ApolloProvider } from '@apollo/client';
// REDUX PACKAGES
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { client } from "./shared/utilities/config";
import { store, persistor } from './redux/store';
// BASE APP
import App from './App';
import * as serviceWorker from './serviceWorker';
// BASE STYLES
import './tailwind.css';

ReactDOM.hydrate(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={client}>
        <Router>
          <App />
        </Router>
      </ApolloProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root') // eslint-disable-line
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default client;
