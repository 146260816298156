/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import doughPro from '../shared/constants/doughPro';

const defaultSku = doughPro.subscriptionProductSku;

// QUERIES
export const DOUGH_PRO_PRODUCT_QUERY = gql`
  query DOUGH_PRO_PRODUCT_QUERY($sku: String = "${defaultSku}") {
    doughProProduct(sku: $sku) {
      id,
      name,
      price
    }
  }
`;

export const DOUGH_PRO_SPECIAL_CATEGORY_QUERY = gql`
  query DOUGH_PRO_SPECIAL_CATEGORY_QUERY {
    doughProSpecialCategory {
      slug
    }
  }
`;

export const DOUGH_PRO_MEMBER = gql`
  query DOUGH_PRO_MEMBER {
    doughProMember {
      active
      onGracePeriod
      expirationDate
      paymentMethod
      freeCookieCount
      freeCookieUsedAt
      freeCookieActiveDuration
      lastBillingDate
      lastBillingAmount
      giftcardNumber
      freeTraditionalProduct {
        id
      }
    }
  }
`;

export const DOUGH_PRO_PAYMENTS = gql`
  query DOUGH_PRO_PAYMENTS {
    doughProPayments {
      orders {
        code
        total
        createdAt
        paymentMethod
        giftcardIds
        coupons
      }
      total
    }
  }
`;

export const DOUGH_PRO_FREE_COOKIE = gql`
  query DOUGH_PRO_FREE_COOKIE {
    doughProFreeCookie {
      id,
      title,
      image
    }
  }
`;

// MUTATIONS
export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation cancelDoughProSubscription {
    cancelDoughProSubscription
  }
`;

export const CHANGE_PAYMENT_METHOD_MUTATION = gql`
  mutation changeDoughProPaymentMethod($paymentCredential: PaymentCredential!) {
    changeDoughProPaymentMethod(paymentCredential: $paymentCredential)
  }
`;

export const DOUGH_PRO_FREE_COOKIE_ACTIVATE_MUTATION = gql`
  mutation  doughProFreeCookieActivate {
     doughProFreeCookieActivate {
      id,
      title,
      image
    }
  }
`;
