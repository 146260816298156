const initialState = {
    isDoughProActive: false, // Enable cookie magic for for all customers.
    selectedModal: '',
    profile: {
        paymentMethod: {
            status: '',
            selected: '',
            giftcardNumber: '',
            creditcard: {
                cardHolderName: '',
                cardNumber: '',
                expirationDate: '',
                cvv: '',
                postcode: ''
            }
        },
        cancelSubscription: {
            status: ''
        }
    }
};

export default(state = initialState, { type, value }) => {
    switch (type) {
        case 'DOUGH_PRO_ACTIVE':
            return {...state, isDoughProActive: value};
        case 'DOUGH_PRO_SELECT_MODAL':
            return {...state, selectedModal: value};
        case 'DOUGH_PRO_PROFILE_UPDATE_PAYMENT_METHOD':
            return {
                ...state,
                profile: {...state.profile, paymentMethod: value}
            };
        case 'DOUGH_PRO_PROFILE_UPDATE_CANCEL_SUBSCRIPTION':
            return {
                ...state,
                profile: {...state.profile, cancelSubscription: value}
            };
        default:
            return state;
    }
}
