import React from 'react';
import styles from './Blog.module.css'

const Blog = () => (
  <div className={styles.blog}>
    <p className={styles.placeholder}>Blog</p>
  </div>
);

export default Blog
