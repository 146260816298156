const initialState = {
    credentials: null,
    orderUpdating: false
};

export default (state = initialState, { type, value }) => {
    switch (type) {
        case 'PAYMENT_CREDENTIALS_UPDATED':
            return {
                ...state,
                credentials: value
            };
        case 'ORDER_UPDATING':
            return {
                ...state,
                orderUpdating: value
            }
        default:
            return state;
    }
};
