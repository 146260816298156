/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ProductModal.module.css';

const item = require('./imgs/item.png');

const ProductModal = ({ exit }) => (
  <div className={styles.modalWrapper}>
    <div className={styles.modalContainer}>
      <img onClick={exit} alt='item' src={item} />
      <div className={styles.modalContent}>
        <div className={styles.productContainer}>
          <h3>Chocolate Cookie</h3>
        </div>
        <div className={styles.detailsContainer}>
          <div>
            <h5>Calories</h5>
          </div>
          <div>
            <h5>Allergen Information</h5>
          </div>
        </div>
        <div className={styles.FAQContainer}>
          <h5>FAQ</h5>
        </div>
      </div>

      <div data-ddog-id="box_product_order_container" className={styles.orderContainer}>
        <div className={styles.quantityContainer}>
          <p>Choose cookies</p>
          <p>
            <span>-</span>
              &nbsp; 0 &nbsp; 
            <span>+</span>
          </p>
        </div>
        <div className={styles.orderButton}>
          <p data-ddog-id="box_product_add_to_order">Add to Order - $5.00</p>
        </div>
      </div>

    </div>
  </div>
);

ProductModal.propTypes = {
  exit: PropTypes.func
}

ProductModal.defaultProps = {
  exit: null
}

export default ProductModal;
