/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const SITE_SETTING = gql`
  query SITE_SETTING($name: String!) {
        siteSetting(name: $name) {
            name
            value
            country_id
    }
  }
`;

export const SITE_SETTINGS = gql`
  query SITE_SETTINGS($names: [String!], $returnAllCountries: Boolean) {
        siteSettings(where: {names: $names}, returnAllCountries: $returnAllCountries) {
            name
            value
            country_id
    }
  }
`;
export const SAVE_DEVICE_ID = gql`
    mutation SAVE_DEVICE_ID($deviceID: String!, $appVersion: String) {
        saveDeviceId(deviceID: $deviceID, appVersion: $appVersion)
    }
`;