const cleanState = {
  contentCardSession: false,
  contentCards: null,
  shouldDisplayIntroBanner: true,
  defaultHeroBanner: null,
  introHeroBanner: null,
  contentCardRefreshDelay:5000
};

const initialState = {
  ...cleanState
};

export default (state = initialState, { type, value }) => {
  const valueByType = [
    { type: 'CONTENT_CARDS_UPDATED', value: { ...state, contentCards: value }},
    { type: 'CONTENT_CARD_SESSION', value: { ...state, contentCardSession: value }},
    { type: 'INTRO_BANNER_VISIBILITY_UPDATED', value: { ...state, shouldDisplayIntroBanner: value }},
    { type: 'SET_DEFAULT_HERO_BANNER', value: { ...state, defaultHeroBanner: value }},
    { type: 'SET_INTRO_HERO_BANNER', value: { ...state, introHeroBanner: value }},
    { type: 'SET_CONTENT_CARD_REFRESH_DELAY', value: { ...state, contentCardRefreshDelay: value }}
  ];

  const selectedType = valueByType.find(item => item.type === type);

  return selectedType ? selectedType.value : state;
};
