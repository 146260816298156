import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Barcode from "react-barcode";

import * as elementsActions from "../../redux/actions/elements";

// const clearIcon = require("./imgs/clear-icon.png");
const clearDarkIcon = require("./imgs/clear-dark-icon.svg");

class BarcodeWidget extends Component {
  handleCloseWidget = () => {
    const { onClose, toggleBarcodeCard } = this.props;
    if(onClose) {
      onClose();
    }
    toggleBarcodeCard({ showBarcodeCard: false, value: null});
  };

  render() {
    const { barcode } = this.props;
    return (
      <div
        className={`
    fixed inset-0 bg-opacity-25
    backdrop-blur-sm flex justify-center items-center
    zIndex
    bg-darkBlack
      ${!barcode?.showBarcodeCard ? "hidden" : ""}
    `}
      >
        <div
          className={`
          bg-white w-[343px] h-[346px] flex flex-col p-[20px] rounded-[40px]
           outline-none
        `}
        >
          <div
            className="w-full flex py-2 justify-center items-center border-b-[1px]"
            role="button"
            tabIndex={0}
            onKeyDown={this.handleCloseWidget}
            onClick={this.handleCloseWidget}
          >
            <p className="flex-1 text-center text-base leading-5 font-filsonProRegular font-bold text-dark">Scan Barcode</p>
            <img
              src={clearDarkIcon}
              alt="Close Widget"
              className="w-6 h-6 object-contain"
            />
          </div>
          <div className="relative flex items-center flex-col gap-4 w-full mx-auto h-full justify-center">
            <Barcode value={barcode?.value} />
          </div>
        </div>
      </div>
    );
  }
}

BarcodeWidget.propTypes = {
  onClose: PropTypes.func,
  toggleBarcodeCard: PropTypes.func,
  barcode: PropTypes.string,
}

BarcodeWidget.defaultProps = {
  onClose: null,
  toggleBarcodeCard: null,
  barcode: null,
}

export const mapStateToProps = state => {
  return {
    barcode: state.elements.barcode,
  };
};

export const mapDispatchToProps = dispatch => ({
  toggleBarcodeCard: value => dispatch(elementsActions.toggleBarcodeCard(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BarcodeWidget));
