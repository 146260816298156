import { STORE_PRODUCT_STOCK_QUERY, PRODUCT_QUERY, PRODUCT_ASSORTMENT_QUERY } from '../../../graphql/product';
import { client } from "../../utilities/config";

/**
 * Check product stock.
 *
 * @param {Int}      userCartId
 * @param {Object}   store
 *
 * @author Elizabeth Clouser <elizabeth@somepage.com>
 */
/* eslint-disable import/prefer-default-export */
export const checkStoreProductStock = (orderCode, storeId) => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: STORE_PRODUCT_STOCK_QUERY,
        variables: {
            storeId,
            orderCode
        }
    });
};

/**
 * Get product.
 *
 * @param {Int}      id
 *
 * @author Albert Rosa <arosa@insomniacookies.com>
 */
/* eslint-disable import/prefer-default-export */
export const getProduct = (id, redeemableId = null, isShowOosProducts = false) => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: PRODUCT_QUERY,
        variables: {
            id,
            redeemableId,
            isShowOosProducts,
        }
    });
};

export const getProductAssortment = (productId, groupIds, storeId) => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: PRODUCT_ASSORTMENT_QUERY,
        variables: {
            productId,
            groupIds,
            storeId
        }
    });
};
