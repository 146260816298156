import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import styles from "./Login.module.css";
import VerifyPhoneNumber from "./VerifyPhoneNumber";
import * as orderRepo from "../../../shared/repos/graphql/order";
import Loader from "../../../components/Loader/Loader";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount = async () => {
    await this.handleWelcome();
  };

  verifyPhoneNumber = () => {
    const { handleLoginWithPhone, token, user, password } = this.props;
    return (
      <VerifyPhoneNumber
        handleLoginWithPhone={handleLoginWithPhone}
        token={token}
        user={user}
        password={password}
      />
    );
  };

  handleWelcome = async () => {
    const { token, order, setUserToken, user } = this.props;
    if (user && user?.telephoneVerified) {
      await setUserToken(token);
      if (order) {
        orderRepo.getOrder(order.code, null).then(() => {});
      }
    }
    this.setState({ loading: true });
  };

  render() {
    const { loading} = this.state;
    const { user, userId, isSignUp, history } = this.props;

    if (user && !user?.telephoneVerified) {
      return this.verifyPhoneNumber();
    }

    return (
      <div className={`${styles.pageContainer} w-full`}>
        <div className="flex flex-col gap-4 justify-center items-center w-full">
          <h1 className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-white">
            {loading || user ? `${!isSignUp ? 'Welcome back' : 'Welcome'}, ${user?.firstname}!` : "Welcome!"}
          </h1>
          <p className="font-filsonProRegular text-base leading-5 text-white">Hang on tight while we prepare your account.</p>
          {!userId ? <Loader /> : history.push('/')}
        </div>
      </div>
    );
  }
}

Welcome.propTypes = {
  setUserToken: PropTypes.func.isRequired,
  handleLoginWithPhone: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  token: PropTypes.string,
  order: PropTypes.shape({
    code: PropTypes.string
  }),
  user: PropTypes.string,
  password: PropTypes.string,
  userId: PropTypes.string,
  isSignUp: PropTypes.bool,
};

Welcome.defaultProps = {
  order: null,
  token: null,
  user: null,
  password: null,
  userId: null,
  isSignUp: false
};

export const mapStateToProps = state => {
  const { userId } = state.user;
  return { userId };
};

export default connect(mapStateToProps)(Welcome);
