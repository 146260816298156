const initialState = {
    orderTotalsTitles: '[]',
    showTaxesAfterTotal: false,
};

export default (state = initialState, { type, value }) => {
    switch (type) {
        case 'SET_ORDER_TOTALS_TITLES':
            return {...state, orderTotalsTitles: value};
        case 'SET_SHOW_TAXES_AFTER_TOTAL':
        return {
            ...state,
            showTaxesAfterTotal: value
        };
        default:
            return state;
    }
}
