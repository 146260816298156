/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint no-param-reassign:  [2, { "props": false }] */
import React from "react";
import styles from "./CustomProductOption.module.css";

const addIcon = require("./imgs/plus.svg");
const minusIcon = require("./imgs/minus.svg");
const darkAddIcon = require("./imgs/dark-plus.svg");
const darkMinusIcon = require("./imgs/dark-minus.svg");

function CustomQuantityOption({
  image,
  name,
  quantity,
  onChangeAdd,
  onChangeRemove,
  calories,
  renderPrice,
  price,
  currency,
  description,
  oos,
}) {
  let paddingClass = null;

  if (name.length < 14) {
    paddingClass = styles.bottomMargin45;
  } else if (name.length > 18) {
    paddingClass = [styles.topMargin0, styles.bottomMargin15].join(" ");
  }
  return (
    <div className={`${oos === 1 ? styles.oos : ''} flex md:flex-col md:items-center w-[343px] md:w-[216px] gap-2`}>
      <div className="w-[156px] h-[156px] md:w-[216px] md:h-[216px]">
        <img
          alt={name}
          src={image}
          onError={i => {
            i.target.style.display = "none";
            return null;
          }}
          className="w-full object-contain"
        />
      </div>

      <div className="flex flex-col md:items-center md:text-center gap-2 w-[179px]">
        <h3 className={`${paddingClass} md:min-h-[46px] font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] text-dark dark:text-white`}>
          {name}
          <span className={styles.caloriesQuantity}>{calories}</span>
        </h3>
        <p className="text-darkElevationPrimary dark:text-[#B4A5BB] text-xs leading-[14px] md:min-h-[42px]">
          {description}
        </p>
        <div>
          <div className="w-[123px] flex flex-col items-center justify-center">
            {quantity ? (
              <div className="flex gap-3 items-center">
                <div
                  onClick={onChangeRemove}
                  className="w-11 h-11 rounded-full flex items-center dark:bg-dark bg-light border-[1px] border-dark dark:border-white"
                >
                  <img
                    alt="minus"
                    src={darkMinusIcon}
                    className="block dark:hidden m-auto cursor-pointer"
                  />
                  <img
                    alt="minus"
                    src={minusIcon}
                    className="hidden dark:block m-auto cursor-pointer"
                  />
                </div>
                <span className="text-dark dark:text-white font-filsonProBold text-lg leading-[22px] tracking-[-0.1px]">
                  {quantity}
                </span>
                <div
                  onClick={oos !== 1 ? onChangeAdd : () => {}}
                  className={`${ oos !== 1 ? 'border-[1px] border-dark' : '' } w-11 h-11 rounded-full flex items-center dark:bg-dark bg-light dark:border-white`}
                >
                  <img
                    alt="plus"
                    src={darkAddIcon}
                    className="block dark:hidden m-auto cursor-pointer"
                  />
                  <img
                    alt="plus"
                    src={addIcon}
                    className="hidden dark:block m-auto cursor-pointer"
                  />
                </div>
              </div>
            ) : (
              <div className="flex gap-3 items-center">
                <div
                  onClick={onChangeRemove}
                  className="w-11 h-11 rounded-full 
                    flex items-center dark:bg-darkElevationPrimary bg-[#E6DFE8]"
                >
                  <img
                    alt="minus"
                    src={darkMinusIcon}
                    className="block dark:hidden m-auto cursor-pointer"
                  />
                  <img
                    alt="minus"
                    src={minusIcon}
                    className="hidden dark:block m-auto cursor-pointer"
                  />
                </div>
                <span className="text-dark dark:text-white font-filsonProBold text-lg leading-[22px] tracking-[-0.1px]">
                  0
                </span>
                <div
                  onClick={oos !== 1 ? onChangeAdd : () => {}}
                  className={`${ oos !== 1 ? 'border-[1px] border-dark' : '' } w-11 h-11 rounded-full flex items-center dark:bg-dark bg-light dark:border-white`}
                >
                  <img
                    alt="plus"
                    src={darkAddIcon}
                    className="block dark:hidden m-auto cursor-pointer"
                  />
                  <img
                    alt="plus"
                    src={addIcon}
                    className="hidden dark:block m-auto cursor-pointer"
                  />
                </div>
              </div>
            )}
            <p className="py-1">{renderPrice(price, currency)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomQuantityOption;
